import React from 'react';

// import ActionButtonGroup from '../ActionButtonGroup';
import FilterTags from '../FilterTags';
import useStyles from './styles';

const FilterWrapper = ({ filterType, numberFilter }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapFilterContent}>
      {filterType}
      <hr className={classes.lineSeparate} />
      <FilterTags numberFilter={numberFilter} />
      {/* <ActionButtonGroup /> */}
    </div>
  );
};

export default FilterWrapper;
