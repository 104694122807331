import { TextField } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import RadioCustom from 'commons/RadioCustom';
import Text from 'commons/Text';
import { ConfirmationOptions } from 'configs/Constant';

import useStyles from './styles';

const ConfirmationOption = ({
  selectedConfirmnOption,
  piece,
  onSelectSection,
  setPiece,
  canEditArtwork = true,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChangeSection = (e) => {
    const intValue = parseInt(e.target.value, 10);

    if (e.target.value === '' || !Number.isInteger(intValue)) {
      setPiece('');
    } else {
      setPiece(intValue);
    }
  };

  return (
    <div>
      <Text
        fontWeightBold
        type="lg"
        mb={0}
        mt={16}
      >
        {t('measurement')}
      </Text>
      <Text
        type="lg"
        mb={0}
        mt={6}
      >
        {t('does_artwork_have_more_than_one_section')}
        <span className={classes.require}>*</span>
      </Text>
      <div className={classes.confirmationOptions}>
        {ConfirmationOptions.map((item, index) => (
          <RadioCustom
            item={item}
            key={index}
            keyLabel={item.id}
            value={t(item.name)}
            selectedOption={selectedConfirmnOption}
            onOptionChange={() => onSelectSection(item)}
            disabled={!canEditArtwork}
          />
        ))}
      </div>
      {selectedConfirmnOption?.id === 1 && (
        <div className={classes.wrapTextInputTotalSection}>
          <Text
            type="lg"
            mb={0}
            mt={-5}
          >
            {t('total_sections')}
            <span className={classes.require}>*</span>
          </Text>
          <TextField
            value={piece}
            onChange={handleChangeSection}
            fullWidth
            placeholder={t('please_input_total_sections')}
            className={clsx({
              [classes.disabled]: !canEditArtwork,
            })}
            disabled={!canEditArtwork}
          />
        </div>
      )}
    </div>
  );
};

export default ConfirmationOption;
