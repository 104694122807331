import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  gridOurMission: {
    '&.MuiGrid-root': {
      marginTop: 40,
      paddingLeft: 54,
      paddingRight: 40,
    },
  },
  wrapImageOurMission: {
    display: 'flex',
    gap: 16,
  },
  ourMission2: {
    width: '48%',
  },
  wrapSubImageOurMission: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: '40%',
  },
  textWhatGladiusArt: {
    color: Colors.Grey10,
    fontSize: '48px',
    fontWeight: 500,
    lineHeight: '60px',
    letterSpacing: '1.92px',
  },
  textEmpoweringArtist: {
    marginTop: 24,
    color: Colors.Grey10,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '0.8px',
  },
  textAtGladiusArt: {
    marginTop: 8,
    color: Colors.Grey10,
    fontSize: '18px',
    fontWeight: 200,
    lineHeight: '28px',
    letterSpacing: '0.72px',
  },
  textBreakTraditional: {
    marginTop: 16,
    color: Colors.Grey10,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '0.8px',
  },
}));

export default useStyles;
