import { createSlice } from '@reduxjs/toolkit';

import { setAxiosDefaultLanguage } from 'utils/utils';

const initialState = {
  currentLanguage: 'en',
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      setAxiosDefaultLanguage(action.payload);
      return {
        ...state,
        currentLanguage: action.payload,
      };
    },
  },
});

export const { changeLanguage } = languageSlice.actions;
export default languageSlice.reducer;
