import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  textArtist: {
    display: 'flex',
    alignContent: 'center',
  },
  textArtistStatusApprove: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  checkIcon: {
    '&.MuiSvgIcon-root': {
      marginLeft: 5,
      fontSize: 12,
      color: Colors.Blue6,
      marginTop: 3,
    },
  },
  closeIcon: {
    '&.MuiSvgIcon-root': {
      marginLeft: 5,
      fontSize: 12,
      color: Colors.Red,
      marginTop: 3,
    },
  },
}));

export default useStyles;
