import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
  },

  //   ArtworkInfoAttribute
  wrapAttribute: {
    padding: 24,
    border: `1px solid ${Colors.Grey3}`,
  },
  attributeItem: {
    marginTop: 10,
    '&:first-child': {
      marginTop: 0,
    },
  },
  attributeName: {
    fontSize: 14,
    minWidth: '40%',
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  textContent: {
    marginTop: 1,
    marginLeft: 8,
  },
}));

export default useStyles;
