import MenuIcon from '@mui/icons-material/Menu';
import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TESTID } from 'configs/Constant';
import LogoPlatForm from 'images/logo-platform.svg';
import Routes from 'utils/Route';

import useStyles from '../styles';

const HeaderLeftSmartPhone = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToHomePage = () => {
    navigate(Routes.Home);
    setAnchorEl(null);
  };

  const navigateToAboutUsPage = () => {
    navigate(Routes.AboutUs);
    setAnchorEl(null);
  };

  return (
    <div className={classes.wrapHeaderLeft}>
      <div className={classes.wrapGroupItem}>
        <MenuIcon
          onClick={handleClick}
          className={classes.iconMenu}
        >
          Menu
        </MenuIcon>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={navigateToHomePage}>
            <div className={classes.textItem}>{t('artists')}</div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className={classes.textItem}>{t('exhibition')}</div>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <div className={classes.textItem}>{t('gallery')}</div>
          </MenuItem>
        </Menu>

        <img
          src={LogoPlatForm}
          onClick={navigateToAboutUsPage}
          className={classes.logoPlatFormSmartPhone}
          testid={TESTID.LOGO}
        />
      </div>
    </div>
  );
};
export default HeaderLeftSmartPhone;
