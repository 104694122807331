import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapBoxContent: {
    padding: 24,
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
    borderRadius: 8,
    border: `1px solid ${Colors.Grey2}`,
    background: Colors.White,
  },
  textFieldStyle: {
    '&.MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      padding: '16px 14px',
    },
  },
  textFieldDisabled: {
    '&.MuiTextField-root': {
      background: Colors.GrayScale10,
    },
    '& fieldset': {
      borderColor: `${Colors.GrayScaleBluePalette30} !important`,
    },
  },
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
    fontWeight: 500,
  },
  wrapBoxCustom: {
    marginTop: 16,
    paddingTop: 18,
    '&:first-child': {
      marginTop: 0,
    },
  },
  scrollbarBox: {
    marginTop: 16,
    overflowY: 'scroll',
    height: 'calc(100vh - 300px)',
    '&::-webkit-scrollbar': {
      width: 8,
      height: 0,
    },
    '&::-webkit-scrollbar-thumb': {
      background: Colors.Neutral3,
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      background: Colors.Neutral,
    },
  },
  wrapCheckBox: {
    marginTop: 8,
  },
  checkBoxStyle: {
    '&.MuiCheckbox-root': {
      padding: 0,
    },
  },
  textAreaDescription: {
    minHeight: 186,
  },
  textAreaNote: {
    height: 81,
  },
  wrapFormInputDescription: {
    marginTop: 15,
  },
  textLocationName: {
    marginBottom: 5,
  },
  valueLocationNone: {
    height: 15,
  },
  formInputPeriod: {
    marginTop: 16,
  },
  textTitle: {
    marginTop: 5,
    marginBottom: 8,
    fontSize: 16,
  },
  textTitleCustom: {
    fontWeight: 500,
  },
  autocompleteField: {
    '&.MuiAutocomplete-root': {
      marginTop: 15,
    },
  },
  wrapFormInput: {
    marginTop: 0,
  },
  textSearchAgain: {
    cursor: 'pointer',
  },
  icon: {
    '&.MuiSvgIcon-root': {
      width: 16,
      height: 16,
      marginLeft: 8,
    },
  },
}));

export default useStyles;
