import { Box, Divider, Grid, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import { getTextEdition } from 'utils/utils';

import useStyles from './styles';

const GridArtworkInfo = ({ artwork, certificate }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { title, size, medium, year_created, period_created, total_edition } = artwork || {};
  const { edition_number } = certificate || {};
  const { legal_name } = certificate?.issued_by || {};

  return (
    <Box className={classes.wrapper}>
      <Grid container>
        <Grid
          item
          xs={8}
        >
          <Stack
            direction="row"
            spacing={4}
          >
            <div>
              <Text
                className={classes.textInfoArtwork}
                type="sm"
              >{`${t('title')}:`}</Text>
              <Text
                className={classes.textInfoArtwork}
                type="sm"
              >{`${t('size')}:`}</Text>
              <Text
                className={classes.textInfoArtwork}
                type="sm"
              >{`${t('medium')}:`}</Text>
              <Text
                className={classes.textInfoArtwork}
                type="sm"
              >{`${t('creation_year')}:`}</Text>
              <Text
                className={classes.textInfoArtwork}
                type="sm"
              >{`${t('edition')}:`}</Text>
            </div>

            <div>
              <Text
                fontWeightBold
                className={classes.textInfoArtwork}
                type="sm"
              >
                {title || '--'}
              </Text>
              <Text
                className={classes.textInfoArtwork}
                type="sm"
              >
                {size?.height && size?.width
                  ? size?.depth
                    ? `${size?.height} cm x ${size?.width} cm x ${size?.depth} cm`
                    : `${size?.height} cm x ${size?.width} cm`
                  : '--'}
              </Text>
              <Text
                className={classes.textInfoArtwork}
                type="sm"
              >
                {medium?.name || '--'}
              </Text>
              <Text
                className={classes.textInfoArtwork}
                type="sm"
              >
                {year_created || period_created || '--'}
              </Text>
              <Text
                className={classes.textInfoArtwork}
                type="sm"
                fontWeightBold
              >
                {getTextEdition(t, edition_number, total_edition)}
              </Text>
            </div>
          </Stack>
        </Grid>

        <Grid
          item
          xs={4}
        >
          <Text
            className={classes.textInfoArtwork}
            type="sm"
          >{`${t('artist_signature')}:`}</Text>

          <Divider className={classes.divier} />

          <Text
            mt={8}
            type="sm"
            fontWeightBold
            className={classes.letterSpacing}
          >
            {legal_name}
          </Text>

          <Stack direction="row">
            <Text
              type="xs"
              className={classes.textWidth}
            >
              {t('artist')}
            </Text>

            <Stack
              direction="row"
              sx={{ flex: 1 }}
            >
              <Text
                type="xs"
                className={classes.textDate}
              >
                {t('date')}
              </Text>

              <div className={classes.line}></div>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GridArtworkInfo;
