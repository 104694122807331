import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import '@sendbird/uikit-react/dist/index.css';
import React from 'react';
import { useSelector } from 'react-redux';

const APP_ID = process.env.REACT_APP_SENDBIRD_APP_ID;

const SendbirdProviderGate = ({ children }) => {
  const user = useSelector((state) => state.auth.account.user);

  return (
    <SendbirdProvider
      appId={APP_ID}
      userId={user?.uuid?.toString()}
      uikitOptions={{ groupChannel: { enableMention: true, enableVoiceMessage: true } }}
    >
      {children}
    </SendbirdProvider>
  );
};

export default SendbirdProviderGate;
