import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import CollectionItem from 'commons/Collection/CollectionItem';
import PaginationCustom from 'commons/PaginationCustom';
import API from 'configs/API';
import usePagination from 'hooks/usePagination';

import useStyles from './styles';

const TableShowAllCollection = () => {
  const classes = useStyles();

  const user = useSelector((state) => state.auth.account.user);
  const [searchParams] = useSearchParams();

  const objectParamsFromSearchParams = Object.fromEntries(searchParams);

  const params = {
    ...objectParamsFromSearchParams,
    owner: user?.id,
  };

  const { data: collections, isPending } = useQuery({
    queryKey: [API.ARTWORK.COLLECTION, params],
  });

  const { page, totalPage, onPageChange } = usePagination(
    collections?.count,
    collections?.page_size,
  );

  const handlePageChange = (_, page) => {
    onPageChange(page);
  };

  if (isPending) {
    return;
  }

  const listCollection = collections?.results || [];

  return (
    <div>
      <Grid
        container
        spacing={5}
      >
        {listCollection?.map((collection, index) => (
          <Grid
            key={index}
            item
            xs={12}
            lg={3}
          >
            <CollectionItem collection={collection} />
          </Grid>
        ))}
      </Grid>

      {!!totalPage && (
        <PaginationCustom
          paginationStyle={classes.pagination}
          count={totalPage}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default TableShowAllCollection;
