import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapBox: {
    marginTop: 70,
  },

  wrapListArtwork: {
    marginTop: 18,
  },
  image: {
    height: 200,
    borderRadius: 0,
    cursor: 'pointer',
  },
}));

export default useStyles;
