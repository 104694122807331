import Button from '@mui/material/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import AuthLayout from 'commons/Auth/AuthLayout';
import Header from 'commons/Header';
// import LoginSocial from 'commons/LoginSocial';
import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import Routes from 'utils/Route';

import FormInput from '../../commons/Form/FormInput';
import FormInputPassword from '../../commons/Form/FormInputPassword';
import LoginHeader from './components/LoginHeader';
// import PrivacyPolicy from './components/PrivacyPolicy';
import useLogin from './hook/useLogin';
import useStyles from './styles';

const Login = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.account.user);
  const { state } = useLocation();

  const {
    username,
    password,

    setUsername,
    setPassword,

    onKeyDown,
    handleSubmit,
  } = useLogin();

  if (isLoggedIn) {
    const redirectTo = state?.redirectTo || Routes.GalleryDetail(user?.uuid);

    return (
      <Navigate
        to={redirectTo}
        replace
      />
    );
  }

  return (
    <div>
      <Header
        isPageLogin
        wrapHeaderStyle={classes.wrapHeaderStyle}
      />
      <AuthLayout>
        <div className={classes.wrapForm}>
          <LoginHeader redirectTo={state?.redirectTo} />

          <FormInput
            value={username}
            label={t('username')}
            placeholder={t('placeholder_username')}
            onKeyDown={onKeyDown}
            onChange={(e) => setUsername(e.target.value)}
            testid={TESTID.INPUT_PHONE_NUMBER}
          />

          <FormInputPassword
            label={t('password')}
            placeholder={t('placeholder_password')}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={onKeyDown}
          />

          {/* <Link className={classes.linkForgotPassword}>{t('forgot_password')}</Link> */}

          <Button
            variant="contained"
            fullWidth
            className={classes.buttonSignUp}
            onClick={handleSubmit}
            data-testid={TESTID.BUTTON_LOGIN}
          >
            <Text>{t('login')}</Text>
          </Button>

          {/* <div className={classes.line} /> */}

          {/* <LoginSocial /> */}

          {/* <PrivacyPolicy /> */}
        </div>
      </AuthLayout>
    </div>
  );
};

export default Login;
