import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
    fontWeight: 500,
  },
  textFieldStyle: {
    '&.MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      padding: '16px 14px',
    },
  },
}));

export default useStyles;
