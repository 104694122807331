import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import DialogCustom from 'commons/DialogCustom';
import GroupActionButton from 'commons/GroupActionButton';
import TermAndCondition from 'features/Certificate/TermAndCondition';
import useUploadImageS3 from 'hooks/useUploadImageS3';
import Routes from 'utils/Route';
import { ToastTopHelper } from 'utils/utils';

import useRequestCertificate from './hooks/useSendCertificateRequest';
import useStyles from './styles';

const termSchema = yup.object().shape({
  checked: yup.boolean().required().oneOf([true], 'Please check agree Terms and Conditions.'),
});

const SendCertificateRequest = ({ onBackStep, artworkFormState, listUploadedImages }) => {
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      checked: false,
    },
    mode: 'onChange',
    resolver: yupResolver(termSchema),
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.account.user);

  const { watch } = artworkFormState;
  const { artworkId } = useParams();
  const { uploadListImage } = useUploadImageS3();
  const { mutate: requestCertificate } = useRequestCertificate(artworkId);

  const [isShowDialogClose, setIsShowDialogClose] = useState(false);
  const [isShowDialogSend, setIsShowDialogSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const data = watch();
  const artistName = data?.selectedUser?.name;

  const handleSendRequestCertificate = () => {
    setIsShowDialogSend(true);
  };

  const handelCancelRequestCertificate = () => {
    setIsShowDialogClose(true);
  };

  const createCertificateRequest = async () => {
    let imagesKey = listUploadedImages.map((image) => image?.imageKey);

    if (!data?.selectedUser?.id) {
      return;
    }

    const formData = {
      artwork: {
        id: artworkId,
        title: data?.titleArtwork,
        size: {
          height: data?.height,
          width: data?.width,
          depth: data?.depth,
        },
        period_year: data?.periodYear,
        year_created: data?.yearCreated,
        total_edition: data?.editionVolume,
        medium: data?.mediumArtWork,
        tag_request: {
          request_by: data?.tagRequest?.request_by?.id,
          request_to: data?.tagRequest?.request_to?.id,
        },
      },
      edition: data?.edition,
      owner_info: {
        name: data?.ownerName,
        year_of_birth: data?.yearOfBirth,
        address: data?.ownerAddress,
      },
      shipping_info: {
        recipient: data?.recipient,
        address: data?.address,
        phone_number: data?.phoneNumber,
      },
      request_to: data?.selectedUser?.id,
      images: imagesKey,
    };

    requestCertificate(formData, {
      onSuccess: () => {
        ToastTopHelper.success(t('request_certificate_success'));
        navigate({
          pathname: Routes.ManageCertificate,
          search: createSearchParams({ tab: 1 }).toString(),
        });
      },
    });
  };

  const handleSubmitlDialogSend = async () => {
    setLoading(true); //TODO refactor move to reuse logic

    const uploadS3Response = await uploadListImage(listUploadedImages);
    const allSuccessful = uploadS3Response.every((response) => response?.success === true);

    if (allSuccessful) {
      createCertificateRequest();
    } else {
      setIsShowDialogSend(false);
      ToastTopHelper.error(t('upload_file_failed'));
    }

    setIsShowDialogSend(false);
    setLoading(false);
  };

  const handleCancelDialogSend = () => {
    setIsShowDialogSend(false);
  };

  const handleCancelDialogClose = () => {
    setIsShowDialogClose(false);
  };

  const handleSubmitlDialogClose = () => {
    setIsShowDialogClose(false);
    navigate(Routes.GalleryDetail(user?.uuid));
  };

  return (
    <div>
      <TermAndCondition
        control={control}
        wrapTermStyle={classes.wrapTerm}
        helperText={formState?.errors.checked?.message}
        error={!!formState?.errors.checked}
      />
      <GroupActionButton
        mainButtonText={t('save_and_send_request')}
        subButtonLeftText={t('cancel')}
        subButtonRightText={t('back')}
        onMainButtonClick={handleSubmit(handleSendRequestCertificate)}
        onSubButtonRight={onBackStep}
        onSubButtonLeft={handelCancelRequestCertificate}
        groupActionButtonWrapStyle={classes.groupActionButonWrap}
      />

      <DialogCustom
        isShowDialog={isShowDialogClose}
        dialogTitle={t('cancel_request')}
        textCancel={t('continue')}
        textSubmit={t('yes_canccel')}
        onCancel={handleCancelDialogClose}
        onSubmit={handleSubmitlDialogClose}
        maxWidth={'md'}
        submitButtonStyle={classes.buttonDialog}
        cancelButtonStyle={classes.buttonDialog}
      >
        <div>{t('are_you_sure_exit_certificate')}</div>
      </DialogCustom>

      <DialogCustom
        isShowDialog={isShowDialogSend}
        dialogTitle={t('send_certificate_request')}
        textCancel={t('cancel')}
        textSubmit={t('send')}
        onCancel={handleCancelDialogSend}
        onSubmit={handleSubmitlDialogSend}
        maxWidth={'md'}
        loading={loading}
        disabledButton={loading}
        submitButtonStyle={classes.buttonDialog}
        cancelButtonStyle={classes.buttonDialog}
      >
        <div>{t('are_you_sure_send_certificate_request', { artistName: artistName })}</div>
      </DialogCustom>
    </div>
  );
};

export default SendCertificateRequest;
