import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

import BackGroundAboutUS from '../../images/background-about-us.png';

const useStyles = makeStyles(() => ({
  background: {
    width: '100%',
    height: '95vh',
    backgroundImage: `url(${BackGroundAboutUS})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textWelcomeTo: {
    marginTop: 40,
    color: Colors.White,
    textAlign: 'center',
    fontSize: '40px',
    fontWeight: 250,
    lineHeight: '60px',
    letterSpacing: '32px',
  },
  textGladiusArt: {
    marginTop: 40,
  },
  textVNleading: {
    marginTop: 40,
    color: Colors.White,
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 250,
    lineHeight: '32px',
    letterSpacing: '0.96px',
  },
  wrapContentMoreThanPlatform: {
    width: '100%',
    backgroundColor: Colors.Gray3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '48px 269px',
  },
  textMoreThanOnlinePlatform: {
    color: Colors.Grey10,
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '40px',
    letterSpacing: '0.96px',
  },
  wrapTab: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: Colors.Gray3,
  },
  tabList: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    boxShadow: 'none',
  },
  tabPanel: {
    width: '100%',
    backgroundColor: 'white',
    padding: '0 !important',
  },

  [mediaQueries.mobile]: {
    textItemTab: {
      '&.MuiTab-root': {
        fontSize: 6,
      },
    },
    wrapContentMoreThanPlatform: {
      padding: 0,
    },
  },
}));

export default useStyles;
