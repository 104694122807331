import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const SelectCustom = ({
  options,
  value,
  onChange,
  valueKey,
  textDisplayKey,
  label,
  placeholder,
  isRequired,
  formControlStyle,
  labelStyle,
  selectStyle,
  disabled = false,
  helperText,
  error,
  children,
  ...props
}) => {
  const classes = useStyles();

  const isEmptyValue = !value && value !== 0;

  const renderPlacholder = () => {
    return (
      <Text
        className={classes.placeholder}
        type="sm"
      >
        {placeholder}
      </Text>
    );
  };

  return (
    <FormControl
      fullWidth
      className={clsx(classes.formControlStyle, formControlStyle)}
    >
      <FormLabel
        required={isRequired}
        className={clsx(
          classes.formLabel,
          { [classes.formLabelSpace]: label || isRequired },
          labelStyle,
        )}
      >
        {label}
      </FormLabel>

      <Select
        value={value}
        onChange={onChange}
        className={clsx(classes.select, selectStyle)}
        IconComponent={KeyboardArrowDownIcon}
        displayEmpty
        renderValue={isEmptyValue ? renderPlacholder : undefined}
        disabled={disabled}
        error={error}
        {...props}
      >
        {options?.map((option) => (
          <MenuItem
            key={option[valueKey]}
            value={option[valueKey]}
          >
            {option[textDisplayKey]}
          </MenuItem>
        ))}

        {children}
      </Select>

      {helperText && (
        <Text
          type="xs"
          ml={5}
        >
          <FormLabel error={error}>{helperText}</FormLabel>
        </Text>
      )}
    </FormControl>
  );
};

export default SelectCustom;
