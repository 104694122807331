import { Box, Divider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import VisibleOption from 'commons/VisibleOption';
import { ReactComponent as IconGlobalLine } from 'images/icon-global-line.svg';
import { ReactComponent as IconPrivate } from 'images/icon-private.svg';

import BoxSection from '../../BoxSection';
import useStyles from './styles';

const CollectionVisible = ({ collectionFormState }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { setValue, watch } = collectionFormState;
  const isCollectionVisible = watch('isCollectionVisible');

  const handlePublicCollection = () => {
    setValue('isCollectionVisible', true);
  };

  const handlePrivateCollection = () => {
    setValue('isCollectionVisible', false);
  };

  return (
    <BoxSection
      sx={{ mt: 2 }}
      heading={t('collection_visibility')}
    >
      <Text
        mt={8}
        type="sm"
      >
        {t('control_how_collection_is_viewed')}
      </Text>

      <Box sx={{ mt: 1 }}>
        <VisibleOption
          isActive={isCollectionVisible}
          onClick={handlePublicCollection}
          title={t('public')}
          description={t('collection_visible_everyone')}
          icon={<IconGlobalLine />}
        />

        <Divider className={classes.divier} />

        <VisibleOption
          isActive={!isCollectionVisible}
          onClick={handlePrivateCollection}
          title={t('private')}
          description={t('only_you_see_this_collection')}
          icon={<IconPrivate />}
        />
      </Box>
    </BoxSection>
  );
};

export default CollectionVisible;
