import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import VisibilityOption from 'commons/BoxStatusArtwork/components/VisibilityOption';
import PriceInput from 'commons/PriceInput';
import Text from 'commons/Text';
import { OptionsCurrency } from 'configs/Constant';
import { useArtworkContext } from 'pages/UploadArtwork/ArtworkContext/useArtworkContext';

const StatusPriceRoleCollector = ({ status }) => {
  const { t } = useTranslation();

  const { artworkData, updateArtwork } = useArtworkContext();
  const { price, currency, visibility } = artworkData;

  const handleChangeCurrency = (e) => {
    const newCurrency = e.target.value;
    updateArtwork({ currency: newCurrency });
  };

  const handleChangeCostPrice = (e) => {
    const newPrice = e.target.value;
    if (newPrice < 0 || newPrice?.length > 12) {
      return;
    }
    updateArtwork({ price: newPrice });
  };

  const handleChangeVisibility = (e, newValue) => {
    updateArtwork({
      visibility: newValue,
    });
  };

  useEffect(() => {
    updateArtwork({ isAskPrice: false, isHidePrice: false, price: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Text
          mt={14}
          fontWeightBold
          type="lg"
        >
          {t('cost_price')}
        </Text>

        <PriceInput
          options={OptionsCurrency}
          price={price}
          currency={currency}
          onChangePrice={handleChangeCostPrice}
          onCurrencyChange={handleChangeCurrency}
          inputProps={{
            'aria-label': t('cost_price'),
          }}
        />
      </Box>

      <VisibilityOption
        value={visibility}
        onChange={handleChangeVisibility}
      />
    </>
  );
};

export default StatusPriceRoleCollector;
