import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapListArtist: {
    marginTop: 24,
  },
  wrapItemImage: {
    height: 330,
    textAlign: 'center !important',
    marginTop: -5,
    cursor: 'pointer',
  },
  image: { height: 242, borderRadius: 0 },
  imageNotDisplay: { height: 242, borderRadius: 0, backgroundColor: Colors.Grey11 },
  avatarProfile: {
    '&.MuiAvatar-root': {
      width: 64,
      height: 64,
      border: `1px solid ${Colors.White}`,
    },
  },
  wrapAvatar: {
    width: '100%',
    transform: 'translateY(-55%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textNameProfile: {
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.64px',
    textAlign: 'center !important',
    marginTop: -30,
  },
  textNickNameProfile: {
    textAlign: 'center !important',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.56px',
    marginTop: 5,
    color: Colors.Grey8,
  },
  pagination: {
    '&.MuiPagination-root': {
      marginTop: 40,
      display: 'flex',
      justifyContent: 'center',
    },
  },

  [mediaQueries.laptopLarge]: {
    wrapItemImage: {
      height: 300,
    },
    image: { height: 220 },
    imageNotDisplay: { height: 220 },
  },
}));

export default useStyles;
