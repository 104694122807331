import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DecimalInput from 'commons/DecimalInput';
import Text from 'commons/Text';

import useStyles from './styles';

const CollectorEditionUIView = ({
  editionNumber,
  onEditionNumberChange,
  editionVolume,
  onEditionVolumeChange,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Text
        fontWeightBold
        type="lg"
        mt={16}
      >
        {t('editions')}
        <span className={classes.require}>*</span>
      </Text>

      <Stack
        direction="row"
        sx={{ mt: 1, alignItems: 'center' }}
      >
        <div className={classes.flexItem}>
          <Text
            type="sm"
            mb={4}
          >
            {t('edition_number')}
          </Text>

          <DecimalInput
            value={editionNumber}
            onChange={onEditionNumberChange}
            inputProps={{
              type: 'text',
            }}
          />
        </div>

        <div className={classes.lineSeparate}>/</div>

        <div className={classes.flexItem}>
          <Text
            type="sm"
            mb={4}
          >
            {t('edition_volume')}
          </Text>

          <DecimalInput
            value={editionVolume}
            onChange={onEditionVolumeChange}
            inputProps={{
              type: 'text',
            }}
          />
        </div>
      </Stack>
    </>
  );
};

export default CollectorEditionUIView;
