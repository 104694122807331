import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  image: {
    width: 'auto',
    maxWidth: '100%',
    maxHeight: 306,
    height: 306,
    marginTop: 24,
    objectFit: 'contain',
  },
  loadingImage: {
    '&.MuiSkeleton-root': {
      width: '50%',
      height: 306,
    },
  },
  iconImage: {
    '&.MuiSvgIcon-root': {
      left: '25%',
    },
  },
  errorStyle: {
    width: '50%',
    height: 306,
  },
}));

export default useStyles;
