import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  container: {
    paddingLeft: 54,
    paddingRight: 45,
  },
  textDiscover: {
    marginTop: 35,
    color: Colors.Grey10,
    fontSize: '18px',
    fontWeight: 250,
    lineHeight: '28px',
    letterSpacing: '0.72px',
    marginBottom: 40,
  },

  gridOurMission: {
    '&.MuiGrid-root': {
      marginTop: 40,
      paddingLeft: 54,
      paddingRight: 40,
      marginBottom: 40,
    },
  },
  wrapImageMainFeature: {
    display: 'flex',
    gap: 16,
  },
  mainFeature1: {
    width: '52%',
  },
  mainFeatureRight1: {
    width: '52%',
    marginLeft: 41,
  },
  wrapSubImageMainFeature: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '43%',
    gap: 16,
  },
  wrapDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
  },
  wrapDescriptionRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    paddingRight: 5,
  },
  textContent: {
    color: Colors.Black,
    fontSize: '36px',
    fontWeight: 400,
    lineHeight: '44px',
    letterSpacing: '1.44px',
  },
  textSubContent: {
    fontSize: '18px',
    fontWeight: 250,
    lineHeight: '28px',
    letterSpacing: '0.72px',
    marginTop: 5,
  },
  grid: {
    '&.MuiGrid-root': {
      marginTop: 80,
    },
  },
  button: {
    '&.MuiButton-root': {
      padding: '12px 20px',
      display: 'flex',
      alignItems: 'center',
      width: '30%',
      whiteSpace: 'nowrap',
      marginTop: 20,
    },
  },

  [mediaQueries.laptopLarge]: {
    wrapSubImageMainFeature: {
      width: '41.5%',
    },
    mainFeatureRight1: {
      marginLeft: 32,
    },
    button: {
      '&.MuiButton-root': {
        width: '45%',
        whiteSpace: 'normal',
      },
    },
  },

  [mediaQueries.tabletMedium]: {
    mainFeatureRight1: {
      marginLeft: 0,
    },
    button: {
      '&.MuiButton-root': {
        whiteSpace: 'normal',
      },
    },
  },
}));

export default useStyles;
