import React from 'react';

import SelectUserCustomRequestCer from 'commons/SelectUserCustomRequestCer';
import OwnerInfoRequestCer from 'features/Certificate/OwnerInfoRequestCer';
import ShippingInfo from 'features/Certificate/ShippingInfo';

import ArtworkInfo from './components/ArtworkInfo';

const MainContentWrapper = ({
  artworkFormState,
  artwork,
  images,
  keyImages,
  listUploadedImages,
  setListUploadedImages,
  mergedListImages,
  setMergedListImages,
  ownerInfo,
}) => {
  const { register, formState } = artworkFormState;

  return (
    <>
      <SelectUserCustomRequestCer artworkFormState={artworkFormState} />

      <ArtworkInfo
        images={images}
        keyImages={keyImages}
        artwork={artwork}
        artworkFormState={artworkFormState}
        listUploadedImages={listUploadedImages}
        setListUploadedImages={setListUploadedImages}
        mergedListImages={mergedListImages}
        setMergedListImages={setMergedListImages}
      />
      <OwnerInfoRequestCer
        ownerName={ownerInfo?.name}
        yearOfBirth={ownerInfo?.year_of_birth}
        address={ownerInfo?.address}
      />
      <ShippingInfo
        errors={formState?.errors}
        register={register}
      />
    </>
  );
};

export default MainContentWrapper;
