import API from 'configs/API';
import { axiosPost } from 'utils/apis/axios';

const useInviteAndTrackingInvitation = () => {
  const handleInviteUser = async ({
    recipient,
    password,
    artworkId,
    certificateId,
    certificateRequestId,
  }) => {
    await axiosPost(API.AUTH.USER_INVITATION, {
      recipient: recipient.id,
      email: recipient.email,
      password: password,
      role_recipient: recipient.role,
      artwork: artworkId,
      certificate: certificateId,
      certificate_request: certificateRequestId,
    });
  };

  return {
    handleInviteUser,
  };
};

export default useInviteAndTrackingInvitation;
