import { TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TableColumn from 'commons/TableColumn';

import useStyles from './styles';

const TableArtworkCollectorHead = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableColumn
          text={t('#')}
          colStyle={classes.columnMini}
        />

        <TableColumn
          text={t('title')}
          colStyle={classes.columnLongWidth}
        />

        <TableColumn
          text={t('visibility')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('artist')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('medium')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('year')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('measurement')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('cost_price')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('contract_validate')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('location')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('status')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('action')}
          colStyle={classes.columnWidth}
        />
      </TableRow>
    </TableHead>
  );
};

export default TableArtworkCollectorHead;
