import { Autocomplete, Box, createFilterOptions, FormLabel, TextField } from '@mui/material';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import Text from 'commons/Text';
import InfoUser from 'features/Artwork/InfoUser';

import useStyles from './styles';

const ListboxComponent = forwardRef(({ titleList, children, ...props }, ref) => {
  const classes = useStyles();

  return (
    <div
      {...props}
      ref={ref}
    >
      <Text
        className={classes.textArtistActive}
        fontWeightBold
      >
        {titleList}
      </Text>
      {children}
    </div>
  );
});

const AutocompleteSearchUser = ({
  userFilter,
  onInputChange,
  PaperComponent,
  onChange,
  placeholder,
  titleList,
  error,
  helperText,
  disabled,
  autocompleteStyle,
  selectUser,
  ...props
}) => {
  const classes = useStyles();

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.legal_name} ${option.name}`,
  });

  return (
    <div>
      <Autocomplete
        options={userFilter || []}
        getOptionLabel={(option) => option?.legal_name || option?.name || ''}
        value={selectUser || null}
        onChange={onChange}
        onInputChange={onInputChange}
        className={clsx(classes.autocompleteField, autocompleteStyle)}
        PaperComponent={PaperComponent}
        disabled={disabled}
        filterOptions={filterOptions}
        ListboxComponent={forwardRef((props, ref) => (
          <ListboxComponent
            {...props}
            titleList={titleList}
            ref={ref}
          />
        ))}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <Box
              key={key}
              {...optionProps}
            >
              <InfoUser
                user={option}
                isShowClearIcon={false}
              />
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            error={error}
          />
        )}
        {...props}
      />

      {helperText && (
        <Text
          type="xs"
          ml={5}
        >
          <FormLabel error={error}>{helperText}</FormLabel>
        </Text>
      )}
    </div>
  );
};

export default AutocompleteSearchUser;
