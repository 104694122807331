import { useMutation } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPost } from 'utils/apis/axios';

const likeArtwork = async (id) => {
  const { data } = await axiosPost(false, API.ARTWORK.LIKE_ARTWORK(id));
  return data;
};

const useLikeArtwork = () => {
  return useMutation({
    mutationFn: likeArtwork,
  });
};

export default useLikeArtwork;
