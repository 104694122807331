import { Container } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Breadcrumbs from 'commons/Breadcrumbs';
import Routes from 'utils/Route';

import InfoReviewCertificateRequest from './components/InfoReviewCertificateRequest';
import useStyles from './styles';

const CollectorReviewCertificateRequest = ({ data, user, breadCrumbs }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isUserRequestBy = data?.request_by?.id === user?.id;
  if (!isUserRequestBy) {
    navigate(Routes.PageNotFound, { replace: true });
  }

  return (
    <main className={classes.wrapPage}>
      <Container>
        <Breadcrumbs items={breadCrumbs} />
        <h1 className={classes.heading}>{t('review_certificate_request')}</h1>
        <InfoReviewCertificateRequest data={data} />
      </Container>
    </main>
  );
};

export default CollectorReviewCertificateRequest;
