import { makeStyles } from '@mui/styles';

import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  breadCrumbs: {
    '&.MuiBreadcrumbs-root': {
      marginTop: 16,
    },
  },
  search: {
    width: 320,
  },
  container: {
    '&.MuiContainer-root': {
      paddingBottom: 40,
    },
  },

  [mediaQueries.mobile]: {
    heading: {
      fontSize: `1.6rem !important`,
    },
  },
}));

export default useStyles;
