import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapMyAccountPage: {
    paddingTop: 24,
    background: Colors.Grey1,
    flex: 1,
  },
  heading: {
    marginTop: 16,
    color: Colors.Grey10,
    textTransform: 'capitalize',
  },
}));

export default useStyles;
