import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  wrapUserInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: 12,
  },
  WrapNameArtist: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textNameArtist: {
    marginRight: 6,
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: 0.64,
  },
  textId: {
    fontSize: 14,
    lineHeight: '142.857%',
    letterSpacing: 0.56,
    color: Colors.Grey6,
  },
  iconClear: {
    '&.MuiSvgIcon-root': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
