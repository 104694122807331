import { List, ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import React from 'react';

import { ReactComponent as IconMenuExtend } from '../../images/icon_menu_extend.svg';
import { ReactComponent as IconMenuNotExtend } from '../../images/icon_menu_not_extend.svg';

const ToggleSideBar = ({ onToggle, open }) => {
  return (
    <List sx={{ pt: 0 }}>
      <ListItem sx={{ p: 0 }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'flex-end' : 'center',
            px: 2.5,
            pt: 0,
          }}
          onClick={onToggle}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              justifyContent: 'center',
            }}
          >
            {open ? <IconMenuExtend /> : <IconMenuNotExtend />}
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    </List>
  );
};

export default ToggleSideBar;
