import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import useDisplayText from 'commons/FilterArtwork/hooks/useDisplayText';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, LOCATION_KEY } from 'configs/FilterArtwork';

import CommonTag from '../CommonTag';

const { LOCATION } = ARTWORK_FILTER_PARAMS;

const LocationTag = () => {
  const { data: locations = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.LOCATION],
  });
  const getDisplayText = useDisplayText(LOCATION_KEY.DISPLAY);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedLocationParams = searchParams.get(LOCATION)?.split(',') || [];
  const selectedLocations = locations.filter((location) =>
    selectedLocationParams.includes(location[LOCATION_KEY.CHECKED]?.toString()),
  );

  const handleRemoveCategory = (location) => {
    const newSelectedLocationParams = [...selectedLocationParams].filter(
      (item) => item !== location[LOCATION_KEY.CHECKED]?.toString(),
    );

    if (newSelectedLocationParams.length) {
      searchParams.set(LOCATION, newSelectedLocationParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(LOCATION);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      {selectedLocations.map((location) => (
        <CommonTag
          key={location[LOCATION_KEY.CHECKED]}
          text={getDisplayText(location)}
          onRemoveFilter={() => handleRemoveCategory(location)}
        />
      ))}
    </>
  );
};

export default LocationTag;
