import { Box } from '@mui/material';
import React, { useEffect } from 'react';

import { useArtworkContext } from 'pages/UploadArtwork/ArtworkContext/useArtworkContext';

import VisibilityOption from '../VisibilityOption';

const StatusPublic = () => {
  const { artworkData, updateArtwork, setValidationStatusPrice } = useArtworkContext();
  const { visibility } = artworkData;

  const handleChangeVisibility = (e, newValue) => {
    updateArtwork({
      visibility: newValue,
    });
  };

  useEffect(() => {
    setValidationStatusPrice(false);
    updateArtwork({ isAskPrice: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ mt: 3 }}>
      <VisibilityOption
        value={visibility}
        onChange={handleChangeVisibility}
      />
    </Box>
  );
};

export default StatusPublic;
