import { Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';

import API from 'configs/API';

import CollectionArtworkList from './CollectionArtworkList';
import CollectionHeader from './CollectionHeader';
import CollectionsSameArtist from './CollectionsSameArtist';

const CollectionDetail = () => {
  const { id } = useParams();

  const { data: collection, isPending } = useQuery({
    queryKey: [API.ARTWORK.COLLECTION_DETAIL(id)],
  });

  if (isPending) {
    return;
  }

  return (
    <Container sx={{ mt: 5 }}>
      <CollectionHeader collection={collection} />
      <CollectionArtworkList collection={collection} />
      <CollectionsSameArtist
        ownerId={collection?.owner?.id}
        collectionId={collection?.id}
      />
    </Container>
  );
};

export default CollectionDetail;
