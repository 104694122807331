import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import CertificateAutoView from 'commons/CertificateAutoView';
import { useRequestCertificateContext } from 'contexts/RequestCertificateContext';
import GridLayout from 'layouts/GridLayout';

import DeliveryMainContent from './DeliveryMainContent';

const DeliveryCertificateStep = ({ onBackStep, artwork }) => {
  const { artworkInfo } = useRequestCertificateContext();
  const user = useSelector((state) => state.auth.account.user);

  const certificate = {
    edition_number: artworkInfo?.edition?.edition_number,
    issued_by: user,
  };

  return (
    <Box sx={{ pt: 3 }}>
      <GridLayout>
        <DeliveryMainContent
          onBackStep={onBackStep}
          user={user}
        />

        <CertificateAutoView
          artwork={artwork}
          certificate={certificate}
        />
      </GridLayout>
    </Box>
  );
};

export default DeliveryCertificateStep;
