import { Box } from '@mui/material';
import React from 'react';

import EditArtworkEdition from '../EditArtworkEdition';
import EditArtworkImages from '../EditArtworkImages';
import EditArtworkInfo from '../EditArtworkInfo';

const EditArtworkDetailBlock = ({ artworkFormState, artwork }) => {
  return (
    <Box>
      <EditArtworkEdition artworkFormState={artworkFormState} />
      <EditArtworkInfo
        artworkFormState={artworkFormState}
        artwork={artwork}
      />
      <EditArtworkImages artworkFormState={artworkFormState} />
    </Box>
  );
};

export default EditArtworkDetailBlock;
