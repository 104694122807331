import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  tableNoBorder: {
    '& .MuiTableCell-body': {
      borderBottom: 'none',
    },
  },
  tableRowBorder: {
    borderBottom: `1px solid ${Colors.Grey3}`,
  },
  tableEvenRow: {
    '& .MuiTableCell-body': {
      background: Colors.Grey1,
    },
  },
  wrapIcon: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  iconOutLine: {
    '&.MuiSvgIcon-root': {
      width: 20,
      height: 20,
      cursor: 'pointer',
      marginRight: 45,
    },
  },
  popover: {
    '& .MuiPopover-paper': {
      padding: 8,
    },
  },
  textItemPopover: {
    fontSize: 14,
    margin: 8,
    cursor: 'pointer',
  },
  buttonDialog: {
    '&.MuiButton-root': {
      minWidth: 90,
      padding: '10px 16px',
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: 0.64,
    },
  },
  buttonDanger: {
    '&.MuiButton-root': {
      marginLeft: 16,
      background: Colors.Error,
      '&:hover': {
        background: Colors.Error,
      },
    },
  },
  disable: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  textDelete: {
    color: 'red',
  },
}));

export default useStyles;
