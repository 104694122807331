import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  button: {
    '&.MuiButton-root': {
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      border: `1px solid ${Colors.Primary}`,
    },
  },
}));

export default useStyles;
