import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapForm: {
    width: 464,
    background: Colors.White,
    borderRadius: 16,
    padding: 40,
  },
  buttonSignUp: {
    '&.MuiButton-root': {
      textTransform: 'none',
      padding: 12,
    },
  },
  line: {
    border: `1px solid ${Colors.Grey4}`,
    marginTop: 28,
  },
  wrapLayoutStyle: {
    height: '93vh',
  },
  rowStyle: {
    height: '91vh',
  },
  textChooseAccount: {
    fontSize: '2.4rem',
    lineHeight: '133.333%',
    letterSpacing: '0.96px',
  },
  wrapTextTitle: {
    marginBottom: 16,
  },
  textNeedMoreInfo: {
    fontSize: '1.6rem',
    lineHeight: '150%',
    letterSpacing: '0.64px',
  },
  itemAccountType: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: 12,
    border: `1px solid ${Colors.Gray5}`,
    '&.selected': {
      border: `1px solid ${Colors.Black}`,
    },
    cursor: 'pointer',
    marginBottom: 16,
  },
  wrapIconItem: {
    display: 'flex',
    alignItems: 'center',
  },
  textItemAccountType: {
    fontSize: '1.6rem',
    lineHeight: '150%',
    letterSpacing: '0.64px',
    marginLeft: 16,
  },

  [mediaQueries.laptopLarge]: {
    wrapLayoutStyle: {
      height: '91.5vh',
    },
  },
  [mediaQueries.laptop]: {
    wrapLayoutStyle: {
      height: '92vh',
    },
    rowStyle: {
      height: '90vh',
    },
  },
  [mediaQueries.tabletMedium]: {
    wrapForm: {
      width: '100%',
    },
  },
  [mediaQueries.mobile]: {
    wrapForm: {
      padding: 30,
    },
  },
}));

export default useStyles;
