import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from '../styles';

const ArtistAbout = ({ aboutArtist }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);
  const [numberOfLines, setNumberOfLines] = useState(0);
  const maxLines = 3;

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    const lines = aboutArtist?.split('\n');
    setNumberOfLines(lines?.length);
  }, [aboutArtist]);

  if (!aboutArtist) {
    return null;
  }

  return (
    <div>
      <Box className={classes.wrapArtistAbout}>
        <Text
          type="xl"
          fontWeightBold
        >
          {t('about_artist')}
        </Text>
        <Text
          className={classes.textAbout}
          type="md"
        >
          {showMore || numberOfLines <= maxLines
            ? aboutArtist
            : `${aboutArtist?.split('\n')?.slice(0, maxLines)?.join('\n')}...`}
        </Text>

        {numberOfLines > maxLines && (
          <div className={classes.textSeeMoreText}>
            <Text onClick={toggleShowMore}>{showMore ? t('show_less') : t('see_more')}</Text>
          </div>
        )}
      </Box>
    </div>
  );
};

export default ArtistAbout;
