import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import Routes from 'utils/Route';

import useStyles from './styles';

const GroupButtonRejectCertificate = ({ message }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(Routes.ManageCertificate);
  };

  return (
    <div>
      <Box className={classes.boxGroupButton}>
        <h2 className={classes.textReject}>{t('request_rejected')}</h2>
        <Text
          className={classes.textMessage}
          data-testid={TESTID.REJECT_MESSAGE}
        >
          {message}
        </Text>
      </Box>
      <Box className={classes.boxGroupButton}>
        <Button
          variant="outlined"
          fullWidth
          className={classes.buttonCancel}
          onClick={handleCancel}
          data-testid={TESTID.BUTTON_CANCEL}
        >
          {t('cancel')}
        </Button>
      </Box>
    </div>
  );
};

export default GroupButtonRejectCertificate;
