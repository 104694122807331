import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    background: Colors.Grey1,
    paddingBottom: 40,
  },
}));

export default useStyles;
