import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapItemWebsite: {
    display: 'flex',
    marginTop: 16,
  },
  iconGlobal: {
    color: Colors.Grey3,
    width: 24,
    height: 24,
  },
  linkItem: {
    '&.MuiLink-root': {
      fontSize: '16px',
      color: 'Colors.Grey8',
      marginLeft: '16px',
      fontStyle: 'normal',
      lineHeight: '24px',
      letterSpacing: '0.64px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  textSeeMore: {
    fontSize: 16,
    color: Colors.Blue6,
    marginTop: 10,
    cursor: 'pointer',
  },
}));

export default useStyles;
