import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 0,
  },
  autoComplete: {
    '&.MuiAutocomplete-root': {
      width: '46%',
      marginTop: 24,
    },
    '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
      padding: '12px 8px',
    },
    '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
      padding: 0,
    },
  },
}));

export default useStyles;
