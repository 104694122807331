import { FormHelperText } from '@mui/material';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const TextArea = (
  {
    value,
    onChange,
    placeholder,
    maxLength,
    textMaxLength,
    textAreaStyle,
    error,
    helperText,
    wrapTextStyle,
    ...props
  },
  ref,
) => {
  const classes = useStyles();

  return (
    <>
      <div className={clsx(classes.wrapTextArea, wrapTextStyle)}>
        <textarea
          className={clsx(classes.textArea, textAreaStyle, {
            [classes.textAreaError]: error,
          })}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={maxLength}
          ref={ref}
          {...props}
        />

        {textMaxLength && (
          <div className={classes.textLengthStatement}>
            <Text>{`${value?.length || 0}/${textMaxLength}`}</Text>
          </div>
        )}
      </div>

      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </>
  );
};

export default forwardRef(TextArea);
