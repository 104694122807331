import React from 'react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import IosSwitch from 'commons/IosSwitch';

import EditArtworkSection from '../../../../commons/BoxSection';

const EditArtworkCertificate = ({ artworkFormState }) => {
  const { t } = useTranslation();

  const { control } = artworkFormState;

  const { field } = useController({
    name: 'isPublicCertificate',
    control,
  });

  return (
    <EditArtworkSection heading={t('online_certificate')}>
      <IosSwitch
        textSwitch={
          field.value ? t('public_certificate_for_eveyone') : t('hide_certificate_only_show_for_me')
        }
        isChecked={field.value}
        onSwitchChange={field.onChange}
      />
    </EditArtworkSection>
  );
};

export default EditArtworkCertificate;
