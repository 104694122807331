import { useTranslation } from 'react-i18next';

import FormInput from 'commons/Form/FormInput';

import useStyles from './styles';

const YearDescriptionInput = ({
  year,
  onChangeYear,
  description,
  onChangeDescription,
  errorYear,
  helperTextYear,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapInput}>
      <FormInput
        value={year}
        label={t('year')}
        placeholder={t('enter_year')}
        onChange={onChangeYear}
        InputProps={{
          inputProps: { type: 'number', step: '1' },
        }}
        error={!!errorYear}
        helperText={helperTextYear}
        textFieldStyle={classes.textFieldStyle}
        wrapFormInputStyle={classes.wrapFormYearInput}
      />
      <FormInput
        value={description}
        label={t('description')}
        placeholder={t('write_something_to_describe')}
        onChange={onChangeDescription}
        textFieldStyle={classes.textFieldStyle}
        wrapFormInputStyle={classes.wrapFormDescriptionInput}
      />
    </div>
  );
};

export default YearDescriptionInput;
