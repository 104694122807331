import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useDisplayText = (textDisplayKey, textDisplayViKey, frontEndTranslate) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state?.language?.currentLanguage);

  const getDisplayText = (item) => {
    if (frontEndTranslate) {
      return t(item[textDisplayKey]);
    }

    switch (language) {
      case 'en':
        return item[textDisplayKey];
      case 'vi':
        return item[textDisplayViKey] || item[textDisplayKey];
      default:
        return item[textDisplayKey];
    }
  };

  return getDisplayText;
};

export default useDisplayText;
