import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const IconActionView = ({ onClick }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Tooltip
      title={t('review_certificate_request')}
      arrow
    >
      <VisibilityOutlinedIcon
        onClick={onClick}
        className={classes.icon}
      />
    </Tooltip>
  );
};

export default IconActionView;
