import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from '../styles';

const ArtistStatements = ({ statement }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const [numberOfLines, setNumberOfLines] = useState(0);
  const maxLines = 3;

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  useEffect(() => {
    const lines = statement?.split('\n');
    setNumberOfLines(lines?.length);
  }, [statement]);

  if (!statement) {
    return null;
  }

  return (
    <div>
      <Box>
        <Text
          type="xl"
          fontWeightBold
        >
          {t('artist_statements')}
        </Text>
        <Box className={classes.boxStatements}>
          <Text
            className={classes.textStatements}
            type="lg"
            fontWeightBold
          >
            {showMore || numberOfLines <= maxLines
              ? statement
              : `${statement?.split('\n')?.slice(0, maxLines)?.join('\n')}...`}
          </Text>
          {numberOfLines > maxLines && (
            <div className={classes.textSeeMoreText}>
              <Text
                bold
                onClick={toggleShowMore}
              >
                {showMore ? t('show_less') : t('see_more')}
              </Text>
            </div>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default ArtistStatements;
