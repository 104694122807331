import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapItemCertificateLog: {
    display: 'flex',
    marginTop: 12,
    borderBottom: `1px solid ${Colors.Grey3}`,
  },
  textActivityLog: {
    fontSize: 18,
    marginBottom: 16,
  },
  wrapTextTime: {
    marginRight: 24,
    marginBottom: 12,
  },
  dialogActionStyle: {
    '&.MuiDialogActions-root': {
      borderTop: 'none',
      padding: 0,
    },
  },
  signature: {
    marginTop: 8,
    marginBottom: 12,
    width: 80,
    height: 60,
    border: `1px solid ${Colors.Grey3}`,
  },
}));

export default useStyles;
