import { TableBody } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import API from 'configs/API';

import ArtworkRow from './components/ArtworkRow';

const ManageEditionTableBody = ({ isPending, listArtworkEdition }) => {
  const { data: status, isPending: statusPending } = useQuery({ queryKey: [API.ARTWORK.STATUS] });
  const { data: location, isPending: locationPending } = useQuery({
    queryKey: [API.AUTH.LOCATION],
  });

  if (isPending || statusPending || locationPending) {
    return;
  }

  return (
    <TableBody>
      {(listArtworkEdition || []).map((artwork, index) => (
        <ArtworkRow
          key={artwork.id}
          artwork={artwork}
          isEvenRow={index % 2 !== 0}
          statusOption={status}
          locationOption={location}
        />
      ))}
    </TableBody>
  );
};

export default ManageEditionTableBody;
