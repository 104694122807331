import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CheckBoxGroup from 'commons/FilterArtwork/CheckBoxGroup';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, STYLE_KEY } from 'configs/FilterArtwork';

const { STYLE } = ARTWORK_FILTER_PARAMS;

const FilterStyle = () => {
  const { data: styles = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.STYLE, { by_artwork_upload: true }],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedStyleParams = searchParams.get(STYLE)?.split(',') || [];

  const handleCheckboxChange = (style) => {
    const isChecked = selectedStyleParams.find(
      (item) => item === style[STYLE_KEY.CHECKED]?.toString(),
    );

    searchParams.delete('page');

    if (!isChecked) {
      searchParams.set(STYLE, [...selectedStyleParams, style[STYLE_KEY.CHECKED]]);
      setSearchParams(searchParams);
      return;
    }

    const newSelectedCategoryParams = [...selectedStyleParams].filter(
      (item) => item !== style[STYLE_KEY.CHECKED]?.toString(),
    );

    if (newSelectedCategoryParams.length) {
      searchParams.set(STYLE, newSelectedCategoryParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(STYLE);
      setSearchParams(searchParams);
    }
  };

  return (
    <CheckBoxGroup
      listCheckBox={styles}
      selectedFilters={selectedStyleParams}
      onCheckboxChange={handleCheckboxChange}
      textDisplayKey={STYLE_KEY.DISPLAY}
      textDisplayViKey={STYLE_KEY.DISPLAY_VI}
      checkedKey={STYLE_KEY.CHECKED}
    />
  );
};

export default FilterStyle;
