import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import { ReactComponent as SettingIcon } from 'images/setting.svg';

import DialogSetting from '../DialogSetting';
import SettingDotPopover from '../SettingDotPopover';
import useStyles from './styles';

const UserSetting = ({ visibleSetting }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isShowDialog, setIsShowDialog] = useState(false);

  const handleOpenPopover = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOpenDialogSetting = () => {
    handleClosePopover();
    setIsShowDialog(true);
  };

  const handleCancelDialog = () => {
    setIsShowDialog(false);
  };

  return (
    <>
      {visibleSetting?.is_public_profile ? (
        <SettingDotPopover
          anchorEl={anchorEl}
          onOpenPopover={handleOpenPopover}
          onClosePopover={handleClosePopover}
          onOpenDialogSetting={handleOpenDialogSetting}
        />
      ) : (
        <Button
          variant="outlined"
          fullWidth
          className={classes.button}
          onClick={handleOpenDialogSetting}
          data-testid={TESTID.BUTTON_SETTING}
        >
          <SettingIcon />
          <Text
            fontWeightMedium
            type="md"
            ml={8}
          >
            {t('settings')}
          </Text>
        </Button>
      )}

      <DialogSetting
        isShowDialog={isShowDialog}
        onCancel={handleCancelDialog}
        visibleSetting={visibleSetting}
        onCloseDialog={handleCancelDialog}
      />
    </>
  );
};

export default UserSetting;
