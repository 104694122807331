import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapListMeasurement: {
    marginTop: 4,
  },
  required: {
    marginLeft: 4,
    color: Colors.Red6,
    fontSize: '1.8rem',
    letterSpacing: 0.8,
  },
  formLabel: {
    '&.MuiFormLabel-root': {
      fontSize: '1.4rem',
      lineHeight: '142.857%',
      letterSpacing: 0.56,
      marginBottom: 5,
      display: 'block',
    },
  },
}));

export default useStyles;
