import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import React from 'react';

import Text from 'commons/Text';
import UploadImage from 'commons/UploadImage';

import useStyles from './styles';

const ArtworkUploadImage = ({
  image,
  onUploadImage,
  onClickImage,
  onRemoveImage,
  isRequired,
  alt,
  textRecommend,
  removeIconTestId,
  uploadImageTestId,
  wrapStyle,
}) => {
  const classes = useStyles();

  if (image) {
    return (
      <div className={clsx(classes.listImageItemWrapper, wrapStyle)}>
        <img
          src={image}
          className={classes.imagItem}
          onClick={onClickImage}
          alt={alt}
        />
        <CloseIcon
          className={classes.removeIcon}
          onClick={onRemoveImage}
          data-testid={removeIconTestId}
        />
      </div>
    );
  }

  return (
    <UploadImage
      onUploadImage={onUploadImage}
      uploadWrapStyle={clsx(classes.wrapSmallUpload, wrapStyle)}
      dataTestId={uploadImageTestId}
    >
      <AddIcon className={classes.iconAddImage} />
      <Text>
        {textRecommend}
        {isRequired && <span className={classes.require}>*</span>}
      </Text>
    </UploadImage>
  );
};

export default ArtworkUploadImage;
