import React from 'react';
import { Controller } from 'react-hook-form';

import CheckBoxCustom from 'commons/CheckBoxCustom';

const FormCheckBoxController = ({ name, control, rules, onChange, textCheckBox, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        const { value, onChange: onCheckBoxChange } = field;

        const handleCheckBoxChange = (e) => {
          onCheckBoxChange(e);

          if (onChange) {
            onChange(e);
          }
        };

        return (
          <CheckBoxCustom
            checked={value}
            onChange={handleCheckBoxChange}
            textCheckBox={textCheckBox}
            {...props}
          />
        );
      }}
    />
  );
};

export default FormCheckBoxController;
