import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 24,
    padding: 24,
    background: Colors.White,
    border: `1px solid ${Colors.Grey2}`,
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
  },
  title: {
    color: Colors.Grey10,
    fontSize: '2rem',
    fontWeight: 500,
    lineHeight: '150%',
    letterSpacing: 0.8,
  },
  required: {
    marginLeft: 4,
    color: Colors.Red6,
    fontSize: '2rem',
    fontWeight: 500,
    lineHeight: 1.5,
    letterSpacing: 0.8,
  },
}));

export default useStyles;
