import { Box, Button, LinearProgress } from '@mui/material';
import React from 'react';

import { MAX_CREATE_ARTWORK_STEPS } from 'configs/Constant';

import useStyles from './styles';

const GroupButtonArtwork = ({
  step,
  textButtonOne,
  textButtonTwo,
  onClickButtonOne,
  disabledButtonOne,
  onClickButtonTwo,
  disabledButtonTwo,
  marginTopBox,
  marginBottomBox,
  isUpdate,
}) => {
  const classes = useStyles();

  const progressValue = ((step - 1) / MAX_CREATE_ARTWORK_STEPS) * 100;
  const buttonText = !isUpdate
    ? `${textButtonOne} (${step}/${MAX_CREATE_ARTWORK_STEPS})`
    : textButtonOne;

  return (
    <>
      {!isUpdate && (
        <LinearProgress
          variant="determinate"
          value={progressValue}
          className={classes.linearProgressStyle}
        />
      )}

      <Box
        className={classes.wrapBoxContent}
        sx={{ mt: marginTopBox, mb: marginBottomBox }}
      >
        {textButtonOne && (
          <Button
            fullWidth
            variant="contained"
            onClick={onClickButtonOne}
            disabled={disabledButtonOne}
          >
            {buttonText}
          </Button>
        )}
        {textButtonTwo && (
          <Button
            fullWidth
            variant="outlined"
            onClick={onClickButtonTwo}
            disabled={disabledButtonTwo}
            className={classes.buttonTwo}
          >
            {textButtonTwo}
          </Button>
        )}
      </Box>
    </>
  );
};

export default GroupButtonArtwork;
