import React from 'react';

import PaginationCustom from 'commons/PaginationCustom';

import useStyles from './styles';

const TableWrapper = ({ children, page, totalPage, onPageChange }) => {
  const classes = useStyles();

  const handlePageCertificateChange = (_, page) => {
    onPageChange(page);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.tableContainer}>{children}</div>

      <PaginationCustom
        paginationStyle={classes.pagination}
        count={totalPage}
        page={page}
        onChange={handlePageCertificateChange}
      />
    </div>
  );
};

export default TableWrapper;
