import { Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { createSearchParams, useParams, useSearchParams } from 'react-router-dom';

import CollectionItem from 'commons/Collection/CollectionItem';
import PaginationCustom from 'commons/PaginationCustom';
import API from 'configs/API';
import usePagination from 'hooks/usePagination';
import Routes from 'utils/Route';

import useStyles from './styles';

const ArtworkCollection = () => {
  const classes = useStyles();

  const { artistId } = useParams();
  const [searchParams] = useSearchParams();
  const objectParamsFromSearchParams = Object.fromEntries(searchParams);
  const params = {
    ...objectParamsFromSearchParams,
    user_uuid: artistId,
  };

  const { data, isPending } = useQuery({
    queryKey: [API.ARTWORK.LIST_GALLERY_COLLECTION, params],
  });

  const { page, totalPage, onPageChange } = usePagination(data?.count, data?.page_size);

  const handlePageChange = (_, page) => {
    onPageChange(page);
  };

  const listCollection = data?.results;

  if (isPending) {
    return;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <Grid
        container
        spacing={5}
      >
        {listCollection?.map((collection, index) => (
          <Grid
            key={index}
            item
            xs={12}
            lg={3}
          >
            <CollectionItem
              collection={collection}
              redirectTo={{
                pathname: Routes.GalleryDetail(artistId),
                search: createSearchParams({ tab: '2' }).toString(),
              }}
            />
          </Grid>
        ))}
      </Grid>

      {!!totalPage && (
        <PaginationCustom
          paginationStyle={classes.pagination}
          count={totalPage}
          page={page}
          onChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default ArtworkCollection;
