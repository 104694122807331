import * as yup from 'yup';

import { email_or_phone_number } from 'utils/Validation';

export const ownerSchema = yup.object().shape(
  {
    owner: yup
      .object()
      .required('Owner is required')
      .when('newOwner', {
        is: (newOwner) => newOwner && Object.keys(newOwner).length > 0,
        then: (schema) => schema.notRequired(),
      }),
    newOwner: yup
      .object()
      .notRequired()
      .when('owner', {
        is: (owner) => !owner,
        then: (schema) =>
          schema.required().shape({
            name: yup.string().required('Owner name is required'),
            contactInfo: yup
              .string()
              .required('Email or phone number is required')
              .matches(email_or_phone_number, 'Must be a valid email or phone number'),
            role: yup
              .number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .typeError('Role must be a number')
              .required('Role is required'),
          }),
      }),
    yearOfBirth: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Year of birth is required')
      .integer('Year of birth must be an integer')
      .test('reasonable-year', 'Year of birth must be a reasonable value', (value) => {
        const currentYear = new Date().getFullYear();
        return value >= 1900 && value <= currentYear;
      }),
    address: yup
      .string()
      .required('Address is required')
      .max(150, 'Address must be no more than 150 characters long'),
    contractNumber: yup.string().matches(/^[a-zA-Z0-9-]{1,15}$/, {
      excludeEmptyString: true,
      message: 'Contract Number must be alphanumeric and can include hyphens, up to 15 characters',
    }),
  },
  [['owner', 'newOwner']],
);
