import { Stack } from '@mui/material';
import Avatar from '@sendbird/uikit-react/ui/Avatar';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const ChatHeader = () => {
  const classes = useStyles();
  const globalStore = useSendbirdStateContext();

  const { user } = globalStore.stores.userStore;

  return (
    <Stack
      direction="row"
      spacing={1}
      className={classes.stack}
    >
      <Avatar
        width="32px"
        height="32px"
        src={user.profileUrl}
      />

      <Text fontWeightMedium>{user.nickname}</Text>
    </Stack>
  );
};

export default ChatHeader;
