import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapDot: {
    width: '17%',
    padding: '8px 16px',
    border: `1px solid ${Colors.Primary}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  popover: {
    '& .MuiPopover-paper': {
      minWidth: 176,
      marginTop: 8,
      padding: 8,
    },
  },
  settingText: {
    padding: 8,
    cursor: 'pointer',
  },
}));

export default useStyles;
