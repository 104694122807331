import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import clsx from 'clsx';

import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';

import useStyles from './styles';

const DialogCustom = ({
  children,
  isShowDialog = false,
  onCancel,
  onSubmit,
  dialogTitle,
  textSubmit,
  disabledButton,
  maxWidth = 'sm',
  textCancel = '',
  fullWidth = true,
  loading,
  dialogActionStyle,
  cancelButtonStyle,
  submitButtonStyle,
  typeDialogTitle,
  PaperProps,
  textBottom,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onCancel}
      open={isShowDialog}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      PaperProps={PaperProps}
      className={classes.dialog}
      {...props}
    >
      <div className={classes.dialogHeader}>
        <div className={classes.wrapHeaderTitle}>
          <Text
            type={typeDialogTitle}
            fontWeightBold
          >
            {dialogTitle}
          </Text>

          <CloseIcon
            onClick={onCancel}
            className={classes.closeIcon}
          />
        </div>
      </div>

      <DialogContent className={classes.dialogContent}>{children}</DialogContent>

      <DialogActions className={clsx(classes.dialogAction, dialogActionStyle)}>
        {textBottom && <Text fontWeightBold>{textBottom}</Text>}

        <div className={clsx({ [classes.buttonContainer]: !textBottom })}>
          {textCancel && (
            <Button
              variant="outlined"
              onClick={onCancel}
              className={cancelButtonStyle}
              data-testid={TESTID.BUTTON_CANCEL}
            >
              {textCancel}
            </Button>
          )}
          {textSubmit && (
            <Button
              variant="contained"
              disabled={disabledButton || loading}
              onClick={onSubmit}
              className={clsx(classes.buttonSubmit, submitButtonStyle)}
            >
              {textSubmit}
              {loading && (
                <CircularProgress
                  color="inherit"
                  className={classes.loading}
                  size={16}
                />
              )}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCustom;
