import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapTerm: {
    marginTop: 0,
  },
  groupActionButonWrap: {
    marginTop: 24,
  },
  buttonDialog: {
    '&.MuiButton-root': {
      minWidth: 90,
      padding: '10px 16px',
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: 0.64,
    },
  },
}));

export default useStyles;
