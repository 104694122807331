import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapBoxPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textField: {
    '&.MuiTextField-root': {
      width: '60%',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 8px',
      lineHeight: 1.7143,
    },
  },

  formControl: {
    '&.MuiFormControl-root': {
      width: '38%',
      marginTop: 0,
    },
  },
}));

export default useStyles;
