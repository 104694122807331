import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

import { TESTID } from 'configs/Constant';

import useStyles from './styles';

const Loading = () => {
  const classes = useStyles();

  return (
    <div
      className={classes.wrap}
      testid={TESTID.WRAP_LOADING}
    >
      <Box>
        <CircularProgress />
      </Box>
    </div>
  );
};

export default Loading;
