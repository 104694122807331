import { TableCell } from '@mui/material';
import React from 'react';

const DisplayData = ({ value }) => {
  if (!value) {
    return <TableCell>--</TableCell>;
  }

  return <TableCell>{value}</TableCell>;
};

export default DisplayData;
