import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import PaginationCustom from 'commons/PaginationCustom';
import API from 'configs/API';
import usePagination from 'hooks/usePagination';
import useRole from 'hooks/useRole';

import RequestCertificateTableArtist from '../RequestCertificateTableArtist';
import RequestCertificateTableCollector from '../RequestCertificateTableCollector';
import useStyles from './styles';

const RequestCertificateTable = () => {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const { isArtist, isCollector } = useRole();
  const user = useSelector((state) => state.auth.account.user);

  const { data: certificateRequests, isPending: isCertificateRequestPending } = useQuery({
    queryKey: [API.ARTWORK.LIST_CERTIFICATE_REQUEST, params, user?.id],
  });

  const listRequestCertificate = certificateRequests?.results || [];

  const { page, totalPage, onPageChange } = usePagination(
    certificateRequests?.count,
    certificateRequests?.page_size,
  );

  const handlePageRequestCertificateChange = (_, page) => {
    onPageChange(page);
  };

  if (isCertificateRequestPending) {
    return;
  }

  return (
    <>
      {isArtist && (
        <RequestCertificateTableArtist
          listRequestCertificate={listRequestCertificate}
          page={page}
          pageSize={certificateRequests?.page_size}
        />
      )}
      {isCollector && (
        <RequestCertificateTableCollector
          listRequestCertificate={listRequestCertificate}
          page={page}
          pageSize={certificateRequests?.page_size}
        />
      )}
      <PaginationCustom
        paginationStyle={classes.pagination}
        count={totalPage}
        page={page}
        onChange={handlePageRequestCertificateChange}
      />
    </>
  );
};

export default RequestCertificateTable;
