import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CheckBoxGroupAlias from 'commons/FilterArtwork/CheckBoxGroupAlias';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS } from 'configs/FilterArtwork';

const { ARTIST } = ARTWORK_FILTER_PARAMS;

const FilterArtist = () => {
  const { data: artists = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.ARTIST_BY_USER],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedArtistParams = searchParams.get(ARTIST)?.split(',') || [];

  const handleCheckboxChange = (artist) => {
    const isChecked = selectedArtistParams.find((item) => item === artist);

    searchParams.delete('page');

    if (!isChecked) {
      searchParams.set(ARTIST, [...selectedArtistParams, artist]);
      setSearchParams(searchParams);
      return;
    }

    const newSelectArtistParams = [...selectedArtistParams].filter((item) => item !== artist);

    if (newSelectArtistParams.length) {
      searchParams.set(ARTIST, newSelectArtistParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(ARTIST);
      setSearchParams(searchParams);
    }
  };

  return (
    <CheckBoxGroupAlias
      listCheckBox={artists}
      checked={(checkBoxItem) => {
        return !!selectedArtistParams.find((item) => item === checkBoxItem);
      }}
      onCheckboxChange={handleCheckboxChange}
      renderText={(checkBoxItem) => checkBoxItem}
    />
  );
};

export default FilterArtist;
