import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapBoxInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    '&.MuiAvatar-root': {
      width: 120,
      height: 120,
    },
  },
  text: {
    marginTop: 8,
    color: Colors.Grey10,
  },
  wrapLocation: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  wrapNameUserProfile: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  nickNameUserProfile: {
    marginLeft: 8,
  },
  buttonShareLink: {
    '&.MuiButton-root': {
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'capitalize',
      border: `1px solid ${Colors.Primary}`,
    },
  },
}));

export default useStyles;
