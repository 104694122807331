import { TableCell } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const TableColumn = ({ text, colStyle }) => {
  const classes = useStyles();

  return (
    <TableCell className={clsx(classes.tableCell, colStyle)}>
      <Text
        fontWeightBold
        type="sm"
      >
        {text}
      </Text>
    </TableCell>
  );
};

export default TableColumn;
