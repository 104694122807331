import React from 'react';
import { useTranslation } from 'react-i18next';

import ManageWrapper from 'commons/ManageWrapper';
import Routes from 'utils/Route';

import ManageCertificateTabPanel from './components/ManageCertificateTabPanel';

const ManageCertificate = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      text: t('manage_certificates'),
      active: true,
    },
  ];

  return (
    <ManageWrapper
      breadCrumbs={breadCrumbs}
      heading={t('manage_certificates')}
      searchPlaceholder={t('search_by_artwork_name')}
    >
      <ManageCertificateTabPanel />
    </ManageWrapper>
  );
};

export default ManageCertificate;
