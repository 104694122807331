import clsx from 'clsx';
import React from 'react';

import useStyles from './styles';

const UploadImage = ({
  children,
  onUploadImage,
  disabled,
  uploadWrapStyle,
  accept = '.png, .jpeg, .jpg',
  dataTestId,
}) => {
  const classes = useStyles();

  return (
    <label className={clsx(classes.wrapper, uploadWrapStyle)}>
      <input
        hidden
        accept={accept}
        type="file"
        onChange={onUploadImage}
        disabled={disabled}
        data-testid={dataTestId}
      />

      {children}
    </label>
  );
};

export default UploadImage;
