import { TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TableColumn from 'commons/TableColumn';

import useStyles from './styles';

const ManageEditionTableHead = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableColumn
          text={t('artwork_name')}
          colStyle={classes.columnWidth}
        />

        <TableColumn
          text={t('edition_number')}
          colStyle={classes.columnSmall}
        />

        <TableColumn
          text={t('status')}
          colStyle={classes.columnSmall}
        />

        <TableColumn
          text={t('id_certificate')}
          colStyle={classes.columnMedium}
        />

        <TableColumn
          text={t('owner')}
          colStyle={classes.columnMedium}
        />

        <TableColumn
          text={t('location')}
          colStyle={classes.columnMedium}
        />

        <TableColumn text={t('action')} />
      </TableRow>
    </TableHead>
  );
};

export default ManageEditionTableHead;
