import { useCallback, useEffect, useRef, useState } from 'react';

import { getScaleValue } from './scaleMode';

// SCALE_MODE = xAxis | yAxis
const useResizeCertificate = (scaleMode = 'xAxis') => {
  const [scaleValue, setScaleValue] = useState(1);

  const rootRef = useRef();
  const parentRef = useRef();
  const certificateRef = useRef();

  const resizeStyle = {
    transform: `scale(${scaleValue})`,
    transformOrigin: 'top left',
  };

  const getRootProps = () => ({
    ref: rootRef,
  });

  const getParentProps = () => {
    const certificateWidth = certificateRef?.current?.offsetWidth;
    const certificateHeight = certificateRef?.current?.offsetHeight;

    return {
      ref: parentRef,
      style: {
        width: `${certificateWidth * scaleValue}px`,
        height: `${certificateHeight * scaleValue}px`,
      },
    };
  };

  const getCertificateProps = () => ({
    ref: certificateRef,
  });

  const handleResizeCertificate = useCallback(() => {
    const value = getScaleValue(scaleMode, rootRef, certificateRef);
    setScaleValue(value);
  }, [scaleMode]);

  useEffect(() => {
    if (!(rootRef.current instanceof HTMLElement)) {
      return;
    }
    if (!(certificateRef.current instanceof HTMLElement)) {
      return;
    }

    handleResizeCertificate();

    window.addEventListener('resize', handleResizeCertificate);

    return () => {
      window.removeEventListener('resize', handleResizeCertificate);
    };
  }, [handleResizeCertificate]);

  return { getRootProps, getParentProps, getCertificateProps, resizeStyle };
};

export default useResizeCertificate;
