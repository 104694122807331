import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  tableCell: {
    '&.MuiTableCell-head': {
      position: 'relative',
      '&:before': {
        content: "''",
        width: 1,
        height: 20,
        position: 'absolute',
        top: '50%',
        right: 0,
        transform: 'translateY(-50%)',
        background: Colors.Grey3,
      },
      '&:last-child': {
        '&::before': {
          display: 'none',
        },
      },
    },
  },
}));

export default useStyles;
