import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapArtworkDetail: {
    marginBottom: 80,
  },
  artworkImage: {
    backgroundColor: Colors.Gray3,
  },
  textAboutWork: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '150%',
    letterSpacing: 0.8,
    marginTop: 30,
  },
  textDescription: {
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: 0.64,
    marginTop: 10,
  },
  textOrtherWorks: {
    marginTop: 70,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '150%',
    letterSpacing: 0.8,
  },
}));

export default useStyles;
