import ImageIcon from '@mui/icons-material/Image';
import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { TESTID } from 'configs/Constant';

import useStyles from '../styles';

const ImageLoading = ({ imageStyle, loadingStyle, iconLoadingStyle }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapSkeleton, imageStyle)}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        className={clsx(classes.skeletonStyle, loadingStyle)}
        testid={TESTID.SKELETON_IMAGE}
      />

      <ImageIcon className={clsx(classes.imageIcon, iconLoadingStyle)} />
    </div>
  );
};

export default ImageLoading;
