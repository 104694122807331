import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  boxUploadWrapper: {
    width: '100%',
    padding: 24,
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
    border: `1px solid ${Colors.Grey2}`,
    background: Colors.White,
  },
  wrapBoxReviewImage: {
    width: '100%',
    height: 'calc(100vh - 393px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    background: Colors.Grey1,
  },
  reviewImage: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  wrapBoxSmallImage: {
    marginTop: 24,
    display: 'flex',
    flexWrap: 'wrap',
  },
  wrapSmallUpload: {
    width: '100%',
    height: 109,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    border: `2px dashed ${Colors.Primary}`,
    color: Colors.Primary,
  },
  listImageItemWrapper: {
    position: 'relative',
    cursor: 'pointer',
  },
  imagItem: {
    width: '100%',
    height: 109,
    objectFit: 'cover',
    display: 'block',
  },
  removeIcon: {
    '&.MuiSvgIcon-root': {
      color: Colors.White,
      position: 'absolute',
      top: 4,
      right: 4,
    },
  },
  iconAddImage: {
    '&.MuiSvgIcon-root': {
      width: 40,
      height: 40,
    },
  },
  noteList: {
    marginLeft: 24,
  },
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
    fontWeight: 500,
  },

  [mediaQueries.tabletLarge]: {
    wrapBoxBigImage: {
      height: 'calc(100vh - 393px * 1.5)',
    },
  },
  [mediaQueries.tabletLargeSmallHeight]: {
    wrapBoxBigImage: {
      height: 'calc(100vh - 393px)',
    },
  },
}));

export default useStyles;
