import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import Section from 'features/Certificate/Section';

import useStyles from './styles';

const RequestToArtist = ({ requestTo }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Section
      title={t('request_to')}
      required
      wrapperStyle={classes.wrapper}
    >
      <Text>{requestTo?.name || requestTo?.email}</Text>
    </Section>
  );
};

export default RequestToArtist;
