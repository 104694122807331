import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ArtistPage from 'commons/ArtistPage';
import API from 'configs/API';
import Routes from 'utils/Route';
import { axiosGet } from 'utils/apis/axios';

const ArtistProfile = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.account.user);
  const [userProfile, setUserProfile] = useState({});
  const { id } = useParams();

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      to: Routes.GalleryDetail(user?.uuid),
      text: t('gallery'),
    },
    {
      text: t('artist_profile'),
      active: true,
    },
  ];

  useEffect(() => {
    const option = {
      params: {},
    };

    if (id) {
      option.params['user_uuid'] = id;
    }

    const getProfile = async () => {
      const { success, data } = await axiosGet(API.AUTH.PROFILE_INFO, option);
      if (success) {
        setUserProfile(data);
      }
    };

    getProfile();
  }, [id]);

  return (
    <>
      <ArtistPage
        userProfile={userProfile}
        breadCrumbs={breadCrumbs}
      />
    </>
  );
};

export default ArtistProfile;
