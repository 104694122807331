import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
    fontWeight: 500,
  },
  confirmationOptions: {
    display: 'flex',
    gap: '16px',
    marginTop: 15,
  },
  wrapTextInputTotalSection: {
    marginBottom: 20,
  },
  disabled: {
    '&.MuiTextField-root': {
      background: Colors.GrayScale10,
    },
    '& fieldset': {
      borderColor: `${Colors.GrayScaleBluePalette30} !important`,
    },
  },
}));

export default useStyles;
