import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formSelectControl: {
    '&.MuiFormControl-root': {
      marginTop: 0,
      flex: 1,
    },
  },
  select: {
    minHeight: 48,
  },
}));

export default useStyles;
