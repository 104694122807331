import { Table } from '@mui/material';
import React from 'react';

import RequestCertificateTableBodyCollector from './components/RequestCertificateTableBodyCollector';
import RequestCertificateTableHeadCollector from './components/RequestCertificateTableHeadCollector';
import useStyles from './styles';

const RequestCertificateTableCollector = ({ listRequestCertificate, page, pageSize }) => {
  const classes = useStyles();

  return (
    <div className={classes.tableContainer}>
      <Table className={classes.table}>
        <RequestCertificateTableHeadCollector />
        <RequestCertificateTableBodyCollector
          listRequestCertificate={listRequestCertificate}
          page={page}
          pageSize={pageSize}
        />
      </Table>
    </div>
  );
};

export default RequestCertificateTableCollector;
