import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import FormInput from 'commons/Form/FormInput';

import useStyles from './styles';

const FilterPrice = ({ priceParam }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const priceParams = searchParams.get(priceParam)?.split(',') || [];

  const [minPriceParams, maxPriceParams] = priceParams;
  const [minPrice, setMinPrice] = useState(minPriceParams || '');
  const [maxPrice, setMaxPrice] = useState(maxPriceParams || '');

  const handleMinPriceChange = (e) => {
    const { value } = e.target;
    if (isNaN(Number(value))) {
      return;
    }
    setMinPrice(value);
  };

  const handleMaxPriceChange = (e) => {
    const { value } = e.target;
    if (isNaN(Number(value))) {
      return;
    }
    setMaxPrice(value);
  };

  const handleApplyFilterPrice = () => {
    const MIN_PRICE = minPrice || 0;
    const MAX_PRICE = maxPrice;

    const price = MAX_PRICE ? [MIN_PRICE, MAX_PRICE] : [MIN_PRICE];
    searchParams.set(priceParam, price);
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    <div className={classes.wrapper}>
      <FormInput
        value={minPrice}
        onChange={handleMinPriceChange}
        label={t('min')}
        placeholder={t('enter_min_price')}
        wrapFormInputStyle={classes.wrapFormInput}
      />
      <FormInput
        value={maxPrice}
        onChange={handleMaxPriceChange}
        label={t('max')}
        placeholder={t('enter_max_price')}
        wrapFormInputStyle={classes.wrapFormInput}
      />

      <Button
        onClick={handleApplyFilterPrice}
        variant="contained"
        className={classes.button}
      >
        {t('apply')}
      </Button>
    </div>
  );
};

export default FilterPrice;
