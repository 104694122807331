import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapBoxContent: {
    padding: 24,
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
    borderRadius: 8,
    border: `1px solid ${Colors.Grey2}`,
    background: Colors.White,
  },
  formControlStyle: {
    marginTop: 16,
  },
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
    fontWeight: 500,
  },
}));

export default useStyles;
