import React from 'react';

import MenuLanguage from 'commons/Header/components/MenuLanguage';

import useStyles from '../styles';
import MenuProfile from './MenuProfile';

const HeaderRight = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapHeaderRight}>
      <div className={classes.wrapGroupItem}>
        <MenuLanguage />
        <MenuProfile />
      </div>
    </div>
  );
};

export default HeaderRight;
