import { Box, Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Breadcrumbs from 'commons/Breadcrumbs';
import Text from 'commons/Text';
import useUserProfile from 'hooks/useUserProfile';
import Routes from 'utils/Route';

import AboutArtist from './components/AboutArtist';
import ArtistAward from './components/ArtistAward';
import ArtistBiography from './components/ArtistBiography';
import ArtistExhibition from './components/ArtistExhibition';
import ArtistInfomation from './components/ArtistInfomation';
import ArtistPublication from './components/ArtistPublication';
import ArtistStatement from './components/ArtistStatement';
import EditArtistProfileNav from './components/EditArtistProfileNav';
import useStyles from './styles';

const EditArtistProfile = () => {
  const user = useSelector((state) => state.auth.account.user);
  const [userProfile, setUserProfile] = useState({});

  const { getUserProfile } = useUserProfile();

  const { t } = useTranslation();
  const classes = useStyles();

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      to: Routes.GalleryDetail(user?.uuid),
      text: t('gallery'),
    },
    {
      to: Routes.ArtistProfile,
      text: t('artist_profile'),
    },
    {
      text: t('edit_artist_profile'),
      active: true,
    },
  ];

  const transformData = (data) => {
    const newData = { ...data };
    for (let key in newData) {
      if (newData[key] === null) {
        newData[key] = '';
      }
    }
    return newData;
  };

  const handleGetProfileSuccess = (data) => {
    const newData = transformData(data);
    setUserProfile(newData);
  };

  const fetchUserProfile = () => {
    getUserProfile(handleGetProfileSuccess);
  };

  const handleUpdateUserProfile = () => {
    fetchUserProfile();
  };

  useEffect(() => {
    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.wrapper}>
      <Container>
        <Box sx={{ pt: 3 }}>
          <Breadcrumbs items={breadCrumbs} />
          <Text
            mt={16}
            fontSize={36}
            fontWeightBold
          >
            {t('edit_artist_profile')}
          </Text>

          <Box sx={{ pt: 3 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                xs={12}
                lg={3}
              >
                <EditArtistProfileNav />
              </Grid>

              <Grid
                item
                xs={12}
                lg={9}
              >
                <ArtistInfomation
                  key={userProfile.id}
                  userProfile={userProfile}
                  onUpdateUserProfile={handleUpdateUserProfile}
                />
                <ArtistStatement
                  userProfile={userProfile}
                  onUpdateUserProfile={handleUpdateUserProfile}
                />
                <AboutArtist
                  userProfile={userProfile}
                  onUpdateUserProfile={handleUpdateUserProfile}
                />
                <ArtistBiography
                  userProfile={userProfile}
                  onUpdateUserProfile={handleUpdateUserProfile}
                />
                <ArtistAward />
                <ArtistExhibition isSoloExhibition />
                <ArtistExhibition isGroupExhibition />
                <ArtistPublication />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default EditArtistProfile;
