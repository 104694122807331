import { useMutation } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPost } from 'utils/apis/axios';

const likeCollection = async (id) => {
  const { data } = await axiosPost(false, API.ARTWORK.LIKE_COLLECTION(id));
  return data;
};

const useLikeCollection = () => {
  return useMutation({
    mutationFn: likeCollection,
  });
};

export default useLikeCollection;
