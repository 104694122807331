import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import clsx from 'clsx';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const AccountTypeOption = ({ type, selectedType, onSelect, icon, label }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.itemAccountType, { selected: selectedType === type })}
      onClick={() => onSelect(type)}
    >
      <div className={classes.wrapIconItem}>
        {icon}
        <Text
          fontWeightBold
          className={classes.textItemAccountType}
        >
          {label}
        </Text>
      </div>
      {selectedType === type ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
    </div>
  );
};

export default AccountTypeOption;
