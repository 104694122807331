import { useSearchParams } from 'react-router-dom';

const usePagination = (total, pageSize) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get('page')) || 1;
  const totalPage = Math.ceil(total / pageSize);

  const onPageChange = (page) => {
    if (page <= 1) {
      searchParams.delete('page');
      setSearchParams(searchParams);
      return;
    }

    searchParams.set('page', page);
    setSearchParams(searchParams);
  };

  return { page, totalPage, onPageChange };
};

export default usePagination;
