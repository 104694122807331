import { Box, Container } from '@mui/material';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'commons/Breadcrumbs';

import ArtistProfileColLeft from './components/ArtistProfileColLeft';
import ArtistProfileColRight from './components/ArtistProfileColRight';
import SpecialWorks from './components/SpecialWorks';
import useStyles from './styles';

const ArtistPage = ({ userProfile, breadCrumbs, artistUuid }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const profileContent = useRef('');
  const { id } = useParams();

  const downloadPDF = async () => {
    const content = profileContent.current;

    const canvas = await html2canvas(content, { useCORS: true });
    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    pdf.addImage(imgData, 'PNG', 10, 10, 190, 0); // 190 is the width of the image, 0 for the height to automatically scale
    pdf.save('profile.pdf');
  };

  return (
    <Box
      className={classes.wrapper}
      ref={profileContent}
    >
      <Container>
        <Box sx={{ pt: 3 }}>
          {breadCrumbs && <Breadcrumbs items={breadCrumbs} />}

          <Box
            sx={{ pt: 3 }}
            className={classes.wrapCol}
          >
            <ArtistProfileColLeft
              userProfile={userProfile}
              downloadPDF={downloadPDF}
            />
            <ArtistProfileColRight userProfile={userProfile} />
          </Box>
        </Box>
        <SpecialWorks
          title={t('special_works')}
          userUuid={artistUuid || id}
        />
      </Container>
    </Box>
  );
};

export default ArtistPage;
