import { InputAdornment, TextField } from '@mui/material';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import Text from 'commons/Text';
import Colors from 'configs/Colors';

import useStyles from './styles';

const FormInput = (
  {
    label,
    isRequired,
    placeholder,
    wrapFormInputStyle,
    textFieldStyle,
    inputProps,
    labelStyle,
    labelProps,
    InputProps,
    iconEndAdorment,
    iconStartAdornment,
    ...props
  },
  ref,
) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapFormInput, wrapFormInputStyle)}>
      <Text
        className={clsx(classes.inputLabel, labelStyle, {
          [classes.labelMargin]: !!label,
        })}
        type={'sm'}
        {...labelProps}
      >
        {label}

        {isRequired && (
          <Text
            variant="span"
            type={labelProps?.type || 'sm'}
            color={Colors.Red6}
            ml={6}
            fontWeightBold
          >
            *
          </Text>
        )}
      </Text>

      <TextField
        fullWidth
        placeholder={placeholder}
        className={clsx(classes.textField, textFieldStyle)}
        inputRef={ref}
        autoComplete="off"
        inputProps={{
          ...inputProps,
          'aria-label': label,
        }}
        InputProps={{
          ...InputProps,
          startAdornment: iconStartAdornment && (
            <InputAdornment position="start">{iconStartAdornment}</InputAdornment>
          ),
          endAdornment: iconEndAdorment && (
            <InputAdornment position="end">{iconEndAdorment}</InputAdornment>
          ),
        }}
        {...props}
      />
    </div>
  );
};

export default forwardRef(FormInput);
