import { Grid, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ImageCustom from 'commons/ImageCustom';
import Section from 'features/Certificate/Section';

import useStyles from './styles';

const ReviewArtworkImages = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { artwork } = data;

  return (
    <Section
      title={t('artwork_images')}
      wrapperStyle={classes.wrapper}
    >
      <Stack
        direction="row"
        sx={{ mt: 2 }}
        spacing={2}
      >
        {(artwork?.images || []).map((item) => (
          <Grid
            key={item}
            item
            xs={12}
            lg={2}
          >
            <ImageCustom
              src={item}
              imageStyle={classes.image}
              loadingStyle={classes.imageLoading}
            />
          </Grid>
        ))}
      </Stack>
    </Section>
  );
};

export default ReviewArtworkImages;
