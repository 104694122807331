import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import Text from 'commons/Text';
import API from 'configs/API';

import useStyles from './styles';

const CertificateLogMoDal = ({ isShowCertificateLog, onCancelCertificateLog, certificate }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data: listCertificateLog } = useQuery({
    queryKey: [API.ACTIVITY_LOG.CERTIFICATE_EXPORT_LOG, { certificate_id: certificate?.id }],
  });

  return (
    <DialogCustom
      isShowDialog={isShowCertificateLog}
      dialogTitle={certificate?.artwork?.title}
      onCancel={onCancelCertificateLog}
      maxWidth={'md'}
      fullWidth
      dialogActionStyle={classes.dialogActionStyle}
    >
      <Text
        fontWeight={700}
        className={classes.textActivityLog}
      >
        {t('activity_log')}
      </Text>
      <div>
        {(listCertificateLog || []).map((certificateLog, index) => {
          const date = moment(certificateLog?.created_at);
          const time = date.format('HH:mm');
          const day = date.format('DD/MM/YYYY');
          return (
            <div
              key={index}
              className={classes.wrapItemCertificateLog}
            >
              <div className={classes.wrapTextTime}>
                <div>{time}</div>
                <div className="date">{day}</div>
              </div>
              <div>
                <div>{certificateLog.data}</div>
              </div>
            </div>
          );
        })}
      </div>
    </DialogCustom>
  );
};

export default CertificateLogMoDal;
