import React, { createContext, useState } from 'react';

import { OptionsCurrency, VisibilityStatusOption } from 'configs/Constant';

export const ArtworkContext = createContext();

export const originalArtworkData = {
  price: '',
  currency: OptionsCurrency[0].key,
  isHidePrice: false,
  isAskPrice: false,
  visibility: VisibilityStatusOption[0],
  contact: '',
};

export const ArtworkProvider = ({ children }) => {
  const [artworkData, setArtworkData] = useState(originalArtworkData);
  const [validationStatusPrice, setValidationStatusPrice] = useState(false);

  const updateArtwork = (newData) => {
    setArtworkData({ ...artworkData, ...newData });
  };

  return (
    <ArtworkContext.Provider
      value={{ artworkData, updateArtwork, validationStatusPrice, setValidationStatusPrice }}
    >
      {children}
    </ArtworkContext.Provider>
  );
};
