import React from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import FormInput from 'commons/Form/FormInput';
import IosSwitch from 'commons/IosSwitch';
import Text from 'commons/Text';
import API from 'configs/API';
import { TESTID } from 'configs/Constant';
import YearDescriptionInput from 'pages/EditArtistProfile/commons/YearDescriptionInput';

import useDialogEditArrtistProfile from './hook/useDialogEditArrtistProfile';
import useStyles from './styles';

const DialogEditArtistProfile = ({
  isShowDialog,
  editedYear,
  editedDescription,
  editedExhibitionLink,
  editedIsPublic,
  selectedExhibition,
  setEditYear,
  setEditDescription,
  setEditedIsPublic,
  setEditedExhibitionLink,
  setIsShowDialogEdit,
  getListExhibition,
  isSoloExhibition,
  dialogTitle,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    wrongExhibitionLink,
    wrongYear,
    isFormValid,
    handleChangeYear,
    handleChangeDescription,
    handleSwitchChange,
    handleCancelExhibition,
    handleChangeLink,
    handleSubmitExhibition,
  } = useDialogEditArrtistProfile(
    editedYear,
    editedDescription,
    editedExhibitionLink,
    setEditYear,
    setEditDescription,
    setEditedIsPublic,
    setIsShowDialogEdit,
    setEditedExhibitionLink,
    selectedExhibition,
    editedIsPublic,
    getListExhibition,
  );

  return (
    <DialogCustom
      isShowDialog={isShowDialog}
      textCancel={t('cancel')}
      dialogTitle={dialogTitle}
      textSubmit={t('save')}
      disabledButton={!isFormValid}
      onCancel={handleCancelExhibition}
      onSubmit={() =>
        isSoloExhibition
          ? handleSubmitExhibition(API.AUTH.SOLO_EXHIBITION.DETAIL(selectedExhibition?.id))
          : handleSubmitExhibition(API.AUTH.GROUP_EXHIBITION.DETAIL(selectedExhibition?.id))
      }
      maxWidth={'md'}
      fullWidth
    >
      <YearDescriptionInput
        year={editedYear}
        description={editedDescription}
        onChangeYear={handleChangeYear}
        onChangeDescription={handleChangeDescription}
        errorYear={editedYear && wrongYear}
        helperTextYear={editedYear && wrongYear && t('please_enter_correct_year')}
      />
      <FormInput
        value={editedExhibitionLink}
        label={t('exhibition_link')}
        placeholder={t('enter_an_url')}
        onChange={handleChangeLink}
        error={editedExhibitionLink && wrongExhibitionLink}
        helperText={editedExhibitionLink && wrongExhibitionLink && t('please_enter_correct_link')}
        textFieldStyle={classes.textFieldStyle}
        testid={TESTID.FORM_INPUT_EDIT_ARTIST_PROFILE}
      />
      <div className={classes.wrapIosSwitch}>
        <IosSwitch
          isChecked={editedIsPublic}
          onSwitchChange={handleSwitchChange}
        />
        <Text
          className={classes.textPublicProfile}
          mt={12}
        >
          {editedIsPublic ? t('public_on_profile') : t('private')}
        </Text>
      </div>
    </DialogCustom>
  );
};

export default DialogEditArtistProfile;
