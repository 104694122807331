import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    width: 70,
    height: 42,
    objectFit: 'contain',
    cursor: 'pointer',
  },
  loadingImage: {
    '&.MuiSkeleton-root': {
      width: 70,
      height: 42,
    },
  },
  iconImage: {
    '&.MuiSvgIcon-root': {
      width: 25,
      height: 25,
    },
  },
  imageError: {
    backgroundColor: Colors.Grey11,
  },
  title: {
    color: Colors.Grey10,
    letterSpacing: '0.64px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecorationLine: 'none',
  },
  titleNotUnderLine: {
    textDecorationLine: 'none',
  },
  editionVolume: {
    marginTop: 4,
    color: Colors.Grey8,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0.48px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
