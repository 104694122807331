import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';

import useStyles from './styles';

const DialogConfirmDeleteArtwork = ({ isShowDialog, onCancel, onSubmit, hasCertificate }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <DialogCustom
      isShowDialog={isShowDialog}
      dialogTitle={t('delete_artwork')}
      textCancel={t('cancel')}
      textSubmit={t('delete')}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitButtonStyle={clsx(classes.buttonDialog, classes.buttonDanger)}
      cancelButtonStyle={clsx(classes.buttonDialog)}
    >
      <div className={classes.wrapDialogContent}>
        {t(
          hasCertificate
            ? 'text_confirm_delete_artwork_has_certificate'
            : 'text_confirm_delete_artwork_no_certificate',
        )}
      </div>
    </DialogCustom>
  );
};

export default DialogConfirmDeleteArtwork;
