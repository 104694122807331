import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  textArtist: {
    fontSize: 14,
  },
  textSearchAgain: {
    cursor: 'pointer',
  },
  formInputLabel: {
    padding: 0,
    marginBottom: 8,
  },
  wrapFormInput: {
    marginTop: -5,
  },
  btn: {
    '&.MuiButton-root': {
      padding: '2px 6px',
      marginTop: 8,
    },
  },
  imageUser: {
    '&.MuiAvatar-root': {
      width: 24,
      height: 24,
      borderRadius: 24,
      marginRight: 8,
    },
  },
  wrapItemUser: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${Colors.Error}`,
    padding: 9,
    borderRadius: 4,
    backgroundColor: Colors.Red14,
  },
}));

export default useStyles;
