import { Dialog, DialogContent } from '@mui/material';
import React from 'react';

import ShareSocialByLink from './components/ShareSociaByLink';
import ShareSocialHeader from './components/ShareSocialHeader';
import ShareSocialPlatform from './components/ShareSocialPlatform';
import ShareSocialWarning from './components/ShareSocialWarning';
import useStyles from './styles';

const ShareSocialPopup = ({ onClose, ...props }) => {
  const classes = useStyles();

  return (
    <Dialog
      {...props}
      onClose={onClose}
    >
      <DialogContent className={classes.dialogContent}>
        <ShareSocialHeader onClose={onClose} />
        <ShareSocialWarning />
        <ShareSocialPlatform />
        <ShareSocialByLink />
      </DialogContent>
    </Dialog>
  );
};

export default ShareSocialPopup;
