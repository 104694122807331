import { FormHelperText, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SelectCustom from 'commons/SelectCustom';

import MeasurementItem from './components/MeasurementItem';
import useStyles from './style';

const ListItemMeasurement = ({
  size,
  optionsDimensionUnits,
  unitDimension,
  onOptionsDimensionUnit,
  onChangeSize,
  heightProps,
  widthProps,
  depthProps,
  formState,
  wrapStyle,
  textFieldStyle,
  disabledDimensionUnits,
  disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={wrapStyle}>
      <Stack
        direction="row"
        spacing={1}
      >
        <MeasurementItem
          unit={t('height_abbreviation')}
          name={'height'}
          value={size?.height}
          onChange={onChangeSize}
          placeholder={t('enter')}
          textFieldStyle={textFieldStyle}
          error={!!formState?.errors.height}
          disabled={disabled}
          {...heightProps}
        />
        <MeasurementItem
          unit={t('width_abbreviation')}
          name={'width'}
          value={size?.width}
          onChange={onChangeSize}
          placeholder={t('enter')}
          textFieldStyle={textFieldStyle}
          error={!!formState?.errors.width}
          disabled={disabled}
          {...widthProps}
        />
        <MeasurementItem
          unit={t('deep_abbreviation')}
          name={'depth'}
          value={size?.depth}
          onChange={onChangeSize}
          placeholder={t('enter')}
          textFieldStyle={textFieldStyle}
          error={!!formState?.errors.depth}
          disabled={disabled}
          {...depthProps}
        />

        <SelectCustom
          options={optionsDimensionUnits}
          value={unitDimension || ''}
          onChange={onOptionsDimensionUnit}
          valueKey="value"
          textDisplayKey="value"
          formControlStyle={classes.formControl}
          selectStyle={classes.select}
          disabled={disabledDimensionUnits}
        />
      </Stack>

      <FormHelperText error={!!formState?.errors.height}>
        {formState?.errors.height?.message}
      </FormHelperText>
      <FormHelperText error={!!formState?.errors.width}>
        {formState?.errors.width?.message}
      </FormHelperText>
      <FormHelperText error={!!formState?.errors.depth}>
        {formState?.errors.depth?.message}
      </FormHelperText>
    </div>
  );
};

export default ListItemMeasurement;
