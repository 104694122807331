import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CheckBoxGroup from 'commons/FilterArtwork/CheckBoxGroup';
import { ARTWORK_FILTER_PARAMS, STATUS_KEY, FILTER_STATUS_OPTION } from 'configs/FilterArtwork';

const { STATUS } = ARTWORK_FILTER_PARAMS;

const FilterStatus = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedStatusParams = searchParams.get(STATUS)?.split(',') || [];

  const handleCheckboxChange = (status) => {
    const isChecked = selectedStatusParams.find(
      (item) => item === status[STATUS_KEY.CHECKED]?.toString(),
    );

    searchParams.delete('page');

    if (!isChecked) {
      searchParams.set(STATUS, [...selectedStatusParams, status[STATUS_KEY.CHECKED]]);
      setSearchParams(searchParams);
      return;
    }

    const newSelectStatusParams = [...selectedStatusParams].filter(
      (item) => item !== status[STATUS_KEY.CHECKED]?.toString(),
    );

    if (newSelectStatusParams.length) {
      searchParams.set(STATUS, newSelectStatusParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(STATUS);
      setSearchParams(searchParams);
    }
  };

  return (
    <CheckBoxGroup
      listCheckBox={FILTER_STATUS_OPTION}
      selectedFilters={selectedStatusParams}
      onCheckboxChange={handleCheckboxChange}
      textDisplayKey={STATUS_KEY.CHECKED}
      checkedKey={STATUS_KEY.CHECKED}
      frontEndTranslate
    />
  );
};

export default FilterStatus;
