import React from 'react';

import ImageCustom from 'commons/ImageCustom';

import GridArtworkInfo from './components/GridArtworkInfo';
import useStyles from './style';

const CertificateArtworkInfo = ({ artwork, certificate }) => {
  const classes = useStyles();

  return (
    <>
      <ImageCustom
        src={artwork?.images?.[0] ?? artwork?.image ?? null}
        imageStyle={classes.image}
        loadingStyle={classes.loadingImage}
        iconLoadingStyle={classes.iconImage}
        errorStyle={classes.errorStyle}
      />

      <GridArtworkInfo
        artwork={artwork}
        certificate={certificate}
      />
    </>
  );
};

export default CertificateArtworkInfo;
