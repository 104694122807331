import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import ImageAttachmentDisplay from 'commons/ImageAttachmentDisplay';
import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import Section from 'features/Certificate/Section';
import { getTextEdition } from 'utils/utils';

import useStyles from './styles';

const ArtworkInformation = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { artwork, edition, images, key_images } = data;
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [imageDisplayItem, setImageDisplayItem] = useState('');

  const handleClickImageDisplay = (src) => {
    setImageDisplayItem(src);
    setIsShowDialog(true);
  };

  const handleCancelDialog = () => {
    setIsShowDialog(false);
  };

  return (
    <Section
      title={t('artwork_information')}
      wrapperStyle={classes.wrapper}
    >
      <Stack
        direction="row"
        sx={{ mt: 2 }}
        spacing={5}
      >
        <div>
          <Text type="H4">{t('artist_name')}</Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('artwork_title')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('measurement')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('medium')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('creation_year')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('edition_number')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('attachments')}
          </Text>
        </div>

        <div>
          <Text
            type="H4"
            data-testid={TESTID.ARTIST_NAME}
          >
            {artwork?.artist_artwork?.artist_name ||
              artwork?.artist_tag_request?.request_to?.name ||
              '--'}
          </Text>
          <Text
            type="H4"
            mt={16}
            data-testid={TESTID.ARTWORK_TITLE}
          >
            {artwork?.title || '--'}
          </Text>
          <Text
            type="H4"
            mt={16}
            data-testid={TESTID.MEASUREMENT}
          >
            {artwork?.size?.height && artwork?.size?.width
              ? artwork?.size?.depth
                ? `${artwork?.size?.height} cm x ${artwork?.size?.width} cm x ${artwork?.size?.depth} cm`
                : `${artwork?.size?.height} cm x ${artwork?.size?.width} cm`
              : '--'}
          </Text>
          <Text
            type="H4"
            mt={16}
            data-testid={TESTID.MEDIUM}
          >
            {artwork?.medium?.name || '--'}
          </Text>
          <Text
            type="H4"
            mt={16}
            data-testid={TESTID.CREATION_YEAR}
          >
            {artwork?.year_created || artwork?.period_created || '--'}
          </Text>
          <Text
            type="H4"
            mt={16}
            data-testid={TESTID.EDITION}
          >
            {getTextEdition(t, edition?.edition_number, artwork?.total_edition)}
          </Text>

          <ImageAttachmentDisplay
            images={images}
            keyImages={key_images}
            onClickImageDisplay={handleClickImageDisplay}
          />
        </div>
      </Stack>
      <DialogCustom
        isShowDialog={isShowDialog}
        textCancel={t('cancel')}
        onCancel={handleCancelDialog}
        maxWidth={'md'}
      >
        <img
          src={imageDisplayItem}
          className={classes.dialogImageItem}
        />
      </DialogCustom>
    </Section>
  );
};

export default ArtworkInformation;
