import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const TextInfo = ({ value }) => {
  const classes = useStyles();

  if (!value) {
    return null;
  }

  return (
    <Text
      className={classes.line}
      type="xs"
    >
      {value}
    </Text>
  );
};

export default TextInfo;
