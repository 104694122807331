import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapForm: {
    marginTop: 16,
  },
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
    fontWeight: 500,
  },
  textArtistActive: {
    marginLeft: 20,
  },
  textTitle: {
    marginBottom: 10,
  },
  textField: {
    '& .MuiInputBase-input': {
      '&.Mui-disabled': {
        background: Colors.Grey1,
      },
    },
  },
}));

export default useStyles;
