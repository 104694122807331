import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LocalSeeIcon from '@mui/icons-material/LocalSee';
import { Avatar, Box, MenuItem, MenuList } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Text from 'commons/Text';
import Colors from 'configs/Colors';
import { TESTID, USER_ROLE_BY_ID } from 'configs/Constant';
import useImageCompression from 'hooks/useImageCompression';
import useRole from 'hooks/useRole';
// import { ReactComponent as Attach } from 'images/attach.svg';
// import { ReactComponent as Bell } from 'images/bell.svg';
// import { ReactComponent as Key } from 'images/key.svg';
// import { ReactComponent as Trash } from 'images/trash.svg';
import { ReactComponent as User } from 'images/user.svg';
import Routes from 'utils/Route';

import useStyles from './styles';

const MyAccountNav = ({ user, userFormState }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { compressImage } = useImageCompression();
  const { isArtist } = useRole();

  const { setValue, watch } = userFormState;

  const avatar = useMemo(() => {
    return watch('avatar');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('avatar')]);

  const navItem = [
    {
      icon: User,
      text: t('my_account'),
      active: true,
    },
    // {
    //   icon: Key,
    //   text: t('change_password'),
    // },
    // {
    //   icon: Bell,
    //   text: t('notification_preferences'),
    // },
    // {
    //   icon: Attach,
    //   text: t('social_connects'),
    // },
    // {
    //   icon: Trash,
    //   text: t('delete_account'),
    // },
  ];

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const compressedFile = await compressImage(file);
    const image = URL.createObjectURL(compressedFile);
    setValue('avatar', image);
    setValue('avatarUploadFile', compressedFile);
  };

  const handleClickEditArtistProfile = () => {
    navigate(Routes.EditArtistProfile);
  };

  useEffect(() => {
    return () => URL.revokeObjectURL(avatar);
  }, [avatar]);

  return (
    <Box className={classes.wrapMyAccountNav}>
      <label
        htmlFor="upload-image"
        className={classes.wrapBoxUpload}
      >
        <Avatar
          src={avatar}
          className={classes.avatar}
          testid={TESTID.USER_AVATAR}
        />

        <input
          id="upload-image"
          hidden
          accept=".png, .jpeg, .jpg"
          type="file"
          onChange={handleFileUpload}
          testid={`${TESTID.INPUT_FIELD}_UPLOAD_AVATAR`}
        />

        <LocalSeeIcon className={classes.iconCamera} />
        <div className={classes.overPlay}></div>
      </label>

      <Text
        fontWeightBold
        type="H2"
        className={classes.userName}
      >
        {user?.name}
      </Text>

      <div className={classes.userRole}>
        <Text
          fontWeightMedium
          type="Body"
        >
          {t(`${USER_ROLE_BY_ID[user?.role]}`)}
        </Text>

        <FiberManualRecordIcon
          sx={{ width: 6 }}
          className={classes.iconDot}
        />

        {isArtist && (
          <Text
            onClick={handleClickEditArtistProfile}
            fontWeightBold
            type="Body"
            color={Colors.Blue6}
            className={classes.pointer}
          >
            {t('edit_profile')}
          </Text>
        )}
      </div>

      <div className={classes.line} />

      <MenuList className={classes.wrapBoxNav}>
        {navItem.map((item, index) => {
          const NavIcon = item?.icon;

          return (
            <MenuItem
              className={clsx(classes.menuItem, {
                [classes.menuItemActive]: item?.active,
              })}
              key={index}
            >
              <NavIcon className={classes.navIcon} />
              <Text className={classes.menuItemText}>{item?.text}</Text>
            </MenuItem>
          );
        })}
      </MenuList>
    </Box>
  );
};

export default MyAccountNav;
