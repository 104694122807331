import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import useDisplayText from 'commons/FilterArtwork/hooks/useDisplayText';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, CATEGORY_KEY } from 'configs/FilterArtwork';

import CommonTag from '../CommonTag';

const { CATEGORY } = ARTWORK_FILTER_PARAMS;

const CategoryTag = () => {
  const { data: categories = [] } = useQuery({
    queryKey: [API.ARTWORK.CATEOGRY],
  });
  const getDisplayText = useDisplayText(CATEGORY_KEY.CHECKED, null, true);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedCategoryParams = searchParams.get(CATEGORY)?.split(',') || [];
  const selectedCategory = categories.filter((category) =>
    selectedCategoryParams.includes(category[CATEGORY_KEY.CHECKED]),
  );

  const handleRemoveCategory = (category) => {
    const newSelectedCategoryParams = [...selectedCategoryParams].filter(
      (item) => item !== category[CATEGORY_KEY.CHECKED],
    );

    if (newSelectedCategoryParams.length) {
      searchParams.set(CATEGORY, newSelectedCategoryParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(CATEGORY);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      {selectedCategory.map((category) => (
        <CommonTag
          key={category[CATEGORY_KEY.CHECKED]}
          text={getDisplayText(category)}
          onRemoveFilter={() => handleRemoveCategory(category)}
        />
      ))}
    </>
  );
};

export default CategoryTag;
