import { makeStyles } from '@mui/styles';

import mediaQueries from 'configs/mediaQueries';

const HEADER_HEIGHT = 79;

const useStyles = makeStyles(() => ({
  wrap: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    padding: '0px 8.333%',
  },
  textMainError: {
    fontFamily: 'Afacad',
    fontSize: 260,
    fontWeight: 700,
    lineHeight: '21.538%',
    letterSpacing: '33.8px',
  },
  textPageNotFound: {
    fontFamily: 'Afacad',
  },
  boxText: {
    marginRight: 104,
  },
  button: {
    '&.MuiButton-root': {
      marginTop: 16,
      padding: '12px 20px',
      fontSize: 16,
      lineHeight: '150%',
    },
  },

  [mediaQueries.tabletMedium]: {
    textMainError: {
      fontSize: 160,
      lineHeight: 1.5,
      letterSpacing: '33.8px',
      textAlign: 'center',
    },
    boxText: {
      marginRight: 0,
      textAlign: 'center',
    },
  },
}));

export default useStyles;
