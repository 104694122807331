import { Stack } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import FormInput from 'commons/Form/FormInput';
import FormSelectController from 'commons/FormHook/FormSelectController';
import Text from 'commons/Text';
import { isValidEmailAddress } from 'utils/Validation';

import useStyles from './styles';

const AddUserInfo = ({ ownerHookForm, onSearchAgain }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { control, register, formState, watch } = ownerHookForm;

  const statusOption = [
    {
      name: t('gallery_owner'),
      value: 2,
    },
    {
      name: t('collector'),
      value: 4,
    },
  ];

  const email = watch('newOwner.contactInfo');
  const isEmail = isValidEmailAddress(email);

  return (
    <>
      {isEmail && (
        <Text
          type={'sm'}
          mt={8}
        >
          <Trans
            i18nKey="we_will_send_your_request"
            values={{ email }}
          />
        </Text>
      )}

      <Stack
        direction={'row'}
        spacing={0.5}
        sx={{ mt: 1 }}
      >
        <FormInput
          {...register('newOwner.name')}
          fullWidth
          placeholder={t('enter_owner_name')}
          error={!!formState?.errors?.newOwner?.name}
          helperText={formState?.errors?.newOwner?.name?.message}
          textFieldStyle={classes.textFieldStyle}
          wrapFormInputStyle={classes.wrapFormInputExtendWith}
        />

        <FormSelectController
          control={control}
          options={statusOption}
          name="newOwner.role"
          valueKey="value"
          textDisplayKey="name"
          placeholder={t('select_role')}
          error={!!formState?.errors?.newOwner?.role}
          helperText={formState?.errors?.newOwner?.role?.message}
          formControlStyle={classes.selectFormControl}
          selectStyle={classes.select}
        />
      </Stack>

      <FormInput
        {...register('newOwner.contactInfo')}
        placeholder={t('enter_artist_email_phone_number')}
        error={!!formState?.errors?.newOwner?.contactInfo}
        helperText={formState?.errors?.newOwner?.contactInfo?.message}
        textFieldStyle={classes.textFieldStyle}
        wrapFormInputStyle={classes.wrapFormInput}
      />

      <Text
        mt={8}
        type="xs"
        onClick={onSearchAgain}
        clickAble
      >
        <Trans
          i18nKey="want_to_change_name_search_again"
          values={{ search: t('search_again') }}
        />
      </Text>
    </>
  );
};

export default AddUserInfo;
