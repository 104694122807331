import React from 'react';
import { useTranslation } from 'react-i18next';

import FormInput from 'commons/Form/FormInput';
import Text from 'commons/Text';
import { ReactComponent as CopyIcon } from 'images/copy_icon.svg';
import { ToastTopHelper } from 'utils/utils';

import useStyles from './styles';

const ShareSocialByLink = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleFormInputClick = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      ToastTopHelper.success(t('link_has_been_copied'));
    });
  };

  return (
    <>
      <Text mt={16}>{t('or_copy_this_link')}</Text>
      <FormInput
        value={window.location.href}
        onClick={handleFormInputClick}
        textFieldStyle={classes.textField}
        iconEndAdorment={<CopyIcon className={classes.copyIcon} />}
      />
    </>
  );
};

export default ShareSocialByLink;
