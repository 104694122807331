import clsx from 'clsx';
import React from 'react';

import useStyles from './styles';

const Section = ({ title, required, wrapperStyle, children }) => {
  const classes = useStyles();

  return (
    <section className={clsx(classes.wrapper, wrapperStyle)}>
      <h2 className={classes.title}>
        {title}
        {required && <span className={classes.required}>*</span>}
      </h2>

      {children}
    </section>
  );
};

export default Section;
