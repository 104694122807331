import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 0,
  },
  labelStyle: {
    padding: 0,
  },
  autocompleteStyle: {
    '&.MuiAutocomplete-root': {
      '& .MuiOutlinedInput-root': {
        padding: 3.5,
      },
    },
  },
  textArtist: {
    fontSize: 14,
  },
  formControlStyle: {
    '&.MuiFormControl-root': {
      width: '100%',
      marginTop: 8,
    },
    '& .MuiSelect-select': {
      padding: 11,
    },
  },
}));

export default useStyles;
