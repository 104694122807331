import clsx from 'clsx';
import React from 'react';

import useStyles from '../styles';

const ImageDisplay = ({ src, imageStyle, onClickImageDisplay }) => {
  const classes = useStyles();

  const handleImageClick = () => {
    onClickImageDisplay && onClickImageDisplay(src);
  };

  return (
    <img
      src={src}
      onClick={handleImageClick}
      className={clsx(classes.image, imageStyle)}
    />
  );
};

export default ImageDisplay;
