import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import { MenuItem } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DialogLocation from 'commons/DialogLocation';
import FormSelectController from 'commons/FormHook/FormSelectController';
import Text from 'commons/Text';
import API from 'configs/API';
import useCreateLocation from 'hooks/useCreateLocation';
import { locationSchema } from 'pages/EditArtwork/validates/locationSchema';

import useStyles from './styles';

const EditArtworkLocation = ({ options, control, name }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.account.user);

  const optionsLocationName = options?.map((option) => option?.location) || [];

  const {
    control: locationControl,
    reset,
    formState,
    handleSubmit,
  } = useForm({
    defaultValues: {
      location: '',
    },
    mode: 'onChange',
    resolver: yupResolver(locationSchema({ locationOptions: optionsLocationName })),
  });
  const { field } = useController({ name: 'location', control: locationControl });

  const queryClient = useQueryClient();
  const { mutate: createLocation } = useCreateLocation();

  const [isShowDialog, setIsShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenDialogLocation = () => {
    setIsShowDialog(true);
  };

  const handleSubmitLocation = async (data) => {
    createLocation(
      { user: user?.id, location: data?.location },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [API.ARTWORK.EDITION] });
          queryClient.invalidateQueries({ queryKey: [API.AUTH.LOCATION] });
          reset();
          setIsShowDialog(false);
        },
        onSettled: () => {
          setLoading(false);
        },
      },
    );
  };

  const handleCancelDialog = () => {
    reset();
    setIsShowDialog(false);
  };

  return (
    <>
      <FormSelectController
        options={options}
        control={control}
        name={name}
        valueKey="id"
        textDisplayKey="location"
        placeholder={t('select')}
        formControlStyle={classes.formSelectControl}
        selectStyle={classes.select}
      >
        <MenuItem
          value={null}
          onClick={handleOpenDialogLocation}
        >
          <AddIcon />
          <Text ml={10}>{t('create_new_artwork_location')}</Text>
        </MenuItem>
      </FormSelectController>

      <DialogLocation
        loading={loading}
        isShowDialog={isShowDialog}
        value={field.value}
        onChange={field.onChange}
        onSubmit={handleSubmit(handleSubmitLocation)}
        onCancel={handleCancelDialog}
        error={!!formState?.errors?.location}
        helperText={formState?.errors?.location?.message}
      />
    </>
  );
};

export default EditArtworkLocation;
