import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapColLeft: {
    width: '35%',
  },
  boxImage: {
    aspectRatio: '1/1',
    border: `1px solid ${Colors.Grey3}`,
  },
  reviewAvatar: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
  },
  nickNameUserProfile: {
    marginLeft: 8,
  },
  wrapNameUserProfile: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: 8,
  },
  wrapTextLiveAndWork: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  textLiveAndWork: {
    color: Colors.Grey8,
    letterSpacing: '0.64px',
  },
  button: {
    '&.MuiButton-root': {
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
      width: '49%',
      whiteSpace: 'nowrap',
    },
  },
  buttonEdit: {
    '&.MuiButton-root': {
      fontWeight: 400,
      marginTop: 12,
      padding: '8px 16px',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  iconAddImage: {
    '&.MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
  dividerStyle: {
    '&.MuiDivider-root': {
      color: Colors.Grey3,
      marginTop: 24,
      width: '100%',
    },
  },
  wrapItemContact: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
  },
  textItemContact: {
    fontSize: 16,
    color: Colors.Grey8,
    marginLeft: 16,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.64px',
  },
  avatarIcon: {
    '&.MuiSvgIcon-root': {
      width: '100%',
      height: '100%',
      color: Colors.Grey3,
    },
  },
  textButton: {
    marginLeft: 8,
  },

  // ArtistSocial
  wrapArtistSocial: {
    marginTop: 16,
    justifyContent: 'center',
    display: 'flex',
  },
  socialItem: {
    color: Colors.Primary,
    marginRight: 16,
  },

  [mediaQueries.laptop]: {
    iconAddImage: {
      '&.MuiSvgIcon-root': {
        width: 20,
        height: 20,
      },
    },
  },
  [mediaQueries.tabletMedium]: {
    wrapColLeft: {
      width: '100%',
    },
  },
}));

export default useStyles;
