import { yupResolver } from '@hookform/resolvers/yup';
import momentTimezone from 'moment-timezone';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import API from 'configs/API';
import { useRequestCertificateContext } from 'contexts/RequestCertificateContext';
import ShippingInfo from 'features/Certificate/ShippingInfo';
import TermAndCondition from 'features/Certificate/TermAndCondition';
import useInviteAndTrackingInvitation from 'hooks/useInviteAndTrackingInvitation';
import Routes from 'utils/Route';
import { isValidEmailAddress, isValidPhoneNumber } from 'utils/Validation';
import { axiosPost } from 'utils/apis/axios';
import { ToastTopHelper, generateStrongPassword } from 'utils/utils';

import DeliveryAction from '../DeliveryAction';
import useStyles from './styles';
import { shippingSchema } from './validations';

const DeliveryMainContent = ({ onBackStep, user }) => {
  const { control, register, handleSubmit, formState } = useForm({
    defaultValues: {
      checked: false,
      recipient: user?.name || '',
      address: user?.place_of_birth || '',
      phoneNumber: user?.phone_number || '',
    },
    mode: 'onChange',
    resolver: yupResolver(shippingSchema),
  });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const tz = momentTimezone.tz.guess();

  const { ownerInfor, artworkInfo } = useRequestCertificateContext();
  const { handleInviteUser } = useInviteAndTrackingInvitation();
  const { artworkId } = useParams();

  const handleRequestCertificate = async (formData, infoNewUser) => {
    const { success, data } = await axiosPost(API.ARTWORK.REQUEST_CERTIFICATE(artworkId), formData);

    if (success) {
      const isEmail = isValidEmailAddress(ownerInfor?.newOwner?.contactInfo);

      if (ownerInfor?.newOwner && isEmail) {
        handleInviteUser({
          recipient: infoNewUser?.user,
          password: infoNewUser?.password,
          certificateId: data?.id,
        });
      }

      ToastTopHelper.success(t('request_create_certificate_successfully'));
      const searchParams = createSearchParams({ certificate: 'show' });

      navigate({
        pathname: Routes.ArtworkDetail(data?.code),
        search: searchParams.toString(),
      });
    }
  };

  const registerNewUser = async (formData) => {
    const { success, data } = await axiosPost(API.AUTH.REGISTER_EMAIL, formData);

    if (success) {
      return data.user;
    }
    return null;
  };

  const onSubmit = async (data) => {
    let newUser = null;

    const password = generateStrongPassword();

    if (ownerInfor?.newOwner) {
      const isEmail = isValidEmailAddress(ownerInfor?.newOwner?.contactInfo);
      const isPhone = isValidPhoneNumber(ownerInfor?.newOwner?.contactInfo);

      const formRegisterUser = {
        role: ownerInfor?.newOwner?.role,
        password1: password,
        password2: password,
        time_zone: tz,
        name: ownerInfor?.owner?.name,
      };

      if (isEmail) {
        formRegisterUser.email = ownerInfor?.newOwner?.contactInfo;
      }

      if (isPhone) {
        formRegisterUser.phone_number = ownerInfor?.newOwner?.contactInfo;
      }

      newUser = await registerNewUser(formRegisterUser);
      if (!newUser) {
        return;
      }
    }

    const formData = {
      owner_info: {
        user: newUser?.id || ownerInfor?.owner?.id,
        year_of_birth: ownerInfor?.yearOfBirth,
        address: ownerInfor?.address,
        contract_number: ownerInfor?.contractNumber,
      },
      shipping_info: {
        recipient: data?.recipient,
        address: data?.address,
        phone_number: data?.phoneNumber,
      },
      edition_id: artworkInfo.edition.id,
      issued_to_id: newUser?.id || ownerInfor?.owner?.id,
    };

    const infoNewUser = {
      user: newUser,
      password: password,
    };

    handleRequestCertificate(formData, infoNewUser);
  };

  return (
    <>
      <ShippingInfo
        errors={formState?.errors}
        register={register}
        wrapperStyle={classes.shippingWrapper}
      />
      <TermAndCondition
        control={control}
        error={!!formState?.errors?.checked}
        helperText={formState?.errors?.checked?.message}
      />
      <DeliveryAction
        onBackStep={onBackStep}
        onSubmit={handleSubmit(onSubmit)}
      />
    </>
  );
};

export default DeliveryMainContent;
