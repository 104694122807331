import clsx from 'clsx';
import React from 'react';

import { TESTID } from 'configs/Constant';

import useStyles from './styles';

const Text = ({
  children,
  type = 'md',
  ml,
  mr,
  mt,
  mb,
  className,
  ucfirst,
  uppercase,
  lowercase,
  numberOfLines,
  variant = 'p',
  title,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
  color,
  clickAble,
  ...rest
}) => {
  const classes = useStyles();

  const Element = variant;

  let marginLeft = null;
  if (ml) {
    marginLeft = ml;
  }
  let marginRight = null;
  if (mr) {
    marginRight = mr;
  }

  let marginTop = null;
  if (mt) {
    marginTop = mt;
  }
  let marginBottom = null;
  if (mb) {
    marginBottom = mb;
  }

  let _children = null;
  if (typeof children === 'string') {
    if (ucfirst) {
      _children = children.charAt(0).toUpperCase() + children.slice(1).toLowerCase();
    } else if (uppercase) {
      _children = children.toUpperCase();
    } else if (lowercase) {
      _children = children.toLowerCase();
    }
  }
  const styleNumberOfLines = numberOfLines
    ? {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: numberOfLines,
      }
    : null;

  const getClasses = {
    [classes.text3Xl]: type === '3xl',
    [classes.text2Xl]: type === '2xl',
    [classes.textXl]: type === 'xl',
    [classes.textLg]: type === 'lg',
    [classes.textMd]: type === 'md',
    [classes.textSm]: type === 'sm',
    [classes.textXs]: type === 'xs',
    [classes.text2Xs]: type === '2xs',
    [classes.fontWeightRegular]: fontWeightRegular,
    [classes.fontWeightMedium]: fontWeightMedium,
    [classes.fontWeightBold]: fontWeightBold,
    [classes.title]: title,
    [classes.clickAble]: clickAble,
  };

  return (
    <Element
      style={{
        marginLeft,
        marginRight,
        marginTop,
        marginBottom,
        color,
        ...styleNumberOfLines,
      }}
      className={clsx(getClasses, className)}
      {...rest}
      testid={TESTID.TEXT_COMMON}
    >
      {_children || children}
    </Element>
  );
};

export default Text;
