import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapFormInput: {
    marginTop: 0,
  },
  wrapContentSection: {
    paddingTop: 24,
  },
  btnSubmit: {
    '&.MuiButton-root': {
      display: 'block',
      marginLeft: 'auto',
      padding: '8px 16px',
      width: 90,
      height: 44,
    },
  },
}));

export default useStyles;
