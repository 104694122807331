import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import useUpdateUserVisibleSetting from 'pages/Gallery/hooks/useUpdateUserVisibleSetting';
import { ToastTopHelper } from 'utils/utils';

import SettingItem from '../SettingItem';

const DialogSetting = ({ isShowDialog, onCancel, visibleSetting, onCloseDialog }) => {
  const { t } = useTranslation();

  const [settingData, setSettingData] = useState({
    isPublicArtwork: visibleSetting?.is_public_artwork,
    isPublicProfile: visibleSetting?.is_public_profile,
    isPublicSocialMedia: visibleSetting?.is_public_social_media,
  });
  const { mutate: updateUserVisibleSetting, isPending } = useUpdateUserVisibleSetting();

  const handleUpdateUserVisibleSetting = () => {
    updateUserVisibleSetting(
      {
        is_public_artwork: settingData.isPublicArtwork,
        is_public_profile: settingData.isPublicProfile,
        is_public_social_media: settingData.isPublicSocialMedia,
      },
      {
        onSuccess: () => {
          ToastTopHelper.success(t('update_successfully'));
          onCloseDialog();
        },
      },
    );
  };

  const handleSettingChange = ({ name, value }) => {
    setSettingData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <DialogCustom
      isShowDialog={isShowDialog}
      dialogTitle={t('visibility_settings')}
      onCancel={onCancel}
      textCancel={t('cancel')}
      textSubmit={t('update')}
      onSubmit={handleUpdateUserVisibleSetting}
      loading={isPending}
    >
      <SettingItem
        label={t('artist_profile')}
        i18nKey="text_setting_artist_profile_visible"
        isPublic={settingData?.isPublicProfile}
        onChangeSetting={handleSettingChange}
        name="isPublicProfile"
      />
      <SettingItem
        label={t('artworks')}
        i18nKey="text_setting_artworks_visible"
        isPublic={settingData?.isPublicArtwork}
        onChangeSetting={handleSettingChange}
        name="isPublicArtwork"
      />
      <SettingItem
        label={t('social_media_links')}
        i18nKey="text_setting_follower_visible"
        isPublic={settingData?.isPublicSocialMedia}
        onChangeSetting={handleSettingChange}
        name="isPublicSocialMedia"
      />
    </DialogCustom>
  );
};

export default DialogSetting;
