import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapArtworkImage: {
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 24,
  },
  viewImage: {
    height: 'calc(100vh - 351px)',
    width: 'auto',
    maxWidth: '100%',
    objectFit: 'contain',
    borderRadius: 'unset',
  },
  listImage: {
    marginTop: 16,
  },
  imageItem: {
    height: 48,
    width: 48,
    objectFit: 'cover',
    cursor: 'pointer',
  },
  active: {
    border: `3px solid ${Colors.Primary}`,
  },
  iconLoadingStyle: {
    '&.MuiSvgIcon-root': {
      width: 50,
      height: 50,
    },
  },

  [mediaQueries.tabletLarge]: {
    viewImage: {
      height: 'auto',
      width: '100%',
    },
  },
  [mediaQueries.mobile]: {
    imageItem: {
      height: 60,
      width: 60,
    },
  },
  [mediaQueries.miniMobile]: {
    imageItem: {
      height: 50,
      width: 50,
    },
  },
}));

export default useStyles;
