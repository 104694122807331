import { QueryClient } from '@tanstack/react-query';
import { experimental_createPersister } from '@tanstack/react-query-persist-client';

import { axiosGet } from 'utils/apis/axios';
import { MS_IN_DAY } from 'utils/time';

export const defaultQueryFn = async ({ queryKey }) => {
  const [api, queryParams] = queryKey;
  const isObject = queryParams && typeof queryParams === 'object' && !Array.isArray(queryParams);
  const params = isObject ? queryParams : null;

  const { data } = await axiosGet(false, api, {
    params,
  });

  return data;
};

export const createPersister = (option) => {
  return experimental_createPersister({
    storage: window.localStorage,
    maxAge: MS_IN_DAY * 1,
    ...option,
  });
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: MS_IN_DAY * 1,
      queryFn: defaultQueryFn,
      retry: false,
    },
  },
});

export default queryClient;
