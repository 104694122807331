import MaterialBreadcrumbs from '@mui/material/Breadcrumbs';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import Text from 'commons/Text';

import useStyles from './styles';

const Breadcrumbs = ({ items, itemStyle, breadcrumbsStyle }) => {
  const classes = useStyles();

  return (
    <MaterialBreadcrumbs
      aria-label="breadcrumb"
      className={breadcrumbsStyle}
    >
      {items?.map((item, index) => (
        <Link
          key={index}
          to={item?.to}
          className={clsx(classes.linkItem, itemStyle, {
            [classes.linkItemActive]: item?.active,
          })}
        >
          <Text className={classes.textLink}>{item?.text}</Text>
        </Link>
      ))}
    </MaterialBreadcrumbs>
  );
};

export default Breadcrumbs;
