import { Stack } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import FilterPanel from './FilterPanel';
import ManageTabPanel from './ManageTabPanel';
import useStyles from './styles';

const ManageArtworkPanel = ({ showFilter, onShowFilterChange, numberFilter }) => {
  const classes = useStyles();

  return (
    <Stack
      direction="row"
      className={clsx(classes.stack, classes.stackSpace)}
    >
      <ManageTabPanel />

      <FilterPanel
        showFilter={showFilter}
        onShowFilterChange={onShowFilterChange}
        numberFilter={numberFilter}
      />
    </Stack>
  );
};

export default ManageArtworkPanel;
