import { Box, ImageList, useMediaQuery } from '@mui/material';
import React from 'react';

import { BREAKPOINTS } from 'configs/Constant';

import CollectionArtworkItem from '../CollectionArtworkItem';

const CollectionArtworkList = ({ collection }) => {
  const isLaptop = useMediaQuery(BREAKPOINTS.laptop);
  const isMobile = useMediaQuery(BREAKPOINTS.mobile);

  const artworks = collection?.artworks || [];

  const getColumns = () => {
    if (isLaptop) {
      return 4;
    }
    if (isMobile) {
      return 1;
    }
    return 2; //tablet
  };

  return (
    <Box sx={{ mt: 5 }}>
      <ImageList
        variant="masonry"
        cols={getColumns()}
        gap={40}
      >
        {artworks?.map((artwork) => (
          <CollectionArtworkItem
            artwork={artwork}
            key={artwork?.id}
          />
        ))}
      </ImageList>
    </Box>
  );
};

export default CollectionArtworkList;
