import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  textHeading: {
    letterSpacing: '1.26px',
  },
  textHeadingTwo: {
    marginTop: 3,
    letterSpacing: '0.96px',
  },
  subText: {
    letterSpacing: '0.4px',
  },
  logoPlatform: {
    height: 48,
  },
  textCertificateNumber: {
    letterSpacing: '0.4px',
  },
  textNameArtist: {
    marginTop: 64,
    minHeight: 32,
    letterSpacing: '1.12px',
  },
  certificateBadge: {
    position: 'absolute',
    top: -30,
    right: 0,
  },
  hideText: {
    opacity: 0,
    visibility: 'hidden',
  },
  textLetterSpacing: {
    letterSpacing: '0.56px',
  },
}));

export default useStyles;
