import { useLayoutEffect, useState } from 'react';

const useScrollSpy = (elements, offset = 0) => {
  const [activeElement, setActiveElement] = useState('');

  const clamp = (value) => Math.max(0, value);

  const isBetween = (value, top, bottom) => {
    return value >= top && value <= bottom;
  };

  useLayoutEffect(() => {
    const listener = () => {
      const scroll = window.scrollY;

      const position = elements
        .map((element) => {
          const rect = element.getBoundingClientRect();
          const top = clamp(rect.top + scroll - offset);
          const bottom = clamp(rect.bottom + scroll - offset);

          return { element, top, bottom };
        })
        .find(({ top, bottom }) => isBetween(scroll, top, bottom));

      setActiveElement(position?.element || '');
    };

    listener();

    window.addEventListener('resize', listener);
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('resize', listener);
      window.removeEventListener('scroll', listener);
    };
  }, [elements, offset]);

  return activeElement;
};

export default useScrollSpy;
