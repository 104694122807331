import { Box } from '@mui/material';
import React from 'react';

import GridLayout from 'layouts/GridLayout';

import CertificateInfo from './CertificateInfo';
import UpdateCertificateInfo from './UpdateCertificateInfo';

const CertificateInfoStep = ({ onNextStep, artwork, isPending }) => {
  return (
    <Box sx={{ pt: 3 }}>
      <GridLayout>
        <CertificateInfo
          onNextStep={onNextStep}
          artwork={artwork}
        />
        <UpdateCertificateInfo
          artwork={artwork}
          isPending={isPending}
        />
      </GridLayout>
    </Box>
  );
};

export default CertificateInfoStep;
