import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from './styles';

const CertificateFooter = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.wrapFooter}>
      <Text
        fontWeightBold
        className={classes.textFooter}
        mt={24}
      >
        Gladius Art
      </Text>

      <Text className={classes.textFooter}>www.gladiusart.com</Text>
      <Text className={classes.textFooter}>{t('text_certificate_address')}</Text>
      <Text className={classes.textFooter}>[+84] 566 024 888</Text>
      <Text className={classes.textFooter}>contact@gladiusart.com</Text>
      <Text className={classes.textFooter}>{t('all_rights_reserved')}</Text>
    </Box>
  );
};

export default CertificateFooter;
