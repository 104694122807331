import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  groupActionWrapper: {
    marginTop: 16,
  },
  subButtonRightStyle: {
    '&.MuiButton-root': {
      fontSize: '1.6rem',
      lineHeight: '150%',
      letterSpacing: 0.64,
      color: Colors.Red,
      border: `1px solid ${Colors.Red}`,
      '&:hover': {
        color: Colors.Red,
        border: `1px solid ${Colors.Red}`,
      },
    },
  },
  buttonDialog: {
    '&.MuiButton-root': {
      minWidth: 90,
      padding: '10px 16px',
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: 0.64,
    },
  },
  buttonDanger: {
    '&.MuiButton-root': {
      marginLeft: 16,
      background: Colors.Error,
      '&:hover': {
        background: Colors.Error,
      },
    },
  },
}));

export default useStyles;
