import clsx from 'clsx';
import React, { useState, useEffect, useRef } from 'react';

import useStyles from './styles';

const Image = ({ src, alt, imageStyle, onClick, ...props }) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = img.dataset.src;
            observer.unobserve(img);
          }
        });
      },
      {
        threshold: 0.01,
        rootMargin: '75%',
      },
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(imgRef.current);
      }
    };
  }, [imgRef]);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  return (
    <img
      src={src}
      alt={alt}
      className={clsx(classes.image, imageStyle)}
      onLoad={handleImageLoad}
      onClick={onClick}
      style={{
        opacity: loaded ? 1 : 0,
        transition: 'opacity 0.5s ease-in-out',
      }}
      {...props}
    />
  );
};

export default Image;
