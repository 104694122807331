import React from 'react';
import { Controller } from 'react-hook-form';

import SelectCustom from 'commons/SelectCustom';

const FormSelectController = ({ name, control, rules, options, error, onChange, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const { value, onChange: onChangeField } = field;

        const handleChange = (e) => {
          onChangeField(e);

          if (onChange) {
            onChange(e);
          }
        };

        return (
          <SelectCustom
            value={value || ''}
            options={options}
            onChange={handleChange}
            error={error}
            {...props}
          />
        );
      }}
    />
  );
};

export default FormSelectController;
