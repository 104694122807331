import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LanguageIcon from '@mui/icons-material/Language';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Popover, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from './styles';

const DropdownSetting = ({ isPublic, onChangeSetting, name }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleOpenPopover = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSettingPublic = () => {
    handleClosePopover();
    onChangeSetting({ name, value: true });
  };

  const handleSettingPrivate = () => {
    handleClosePopover();
    onChangeSetting({ name, value: false });
  };

  return (
    <>
      <Stack
        direction="row"
        className={classes.wrapBoxSetting}
        spacing={0.5}
        onClick={handleOpenPopover}
      >
        {isPublic ? <LanguageIcon /> : <LockOutlinedIcon />}
        <Text
          type="sm"
          fontWeightMedium
        >
          {isPublic ? 'Public' : 'Private'}
        </Text>
        <ArrowDropDownIcon />
      </Stack>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        <Stack
          direction="row"
          sx={{ alignItems: 'center', cursor: 'pointer' }}
          onClick={handleSettingPublic}
        >
          <LanguageIcon />
          <Text
            type="sm"
            className={classes.textItem}
          >
            {t('public_on_my_gallery')}
          </Text>
        </Stack>

        <Stack
          direction="row"
          sx={{ alignItems: 'center', cursor: 'pointer' }}
          onClick={handleSettingPrivate}
        >
          <LockOutlinedIcon />
          <Text
            type="sm"
            className={classes.textItem}
          >
            {t('private_on_my_gallery')}
          </Text>
        </Stack>
      </Popover>
    </>
  );
};

export default DropdownSetting;
