import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  formInputLabel: {
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.56px',
    color: Colors.Grey10,
  },
  buttonUpdate: {
    '&.MuiButton-root': {
      marginTop: 16,
      padding: '8px 16px',
      width: 128,
    },
  },
  textOwnerInformation: {
    letterSpacing: '0.72px',
    marginTop: 40,
  },
  buttonSearch: {
    '&.MuiButton-root': {
      marginTop: 8,
    },
  },
  inputLabel: {
    padding: '0px 4px',
    marginBottom: 4,
    marginTop: 16,
  },
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
  },
  wrapperStyle: {
    marginTop: -10,
  },
  textFieldStyle: {
    '& .MuiInputBase-input': {
      padding: '12px 14px !important',
    },
  },
  textFieldStyleDisable: {
    '-webkit-text-security': 'disc',
  },
}));

export default useStyles;
