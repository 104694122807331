import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formInputLabel: {
    padding: 0,
    marginBottom: 8,
    fontSize: 14,
    lineHeight: '24px',
  },
  textFieldStyle: {
    '&.MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      padding: '16px 14px',
    },
  },
  wrapFormInput: {
    marginTop: 0,
    position: 'relative',
  },

  wrapFormInput2: {
    marginTop: 16,
    position: 'relative',
  },
  textAdding: {
    marginTop: 8,
    fontSize: 12,
    lineHeight: '18px',
  },
}));

export default useStyles;
