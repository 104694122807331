import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  // ImageLoading
  wrapSkeleton: { position: 'relative' },
  skeletonStyle: {
    '&.MuiSkeleton-root': {
      width: '100%',
      height: '100%',
    },
  },
  imageIcon: {
    '&.MuiSvgIcon-root': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: Colors.Grey2,
      width: 100,
      height: 100,
    },
  },

  // ImageError
  imageError: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.Grey11,
  },

  // ImageDisplay
  image: {
    width: '100%',
    objectFit: 'cover',
    display: 'block',
  },
}));

export default useStyles;
