import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import useDisplayText from 'commons/FilterArtwork/hooks/useDisplayText';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, SUBJECT_KEY } from 'configs/FilterArtwork';

import CommonTag from '../CommonTag';

const { SUBJECT } = ARTWORK_FILTER_PARAMS;

const SubjectTag = () => {
  const { data: subjects = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.SUBJECT, { by_artwork_upload: true }],
  });
  const getDisplayText = useDisplayText(SUBJECT_KEY.DISPLAY, SUBJECT_KEY.DISPLAY_VI);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedSubjectParams = searchParams.get(SUBJECT)?.split(',') || [];
  const selectedSubject = subjects.filter((subject) =>
    selectedSubjectParams.includes(subject[SUBJECT_KEY.CHECKED]?.toString()),
  );

  const handleRemoveCategory = (subject) => {
    const newSelectedSubjectParams = [...selectedSubjectParams].filter(
      (item) => item !== subject[SUBJECT_KEY.CHECKED]?.toString(),
    );

    if (newSelectedSubjectParams.length) {
      searchParams.set(SUBJECT, newSelectedSubjectParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(SUBJECT);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      {selectedSubject.map((subject) => (
        <CommonTag
          key={subject[SUBJECT_KEY.CHECKED]}
          text={getDisplayText(subject)}
          onRemoveFilter={() => handleRemoveCategory(subject)}
        />
      ))}
    </>
  );
};

export default SubjectTag;
