import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPatch } from 'utils/apis/axios';

const updateCertificateRequest = async (id, formData) => {
  const { data } = await axiosPatch(false, API.ARTWORK.CERTIFICATE_REQUEST_DETAIL(id), formData);

  return data;
};

const useUpdateCertificateRequest = (id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => updateCertificateRequest(id, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API.ARTWORK.CERTIFICATE_REQUEST_DETAIL(id)] });
    },
  });
};

export default useUpdateCertificateRequest;
