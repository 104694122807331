import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Divider, Menu, MenuItem, Tooltip } from '@mui/material';
import clsx from 'clsx';
import i18n from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import { changeLanguage } from 'redux/reducer/languageSlice';

import useStyles from '../styles';

const MenuLanguage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const language = useSelector((state) => state?.language?.currentLanguage);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    dispatch(changeLanguage(language));
  };

  return (
    <>
      <Box className={classes.boxMenuLanguage}>
        <Tooltip title={t('language_setting')}>
          <div
            onClick={handleClick}
            className={classes.wrapMenuLanguage}
          >
            <Text
              fontWeightBold
              className={classes.textLanguage}
            >
              {language}
            </Text>
            <ArrowDropDownIcon className={classes.iconArrow} />
          </div>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="language-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        classes={{
          paper: clsx(classes.menuPaper, classes.customMenuPaper),
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        testid={TESTID.MENU_PAPER_PROFILE}
      >
        <Text
          fontWeightBold
          className={classes.textSiteLanguage}
        >
          {t('site_language')}
        </Text>
        <Divider />
        <MenuItem
          onClick={() => handleClickChangeLanguage('en')}
          className={classes.menuLanguage}
        >
          {t('en_english')}
          {language === 'en' && <CheckIcon fontSize="small" />}
        </MenuItem>
        <MenuItem
          onClick={() => handleClickChangeLanguage('vi')}
          className={classes.menuLanguage}
        >
          {t('vi_vietnamese')}
          {language === 'vi' && <CheckIcon fontSize="small" />}
        </MenuItem>
      </Menu>
    </>
  );
};
export default MenuLanguage;
