const mediaQueries = {
  laptopLarge: '@media screen and (max-width: 1599px)',
  laptop: '@media screen and (max-width: 1399px)',
  tabletLarge: '@media screen and (max-width: 1199px)',
  tabletLargeSmallHeight: '@media screen and (max-width: 1199px) and (max-height: 800px)',
  tabletMedium: '@media screen and (max-width: 991px)',
  tablet: '@media screen and (max-width: 767px)',
  mobile: '@media screen and (max-width: 575px)',
  miniMobile: '@media screen and (max-width: 400px)',
};

export default mediaQueries;
