import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  dialogHeader: {
    borderBottom: `1px solid ${Colors.Grey3}`,
  },
  wrapHeaderTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 24,
  },
  closeIcon: {
    '&.MuiSvgIcon-root': {
      cursor: 'pointer',
    },
  },

  dialogContent: {
    '&.MuiDialogContent-root': {
      padding: 24,
    },
  },

  dialogAction: {
    '&.MuiDialogActions-root': {
      padding: 24,
      borderTop: `1px solid ${Colors.Grey3}`,
      justifyContent: 'space-between',
    },
  },

  loading: {
    '&.MuiCircularProgress-root': {
      marginLeft: 4,
    },
  },
  buttonSubmit: {
    '&.MuiButton-root': {
      marginLeft: 8,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
}));

export default useStyles;
