import { Button } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FilterAlt } from 'images/filter_alt.svg';

import SortCollection from '../SortCollection';
import useStyles from './style';

const ManageCollectionFilterPanel = ({ showFilter, onShowFilterChange, numberFilter }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <SortCollection />

      <Button
        variant="outlined"
        onClick={onShowFilterChange}
        className={clsx(classes.btnFilter, {
          [classes.btnFilterActive]: showFilter,
        })}
      >
        <FilterAlt />
        {t('filtered')}
        {!!numberFilter && <div className={classes.numberFilter}>{numberFilter}</div>}
      </Button>
    </div>
  );
};

export default ManageCollectionFilterPanel;
