import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  tableEvenRow: {
    '& .MuiTableCell-body': {
      background: Colors.Grey1,
    },
  },
  tableCell: {
    '&.MuiTableCell-body': {
      position: 'relative',
    },
  },
  viewMore: {
    display: 'inline-block',
    padding: '0 24px',
    color: Colors.Grey10,
    background: Colors.White,
    cursor: 'pointer',
    position: 'relative',
    zIndex: 1,
  },
  backgroundEvenRow: {
    background: Colors.Grey1,
  },
  line: {
    position: 'absolute',
    top: '50%',
    left: 0,
    width: '100%',
    display: 'block',
    borderBottom: `1px dashed ${Colors.Grey6}`,
  },
}));

export default useStyles;
