import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArtworkCol from 'commons/ArtworkCol';
import { STATUS_CERTIFICATE_REQUEST_DISPLAY, STATUS_REQUEST_KEY } from 'configs/Constant';
import Routes from 'utils/Route';
import { formatDateString, getTextEdition } from 'utils/utils';

import useStyles from './styles';

const RequestCertificateRowArtist = ({ certificate, index, page, pageSize }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const displayIndex = (page - 1) * pageSize + (index + 1);
  const formattedIssuedDate = formatDateString(certificate?.created_at);
  const formattedUpdateDate = formatDateString(certificate?.updated_at);

  const handleShowCertificateView = () => {
    navigate(Routes.ReviewCertificateRequest(certificate.id));
  };

  return (
    <>
      <TableRow
        key={index}
        className={clsx(classes.tableRowBorder)}
      >
        <TableCell>{displayIndex}</TableCell>

        <TableCell>
          <ArtworkCol
            id={certificate?.artwork.id}
            image={certificate?.artwork?.image}
            title={certificate?.artwork?.title}
            content={certificate?.code}
            isLink={false}
            contentClassName={classes.textCodeCertificate}
          />
        </TableCell>
        <TableCell>
          {getTextEdition(
            t,
            certificate?.edition?.edition_number,
            certificate?.artwork?.total_edition,
          )}
        </TableCell>
        <TableCell>{certificate?.request_by?.name}</TableCell>
        <TableCell>{formattedIssuedDate}</TableCell>
        <TableCell>{formattedUpdateDate}</TableCell>
        <TableCell
          className={clsx({
            [classes.statusDenied]: certificate?.status === STATUS_REQUEST_KEY['REQUEST_DENIED'],
          })}
        >
          {t(STATUS_CERTIFICATE_REQUEST_DISPLAY[certificate?.status])}
        </TableCell>
        <TableCell>
          <div className={classes.wrapIcon}>
            <Tooltip
              title={t('review_certificate_request')}
              arrow
            >
              <VisibilityOutlinedIcon
                onClick={handleShowCertificateView}
                className={classes.icon}
              />
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RequestCertificateRowArtist;
