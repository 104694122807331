import { TextField } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import SelectCustom from 'commons/SelectCustom';

import useStyles from './styles';

const PriceInput = ({
  options,
  price,
  onChangePrice,
  currency,
  onCurrencyChange,
  textFieldStyle,
  formControlStyle,
  disabled,
  inputProps,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.wrapBoxPrice}>
        <TextField
          value={price}
          onChange={onChangePrice}
          id="outlined-basic"
          placeholder={t('enter_price')}
          variant="outlined"
          inputProps={{
            type: 'number',
            step: '1',
            ...inputProps,
          }}
          className={clsx(classes.textField, textFieldStyle)}
          autoComplete="off"
          disabled={disabled}
        />

        <SelectCustom
          options={options}
          value={currency}
          onChange={onCurrencyChange}
          valueKey="key"
          textDisplayKey="value"
          disabled={disabled}
          formControlStyle={clsx(classes.formControl, formControlStyle)}
        />
      </div>
    </>
  );
};

export default PriceInput;
