import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  select: {
    '&.MuiInputBase-root': {
      borderRadius: '99px',
      background: Colors.Grey2,
      textAlign: 'center',
    },
    '& .MuiSelect-select': {
      padding: 10,
    },
  },
}));

export default useStyles;
