import { FormHelperText } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AutocompleteSearchUser from 'commons/AutocompleteSearchUser';
import API from 'configs/API';
import CustomPaperSearchUser from 'features/Artwork/CustomPaperSearchUser';

import useStyles from './style';

const AutocompleteSearchUserWrapper = ({
  userFilter,
  handleInputSearchUser,
  handleClickPaper,
  handleSelectUser,
  handleClickSelectSaveUser,
  selectedUser,
  checkedArtistUnknown,
  formState,
  isShowCustomPaper = true,
  titleListUser,
  titleSaveUser,
  roleUser,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: listSavedUser = [] } = useQuery({
    queryKey: [API.AUTH.SAVED_USER, { role: roleUser }],
    enabled: isShowCustomPaper,
  });

  return (
    <div>
      <AutocompleteSearchUser
        userFilter={userFilter}
        placeholder={t('search_legal_name_nickname')}
        onInputChange={handleInputSearchUser}
        PaperComponent={(props) => (
          <CustomPaperSearchUser
            {...props}
            titleSaveUser={titleSaveUser}
            onClickPaper={handleClickPaper()}
            onClickSelectSaveUser={handleClickSelectSaveUser()}
            isShowCustomPaper={isShowCustomPaper}
            listSavedUser={listSavedUser}
          />
        )}
        onChange={handleSelectUser}
        titleList={titleListUser}
        selectUser={selectedUser}
        disabled={checkedArtistUnknown}
        autocompleteStyle={classes.autocompleteStyle}
      />
      <FormHelperText error>
        {formState?.errors.selectedUser?.message || formState?.errors.selectedUser?.name?.message}
      </FormHelperText>
    </div>
  );
};

export default AutocompleteSearchUserWrapper;
