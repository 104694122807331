import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapPage: {
    paddingTop: 24,
    paddingBottom: 24,
    background: Colors.Grey1,
    flex: 1,
  },
  heading: {
    marginTop: 16,
    color: Colors.Grey10,
    fontSize: '2.4rem',
    fontWeight: 500,
    lineHeight: '133.333%',
    letterSpacing: '0.96px',
  },
}));

export default useStyles;
