import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import GridLayout from 'layouts/GridLayout';
import Routes from 'utils/Route';

import MainContentWrapper from '../MainContentWrapper';
import useStyles from './styles';

const InfoReviewCertificateRequest = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(Routes.ManageCertificate);
  };

  return (
    <Box sx={{ pt: 3 }}>
      <GridLayout>
        <MainContentWrapper data={data} />

        <Button
          variant="outlined"
          fullWidth
          className={classes.buttonCancel}
          onClick={handleCancel}
        >
          {t('back_to_manage_certificate')}
        </Button>
      </GridLayout>
    </Box>
  );
};

export default InfoReviewCertificateRequest;
