import React from 'react';

const ShareSocialPlatformItem = ({ shareButton, socialIcon, ...props }) => {
  const ShareButton = shareButton;

  if (!ShareButton) {
    return;
  }

  return <ShareButton {...props}>{socialIcon}</ShareButton>;
};

export default ShareSocialPlatformItem;
