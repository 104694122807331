import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 0,
  },
  textAttachment: {
    fontSize: '1.4rem',
    lineHeight: '142.857%',
    letterSpacing: '0.56px',
  },
  labelStyle: {
    padding: 0,
  },
  wrapSmallUpload: {
    width: '20%',
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${Colors.Primary}`,
    marginTop: 16,
    marginRight: 38,
  },
  disabledUpload: {
    cursor: 'not-allowed',
    color: Colors.Grey6,
  },
  iconAddImage: {
    '&.MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  textUploadMedia: {
    fontSize: '1.4rem',
    fontWeight: 500,
    marginLeft: 8,
  },
  textCanUploadFile: {
    marginTop: 16,
    fontSize: '1.2rem',
    color: Colors.Grey8,
  },
  formInputLabel: {
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.56px',
    color: Colors.Grey10,
  },
  wrapFormInputStyle: {
    marginTop: 1,
  },
  wrapFormInputYearCreated: {
    marginTop: 1,
    width: '100%',
  },
  wrapFormStyle: {
    marginTop: -4,
  },
  autocompleteStyle: {
    '&.MuiAutocomplete-root': {
      '& .MuiOutlinedInput-root': {
        padding: 3.5,
      },
    },
  },
  textTitleMedium: {
    fontSize: '1.4rem',
    lineHeight: '20px',
    letterSpacing: '0.56px',
  },
  textFieldStyle: {
    '& .MuiInputBase-input': {
      padding: '11px 10px',
      fontSize: '1.4rem',
    },
  },
  disableTextFieldStyle: {
    '& .MuiInputBase-input': {
      backgroundColor: Colors.GrayScale10,
    },
  },
  helperText: {
    '&.MuiFormHelperText-root': {
      marginTop: 10,
    },
  },
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 15,
    fontWeight: 500,
  },
  inputLabel: {
    marginBottom: 4,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '22px',
    marginLeft: 0,
  },
  imageCover: {
    width: 100,
    height: 100,
  },
}));

export default useStyles;
