import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapCheckBoxStyle: {
    marginTop: -10,
  },
  textContactInfomation: {
    '&.MuiTextField-root': {
      width: '100%',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 8px',
      lineHeight: 1.7143,
    },
  },
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
    fontWeight: 500,
  },
  textYourContact: {
    fontSize: 14,
    marginBottom: 4,
  },
}));

export default useStyles;
