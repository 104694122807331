import { Table } from '@mui/material';
import React from 'react';

import ManageEditionTableBody from './components/ManageEditionTableBody';
import ManageEditionTableHead from './components/ManageEditionTableHead';
import useStyles from './styles';

const ManageEditionTable = ({ isPending, listArtworkEdition }) => {
  const classes = useStyles();

  return (
    <div className={classes.tableContainer}>
      <Table className={classes.table}>
        <ManageEditionTableHead />
        <ManageEditionTableBody
          isPending={isPending}
          listArtworkEdition={listArtworkEdition}
        />
      </Table>
    </div>
  );
};

export default ManageEditionTable;
