import { Button } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Text from 'commons/Text';
import API from 'configs/API';
import { TESTID } from 'configs/Constant';
import useImageCompression from 'hooks/useImageCompression';
import useUserAccount from 'hooks/useUserAccount';
import { ReactComponent as IconAdd } from 'images/icon_add.svg';
import { axiosGet, axiosPost } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

import useStyles from './styles';

const Banner = ({ isOwner, userBackground }) => {
  const { artistId } = useParams();

  const queryClient = useQueryClient();
  const { updateUserAccount } = useUserAccount();
  const { compressImage } = useImageCompression();

  let imageKey;

  const [background, setBackground] = useState({
    image: userBackground,
    uploadFile: null,
  });

  const classes = useStyles();
  const { t } = useTranslation();

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const compressedFile = await compressImage(file);
    const image = URL.createObjectURL(compressedFile);
    setBackground({
      image,
      uploadFile: compressedFile,
    });
  };

  // case user review same image twice
  const handleClearImageValue = (e) => {
    e.target.value = null;
  };

  const invalidateQueriesUserProfile = () => {
    queryClient.invalidateQueries({
      queryKey: [
        API.AUTH.PROFILE_INFO,
        {
          user_id: artistId,
        },
      ],
    });
  };

  const handleUploadBackgroundSuccess = () => {
    ToastTopHelper.success(t('update_successfully'));
    invalidateQueriesUserProfile();
  };

  const getS3PresignedUrl = async (image) => {
    const [, typeImage] = image.type.split('/');
    const randomKey = `${uuidv4()}.${typeImage}`;
    const { success, data } = await axiosGet(API.AWS.S3_PRESIGNED, {
      params: {
        key: randomKey,
      },
    });
    if (success) {
      imageKey = randomKey;
      return data;
    }
  };

  const handleClickSave = async () => {
    const formData = new FormData();

    const dataS3 = await getS3PresignedUrl(background?.uploadFile);
    if (dataS3 && dataS3.fields) {
      const formDataS3 = new FormData();
      Object.entries(dataS3.fields).forEach(([k, v]) => {
        formDataS3.append(k, v);
      });

      formDataS3.append('file', background?.uploadFile);

      const { success } = await axiosPost(dataS3.url, formDataS3, {
        transformRequest: (req, headers) => {
          delete headers.Authorization;
          return req;
        },
      });
      if (success) {
        formData.append('background', imageKey);
        updateUserAccount(formData, handleUploadBackgroundSuccess);
      }
    }
  };

  const handleCancelUpload = () => {
    setBackground({
      image: userBackground,
      uploadFile: null,
    });
  };

  const handleRemoveBanner = async () => {
    const { success } = await axiosPost(API.AUTH.REMOVE_BANNER);

    if (success) {
      ToastTopHelper.success(t('remove_successfully'));
      invalidateQueriesUserProfile();
    }
  };

  useEffect(() => {
    return () => URL.revokeObjectURL(background?.image);
  }, [background?.image]);

  return (
    <div className={clsx(classes.bannerBox, classes.bannerBoxTextVisible)}>
      <img
        src={background?.image}
        className={clsx(classes.bannerImage, {
          [classes.hidden]: !userBackground && !background?.image,
        })}
        alt="User Banner"
      />

      {isOwner && !background?.uploadFile && (
        <div
          className={clsx(classes.wrapBannerText, {
            [classes.visibilityHidden]: userBackground,
          })}
        >
          {!userBackground && (
            <label
              htmlFor="upload-banner"
              className={classes.addImage}
            >
              <input
                id="upload-banner"
                hidden
                accept=".png, .jpeg, .jpg"
                type="file"
                onChange={handleFileUpload}
                onClick={handleClearImageValue}
                testid={TESTID.INPUT_UPLOAD_BANNER}
              />
              <IconAdd />
            </label>
          )}

          <Text
            type="lg"
            mt={8}
            fontWeightBold
          >
            {userBackground ? t('replace_banner_image') : t('add_a_banner')}
          </Text>

          {userBackground && (
            <div
              className={clsx(classes.wrapButtonEditAction, {
                [classes.visibleHidden]: userBackground,
              })}
            >
              <Button
                component="label"
                variant="contained"
                sx={{ mr: 2 }}
              >
                <input
                  id="upload-banner"
                  hidden
                  accept=".png, .jpeg, .jpg"
                  type="file"
                  onChange={handleFileUpload}
                  onClick={handleClearImageValue}
                  testid={TESTID.INPUT_UPLOAD_BANNER}
                />
                {t('replace')}
              </Button>

              <Button
                className={classes.btnWhite}
                variant="outlined"
                onClick={handleRemoveBanner}
                testid={TESTID.BUTTON_REMOVE_BANNER}
              >
                {t('remove')}
              </Button>
            </div>
          )}
        </div>
      )}

      {background?.uploadFile && (
        <div className={classes.wrapBtnActions}>
          <Button
            variant="contained"
            onClick={handleClickSave}
            testid={TESTID.BUTTON_SAVE}
          >
            {t('save')}
          </Button>
          <Button
            className={classes.btnWhite}
            variant="outlined"
            sx={{ ml: 2 }}
            onClick={handleCancelUpload}
            testid={TESTID.BUTTON_CANCEL_UPLOAD_BANNER}
          >
            {t('cancel')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Banner;
