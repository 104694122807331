import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CheckBoxCustom from 'commons/CheckBoxCustom';
import FormInput from 'commons/Form/FormInput';
import Image from 'commons/Image';
import Measurement from 'commons/Measurement';
import Text from 'commons/Text';
import UploadImage from 'commons/UploadImage';
import API from 'configs/API';
import AutocompleteWithOptionExtendController from 'features/Artwork/AutocompleteWithOptionExtendController';
import Section from 'features/Certificate/Section';
import useUploadImageS3 from 'hooks/useUploadImageS3';

import ImagesGridEditCertificate from '../ImagesGridEditCertificate';
import useStyles from './styles';

const ArtworkInfo = ({
  images,
  keyImages,
  artworkFormState,
  artwork,
  listUploadedImages,
  setListUploadedImages,
  mergedListImages,
  setMergedListImages,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [listImageCertificate, setListImageCertificate] = useState(keyImages || []);

  const { data: mediumDropdown } = useQuery({
    queryKey: [
      API.ARTWORK.FILTER.MEDIUM,
      {
        category: artwork?.category?.key,
      },
    ],
    enabled: !!artwork?.category?.key,
  });

  const { control, register, formState, setValue } = artworkFormState;
  const { generateImageKey } = useUploadImageS3();
  const [checkedPeriod, setCheckedPeriod] = useState(
    artwork?.year_created || artwork?.period_created ? !artwork?.year_created : false,
  );

  const handleUploadImage = async (e) => {
    const file = e?.target?.files[0];
    if (!file || listUploadedImages.length >= 5) {
      return;
    }
    const imageKey = generateImageKey(file);
    const uri = URL.createObjectURL(file);

    const data = {
      uri,
      uploadFile: file,
      imageKey,
    };
    setListUploadedImages([...listUploadedImages, { ...data }]);
  };

  const handleRemoveImage = (objectImage) => {
    if (objectImage?.uri) {
      const listImageClone = [...listUploadedImages];
      const newListImage = listImageClone.filter((item) => {
        if (item?.uri === objectImage?.uri) {
          URL.revokeObjectURL(item?.uri);
        }
        return item?.uri !== objectImage?.uri;
      });
      setListUploadedImages(newListImage);
    } else {
      const listImageClone = [...listImageCertificate];
      const newListImage = listImageClone.filter((item) => {
        return item !== objectImage;
      });
      setListImageCertificate(newListImage);
    }
  };

  const handleChangeYearCreated = (e) => {
    const { value } = e.target;
    setValue('yearCreated', value, { shouldValidate: true });
    setValue('periodYear', '', { shouldValidate: false });
  };

  const handleChangePeriodYear = (e) => {
    const { value } = e.target;
    setValue('periodYear', value, { shouldValidate: true });
    setValue('yearCreated', '', { shouldValidate: false });
  };

  const handleCheckBox = (event) => {
    const checked = event.target.checked;
    setCheckedPeriod(checked);
    if (checked) {
      setValue('yearCreated', '', { shouldValidate: false });
      setValue('periodYear', artwork?.period_created, { shouldValidate: true });
    } else {
      setValue('periodYear', '', { shouldValidate: false });
      setValue('yearCreated', artwork?.year_created, { shouldValidate: true });
    }
  };

  useEffect(() => {
    setMergedListImages((listImageCertificate || []).concat(listUploadedImages));
  }, [listImageCertificate, listUploadedImages, setMergedListImages]);

  return (
    <Section title={t('artwork_information')}>
      <Box sx={{ pt: 3 }}>
        <Text className={classes.inputLabel}>
          {t('artwork_cover')}
          <span className={classes.require}>*</span>
        </Text>
        <Image
          src={artwork?.image}
          imageStyle={classes.imageCover}
        />
      </Box>

      <Box sx={{ pt: 2 }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            lg={6}
          >
            <FormInput
              {...register('artistNameArtwork')}
              label={t('artist_name')}
              isRequired
              wrapFormInputStyle={classes.formControl}
              labelStyle={classes.labelStyle}
              disabled
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            <FormInput
              {...register('titleArtwork')}
              label={t('artwork_title')}
              placeholder={t('please_enter')}
              isRequired
              error={!!formState?.errors.titleArtwork}
              helperText={formState?.errors.titleArtwork?.message}
              labelStyle={classes.formInputLabel}
              wrapFormInputStyle={classes.wrapFormInputStyle}
            />
          </Grid>
        </Grid>
      </Box>

      <Measurement
        register={register}
        formState={formState}
      />

      <Box sx={{ pt: 2 }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            lg={6}
          >
            <AutocompleteWithOptionExtendController
              control={control}
              name={'mediumArtWork'}
              options={mediumDropdown || []}
              isRequired
              categoryProp={artwork?.category}
              apiOption={API.ARTWORK.FILTER.MEDIUM}
              helperText={formState?.errors.mediumArtWork?.message}
              error={!!formState?.errors.mediumArtWork}
              placeholder={t('select_medium_on_material')}
              label={t('medium_on_material')}
              fontWeightTitle={200}
              autocompleteStyle={classes.autocompleteStyle}
              textTitleCustom={classes.textTitleMedium}
              wrapFormStyle={classes.wrapFormStyle}
            />
            <CheckBoxCustom
              textCheckBox={t('show_creation_year_as_period')}
              checked={checkedPeriod}
              onChange={handleCheckBox}
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            {!checkedPeriod && (
              <FormInput
                {...register('yearCreated', { valueAsNumber: true })}
                onChange={handleChangeYearCreated}
                label={t('creation_year')}
                isRequired={!checkedPeriod}
                error={!!formState?.errors?.yearCreated}
                helperText={!checkedPeriod && formState?.errors.yearCreated?.message}
                disabled={checkedPeriod}
                labelStyle={classes.formInputLabel}
                textFieldStyle={clsx(classes.textFieldStyle, {
                  [classes.disableTextFieldStyle]: checkedPeriod,
                })}
                InputProps={{
                  inputProps: { type: 'number', step: '1' },
                }}
                wrapFormInputStyle={classes.wrapFormInputYearCreated}
              />
            )}

            {checkedPeriod && (
              <FormInput
                {...register('periodYear')}
                onChange={handleChangePeriodYear}
                label={t('period_year')}
                isRequired={checkedPeriod}
                error={!!formState?.errors?.periodYear}
                helperText={formState?.errors.periodYear?.message}
                labelStyle={classes.formInputLabel}
                wrapFormInputStyle={classes.wrapFormInputYearCreated}
              />
            )}
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            <FormInput
              {...register('editionVolume', { valueAsNumber: true })}
              label={t('edition_volume')}
              isRequired
              disabled
              error={!!formState?.errors?.editionVolume}
              helperText={formState?.errors.editionVolume?.message}
              InputProps={{
                inputProps: { type: 'number', step: '1' },
              }}
              wrapFormInputStyle={classes.wrapFormInputStyle}
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            <FormInput
              {...register('edition.edition_number', { valueAsNumber: true })}
              label={t('edition_number')}
              isRequired
              disabled
              error={!!formState?.errors?.editionNumber}
              helperText={formState?.errors.editionNumber?.message}
              InputProps={{
                inputProps: { type: 'number', step: '1' },
              }}
              wrapFormInputStyle={classes.wrapFormInputStyle}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ pt: 2 }}>
        <Text className={classes.textAttachment}>{t('attachments')}</Text>
        <UploadImage
          onUploadImage={handleUploadImage}
          accept=".png, .jpeg, .jpg, .pdf"
          disabled={listUploadedImages.length >= 5}
          uploadWrapStyle={clsx(classes.wrapSmallUpload, {
            [classes.disabledUpload]: listUploadedImages.length >= 5,
          })}
        >
          <FileUploadOutlinedIcon className={classes.iconAddImage} />
          <Text className={classes.textUploadMedia}>{t('upload_files')}</Text>
        </UploadImage>
        <Text className={classes.textCanUploadFile}>{t('you_can_upload_maximum_5_files')}</Text>
        <ImagesGridEditCertificate
          listKeyImage={mergedListImages}
          onClickRemoveImage={handleRemoveImage}
          images={images}
        />
      </Box>
    </Section>
  );
};

export default ArtworkInfo;
