import { IconButton } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import Image from 'commons/Image';
import Text from 'commons/Text';
import { getLabelForOption } from 'configs/Constant';
import { ReactComponent as RadioButtonCheckedOutlinedIcon } from 'images/radio_checked.svg';
import { ReactComponent as RadioButtonUncheckedOutlinedIcon } from 'images/radio_unchecked.svg';
import { formatArtworkTitle } from 'utils/utils';

import useStyles from '../styles';

const SelectedArtworksItem = ({ artwork, selectedArtworks, onClick }) => {
  const classes = useStyles();
  const language = useSelector((state) => state?.language?.currentLanguage);

  const { size, medium } = artwork || {};

  const sizeData =
    size?.height &&
    size?.width &&
    `${size?.height} x ${size?.width} ${size?.depth ? ` x ${size?.depth}` : ''}cm `;

  return (
    <div>
      <div
        className={classes.imageContainer}
        onClick={onClick}
      >
        <IconButton className={classes.customCheckbox}>
          {selectedArtworks.some((item) => item.id === artwork.id) ? (
            <RadioButtonUncheckedOutlinedIcon />
          ) : (
            <RadioButtonCheckedOutlinedIcon />
          )}
        </IconButton>
        <Image
          src={artwork.images[0]}
          imageStyle={classes.image}
        />
      </div>
      <Text
        fontWeightBold
        className={classes.titleArtwork}
      >
        {formatArtworkTitle(artwork)}
      </Text>

      <Text
        type="xs"
        mt={8}
      >
        {getLabelForOption(medium, language)} {sizeData}
      </Text>
    </div>
  );
};

export default SelectedArtworksItem;
