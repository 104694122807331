import { Container } from '@mui/material';
import React, { useState } from 'react';

import ArtworkAttribute from './components/ArtworkAttribute';
import BoxUploadImage from './components/BoxUploadImage';
import useStyles from './styles';

const UploadArtwork = () => {
  const classes = useStyles();
  const [listImage, setListImage] = useState([]);

  return (
    <div className={classes.wrapper}>
      <Container className={classes.container}>
        <div className={classes.wrapGrid}>
          <div className={classes.boxUploadImage}>
            <BoxUploadImage setListImage={setListImage} />
          </div>

          <div className={classes.artworkAttribute}>
            <ArtworkAttribute listImage={listImage} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default UploadArtwork;
