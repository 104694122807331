import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormInput from 'commons/Form/FormInput';
import { TESTID } from 'configs/Constant';
import AddRowInput from 'pages/EditArtistProfile/commons/AddRowInput';

import ContentGroup from '../ContentGroup';
import useStyles from './styles';

const Membership = ({ membership, setMembership, errorYear }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClickAdd = () => {
    setMembership([...membership, { year: '', description: '' }]);
  };

  const handleChangeYear = (e, index) => {
    const newPeriodTime = JSON.parse(JSON.stringify(membership));
    newPeriodTime[index].year = e?.target?.value;
    setMembership(newPeriodTime);
  };

  const handleChangeDescription = (e, index) => {
    const newPeriodTime = JSON.parse(JSON.stringify(membership));
    newPeriodTime[index].description = e?.target?.value;
    setMembership(newPeriodTime);
  };

  return (
    <ContentGroup heading={t('membership')}>
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            spacing={2}
          >
            {membership.map((item, index) => (
              <React.Fragment key={index}>
                <Grid
                  item
                  xs={12}
                  lg={3}
                >
                  <FormInput
                    value={item?.year}
                    onChange={(e) => handleChangeYear(e, index)}
                    label={t('year')}
                    placeholder={t('enter_a_year')}
                    wrapFormInputStyle={classes.wrapFormInput}
                    error={!!errorYear(item)}
                    helperText={errorYear(item)}
                    InputProps={{
                      inputProps: { type: 'number', step: '1' },
                    }}
                    testid={`${TESTID.INPUT_FIELD}_YEAR`}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={9}
                >
                  <FormInput
                    value={item?.description}
                    onChange={(e) => handleChangeDescription(e, index)}
                    label={t('description')}
                    placeholder={t('text_placeholder_membership')}
                    wrapFormInputStyle={classes.wrapFormInput}
                    testid={`${TESTID.INPUT_FIELD}_DESCRIPTION`}
                  />
                </Grid>
              </React.Fragment>
            ))}
          </Grid>

          <AddRowInput
            text={t('add_membership')}
            onClick={handleClickAdd}
          />
        </Grid>
      </Grid>
    </ContentGroup>
  );
};

export default Membership;
