import { Box } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AutocompleteCustom from 'commons/AutocompleteCustom';
import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';

import StatusDisplayItem from './StatusDisplayItem';
import useStyles from './styles';

const BoxStatusArtwork = ({ statusOption, status, setStatus }) => {
  const [inputStatus, setInputStatus] = useState('');

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={clsx(classes.wrapBoxContent, classes.wrapBoxCustom)}>
      <Text
        fontWeightBold
        type="lg"
      >
        {t('status')}
        <span className={classes.require}>*</span>
      </Text>

      <AutocompleteCustom
        value={status}
        onChange={(event, newValue) => {
          setStatus(newValue);
        }}
        inputValue={inputStatus}
        onInputChange={(event, newInputValue) => {
          setInputStatus(newInputValue);
        }}
        options={statusOption}
        placeholder={t('select_a_status')}
        getOptionLabel={(option) => t(option.key)}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        required
        autocompleteStyle={classes.autocompleteField}
        testid={`${TESTID.AUTO_COMPLETE_CUSTOME}_STATUS`}
      />

      <StatusDisplayItem status={status} />
    </Box>
  );
};

export default BoxStatusArtwork;
