import { Stack } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import DropdownSetting from '../DropdownSetting';
import useStyles from './styles';

const SettingItem = ({ label, isPublic, onChangeSetting, name, i18nKey }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      spacing={2}
      className={classes.wrapper}
    >
      <div>
        <Text
          fontWeightMedium
          type="md"
        >
          {label}
        </Text>

        <Text
          type="sm"
          mt={8}
        >
          <Trans
            i18nKey={i18nKey}
            values={{ value: isPublic ? t('public') : t('private') }}
          />
        </Text>
      </div>

      <DropdownSetting
        isPublic={isPublic}
        onChangeSetting={onChangeSetting}
        name={name}
      />
    </Stack>
  );
};

export default SettingItem;
