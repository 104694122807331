import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import Text from 'commons/Text';
import { ReactComponent as FaceBookIcon } from 'images/face_icon.svg';
import { ReactComponent as LinkedinIcon } from 'images/linkedin_icon.svg';
import { ReactComponent as MessengerIcon } from 'images/messenger_icon.svg';
import { ReactComponent as TwitterIcon } from 'images/twitter_icon.svg';

import ShareSocialPlatformItem from '../ShareSocialPlatformItem';
import useStyles from './styles';

const ShareSocialPlatform = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const url = window.location.href;

  return (
    <>
      <Text
        mt={16}
        type="sm"
      >
        {t('share_via_social_media')}
      </Text>

      <div className={classes.shareSocialList}>
        <ShareSocialPlatformItem
          shareButton={FacebookShareButton}
          socialIcon={<FaceBookIcon />}
          url={url}
        />
        <ShareSocialPlatformItem
          shareButton={TwitterShareButton}
          socialIcon={<TwitterIcon />}
          url={url}
        />
        <ShareSocialPlatformItem
          shareButton={FacebookMessengerShareButton}
          appId="835815338151840"
          socialIcon={<MessengerIcon />}
          url={url}
        />
        <ShareSocialPlatformItem
          shareButton={LinkedinShareButton}
          socialIcon={<LinkedinIcon />}
          url={url}
        />
      </div>
    </>
  );
};

export default ShareSocialPlatform;
