import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClientProvider } from '@tanstack/react-query';
import { theme } from 'muiStyles';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import queryClient from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';

import AppRouter from './AppRouter';
import Loading from './commons/Loading';
import './fonts/font.css';
import './index.css';
import store, { persistor } from './redux/store';
import reportWebVitals from './reportWebVitals';
import './utils/i18n';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ToastContainer />
      <Suspense fallback={<Loading />}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
              <QueryClientProvider client={queryClient}>
                <ScopedCssBaseline>
                  <AppRouter />
                </ScopedCssBaseline>
              </QueryClientProvider>
            </GoogleOAuthProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Suspense>
    </PersistGate>
  </Provider>,
);
reportWebVitals();
