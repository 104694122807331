import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { formatCurrency } from 'utils/utils';

import CommonTag from '../CommonTag';

const PriceTag = ({ priceParam }) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const price = searchParams.get(priceParam)?.split(',') || [];
  const [minPrice, maxPrice] = price;
  const formatMinPrice = formatCurrency(minPrice);
  const formatMaxPrice = formatCurrency(maxPrice);

  const handleRemoveCategory = () => {
    searchParams.delete(priceParam);
    setSearchParams(searchParams);
  };

  const getTextMaxPrice = () => {
    if (!formatMaxPrice) {
      return t('unlimited');
    }

    return `${formatMaxPrice} VND`;
  };

  const getTextTag = `${formatMinPrice} VND - ${getTextMaxPrice()}`;

  if (!minPrice) {
    return;
  }

  return (
    <CommonTag
      text={getTextTag}
      onRemoveFilter={handleRemoveCategory}
    />
  );
};

export default PriceTag;
