import { Stack } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import ManageCollectionFilterPanel from '../ManageCollectionFilterPanel';
import ManageCollectionTabPanel from '../ManageCollectionTabPanel';
import useStyles from './styles';

const ManageCollectionPanel = () => {
  const classes = useStyles();

  return (
    <Stack
      direction="row"
      className={clsx(classes.stack, classes.stackSpace)}
    >
      <ManageCollectionTabPanel />

      <ManageCollectionFilterPanel />
    </Stack>
  );
};

export default ManageCollectionPanel;
