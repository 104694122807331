import * as yup from 'yup';

import { email_or_phone_number } from 'utils/Validation';

export const ownerInfoSchema = yup.object().shape({
  name: yup.string().required('Owner name is required'),
  contactInfo: yup
    .string()
    .required('Email or phone number is required')
    .matches(email_or_phone_number, 'Must be a valid email or phone number'),
});
