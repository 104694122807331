import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import FormInput from 'commons/Form/FormInput';
import FormInputDescription from 'commons/FormInputDescription';
import Text from 'commons/Text';

import useStyles from './style';

const DialogCollection = ({
  isShowDialog,
  disabledButton,
  onCancel,
  onSubmit,
  name,
  description,
  onChangeName,
  onChangeDescription,
  loading,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DialogCustom
      isShowDialog={isShowDialog}
      disabledButton={disabledButton}
      loading={loading}
      dialogTitle={t('create_new_collection')}
      textSubmit={t('create')}
      onCancel={onCancel}
      onSubmit={onSubmit}
    >
      <FormInput
        value={name}
        isRequired={true}
        onChange={onChangeName}
        label={t('collection_name')}
        placeholder={t('please_input_collection_name')}
        labelStyle={classes.formInputLabel}
        textFieldStyle={classes.textFieldStyle}
        wrapFormInputStyle={classes.wrapFormInput}
        {...props}
      />
      <FormInputDescription
        value={description}
        maxLength={300}
        onChange={onChangeDescription}
        label={t('description')}
        labelStyle={classes.formInputLabel}
      />
      <div className={classes.wrapNote}>
        <LockOutlinedIcon className={classes.lockIcon} />
        <Text
          style={{ marginTop: 3 }}
          className={classes.textByDefault}
          type="sm"
        >
          {t('by_default_the_visibility_collection')}
        </Text>
      </div>
    </DialogCustom>
  );
};

export default DialogCollection;
