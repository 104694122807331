import { Box } from '@mui/material';
import React from 'react';

import GridLayout from 'layouts/GridLayout';

import CertificateInfo from './components/CertificateInfo';
import SendCertificateRequest from './components/SendCertificateRequest';

const ReviewCertificateStep = ({ onBackStep, artworkFormState, artwork, listUploadedImages }) => {
  return (
    <Box sx={{ pt: 3 }}>
      <GridLayout>
        <CertificateInfo
          artworkFormState={artworkFormState}
          artwork={artwork}
        />
        <SendCertificateRequest
          onBackStep={onBackStep}
          artworkFormState={artworkFormState}
          listUploadedImages={listUploadedImages}
        />
      </GridLayout>
    </Box>
  );
};

export default ReviewCertificateStep;
