import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ArtworkUploadImage from 'commons/ArtworkUploadImage';
import Text from 'commons/Text';
import Colors from 'configs/Colors';
import { TESTID } from 'configs/Constant';
import useImageCompression from 'hooks/useImageCompression';
import useUploadImageS3 from 'hooks/useUploadImageS3';

import ReviewImage from './ReviewImage';
import useStyles from './styles';

const BoxUploadImage = ({ setListImage }) => {
  const { generateImageKey } = useUploadImageS3();
  const { compressImage } = useImageCompression();

  const [reviewImage, setReviewImage] = useState();
  const [imageUpload, setImageUpload] = useState({
    main: '',
    image2: '',
    image3: '',
    image4: '',
    image5: '',
  });

  const classes = useStyles();
  const { t } = useTranslation();

  const arrayFromObject = (object) => {
    return Object.values(object).filter((item) => item !== '');
  };

  const handleUploadImage = async (e, key) => {
    const file = e?.target?.files[0];
    if (!file) {
      return;
    }

    const compressedFile = await compressImage(file);
    const uri = URL.createObjectURL(compressedFile);
    const imageKey = generateImageKey(file);

    const data = {
      uri,
      uploadFile: compressedFile,
      main: key === 'main',
      imageKey,
    };

    setImageUpload({ ...imageUpload, [key]: data });
    setReviewImage(uri);
  };

  const handleChangeReviewImage = (item) => {
    setReviewImage(item?.uri);
  };

  const handleRemoveImage = (key) => {
    setImageUpload((preState) => {
      const newImageUpload = { ...preState, [key]: '' };

      if (reviewImage === imageUpload[key]?.uri) {
        const listImage = arrayFromObject(newImageUpload);
        setReviewImage(listImage[0]?.uri);
      }

      return newImageUpload;
    });

    URL.revokeObjectURL(imageUpload[key]?.uri);
  };

  useEffect(() => {
    const listImage = arrayFromObject(imageUpload);
    setListImage(listImage);
  }, [imageUpload, setListImage]);

  return (
    <div className={classes.boxUploadWrapper}>
      <ReviewImage reviewImage={reviewImage} />

      <Box className={classes.wrapBoxSmallImage}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg
            xs={12}
          >
            <ArtworkUploadImage
              image={imageUpload?.main?.uri}
              onUploadImage={(e) => handleUploadImage(e, 'main')}
              onClickImage={() => handleChangeReviewImage(imageUpload?.main)}
              onRemoveImage={() => handleRemoveImage('main')}
              isRequired
              textRecommend={t('front')}
              testid={TESTID.UPLOAD_IMAGE}
            />
          </Grid>

          <Grid
            item
            lg
            xs={12}
          >
            <ArtworkUploadImage
              image={imageUpload?.image2?.uri}
              onUploadImage={(e) => handleUploadImage(e, 'image2')}
              onClickImage={() => handleChangeReviewImage(imageUpload?.image2)}
              onRemoveImage={() => handleRemoveImage('image2')}
              textRecommend={t('back_side')}
              testid={TESTID.UPLOAD_IMAGE}
            />
          </Grid>

          <Grid
            item
            lg
            xs={12}
          >
            <ArtworkUploadImage
              image={imageUpload?.image3?.uri}
              onUploadImage={(e) => handleUploadImage(e, 'image3')}
              onClickImage={() => handleChangeReviewImage(imageUpload?.image3)}
              onRemoveImage={() => handleRemoveImage('image3')}
              textRecommend={t('detail')}
              testid={TESTID.UPLOAD_IMAGE}
            />
          </Grid>

          <Grid
            item
            lg
            xs={12}
          >
            <ArtworkUploadImage
              image={imageUpload?.image4?.uri}
              onUploadImage={(e) => handleUploadImage(e, 'image4')}
              onClickImage={() => handleChangeReviewImage(imageUpload?.image4)}
              onRemoveImage={() => handleRemoveImage('image4')}
              textRecommend={t('signature')}
              testid={TESTID.UPLOAD_IMAGE}
            />
          </Grid>

          <Grid
            item
            lg
            xs={12}
          >
            <ArtworkUploadImage
              image={imageUpload?.image5?.uri}
              onUploadImage={(e) => handleUploadImage(e, 'image5')}
              onClickImage={() => handleChangeReviewImage(imageUpload?.image5)}
              onRemoveImage={() => handleRemoveImage('image5')}
              textRecommend={t('damage')}
              testid={TESTID.UPLOAD_IMAGE}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 3 }}>
        <ul className={classes.noteList}>
          <li>
            <Text
              color={Colors.Grey8}
              type="md"
            >
              {t('text_standard_resolution')}
            </Text>
          </li>

          <li>
            <Text
              color={Colors.Grey8}
              mt={8}
              type="md"
            >
              {t('text_upload_max_5_image')}
            </Text>
          </li>
        </ul>
      </Box>
    </div>
  );
};

export default BoxUploadImage;
