import clsx from 'clsx';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const AttributeField = ({
  attributeName,
  attributeValue,
  bold,
  onValueClick,
  attributeValueStyle,
}) => {
  const classes = useStyles();

  if (!attributeValue) {
    return null;
  }

  return (
    <div className={clsx(classes.row, classes.attributeItem)}>
      <Text className={classes.attributeName}>{attributeName}</Text>
      <Text
        onClick={onValueClick}
        fontWeight={bold ? 500 : 200}
        className={clsx(classes.textContent, attributeValueStyle)}
        type="sm"
      >
        {attributeValue}
      </Text>
    </div>
  );
};

export default AttributeField;
