import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  avatar: {
    '&.MuiAvatar-root': {
      marginRight: 8,
      width: 24,
      height: 24,
    },
  },
  wrapFormInput: {
    marginTop: 0,
  },
  textFieldStyle: {
    '&.MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      padding: '16px 14px',
    },
  },
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
    fontWeight: 500,
  },
}));

export default useStyles;
