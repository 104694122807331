import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  formControlStyle: {
    '&.MuiFormControl-root': {
      marginTop: 16,
    },
  },
  formLabel: {
    '&.MuiFormLabel-root': {
      marginLeft: 4,
      color: Colors.Grey10,
      fontSize: '1.4rem',
      lineHeight: '142.858%',
      letterSpacing: 0.56,
    },
    '& .MuiFormLabel-asterisk': {
      color: Colors.Red6,
      fontSize: '1.4rem',
      fontWeight: 200,
    },
  },
  formLabelSpace: {
    '&.MuiFormLabel-root': {
      marginBottom: 4,
    },
  },
  select: {
    '& .MuiSelect-select': {
      padding: '12px 8px',
      fontSize: '1.4rem',
      lineHeight: 1.7143,
    },
  },
  placeholder: {
    fontWeight: '200 !important',
  },
}));

export default useStyles;
