import AddIcon from '@mui/icons-material/Add';
import { Box, Divider, Paper } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from './styles';

const CustomPaperSearchUser = ({
  onClickPaper,
  isShowCustomPaper = true,
  onClickSelectSaveUser,
  titleSaveUser,
  listSavedUser = [],
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleSelectSaveUser = (item) => () => {
    if (onClickSelectSaveUser) {
      onClickSelectSaveUser(item);
    }
  };

  return (
    <Paper {...props}>
      {props.children}
      {isShowCustomPaper && (
        <div>
          <Box>
            {listSavedUser?.length > 0 && (
              <Text
                className={classes.titleSavedUser}
                fontWeightBold
              >
                {titleSaveUser}
              </Text>
            )}
            {listSavedUser.map((item, index) => (
              <div
                key={index}
                className={classes.wrapSavedUser}
                onMouseDown={handleSelectSaveUser(item)}
              >
                <Text>{item?.saved_user?.legal_name || item?.name_saved_user}</Text>
                <Text className={classes.textEmailOrPhone}>
                  {item?.saved_user?.email ||
                    item?.saved_user?.phone_number ||
                    item?.contact_info_saved_user}
                </Text>
                <Divider className={classes.divier} />
              </div>
            ))}
          </Box>
          <Box
            className={classes.wrapBoxContent}
            onMouseDown={() => onClickPaper()}
          >
            <div className={classes.wrapItem}>
              <AddIcon />
              <Text
                className={classes.text}
                fontWeightBold
              >
                {t('add_artist_manually')}
              </Text>
            </div>
          </Box>
        </div>
      )}
    </Paper>
  );
};

export default CustomPaperSearchUser;
