import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    background: Colors.White,
    paddingBottom: 142,
  },
  dividerStyle: {
    '&.MuiDivider-root': {
      color: Colors.Grey3,
      marginTop: 25,
      width: '100%',
    },
  },
}));

export default useStyles;
