import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formInputPeriod: {
    marginTop: 16,
  },
  textNotePeriod: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '0.48px',
  },
}));

export default useStyles;
