import React from 'react';

import { STATUS_CHOICES } from 'configs/Constant';
import useRole from 'hooks/useRole';

import StatusPriceRoleArtist from './components/StatusPriceOption/StatusPriceRoleArtist';
import StatusPriceRoleCollector from './components/StatusPriceOption/StatusPriceRoleCollector';
import StatusPublic from './components/StatusPublic';

const StatusDisplayItem = ({ status }) => {
  const { isArtist, isCollectorOrGalleryOwner } = useRole();

  if (isArtist) {
    switch (status?.key) {
      case STATUS_CHOICES.AVAILABLE:
      case STATUS_CHOICES.CONSIGNMENT:
        return <StatusPriceRoleArtist status={status?.key} />;

      case STATUS_CHOICES.NOT_FOR_SALE:
      case STATUS_CHOICES.LOST:
      case STATUS_CHOICES.UNDER_MAINTENANCE:
      case STATUS_CHOICES.SOLD:
      case STATUS_CHOICES.DONATED_GIFTED:
        return <StatusPublic />;

      default:
        return null;
    }
  }

  if (isCollectorOrGalleryOwner) {
    if (Object.values(STATUS_CHOICES).includes(status?.key)) {
      return <StatusPriceRoleCollector status={status?.key} />;
    }
  }

  return null;
};

export default StatusDisplayItem;
