import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  channelList: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRight: 'none',
  },
}));

export default useStyles;
