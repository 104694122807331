import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapImage: {
    display: 'flex',
    marginTop: 16,
    gap: 10,
  },
  imageStyle: {
    width: 60,
    height: 60,
    cursor: 'pointer',
  },
}));

export default useStyles;
