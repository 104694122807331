import React, { useState } from 'react';

import EditionRow from '../EditionRow';
import ExpandCollapse from '../ExpandCollapse';

const MAX_EDITION = 3;
const ArtworkRow = ({ artwork, isEvenRow, statusOption, locationOption }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const showLess = artwork.editions.slice(0, MAX_EDITION);
  const showFull = artwork.editions;
  const editionsToShow = isExpanded ? showFull : showLess;
  const canViewMore = artwork.editions.length > 3;

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {editionsToShow.map((edition, editionIndex) => (
        <EditionRow
          key={edition.id}
          artwork={artwork}
          edition={edition}
          isEvenRow={isEvenRow}
          editionIndex={editionIndex}
          editionsSize={editionsToShow.length}
          statusOption={statusOption}
          locationOption={locationOption}
        />
      ))}

      {canViewMore && (
        <ExpandCollapse
          remainingEditions={showFull?.length - MAX_EDITION}
          isEvenRow={isEvenRow}
          isExpanded={isExpanded}
          onToggleExpand={handleToggleExpand}
        />
      )}
    </>
  );
};

export default ArtworkRow;
