import ErrorIcon from '@mui/icons-material/Error';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { InputAdornment, Stack, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormSelectController from 'commons/FormHook/FormSelectController';

import EditArtworkLocation from '../EditArtworkLocation';
import useStyles from './styles';

const EditArtworkEditionItem = ({
  edition,
  index,
  control,
  statusOptions,
  locationOptions,
  onStatusChange,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      spacing={2}
      className={classes.editionItemWrapper}
    >
      <div className={classes.statusNumberWidth}>{edition?.edition_number}</div>

      <FormSelectController
        options={statusOptions}
        control={control}
        name={`editions.${index}.status`}
        valueKey="key"
        textDisplayKey="value"
        onChange={onStatusChange}
        disabled={!!edition?.id_certificate}
        IconComponent={edition?.id_certificate ? '' : KeyboardArrowDownIcon}
        endAdornment={
          edition?.id_certificate && (
            <InputAdornment position="end">
              <Tooltip
                title={t('this_edition_has_created')}
                placement="top"
                arrow
              >
                <ErrorIcon className={classes.errorIcon} />
              </Tooltip>
            </InputAdornment>
          )
        }
        selectStyle={classes.selectDisable}
        formControlStyle={classes.formSelectControl}
      />

      <EditArtworkLocation
        options={locationOptions}
        control={control}
        name={`editions.${index}.location`}
      />
    </Stack>
  );
};

export default EditArtworkEditionItem;
