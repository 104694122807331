import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Popover, TableCell } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DialogConfirmDeleteArtwork from 'commons/Dialog/DialogConfirmDeleteArtwork';
import Text from 'commons/Text';
import Colors from 'configs/Colors';
import useDeleteArtwork from 'hooks/useDeleteArtwork';
import useStatusCanRequestCertificate from 'react-query/hooks/queries/useStatusCanRequestCertificate';
import Routes from 'utils/Route';
import { ToastTopHelper } from 'utils/utils';

import useStyles from './styles';

const TableArtworkCollectorAction = ({ artwork }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isShowDialogDelete, setIsShowDialogDelete] = useState(false);

  const { mutate: deleteArtwork } = useDeleteArtwork();
  const { data: status } = useStatusCanRequestCertificate();

  const open = Boolean(anchorEl);

  const handleOpenPopover = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClickRequestCertificate = () => {
    navigate(Routes.RequestCertificateId(artwork?.id));
  };

  const handleNavigateEditArtwork = () => {
    navigate(Routes.EditArtwork(artwork?.id));
  };

  const handleShowPopUpConfirmDelete = () => {
    setAnchorEl(null);
    setIsShowDialogDelete(true);
  };

  const handleCancelDelete = () => {
    setIsShowDialogDelete(false);
  };

  const handleDeleteArtwork = () => {
    deleteArtwork(artwork?.id, {
      onSuccess: () => {
        ToastTopHelper.success(
          t(
            artwork?.has_certificate ? 'text_move_artwork_to_store' : 'text_delete_artwork_success',
          ),
        );
      },
    });

    setIsShowDialogDelete(false);
  };

  const numberEditionHadCer = artwork?.editions?.reduce((acc, edition) => {
    return acc + (edition?.id_certificate !== null ? 1 : 0);
  }, 0);
  const isUniqueEdition = artwork?.total_edition === 1;
  const isAllEditionHadCer = numberEditionHadCer === artwork?.total_edition;

  const canRequestCertificate =
    Array.isArray(status) && status?.find((item) => item?.value === artwork?.status?.value);

  const isCertificateDisabled =
    !canRequestCertificate ||
    (isUniqueEdition && artwork?.has_certificate) ||
    (!isUniqueEdition && isAllEditionHadCer);

  return (
    <TableCell className={classes.tableCell}>
      <MoreHorizOutlinedIcon
        onClick={handleOpenPopover}
        className={classes.iconOutLine}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        <Text
          type="sm"
          clickAble
          className={clsx(classes.textItemPopover, {
            [classes.disable]: isCertificateDisabled,
          })}
          onClick={handleClickRequestCertificate}
        >
          {t('request_certificate')}
        </Text>

        <Text
          type="sm"
          clickAble
          className={classes.textItemPopover}
          onClick={handleNavigateEditArtwork}
        >
          {t('edit_artwork')}
        </Text>

        <Text
          type="sm"
          clickAble
          onClick={handleShowPopUpConfirmDelete}
          className={classes.textItemPopover}
          color={Colors.Error}
        >
          {t('delete_artwork')}
        </Text>
      </Popover>

      <DialogConfirmDeleteArtwork
        isShowDialog={isShowDialogDelete}
        onCancel={handleCancelDelete}
        onSubmit={handleDeleteArtwork}
        hasCertificate={artwork?.has_certificate}
      />
    </TableCell>
  );
};

export default TableArtworkCollectorAction;
