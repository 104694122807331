import { TextField } from '@mui/material';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './style';

const FormInputPeriod = ({ placeholder, value, onChange, textInputPeriod, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.formInputPeriod}>
      <TextField
        fullWidth
        placeholder={placeholder}
        value={value || ''}
        onChange={onChange}
        {...props}
      />
      <Text
        mt={2}
        className={classes.textNotePeriod}
      >
        {textInputPeriod}
      </Text>
    </div>
  );
};

export default FormInputPeriod;
