import PersonIcon from '@mui/icons-material/Person';
import { Box, Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormInput from 'commons/Form/FormInput';
import FormSelect from 'commons/Form/FormSelect';
import Text from 'commons/Text';
import UploadImage from 'commons/UploadImage';
import { TESTID, SOCIAL_PLATFORM } from 'configs/Constant';
import useImageCompression from 'hooks/useImageCompression';
import useUploadImageS3 from 'hooks/useUploadImageS3';
import useUserAccount from 'hooks/useUserAccount';
import SectionWrapper from 'pages/EditArtistProfile/commons/SectionWrapper';
import { isValidEmailAddress, isValidPhoneNumber, isValidURL } from 'utils/Validation';
import { ToastTopHelper } from 'utils/utils';

import AddRowInput from '../../commons/AddRowInput';
import useStyles from './styles';

const ArtistInfomation = ({ userProfile, onUpdateUserProfile }) => {
  const { compressImage } = useImageCompression();
  const { updateUserAccount } = useUserAccount();

  const websiteItem = '';
  const sociaItem = {
    platform: '',
    url: '',
  };

  const [avatar, setAvatar] = useState(userProfile?.image_portrait);
  const [avatarUploadFile, setAvatarUploadFile] = useState();
  const [legalName] = useState(userProfile?.legal_name);
  const [nickName, setNickName] = useState(userProfile?.name);
  const [liveAt, setLiveAt] = useState(userProfile?.live_at);
  const [email, setEmail] = useState(userProfile?.email);
  const [phoneNumber, setPhoneNumber] = useState(userProfile?.phone_number);
  const [address, setAddress] = useState(userProfile?.address);
  const [websites, setWebsites] = useState(
    userProfile?.websites?.length ? userProfile?.websites : [websiteItem],
  );
  const [socials, setSocials] = useState(
    userProfile?.socials?.length ? userProfile?.socials : [sociaItem],
  );

  const nonBlankWebsites = websites.filter((website) => website.trim() !== '');
  const nonBlankSocials = socials.filter(
    (social) => social?.platform.trim() !== '' || social?.url.trim() !== '',
  );

  const updateData = {
    email,
    address,
    websites: nonBlankWebsites,
    socials: nonBlankSocials,
    phone_number: phoneNumber,
    live_at: liveAt,
  };

  const hasChanged = (field) =>
    JSON.stringify(userProfile[field]) !== JSON.stringify(updateData[field]);

  const anyFieldChange = Object.keys(updateData).some(hasChanged);

  const { generateImageKey, uploadImage } = useUploadImageS3();

  const errorEmail = email && !isValidEmailAddress(email);
  const errorPhoneNumber = phoneNumber && !isValidPhoneNumber(phoneNumber);
  const errorSocialPlatForm = (social) => {
    if (social?.url && !social?.platform) {
      return t('please_select_social_platform');
    }
    return false;
  };
  const errorSocialUrl = (social) => {
    if (social?.platform && !social?.url) {
      return t('please_typing_url');
    }
    if (social?.url && !isValidURL(social?.url)) {
      return t('invalid_url');
    }
    return false;
  };
  const errorWebsite = (website) => {
    if (website && !isValidURL(website)) {
      return t('invalid_url');
    }
    return false;
  };

  const { t } = useTranslation();
  const classes = useStyles();

  const handleUploadImage = async (e) => {
    const file = e?.target?.files[0];
    const imageKey = generateImageKey(file);

    const compressedFile = await compressImage(file);
    const url = URL.createObjectURL(compressedFile);

    const data = {
      uploadFile: compressedFile,
      imageKey,
    };

    setAvatar(url);
    setAvatarUploadFile(data);
  };

  const handleChangeEmail = (e) => {
    setEmail(e?.target?.value);
  };

  const handleChangePhoneNumber = (e) => {
    setPhoneNumber(e?.target?.value);
  };

  const handleChangeWebiste = (e, index) => {
    const newWebsite = [...websites];
    newWebsite[index] = e.target.value;
    setWebsites(newWebsite);
  };

  const handleAddNewWebsite = () => {
    setWebsites([...websites, websiteItem]);
  };

  const handleChangeSocialUrl = (e, index) => {
    const newSocials = [...socials];
    newSocials[index].url = e.target.value;
    setSocials(newSocials);
  };

  const handleChangeSocialPlatForm = (e, index) => {
    const newSocial = [...socials];
    newSocial[index].platform = e.target.value;
    setSocials(newSocial);
  };

  const handleAddNewSocial = () => {
    setSocials([...socials, { ...sociaItem }]);
  };

  const validate = () => {
    if (!anyFieldChange && !avatarUploadFile && nickName === userProfile.name) {
      return false;
    }

    if (errorEmail) {
      return false;
    }

    if (errorPhoneNumber) {
      return false;
    }

    const validWebsite = nonBlankWebsites.every((website) => !errorWebsite(website));
    if (!validWebsite) {
      return false;
    }

    const validSocial = nonBlankSocials.every(
      (social) => !errorSocialPlatForm(social) && !errorSocialUrl(social),
    );
    if (!validSocial) {
      return false;
    }

    return true;
  };

  const handleUploadProfileSuccess = () => {
    ToastTopHelper.success(t('update_successfully'));
    setAvatarUploadFile(null);
    onUpdateUserProfile();
  };

  const handleSubmit = async () => {
    if (!validate()) {
      return;
    }

    const dataUser = {
      name: nickName,
      profile: {
        email,
        address,
        websites: nonBlankWebsites,
        socials: nonBlankSocials,
        phone_number: phoneNumber,
        live_at: liveAt,
      },
    };

    if (avatarUploadFile) {
      const { success: uploadAvatarSuccess } = await uploadImage(avatarUploadFile);

      if (uploadAvatarSuccess) {
        dataUser.profile['image_portrait'] = avatarUploadFile?.imageKey;
      }
    }

    updateUserAccount(dataUser, handleUploadProfileSuccess);
  };

  return (
    <SectionWrapper heading={t('basic_information')}>
      <div className={classes.wrapContent}>
        <div className={classes.wrapUploadImage}>
          <div className={classes.boxImage}>
            {avatar ? (
              <img
                src={avatar}
                className={classes.reviewAvatar}
              />
            ) : (
              <PersonIcon className={classes.avatarIcon} />
            )}
          </div>

          <UploadImage
            onUploadImage={handleUploadImage}
            testid={TESTID.UPLOAD_IMAGE}
          >
            <div className={classes.buttonUpload}>{t('upload')}</div>
          </UploadImage>

          <Text mt={16}>{t('text_recommend_use_portrait')}</Text>
        </div>

        <div className={classes.line}></div>

        <Box className={classes.wrapForm}>
          <Grid
            container
            columnSpacing={2}
            rowSpacing={3}
          >
            <Grid
              item
              xs={12}
              lg={6}
            >
              <FormInput
                value={legalName}
                label={t('legal_name')}
                placeholder={t('enter_your_fullname')}
                disabled
                wrapFormInputStyle={classes.wrapFormInput}
                testid={`${TESTID.TEXT_FIELD}_NAME`}
              />
            </Grid>

            <Grid
              item
              xs={12}
              lg={6}
            >
              <FormInput
                value={nickName}
                onChange={(e) => setNickName(e?.target?.value)}
                label={t('artist_nickname')}
                wrapFormInputStyle={classes.wrapFormInput}
                testid={`${TESTID.TEXT_FIELD}_NICK_NAME`}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <FormInput
                value={liveAt}
                onChange={(e) => setLiveAt(e?.target?.value)}
                label={t('live_at')}
                placeholder={t('write_anything')}
                wrapFormInputStyle={classes.wrapFormInput}
                testid={`${TESTID.TEXT_FIELD}_LIVE_AT`}
              />
            </Grid>

            <Grid
              item
              xs={12}
              lg={6}
            >
              <FormInput
                value={email}
                onChange={handleChangeEmail}
                label={t('email')}
                placeholder={t('enter_your_email')}
                wrapFormInputStyle={classes.wrapFormInput}
                error={!!errorEmail}
                helperText={errorEmail ? t('invalid_email') : ''}
                testid={`${TESTID.TEXT_FIELD}_EMAIL`}
              />
            </Grid>

            <Grid
              item
              xs={12}
              lg={6}
            >
              <FormInput
                value={phoneNumber}
                onChange={handleChangePhoneNumber}
                label={t('phone_number')}
                placeholder={t('enter_your_phone_number')}
                wrapFormInputStyle={classes.wrapFormInput}
                error={!!errorPhoneNumber}
                helperText={errorPhoneNumber ? t('invalid_phone') : ''}
                testid={`${TESTID.TEXT_FIELD}_PHONE_NUMBER`}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <FormInput
                value={address}
                onChange={(e) => setAddress(e?.target?.value)}
                label={t('address')}
                placeholder={t('enter_your_address')}
                wrapFormInputStyle={classes.wrapFormInput}
                testid={`${TESTID.TEXT_FIELD}_ADDRESS`}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              {websites.map((item, index) => (
                <FormInput
                  key={index}
                  value={item}
                  onChange={(e) => handleChangeWebiste(e, index)}
                  label={t('website')}
                  placeholder={t('enter_an_url')}
                  wrapFormInputStyle={classes.spacingTop}
                  error={!!errorWebsite(item)}
                  helperText={errorWebsite(item)}
                  testid={`${TESTID.TEXT_FIELD}_WEBSITE`}
                />
              ))}

              <AddRowInput
                text={t('add_website')}
                onClick={handleAddNewWebsite}
                testid={`${TESTID.ADD_ROW_INPUT}_WEBSITE`}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Grid
                container
                spacing={2}
              >
                {socials.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                    >
                      <FormSelect
                        value={item?.platform}
                        onChange={(e) => handleChangeSocialPlatForm(e, index)}
                        label={t('social_links')}
                        options={SOCIAL_PLATFORM}
                        helperText={errorSocialPlatForm(item)}
                        testid={`${TESTID.TEXT_FIELD}_SOCIAL_PLATFORM`}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={8}
                    >
                      <FormInput
                        value={item?.url}
                        onChange={(e) => handleChangeSocialUrl(e, index)}
                        label={t('url')}
                        placeholder={t('enter_an_url')}
                        wrapFormInputStyle={classes.wrapFormInput}
                        error={!!errorSocialUrl(item)}
                        helperText={errorSocialUrl(item)}
                        testid={`${TESTID.TEXT_FIELD}_SOCIAL_URL`}
                      />
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>

              <AddRowInput
                text={t('add_social_link')}
                onClick={handleAddNewSocial}
                testid={`${TESTID.ADD_ROW_INPUT}_SOCIAL`}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleSubmit}
            className={classes.btnSubmit}
            disabled={!validate()}
            testid={`${TESTID.BUTTON}_SUBMIT`}
          >
            {t('save')}
          </Button>
        </Box>
      </div>
    </SectionWrapper>
  );
};

export default ArtistInfomation;
