import { Box, MenuItem, MenuList } from '@mui/material';
import React, { useEffect, useState } from 'react';

import useScrollSpy from 'pages/EditArtistProfile/hooks/useScrollspy';

import useStyles from './styles';

const EditArtistProfileNav = () => {
  const [listMenu, setListMenu] = useState([]);
  const activeMenu = useScrollSpy(listMenu, 350);

  const classes = useStyles();

  const handleClickMenuItem = (item) => {
    item.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };

  useEffect(() => {
    const elements = document.querySelectorAll('[name=section-wrapper]');
    const elementsArray = Array.from(elements);
    setListMenu(elementsArray);
  }, []);

  return (
    <Box className={classes.wrapper}>
      <MenuList>
        {listMenu.map((item, index) => (
          <MenuItem
            key={index}
            className={classes.menuItem}
            onClick={() => handleClickMenuItem(item)}
            selected={activeMenu?.innerHTML === item?.innerHTML}
          >
            {item?.children[0]?.innerHTML}
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  );
};

export default EditArtistProfileNav;
