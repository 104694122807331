import React, { useState } from 'react';

import Stepper from 'commons/Stepper';
import { ArtworkProvider } from 'pages/UploadArtwork/ArtworkContext/ArtworkProvider';

import ArtworkStepOne from '../ArtworkStepOne';
import ArtworkStepTwo from '../ArtworkStepTwo';

const ArtWorkAttribute = ({ listImage }) => {
  const [step, setStep] = useState(1);
  const [selectedCategory, setSelectCategory] = useState({});

  return (
    <div>
      <Stepper active={step === 1}>
        <ArtworkStepOne
          selectedCategory={selectedCategory}
          step={step}
          setSelectCategory={setSelectCategory}
          setStep={setStep}
        />
      </Stepper>

      <Stepper active={step === 2}>
        <ArtworkProvider>
          <ArtworkStepTwo
            categoryProp={selectedCategory}
            step={step}
            listImage={listImage}
            setStep={setStep}
            setSelectCategory={setSelectCategory}
          />
        </ArtworkProvider>
      </Stepper>
    </div>
  );
};

export default ArtWorkAttribute;
