import { useMutation } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPut } from 'utils/apis/axios';

const updateEditions = async (formData) => {
  const { data } = await axiosPut(false, API.ARTWORK.EDITIONS_BULK_UPDATE, formData);

  return data;
};

const useUpdateEditions = () => {
  return useMutation({
    mutationFn: (formData) => updateEditions(formData),
  });
};

export default useUpdateEditions;
