import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { FormHelperText } from '@mui/material';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const AutocompleteCustom = (
  {
    inputValue,
    options,
    label,
    placeholder,
    autocompleteStyle,
    required,
    isRequired,
    helperText,
    error,
    fontWeightTitle = 500,
    inputProps,
    wrapFormStyle,
    textTitleCustom,
    labelProps,
    maxLengthCounter,
    endAdornment,
    ...props
  },
  ref,
) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapForm, wrapFormStyle)}>
      <Text
        fontWeight={fontWeightTitle}
        className={clsx(classes.textTitle, textTitleCustom)}
        {...labelProps}
      >
        {label}
        {isRequired && <span className={classes.require}>*</span>}
      </Text>

      <Autocomplete
        options={options || []}
        inputValue={inputValue}
        className={autocompleteStyle}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            className={classes.textField}
            required={required}
            inputProps={{
              ...params?.inputProps,
              ...inputProps,
              'aria-label': label,
            }}
            inputRef={ref}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}

                  {endAdornment}

                  {maxLengthCounter && (
                    <InputAdornment position="end">
                      <Text>{`${inputValue?.length}/${maxLengthCounter}`}</Text>
                    </InputAdornment>
                  )}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...props}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </div>
  );
};

export default forwardRef(AutocompleteCustom);
