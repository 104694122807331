import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tableContainer: {
    overflowX: 'auto',
  },
  table: {
    '&.MuiTable-root': {
      marginTop: 24,
    },
  },
}));

export default useStyles;
