import AddIcon from '@mui/icons-material/Add';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const AddRowInput = ({ onClick, text }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.addItem}
      onClick={onClick}
    >
      <AddIcon />

      <Text
        type={'Body'}
        fontWeightBold
        ml={8}
      >
        {text}
      </Text>
    </div>
  );
};

export default AddRowInput;
