import { useMutation } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPost } from 'utils/apis/axios';

const cancelTagRequest = async (id, formData) => {
  const { data } = await axiosPost(false, API.ARTWORK.CANCEL_ARTIST_TAG_REQUEST(id), formData);
  return data;
};

const useCancelTagRequest = () => {
  return useMutation({
    mutationFn: cancelTagRequest,
  });
};

export default useCancelTagRequest;
