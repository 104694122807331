import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormCheckBoxController from 'commons/FormHook/FormCheckBoxController';
import Text from 'commons/Text';

import useStyles from './styles';

const TermAndCondition = ({ control, helperText, error, wrapTermStyle }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.wrapper, wrapTermStyle)}>
      <div className={classes.wrapTermBox}>
        <Text>{t('validate_form')}</Text>

        <ul className={classes.listTerm}>
          <li className={classes.listTermItem}>{t('acknowledge_responsibilities')}</li>

          <li className={classes.listTermItem}>{t('informed_artcertificate_role')}</li>

          <li className={classes.listTermItem}>{t('warned_false_declaration')}</li>

          <li className={classes.listTermItem}>{t('authorize_display_photo')}</li>
        </ul>
      </div>

      <FormCheckBoxController
        name="checked"
        control={control}
        helperText={helperText}
        error={error}
        textCheckBox={t('terms_conditions_agreement')}
      />
    </div>
  );
};

export default TermAndCondition;
