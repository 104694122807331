import { Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router-dom';

import CertificateAutoView from 'commons/CertificateAutoView';
import API from 'configs/API';

const ViewCertificate = () => {
  const { id } = useParams();

  const { data: artwork, isPending } = useQuery({
    queryKey: [API.ARTWORK.ARTWORK_DETAIL(id)],
  });

  if (isPending) {
    return;
  }

  return (
    <Container sx={{ pt: 2, pb: 2 }}>
      <CertificateAutoView
        artwork={artwork}
        certificate={artwork?.certificate}
      />
    </Container>
  );
};

export default ViewCertificate;
