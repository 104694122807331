import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import TabPanel from 'commons/TabPanel';
import useRole from 'hooks/useRole';

import ManageCertificateTable from '../ManageCertificateTable';
import RequestCertificateTable from '../RequestCertificateTable';
import useStyles from './styles';

const ManageCertificateTabPanel = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const tabValue = Number(searchParams.get('tab') || 0);
  const { isArtist } = useRole();

  const handleChangeTabPanel = (e, newValue) => {
    searchParams.set('tab', newValue);
    searchParams.delete('search');
    searchParams.delete('page');
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleChangeTabPanel}
      >
        <Tab
          label={isArtist ? t('issued_certificates') : t('owned_certificates')}
          value={0}
          className={classes.tab}
        />
        <Tab
          label={isArtist ? t('requested_certificate') : t('requesting_certificate')}
          value={1}
          className={classes.tab}
        />
      </Tabs>

      <TabPanel
        value={tabValue}
        index={0}
        sx={{ pt: 3 }}
      >
        <ManageCertificateTable />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={1}
        sx={{ pt: 3 }}
      >
        <RequestCertificateTable />
      </TabPanel>
    </div>
  );
};

export default ManageCertificateTabPanel;
