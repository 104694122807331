import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const sharedTextProperties = {
  color: Colors.Grey10,
  fontSize: '16px',
  fontWeight: 250,
  lineHeight: '24px',
  letterSpacing: '0.64px',
};

const useStyles = makeStyles(() => ({
  gridContactUs: {
    '&.MuiGrid-root': {
      marginTop: 40,
      paddingLeft: 52,
      paddingRight: 40,
      marginBottom: 80,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  textContactUs: {
    marginTop: 120,
    textAlign: 'center',
    color: Colors.Grey10,
    fontSize: '48px',
    fontWeight: 500,
    lineHeight: '60px',
    letterSpacing: '1.92px',
  },
  textForMoreInfo: {
    marginTop: 16,
    textAlign: 'center',
    color: Colors.Grey10,
    fontSize: '18px',
    fontWeight: 250,
    lineHeight: '28px',
    letterSpacing: '0.72px',
  },
  gridItem: {
    border: `1px solid ${Colors.Gray5}`,
    padding: 32,
  },
  textGridItem1: {
    marginTop: 10,
    color: Colors.Grey10,
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '0.72px',
  },
  textGridItem2: {
    ...sharedTextProperties,
    textDecorationLine: 'underline',
  },
  textGridItem3: {
    ...sharedTextProperties,
  },
}));

export default useStyles;
