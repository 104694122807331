import React, { useState } from 'react';

import GridLayout from 'layouts/GridLayout';

import Banner from '../Banner';
import GalleryInfo from '../GalleryInfo';

const GalleryWrapper = ({ userProfile }) => {
  const [defaultStatement, setDefaultStatement] = useState(userProfile?.introduction);
  const [statement, setStatement] = useState(userProfile?.introduction);

  return (
    <GridLayout
      spacing={5}
      leftColumnProps={{
        lg: 3,
      }}
      rightColumnProps={{
        lg: 9,
      }}
    >
      <GalleryInfo
        userProfile={userProfile}
        defaultStatement={defaultStatement}
        statement={statement}
        isOwner={userProfile?.is_owner}
        setStatement={setStatement}
        setDefaultStatement={setDefaultStatement}
      />

      <Banner
        isOwner={userProfile?.is_owner}
        userBackground={userProfile?.background}
        key={userProfile?.background}
      />
    </GridLayout>
  );
};

export default GalleryWrapper;
