import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';

import API from 'configs/API';
import Routes from 'utils/Route';
import { axiosPost } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

const approveTagRequest = async (id, formData) => {
  const { data } = await axiosPost(false, API.ARTWORK.APPROVE_ARTIST_TAG_REQUEST(id), formData);
  return data;
};

const useApproveTagRequest = (id) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => approveTagRequest(id, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API.ARTWORK.LIST_CERTIFICATE_REQUEST] });
      ToastTopHelper.success(t('approve_success'));
      navigate(
        {
          pathname: Routes.ManageArtwork,
          search: createSearchParams({ tab: 1 }).toString(),
        },
        {
          replace: true,
        },
      );
    },
  });
};

export default useApproveTagRequest;
