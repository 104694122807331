import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
  },
  container: {
    '&.MuiContainer-root': {
      maxWidth: '100% !important',
      height: '100%',
      paddingTop: 24,
      paddingBottom: 24,

      background: Colors.Grey1,
    },
  },
  wrapGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 23,
  },
  boxUploadImage: {
    width: '66.3%',
  },
  artworkAttribute: {
    width: '32.5%',
  },

  [mediaQueries.laptopLarge]: {
    boxUploadImage: {
      width: '65%',
    },
    artworkAttribute: {
      width: '43%',
    },
  },
  [mediaQueries.tabletMedium]: {
    wrapGrid: {
      display: 'block',
      gap: 23,
    },
    boxUploadImage: {
      width: '100%',
    },
    artworkAttribute: {
      width: '100%',
    },
  },
}));

export default useStyles;
