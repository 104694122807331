import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';

import Image from 'commons/Image';
import ImageCustom from 'commons/ImageCustom';
import { TESTID } from 'configs/Constant';

import useStyles from './styles';

const ArtworkImage = ({ artwork }) => {
  const [viewImage, setViewImage] = useState();

  const classes = useStyles();

  const listImages = useMemo(() => {
    return artwork?.images || [];
  }, [artwork?.images]);

  const handleClickImage = (image) => {
    setViewImage(image);
  };

  useEffect(() => {
    setViewImage(listImages[0]);
  }, [listImages]);

  return (
    <Box className={classes.wrapArtworkImage}>
      <Image
        src={viewImage}
        imageStyle={classes.viewImage}
        testid={TESTID.VIEW_IMAGE}
      />

      <div className={classes.listImage}>
        <Grid
          container
          spacing={2}
        >
          {listImages.map((item) => (
            <Grid
              item
              lg
              key={item}
            >
              <div
                onClick={() => handleClickImage(item)}
                testid={TESTID.WRAP_IMAGE}
              >
                <ImageCustom
                  src={item}
                  imageStyle={clsx(classes.imageItem, {
                    [classes.active]: viewImage === item,
                  })}
                  iconLoadingStyle={classes.iconLoadingStyle}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  );
};

export default ArtworkImage;
