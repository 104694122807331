import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from './styles';

const ShareSocialWarning = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.shareSocialWarningWrapper}>
      <ErrorOutlineIcon />
      <Text ml={8}>{t('text_warning_share_social')}</Text>
    </div>
  );
};

export default ShareSocialWarning;
