import AddIcon from '@mui/icons-material/Add';
import { Box, FormHelperText, Grid } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Text from 'commons/Text';
import API from 'configs/API';
import { axiosGet } from 'utils/apis/axios';

import AddedArtworkItem from './components/AddedArtworkItem';
import DialogSelectArtworks from './components/DialogSelectArtworks';
import useStyles from './styles';

const CollectionActionBlock = ({ formState, setValue, collection, title }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.account.user);
  const [isShowDialog, setIsShowDialog] = useState(false);

  const [selectedArtworks, setSelectedArtworks] = useState(collection?.artworks || []);
  const [addedArtworks, setAddedArtworks] = useState(collection?.artworks || []);
  const [searchParams, setSearchParams] = useSearchParams();

  const objectParamsFromSearchParams = Object.fromEntries(searchParams);

  const params = {
    ...objectParamsFromSearchParams,
    user_id: user?.id,
  };

  const fetchArtwork = async ({ pageParam }) => {
    const { data } = await axiosGet(false, API.ARTWORK.LIST_ARTWORK_SELECTION, {
      params: {
        ...params,
        page: pageParam,
      },
    });

    return data;
  };

  const { data, fetchNextPage } = useInfiniteQuery({
    queryKey: [API.ARTWORK.LIST_ARTWORK_SELECTION, params],
    queryFn: fetchArtwork,
    getNextPageParam: (lastPage) => {
      if (!lastPage?.next) {
        return null;
      }
      const urlObj = new URL(lastPage?.next);
      const params = new URLSearchParams(urlObj.search);
      return params.get('page');
    },
    getPreviousPageParam: (firstPage) => {
      if (!firstPage?.previous) {
        return null;
      }
      const urlObj = new URL(firstPage?.previous);
      const params = new URLSearchParams(urlObj.search);
      return params.get('page');
    },
    enabled: isShowDialog,
  });

  const handleCancelDialog = () => {
    setIsShowDialog(false);
    setSearchParams({});
  };

  const handleOpenDialogSelect = () => {
    setSearchParams({});
    setIsShowDialog(true);
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    if (scrollTop + clientHeight >= scrollHeight - 1) {
      fetchNextPage();
    }
  };

  const handleSelectArtwork = (artwork) => () => {
    setSelectedArtworks((prevSelected) => {
      if (prevSelected.some((item) => item.id === artwork.id)) {
        return prevSelected.filter((item) => item.id !== artwork.id);
      } else {
        return [...prevSelected, artwork];
      }
    });
  };

  const handleAddArtworks = () => {
    const selectedArtworkIds = selectedArtworks.map((artwork) => artwork.id);
    setAddedArtworks(selectedArtworks);
    setValue('addedArtworks', selectedArtworkIds, { shouldValidate: true });
    setIsShowDialog(false);
  };

  const handleClickDeleteArtwork = (artwork) => () => {
    setSelectedArtworks((prevSelected) => prevSelected.filter((item) => item.id !== artwork.id));

    setAddedArtworks((prevAdded) => {
      const updatedAdded = prevAdded.filter((item) => item.id !== artwork.id);
      setValue(
        'addedArtworks',
        updatedAdded.map((item) => item.id),
        { shouldValidate: true },
      );
      return updatedAdded;
    });
  };

  return (
    <Box>
      <Text
        variant="h1"
        type="2xl"
        fontWeightBold
      >
        {title}
      </Text>
      <Text>{t('select_the_artworks_want_add_collection')}</Text>

      <Grid
        container
        spacing={2}
        sx={{ mt: 2 }}
      >
        <Grid
          item
          lg={3}
        >
          <div
            className={classes.boxAddArtwork}
            onClick={handleOpenDialogSelect}
          >
            <AddIcon />
            <Text
              type={'xs'}
              className={classes.addArtworkText}
            >
              {t('add_artwork')}
            </Text>
          </div>
          <FormHelperText
            error
            className={classes.helperText}
          >
            {formState?.errors.addedArtworks?.message}
          </FormHelperText>
        </Grid>

        {addedArtworks.map((artwork) => (
          <Grid
            item
            xs={12}
            lg={3}
            key={artwork.id}
          >
            <AddedArtworkItem
              artwork={artwork}
              onClick={handleClickDeleteArtwork(artwork)}
            />
          </Grid>
        ))}
      </Grid>

      <DialogSelectArtworks
        isShowDialog={isShowDialog}
        selectedArtworks={selectedArtworks}
        artworks={data}
        onCancel={handleCancelDialog}
        onSubmit={handleAddArtworks}
        onScroll={handleScroll}
        onClickSelectArtworkItem={handleSelectArtwork}
      />
    </Box>
  );
};

export default memo(CollectionActionBlock);
