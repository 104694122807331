import React from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import Text from 'commons/Text';

import useStyles from './style';

const DialogCancelTagRequest = ({
  isShowDialog,
  disabledButton,
  onCancel,
  onSubmit,
  loading,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DialogCustom
      isShowDialog={isShowDialog}
      disabledButton={disabledButton}
      loading={loading}
      dialogTitle={t('cancel_request')}
      textSubmit={t('ok')}
      textCancel={t('cancel')}
      onCancel={onCancel}
      onSubmit={onSubmit}
      {...props}
    >
      <Text className={classes.textAdding}>{t('cancel_request_artist_tagging')}</Text>
    </DialogCustom>
  );
};

export default DialogCancelTagRequest;
