const handleScaleXAxis = (rootRef, certificateRef) => {
  const rootWidth = rootRef?.current?.offsetWidth;
  const certificateWidth = certificateRef?.current?.offsetWidth;

  return rootWidth / certificateWidth;
};

const handleScaleYAxis = (rootRef, certificateRef) => {
  const rootHeight = rootRef?.current?.offsetHeight;
  const certificateHeight = certificateRef?.current?.offsetHeight;

  return rootHeight / certificateHeight;
};

const SCALE_MODE = {
  xAxis: handleScaleXAxis,
  yAxis: handleScaleYAxis,
};

export const getScaleValue = (mode, rootRef, certificateRef) => {
  const scaleFunction = SCALE_MODE[mode];

  if (!scaleFunction) {
    return 1;
  }

  const value = scaleFunction(rootRef, certificateRef);
  return value;
};
