import * as yup from 'yup';

import { onlyLetterRegExp, phoneRegExp } from 'utils/Validation';

export const shippingSchema = yup.object().shape({
  recipient: yup
    .string()
    .matches(onlyLetterRegExp, 'Recipient must only letters and not special character')
    .required('This field is required')
    .max(50),
  address: yup.string().required('This field is required').max(80),
  phoneNumber: yup
    .string()
    .required('This field is required')
    .matches(phoneRegExp, 'Please enter a valid phone number'),
  checked: yup.boolean().required().oneOf([true], 'You should agree terms and conditions'),
});
