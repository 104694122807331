import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 0,
  },
  dialogImageItem: {
    width: '100% !important',
    height: 500,
    objectFit: 'cover',
    display: 'block',
    borderRadius: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
}));

export default useStyles;
