import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    cursor: 'pointer',
  },
  textArtworkIsView: {
    color: Colors.Grey8,
    marginTop: 8,
  },
  iconRadioButtonFill: {
    width: '36px',
    height: '36px',
    backgroundColor: Colors.White,
    border: '10px solid black',
    borderRadius: '70%',
    display: 'inline-block',
  },
  iconRadioButton: {
    width: '36px',
    height: '36px',
    backgroundColor: 'white',
    border: `3px solid ${Colors.Grey3}`,
    borderRadius: '70%',
    display: 'inline-block',
    cursor: 'pointer',
  },
}));

export default useStyles;
