import ImageIcon from '@mui/icons-material/Image';
import { Box, Skeleton } from '@mui/material';
import React from 'react';

import useStyles from './styles';

const ArtworkImageLoading = () => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapArtworkImage}>
      <div className={classes.viewImage}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          className={classes.skeleton}
        />
        <ImageIcon className={classes.imageIcon} />
      </div>

      <div className={classes.blankItem} />
    </Box>
  );
};

export default ArtworkImageLoading;
