import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  subHeaderTextLink: {
    marginLeft: 6,

    color: Colors.Primary,
    fontSize: '1.6rem',
    fontWeight: 200,
    lineHeight: '150%',
    letterSpacing: 0.64,
  },
  textHeader: {
    whiteSpace: 'wrap',
  },
  extraSpacing: {
    letterSpacing: '0.64px',
  },
  textResigsterAccount: {
    color: Colors.Grey8,
  },
}));

export default useStyles;
