import React from 'react';
import { useSelector } from 'react-redux';

import SelectUserCustomRequestCer from 'commons/SelectUserCustomRequestCer';
import OwnerInfoRequestCer from 'features/Certificate/OwnerInfoRequestCer';
import ShippingInfo from 'features/Certificate/ShippingInfo';

import ArtworkInfo from './components/ArtworkInfo';

const MainContentWrapper = ({
  artworkFormState,
  artwork,
  listUploadedImages,
  setListUploadedImages,
  requestUser,
}) => {
  const { register, formState } = artworkFormState;
  const user = useSelector((state) => state.auth.account.user);

  return (
    <>
      <SelectUserCustomRequestCer
        requestUser={requestUser}
        artworkFormState={artworkFormState}
      />

      <ArtworkInfo
        artwork={artwork}
        artworkFormState={artworkFormState}
        listUploadedImages={listUploadedImages}
        setListUploadedImages={setListUploadedImages}
      />
      <OwnerInfoRequestCer
        ownerName={user?.legal_name}
        yearOfBirth={user?.year_of_birth}
        address={user?.place_of_birth}
      />
      <ShippingInfo
        errors={formState?.errors}
        register={register}
      />
    </>
  );
};

export default MainContentWrapper;
