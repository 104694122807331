import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import Text from 'commons/Text';
import { ARTWORK_FILTER_PARAMS } from 'configs/FilterArtwork';
import useRole from 'hooks/useRole';

import ArtistTag from './ArtistTag';
import CategoryTag from './CategoryTag';
import CreationYearTag from './CreationYearTag';
import LocationTag from './LocationTag';
import MediumTag from './MediumTag';
import OrientationTag from './OrientationTag';
import PriceTag from './PriceTag';
import StatusTag from './StatusTag';
import StyleTag from './StyleTag';
import SubjectTag from './SubjectTag';
import useStyles from './styles';

const FilterTags = ({ numberFilter, wrapFilterStyle }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isCollectorOrGalleryOwner } = useRole();

  const numberFilterResults = useSelector((state) => state.manageArtwork.filterResults);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleClearFilter = () => {
    const tab = searchParams.get('tab');

    const newSearchParams = new URLSearchParams();
    tab && newSearchParams.set('tab', tab);
    setSearchParams(newSearchParams);
  };

  return (
    <div className={wrapFilterStyle}>
      <div className={classes.wrapFilterSelect}>
        <Text type="sm">{t('filtered')}:</Text>

        <div className={classes.wrapTag}>
          <CategoryTag />
          <StyleTag />
          <SubjectTag />
          <MediumTag />
          <PriceTag priceParam={ARTWORK_FILTER_PARAMS.PRICE} />
          {isCollectorOrGalleryOwner && <PriceTag priceParam={ARTWORK_FILTER_PARAMS.COST_PRICE} />}
          <OrientationTag />
          <CreationYearTag />
          {isCollectorOrGalleryOwner && <ArtistTag />}
          <StatusTag />
          <LocationTag />

          <Text
            type="sm"
            clickAble
            onClick={handleClearFilter}
          >
            {t('clear_all')}
          </Text>
        </div>
      </div>

      {!!numberFilter && (
        <Text
          type="xs"
          ml={62}
        >
          {t('showing_results_by_filter', { number: numberFilterResults })}
        </Text>
      )}
    </div>
  );
};

export default FilterTags;
