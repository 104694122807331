import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Image from 'commons/Image';

import useStyles from './styles';

const ImageSectionSameArtist = ({ artworks }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [artwork1] = artworks && artworks.length > 0 ? artworks : [];

  const subArtworks = artworks?.slice(1, 4) || [];

  return (
    <div className={classes.container}>
      <div className={classes.mainImageBlock}>
        {artwork1?.images?.[0] ? (
          <Image
            src={artwork1?.images[0]}
            className={clsx(classes.image, { [classes.noneImage]: !artwork1?.images?.[0] })}
          />
        ) : (
          <div />
        )}
      </div>

      <div className={classes.wrapImage}>
        {subArtworks.length > 0 ? (
          subArtworks.map((image, index) =>
            image?.images?.[0] ? (
              <Image
                key={index}
                src={image?.images[0]}
                className={classes.subImage}
              />
            ) : (
              <div
                key={index}
                className={classes.subImage}
              >
                {t('image_not_public')}
              </div>
            ),
          )
        ) : (
          <div className={classes.subImage}>{t('image_not_public')}</div>
        )}
      </div>
    </div>
  );
};

export default ImageSectionSameArtist;
