import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tab: {
    '&.MuiTab-root': {
      marginRight: 16,
    },
  },
}));

export default useStyles;
