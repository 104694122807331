import { Box, FormLabel } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ListItemMeasurement from 'commons/ListItemMeasurement';
import { OPTIONS_DIMENSION_UNITS } from 'configs/Constant';

import useStyles from './styles';

const Measurement = ({ register, formState, disabled }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [unitDimension, setUnitDimension] = useState(OPTIONS_DIMENSION_UNITS[0].key);

  const handleOptionsDimensionUnit = (e) => {
    const value = e.target.value;
    setUnitDimension(value);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <FormLabel
        required
        className={classes.formLabel}
      >
        {t('measurement')}
      </FormLabel>
      <ListItemMeasurement
        heightProps={{
          ...register('height'),
        }}
        widthProps={{
          ...register('width'),
        }}
        depthProps={{
          ...register('depth'),
        }}
        disabled={disabled}
        optionsDimensionUnits={OPTIONS_DIMENSION_UNITS}
        unitDimension={unitDimension}
        onOptionsDimensionUnit={handleOptionsDimensionUnit}
        formState={formState}
        textFieldStyle={classes.textFieldItemMeasurement}
        itemMeasurementStyle={classes.itemMeasurementStyle}
        selectStyle={classes.customSelect}
      />
    </Box>
  );
};

export default Measurement;
