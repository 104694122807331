import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'sticky',
    top: 0,
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      padding: '12px 16px',
      color: Colors.Grey8,

      '&.Mui-selected': {
        fontWeight: 500,
        color: Colors.Primary,
      },
    },
  },
}));

export default useStyles;
