import { useMutation } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPatch } from 'utils/apis/axios';

const updateArtist = async (id, formData) => {
  const { data } = await axiosPatch(false, API.ARTWORK.USER_ARITST(id), formData);

  return data;
};

const useUpdateArtist = (id) => {
  return useMutation({
    mutationFn: (formData) => updateArtist(id, formData),
  });
};

export default useUpdateArtist;
