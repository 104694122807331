import { Box } from '@mui/material';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import CertificateArtworkInfo from './components/CertificateArtworkInfo';
import CertificateFooter from './components/CertificateFooter';
import CertificateHeader from './components/CertificateHeader';
import CertificateOwnerInfo from './components/CertificateOwnerInfo';
import useStyles from './styles';

const Certificate = ({ artwork, certificate, wrapperStyle, wrapperInlineStyle }, ref) => {
  const classes = useStyles();

  return (
    <Box
      ref={ref}
      className={clsx(classes.wrapper, wrapperStyle)}
      style={wrapperInlineStyle}
    >
      <CertificateHeader certificate={certificate} />
      <CertificateArtworkInfo
        artwork={artwork}
        certificate={certificate}
      />

      <CertificateOwnerInfo />
      <CertificateFooter />
    </Box>
  );
};

export default forwardRef(Certificate);
