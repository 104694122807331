import React from 'react';

import SocialItem from './SocialItem';
import useStyles from './styles';

const SocialMedia = ({ socials }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapArtistSocial}>
      {socials?.map((social, index) => (
        <SocialItem
          key={index}
          social={social}
        />
      ))}
    </div>
  );
};

export default SocialMedia;
