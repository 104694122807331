import { List } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import useRole from 'hooks/useRole';
import Routes from 'utils/Route';

import { ReactComponent as IconFolder } from '../../images/folder.svg';
import { ReactComponent as IconCertificate } from '../../images/icon_certificate.svg';
import { ReactComponent as IconImage } from '../../images/icon_image.svg';
import { ReactComponent as IconImageEdition } from '../../images/icon_image_edition.svg';
import ListMenuItem from './ListMenuItem';

const ListMenu = ({ open }) => {
  const { t } = useTranslation();
  const { isArtist } = useRole();
  let { pathname } = useLocation();

  const lastPath = pathname?.split('/').filter(Boolean).pop();

  return (
    <List>
      <ListMenuItem
        open={open}
        text={t('artworks')}
        icon={<IconImage />}
        isActive={lastPath === 'manage-artwork'}
        to={Routes.ManageArtwork}
      />
      {isArtist && (
        <ListMenuItem
          open={open}
          text={t('editions')}
          icon={<IconImageEdition />}
          isActive={lastPath === 'manage-edition'}
          to={Routes.ManageEdition}
        />
      )}

      <ListMenuItem
        open={open}
        text={t('certificates')}
        icon={<IconCertificate />}
        isActive={lastPath === 'manage-certificate'}
        to={Routes.ManageCertificate}
      />

      <ListMenuItem
        open={open}
        text={t('collections')}
        icon={<IconFolder />}
        isActive={lastPath === 'manage-collection'}
        to={Routes.ManageCollection}
      />
    </List>
  );
};

export default ListMenu;
