import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FormHelperText, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useMemo, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AutocompleteCustom from 'commons/AutocompleteCustom';
import { getLabelForOption } from 'configs/Constant';
import { MS_IN_MINUTE } from 'utils/time';

import useStyles from './styles';

const OTHER_ID = 'other';

const AutocompleteWithOptionExtend = (
  {
    value,
    options,
    onChange,
    categoryProp,
    apiOption,
    helperText,
    error,
    disabled,
    labelProps,
    maxLengthCounter,
    wrapFormStyle,
    autocompleteStyle,
    ...props
  },
  ref,
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const OTHER_OPTION = useMemo(
    () => ({ id: OTHER_ID, name: t('other'), name_vi: t('other') }),
    [t],
  );

  const user = useSelector((state) => state.auth.account.user);
  const language = useSelector((state) => state?.language?.currentLanguage);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const withOtherOption = useMemo(() => [...options, { ...OTHER_OPTION }], [OTHER_OPTION, options]);

  const autocompleteValue = useMemo(() => {
    if (value?.user || isOtherSelected) {
      return { ...OTHER_OPTION };
    }
    return value;
  }, [OTHER_OPTION, isOtherSelected, value]);

  const showOrder = autocompleteValue?.id === OTHER_ID;

  const { data: extendOptions } = useQuery({
    queryKey: [apiOption, { category: categoryProp?.key, user: user?.id }],
    enabled: showOrder,
    staleTime: MS_IN_MINUTE * 5,
  });

  const handleOptionChange = (_, newValue) => {
    const isOther = newValue?.id === OTHER_ID;
    setIsOtherSelected(isOther);

    if (isOther) {
      onChange(null);
    } else {
      onChange(newValue);
    }
  };

  const handleOptionExtendChange = (_, newValue) => {
    onChange(newValue);
  };

  const handleInputChange = (_, newValue) => {
    onChange({
      ...value,
      name: newValue,
      category: categoryProp?.key,
    });
    setInputValue(newValue);
  };

  return (
    <>
      <AutocompleteCustom
        value={autocompleteValue}
        onChange={handleOptionChange}
        options={withOtherOption}
        getOptionLabel={(option) => getLabelForOption(option, language)}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        disabled={disabled}
        labelProps={labelProps}
        autocompleteStyle={autocompleteStyle}
        wrapFormStyle={wrapFormStyle}
        error={error}
        ref={ref}
        {...props}
      />

      {showOrder && (
        <Stack
          direction="row"
          spacing={1}
          sx={{ alignItems: 'center' }}
        >
          <ArrowForwardIcon />

          <AutocompleteCustom
            value={value}
            options={extendOptions}
            onChange={handleOptionExtendChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            freeSolo
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            wrapFormStyle={clsx(classes.autocompleteWrapStyle, wrapFormStyle)}
            autocompleteStyle={autocompleteStyle}
            disabled={disabled}
            maxLengthCounter={maxLengthCounter}
            error={error}
          />
        </Stack>
      )}

      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </>
  );
};

export default forwardRef(AutocompleteWithOptionExtend);
