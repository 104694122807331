import { DatePicker } from '@mui/x-date-pickers';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import { ReactComponent as CalendarIcon } from 'images/calendar.svg';

import useStyles from './style';

const YearSelector = ({ onChangeDate, selectYear, disabled, isRequired }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapYearSelector}>
      <Text
        fontWeightBold
        type="lg"
        mb={6}
      >
        {t('creation_year')}
        {isRequired && <span className={classes.require}>*</span>}
      </Text>
      <DatePicker
        value={selectYear}
        label={t('select_year')}
        openTo="year"
        views={['year']}
        maxDate={moment()}
        onChange={onChangeDate}
        slots={{
          openPickerIcon: CalendarIcon,
        }}
        slotProps={{
          textField: {
            error: false,
          },
        }}
        disabled={disabled}
        className={clsx(classes.datePicker, {
          [classes.datePickerDisable]: disabled,
        })}
      />
    </div>
  );
};

export default YearSelector;
