import { useEffect, useState } from 'react';

import API from 'configs/API';
import { axiosGet } from 'utils/apis/axios';

const useArtworkDropdown = (categoryProp) => {
  const [dropdowns, setDropdowns] = useState({
    status: [],
    style: [],
    subject: [],
    orientation: [],
    color: [],
    medium: [],
  });

  const fetchStatus = async () => {
    const { success, data } = await axiosGet(API.ARTWORK.STATUS);
    return success ? data : [];
  };

  const fetchStyle = async () => {
    const { success, data } = await axiosGet(API.ARTWORK.FILTER.STYLE, {
      params: {
        category: categoryProp.key,
      },
    });
    return success ? data : [];
  };

  const fetchSubject = async () => {
    const { success, data } = await axiosGet(API.ARTWORK.FILTER.SUBJECT, {
      params: {
        category: categoryProp.key,
      },
    });
    return success ? data : [];
  };

  const fetchColor = async () => {
    const { success, data } = await axiosGet(API.ARTWORK.FILTER.COLOR, {
      params: {
        category: categoryProp.key,
      },
    });
    return success ? data : [];
  };

  const fetchOrientation = async () => {
    const { success, data } = await axiosGet(API.ARTWORK.FILTER.ORIENTATION, {
      params: {
        category: categoryProp.key,
      },
    });
    return success ? data : [];
  };

  const fetchMedium = async () => {
    const { success, data } = await axiosGet(API.ARTWORK.FILTER.MEDIUM, {
      params: {
        category: categoryProp.key,
      },
    });
    return success ? data : [];
  };

  useEffect(() => {
    if (categoryProp?.key) {
      Promise.all([
        fetchStatus(),
        fetchStyle(categoryProp.key),
        fetchSubject(categoryProp.key),
        fetchColor(categoryProp.key),
        fetchOrientation(categoryProp.key),
        fetchMedium(categoryProp.key),
      ]).then(([statusData, styleData, subjectData, colorData, orientationData, mediumData]) => {
        setDropdowns({
          status: statusData,
          style: styleData,
          subject: subjectData,
          color: colorData,
          orientation: orientationData,
          medium: mediumData,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryProp?.key]);

  return {
    dropdowns,
    setDropdowns,
  };
};
export default useArtworkDropdown;
