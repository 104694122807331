import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPatch } from 'utils/apis/axios';

const updateUserVisibleSetting = async (updateData) => {
  const { data } = await axiosPatch(false, API.AUTH.USER_VISIBLE_SETTING, updateData);
  return data;
};

const useUpdateUserVisibleSetting = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUserVisibleSetting,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API.AUTH.PROFILE_INFO] });
    },
  });
};

export default useUpdateUserVisibleSetting;
