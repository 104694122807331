import { Table } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import PaginationCustom from 'commons/PaginationCustom';
import API from 'configs/API';
import usePagination from 'hooks/usePagination';

import ManageCertificateTableBody from './components/ManageCertificateTableBody';
import ManageCertificateTableHead from './components/ManageCertificateTableHead';
import useStyles from './styles';

const ManageCertificateTable = () => {
  const classes = useStyles();

  const [searchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams);
  const user = useSelector((state) => state.auth.account.user);

  const { data: certificates, isPending: isCertificatePending } = useQuery({
    queryKey: [API.ARTWORK.CERTIFICATE, params, user?.id],
  });

  const listCertificate = certificates?.results || [];

  const { page, totalPage, onPageChange } = usePagination(
    certificates?.count,
    certificates?.page_size,
  );

  const handlePageCertificateChange = (_, page) => {
    onPageChange(page);
  };

  if (isCertificatePending) {
    return;
  }

  return (
    <div className={classes.tableContainer}>
      <Table className={classes.table}>
        <ManageCertificateTableHead />
        <ManageCertificateTableBody
          listCertificate={listCertificate}
          page={page}
          pageSize={certificates?.page_size}
        />
      </Table>

      <PaginationCustom
        paginationStyle={classes.pagination}
        count={totalPage}
        page={page}
        onChange={handlePageCertificateChange}
      />
    </div>
  );
};

export default ManageCertificateTable;
