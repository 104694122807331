import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '1px 8px',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${Colors.Primary}`,
  },
  closeIcon: {
    '&.MuiSvgIcon-root': {
      marginLeft: 4,
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
