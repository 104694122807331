import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  iconOutLine: {
    '&.MuiSvgIcon-root': {
      width: 20,
      height: 20,
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
