import { Button, Grid } from '@mui/material';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const GridMainFeature2 = ({
  xsGridLeft,
  mdGridLeft,
  xsGridRight,
  mdGridRight,
  src1,
  src2,
  src3,
  textContent,
  textSubContent,
  textButton,
  gridStyle,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={gridStyle}
    >
      <Grid
        item
        xs={xsGridLeft}
        md={mdGridLeft}
      >
        <div className={classes.wrapDescriptionRight}>
          <Text className={classes.textContent}>{textContent}</Text>
          <Text className={classes.textSubContent}>{textSubContent}</Text>
          <Button
            variant="outlined"
            fullWidth
            className={classes.button}
          >
            <Text className={classes.textButton}>{textButton}</Text>
          </Button>
        </div>
      </Grid>

      <Grid
        item
        xs={xsGridRight}
        md={mdGridRight}
      >
        <div className={classes.wrapImageMainFeature}>
          <img
            className={classes.mainFeatureRight1}
            src={src1}
          />
          <div className={classes.wrapSubImageMainFeature}>
            <img src={src2} />
            <img src={src3} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default GridMainFeature2;
