import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import DialogCustom from 'commons/DialogCustom';
import GroupActionButton from 'commons/GroupActionButton';
import TermAndCondition from 'features/Certificate/TermAndCondition';
import useUpdateArtwork from 'hooks/useUpdateArtwork';
import useUploadImageS3 from 'hooks/useUploadImageS3';
import Routes from 'utils/Route';
import { ToastTopHelper } from 'utils/utils';

import useUpdateCertificateRequest from '../hooks/useUpdateArtwork';
import useStyles from './styles';

const termSchema = yup.object().shape({
  checked: yup.boolean().required().oneOf([true], 'Please check agree Terms and Conditions.'),
});

const EditCertificateRequest = ({
  artwork,
  onBackStep,
  artworkFormState,
  listUploadedImages,
  mergedListImages,
}) => {
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      checked: false,
    },
    mode: 'onChange',
    resolver: yupResolver(termSchema),
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();

  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.account.user);

  const { getValues } = artworkFormState;
  const { uploadListImage } = useUploadImageS3();
  const { mutate: updateArtwork, isSuccess: isUpdateArtworkSuccess } = useUpdateArtwork(
    artwork?.id,
  );
  const { mutate: updateCertificateRequest, isSuccess: isUpdateCertificateSuccess } =
    useUpdateCertificateRequest(id);

  const [isShowDialogClose, setIsShowDialogClose] = useState(false);
  const [isShowDialogSend, setIsShowDialogSend] = useState(false);
  const [loading, setLoading] = useState(false);

  const data = getValues();
  const artistName = data?.selectedUser?.name;

  const handleSendRequestCertificate = () => {
    setIsShowDialogSend(true);
  };

  const handelCancelRequestCertificate = () => {
    setIsShowDialogClose(true);
  };

  const updateInfoCertificateRequest = async () => {
    const imagesKey = mergedListImages.map((item) =>
      typeof item === 'string' ? item : item.imageKey,
    );

    const requestUser = data?.selectedUser;

    if (!requestUser?.id) {
      return;
    }

    updateArtwork({
      title: data?.titleArtwork,
      size: {
        height: data?.height,
        width: data?.width,
        depth: data?.depth,
      },
      medium: data?.mediumArtWork,
      year_created: data?.yearCreated,
      period_created: data?.periodYear,
    });

    updateCertificateRequest({
      request_to: requestUser?.id,
      images: imagesKey,
      shipping_info: {
        recipient: data?.recipient,
        address: data?.address,
        phone_number: data?.phoneNumber,
      },
      owner_info: {
        name: data?.ownerName,
        year_of_birth: data?.yearOfBirth,
        id_card: data?.identityCardNumber,
        address: data?.ownerAddress,
      },
    });

    setIsShowDialogSend(false);
  };

  const handleSubmitlDialogSend = async () => {
    setLoading(true);

    const uploadS3Response = await uploadListImage(listUploadedImages);
    const allSuccessful = uploadS3Response.every((response) => response?.success === true);

    if (allSuccessful) {
      updateInfoCertificateRequest();
    } else {
      setIsShowDialogSend(false);
      ToastTopHelper.error(t('upload_file_failed'));
    }

    setLoading(false);
  };

  const handleCancelDialogSend = () => {
    setIsShowDialogSend(false);
  };

  const handleCancelDialogClose = () => {
    setIsShowDialogClose(false);
  };

  const handleSubmitlDialogClose = () => {
    setIsShowDialogClose(false);
    navigate(Routes.GalleryDetail(user?.uuid));
  };

  useEffect(() => {
    if (isUpdateArtworkSuccess && isUpdateCertificateSuccess) {
      ToastTopHelper.success(t('update_successfully'));
      navigate(Routes.ManageCertificate);
    }
  }, [isUpdateArtworkSuccess, isUpdateCertificateSuccess, navigate, t]);

  return (
    <div>
      <TermAndCondition
        control={control}
        wrapTermStyle={classes.wrapTerm}
        helperText={formState?.errors.checked?.message}
        error={!!formState?.errors.checked}
      />
      <GroupActionButton
        mainButtonText={t('save_and_send_request')}
        subButtonLeftText={t('cancel')}
        subButtonRightText={t('back')}
        onMainButtonClick={handleSubmit(handleSendRequestCertificate)}
        onSubButtonRight={onBackStep}
        onSubButtonLeft={handelCancelRequestCertificate}
        groupActionButtonWrapStyle={classes.groupActionButonWrap}
      />

      <DialogCustom
        isShowDialog={isShowDialogClose}
        dialogTitle={t('cancel_request')}
        textCancel={t('continue')}
        textSubmit={t('yes_canccel')}
        onCancel={handleCancelDialogClose}
        onSubmit={handleSubmitlDialogClose}
        maxWidth={'md'}
        submitButtonStyle={classes.buttonDialog}
        cancelButtonStyle={classes.buttonDialog}
      >
        <div>{t('are_you_sure_exit_certificate')}</div>
      </DialogCustom>

      <DialogCustom
        isShowDialog={isShowDialogSend}
        dialogTitle={t('send_certificate_request')}
        textCancel={t('cancel')}
        textSubmit={t('send')}
        onCancel={handleCancelDialogSend}
        onSubmit={handleSubmitlDialogSend}
        maxWidth={'md'}
        loading={loading}
        disabledButton={loading}
        submitButtonStyle={classes.buttonDialog}
        cancelButtonStyle={classes.buttonDialog}
      >
        <div>{t('are_you_sure_send_certificate_request', { artistName })}</div>
      </DialogCustom>
    </div>
  );
};

export default EditCertificateRequest;
