import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  copyIcon: {
    cursor: 'pointer',
  },
  textField: {
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
