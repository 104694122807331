import * as yup from 'yup';

export const artworkSchema = yup.object().shape(
  {
    titleArtwork: yup.string().required('This field is required').max(50),
    height: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Height field is required')
      .positive('Must be a positive number')
      .max(99999999, 'Maximum height is 8 digits')
      .test('max-two-decimals', 'No more than 2 decimal places allowed', (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      ),
    width: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Width field is required')
      .positive('Must be a positive number')
      .max(99999999, 'Maximum width is 8 digits')
      .test('max-two-decimals', 'No more than 2 decimal places allowed', (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      ),
    depth: yup
      .string()
      .notRequired()
      .test('max-two-decimals', 'No more than 2 decimal places allowed', (number) => {
        if (!number) {
          return true;
        }
        return /^\d+(\.\d{1,2})?$/.test(String(number));
      }),
    yearCreated: yup.number('invalid number').when('periodYear', {
      is: (periodYear) => !!periodYear,
      then: (schema) =>
        schema.transform((value) => (Number.isNaN(value) ? null : value)).notRequired(),
      otherwise: (schema) =>
        schema
          .transform((value) => (Number.isNaN(value) ? null : value))
          .typeError('Amount must be a number')
          .required('This field is required')
          .positive('Year must be a positive number')
          .integer('Year must be an integer')
          .test('valid-year', 'Invalid year', (value) => {
            const currentYear = new Date().getFullYear();
            return value >= 1 && value <= currentYear;
          }),
    }),
    periodYear: yup.string().when('yearCreated', {
      is: (yearCreated) => !!yearCreated,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.required().max(30, 'Period Year must be at most 30 characters long'),
    }),
    edition: yup
      .object()
      .test(
        'has-data',
        'This field is required and must have data',
        (value) => value && Object.keys(value).length > 0,
      )
      .required('This field is required'),
    mediumArtWork: yup.object().required('This field is required'),
  },
  [['periodYear', 'yearCreated']],
);
