import imageCompression from 'browser-image-compression';

const useImageCompression = () => {
  const compressImage = async (file, maxSizeMB = 0.5) => {
    if (!file) {
      return null;
    }

    const options = {
      maxSizeMB: maxSizeMB,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      return null;
    }
  };

  return { compressImage };
};

export default useImageCompression;
