import React, { useState, useEffect } from 'react';

import ImageDisplay from './components/ImageDisplay';
import ImageError from './components/ImageError';
import ImageLoading from './components/ImageLoading';

const ImageCustom = ({
  src,
  onClickImageDisplay,
  imageStyle,
  errorStyle,
  loadingStyle,
  iconLoadingStyle,
  customeError,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const onLoad = () => {
    setIsLoading(false);
    setIsError(false);
  };

  const onError = () => {
    setIsLoading(false);
    setIsError(true);
  };

  useEffect(() => {
    const img = new Image();

    img.src = src;
    img.addEventListener('load', onLoad);
    img.addEventListener('error', onError);

    return () => {
      img.removeEventListener('load', onLoad);
      img.removeEventListener('error', onError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <>
      {isLoading && (
        <ImageLoading
          imageStyle={imageStyle}
          loadingStyle={loadingStyle}
          iconLoadingStyle={iconLoadingStyle}
        />
      )}
      {isError && (
        <ImageError
          imageStyle={imageStyle}
          errorStyle={errorStyle}
          customeError={customeError}
        />
      )}
      {!isLoading && !isError && (
        <ImageDisplay
          src={src}
          onClickImageDisplay={onClickImageDisplay}
          imageStyle={imageStyle}
        />
      )}
    </>
  );
};

export default ImageCustom;
