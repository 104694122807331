import * as yup from 'yup';

import { onlyLetterRegExp, phoneRegExp } from 'utils/Validation';

export const userSchema = yup.object().shape({
  legalName: yup
    .string()
    .matches(onlyLetterRegExp, 'Recipient must only letters and not special character')
    .required('This field is required')
    .max(50),
  nickname: yup
    .string()
    .required('Nickname is required')
    .max(50, 'Nickname must be no more than 50 characters long'),
  email: yup.string().required('Email is required').email('Email must be a valid email address'),
  phoneNumber: yup
    .string()
    .required('This field is required')
    .matches(phoneRegExp, 'Please enter a valid phone number'),
  placeOfBirth: yup
    .string()
    .required('This field is required')
    .max(100, 'Place of birth must be no more than 100 characters long'),
  yearOfBirth: yup
    .number()
    .transform((value, originalValue) => {
      return String(originalValue).trim() === '' ? undefined : value;
    })
    .typeError('Year of birth must be a number')
    .required('Year of birth is required')
    .min(1900, 'Year of birth must be no earlier than 1900')
    .max(
      new Date().getFullYear(),
      `Year of birth must be no later than ${new Date().getFullYear()}`,
    ),
});
