import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  pagination: {
    '&.MuiPagination-root': {
      marginTop: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  tab: {
    '&.MuiTab-root': {
      marginRight: 24,
      borderRadius: 99,
      textTransform: 'capitalize',
      '&.Mui-selected': {
        fontWeight: 500,
      },
    },
  },
}));

export default useStyles;
