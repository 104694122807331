import { Box } from '@mui/material';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import ImageCustom from 'commons/ImageCustom';
import Text from 'commons/Text';
import { IMAGE_TYPE } from 'configs/Constant';
import { ReactComponent as IconButtonRadio } from 'images/icon_button_radio.svg';
import { ReactComponent as IconPdf } from 'images/icon_pdf.svg';
import { ToastTopHelper } from 'utils/utils';

import useStyles from '../styles';

const Awards = ({ listAward }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isVisibilityAwards = listAward?.length;
  const maxLines = 3;
  const [showMore, setShowMore] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [imageDisplayItem, setImageDisplayItem] = useState('');

  if (!isVisibilityAwards) {
    return null;
  }

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const visibleAwards = showMore ? listAward : listAward.slice(0, maxLines);

  const handleClickImageDisplay = (src) => {
    setImageDisplayItem(src);
    setIsShowDialog(true);
  };

  const handleCancelDialog = () => {
    setIsShowDialog(false);
  };

  const downloadPDF = (pdfUrl, description, index) => {
    const pdfFileName = `${description?.slice(0, 10)}-${index}.pdf`;

    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, pdfFileName);
      })
      .catch(() => {
        ToastTopHelper.error(t('error_download_pdf_file'));
      });
  };
  return (
    <Box className={classes.boxWrapItemsProfile}>
      <IconButtonRadio className={classes.iconButtonRadio} />
      <div className={classes.wrapItemProfile}>
        <Box className={classes.boxItemProfile}>
          <Text
            type="xl"
            fontWeightBold
          >
            {t('awards')}
          </Text>
          <div className={classes.wrapListProfile}>
            {visibleAwards.map((item, index) => (
              <div
                key={index}
                className={classes.wrapListItemProfile}
              >
                <div className={classes.textYear}>{item?.year}</div>
                <div className={classes.wrapDescriptionAndItem}>
                  <Text className={classes.textDescription}>{item?.description}</Text>
                  <div className={classes.wrapImageItem}>
                    {item.list_image.map((objectImage, index) => {
                      const typeFile = objectImage?.key_images?.split('.').pop();
                      return (
                        <div key={index}>
                          {typeFile === IMAGE_TYPE.PDF ? (
                            <IconPdf
                              className={classes.iconPdf}
                              onClick={() =>
                                downloadPDF(objectImage.image, item?.description, index)
                              }
                            />
                          ) : (
                            <ImageCustom
                              src={objectImage?.image}
                              onClickImageDisplay={handleClickImageDisplay}
                              imageStyle={classes.imageItem}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {listAward.length > maxLines && (
            <div className={classes.textSeeMore}>
              <Text
                bold
                onClick={toggleShowMore}
              >
                {showMore ? t('show_less') : t('see_more')}
              </Text>
            </div>
          )}
        </Box>
      </div>
      <DialogCustom
        isShowDialog={isShowDialog}
        textCancel={t('cancel')}
        onCancel={handleCancelDialog}
        maxWidth={'md'}
      >
        <img
          src={imageDisplayItem}
          className={classes.dialogImageItem}
        />
      </DialogCustom>
    </Box>
  );
};

export default Awards;
