import * as yup from 'yup';

import { USER_ROLE_BY_KEY, isEditionStatusAvailable } from 'configs/Constant';

export const editArtworkSchema = yup.object().shape(
  {
    title: yup.string().required('This field is required').max(50),
    yearCreated: yup
      .number('invalid number')
      .transform((value) => (Number.isNaN(value) ? null : value))
      .typeError('Amount must be a number')
      .positive('Year must be a positive number')
      .integer('Year must be an integer')
      .when(['editions', 'periodYear'], {
        is: (editions, periodYear) => isEditionStatusAvailable(editions) && !periodYear,
        then: (schema) =>
          schema
            .required('Created year is a required field')
            .test('valid-year', 'Invalid year', (value) => {
              const currentYear = new Date().getFullYear();
              return value >= 1 && value <= currentYear;
            }),
        otherwise: (schema) => schema.notRequired(),
      }),
    periodYear: yup
      .string()
      .max(30, 'Period Year must be at most 30 characters long')
      .when(['editions', 'yearCreated'], {
        is: (editions, yearCreated) => isEditionStatusAvailable(editions) && !yearCreated,
        then: (schema) => schema.required('Period year is a required field'),
        otherwise: (schema) => schema.notRequired(),
      }),
    editionVolume: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Edition Volume is required')
      .positive('Edition Voumne must be a positive number')
      .integer('Edition Volume must be an integer'),
    medium: yup.object().required('This field is required'),
    height: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Height field is required')
      .positive('Must be a positive number')
      .max(99999999, 'Maximum height is 8 digits')
      .test('max-two-decimals', 'No more than 2 decimal places allowed', (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      ),
    width: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Width field is required')
      .positive('Must be a positive number')
      .max(99999999, 'Maximum width is 8 digits')
      .test('max-two-decimals', 'No more than 2 decimal places allowed', (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      ),
    depth: yup
      .string()
      .notRequired()
      .test('max-two-decimals', 'No more than 2 decimal places allowed', (number) => {
        if (!number) {
          return true;
        }
        return /^\d+(\.\d{1,2})?$/.test(String(number));
      }),
    description: yup
      .string()
      .notRequired()
      .max(6000, 'Description must be no more than 6000 characters long'),
    note: yup.string().notRequired().max(200, 'Note must be no more than 200 characters long'),
    price: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      })
      .nullable()
      .typeError('Price must be a number'),

    contactInformation: yup.string().when('isAskPriceVisible', {
      is: (isAskPriceVisible) => !!isAskPriceVisible,
      then: (schema) =>
        schema
          .required('This field is required')
          .max(255, 'Contact information must be no more than 6000 characters long'),
      otherwise: (schema) => schema.notRequired(),
    }),
    images: yup
      .array()
      .of(yup.string().required('Image is required'))
      .min(1, 'At least one image is required')
      .max(5, 'You can upload a maximum of 5 images'),
    selectedUser: yup
      .object()
      .when('checkedArtistUnknown', {
        is: (checkedArtistUnknown) => !!checkedArtistUnknown,
        then: (schema) => schema.notRequired(),
      })
      .when('artistName', {
        is: (artistName) => !!artistName,
        then: (schema) => schema.notRequired(),
      })
      .when(['checkedArtistUnknown', 'artistName'], {
        is: (checkedArtistUnknown, artistName) => !checkedArtistUnknown && !artistName,
        then: (schema) =>
          schema
            .shape({
              name: yup.string().required('Artist is a required field'),
            })
            .required('Artist is a required field'),
      }),
    artistName: yup
      .string()
      .when('checkedArtistUnknown', {
        is: (checkedArtistUnknown) => !!checkedArtistUnknown,
        then: (schema) => schema.notRequired(),
      })
      .when('selectedUser', {
        is: (selectedUser) => !!selectedUser && !!selectedUser?.name,
        then: (schema) => schema.notRequired(),
      })
      .when(['checkedArtistUnknown', 'selectedUser'], {
        is: (checkedArtistUnknown, selectedUser) => !checkedArtistUnknown && !selectedUser?.name,
        then: (schema) =>
          schema
            .required('Artist is a required field')
            .max(30, 'artistName must be at most 30 characters long'),
      }),
    checkedArtistUnknown: yup.boolean(),
    edition: yup.object().when(['role', 'editionVolume'], ([role, editionVolume], schema) => {
      return role === USER_ROLE_BY_KEY['COLLECTOR']
        ? schema.shape({
            edition_number: yup
              .number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .required('Edition number is required')
              .integer('Edition number must be an integer')
              .positive('Edition number must be a positive number')
              .max(editionVolume, 'Edition number must be less than edition volume'),
          })
        : schema;
    }),
    inputSearchUser: yup.string().notRequired(),
    contactInfoArtist: yup.string().notRequired(),
  },
  [
    ['periodYear', 'yearCreated'],
    ['price', 'isHidePriceVisible'],
    ['isAskPriceVisible', 'contactInformation'],
    ['selectedUser', 'artistName'],
  ],
);
