import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const buttonBaseStyle = {
  padding: '12px 20px',
  fontSize: '1.6rem',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: 0.64,
};

const useStyles = makeStyles(() => ({
  textReject: {
    color: Colors.Red,
    fontSize: '2rem',
    fontWeight: 500,
    lineHeight: '150%',
    letterSpacing: 0.8,
  },
  textMessage: {
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.72px',
  },
  boxGroupButton: {
    padding: '22px',
    background: Colors.White,
    border: `1px solid ${Colors.Grey2}`,
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
    marginBottom: 20,
  },
  button: {
    '&.MuiButton-root': {
      ...buttonBaseStyle,
      padding: '10px 20px',
      marginTop: 10,
    },
  },
}));

export default useStyles;
