export const ARTWORK_FILTER_PARAMS = {
  CATEGORY: 'category__in',
  STYLE: 'style__in',
  MEDIUM: 'medium__in',
  SUBJECT: 'subject__in',
  ORIENTATION: 'orientation__in',
  YEAR: 'year',
  PRICE: 'price',
  COST_PRICE: 'cost_price',
  ORDERING: 'ordering',
  ARTIST: 'artist',
  STATUS: 'status__in',
  LOCATION: 'location__in',
};

export const CATEGORY_KEY = {
  CHECKED: 'key',
  DISPLAY: 'value',
};

const COMMON_KEY = {
  CHECKED: 'id',
  DISPLAY: 'name',
  DISPLAY_VI: 'name_vi',
};

export const STYLE_KEY = {
  ...COMMON_KEY,
};

export const SUBJECT_KEY = {
  ...COMMON_KEY,
};

export const MEDIUM_KEY = {
  ...COMMON_KEY,
};

export const ORIENTATION_KEY = {
  ...COMMON_KEY,
};

export const ARTIST_KEY = {
  CHECKED: 'id',
  DISPLAY: 'name',
};

export const LOCATION_KEY = {
  CHECKED: 'id',
  DISPLAY: 'location',
};

export const CREATION_YEAR_KEY = {
  CHECKED: '',
  DISPLAY: 'display',
};

export const ORDER_TYPE = {
  PRICE_ASC: 'price',
  PRICE_DESC: '-price',
  YEAR_ASC: 'year_created',
  YEAR_DESC: '-year_created',
};

export const STATUS_KEY = {
  ...CATEGORY_KEY,
};

export const FILTER_STATUS_OPTION = [
  { key: 'available', value: 'Available for sale' },
  { key: 'not_for_sale', value: 'Not for sale' },
  { key: 'lost', value: 'Lost' },
  { key: 'consignment', value: 'Consignment' },
  { key: 'under_maintenance', value: 'Under Maintenance' },
];
