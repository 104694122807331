import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import Section from 'features/Certificate/Section';

import useStyles from './styles';

const ReviewStatusArtwork = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { artwork } = data;

  return (
    <Section
      title={t('status')}
      wrapperStyle={classes.wrapper}
    >
      <Stack
        direction="row"
        sx={{ mt: 2 }}
        spacing={5}
      >
        <div>
          <Text type="H4">{t('edition_number')}</Text>
        </div>

        <div>
          <Text
            type="H4"
            fontWeightBold
          >
            {artwork?.editions[0]?.edition_number}
          </Text>
        </div>
      </Stack>
    </Section>
  );
};

export default ReviewStatusArtwork;
