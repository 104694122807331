import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import languageReducer from './languageSlice';
import manageArtworkReducer from './manageArtworkSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  language: languageReducer,
  manageArtwork: manageArtworkReducer,
});

export default rootReducer;
