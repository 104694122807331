import { clsx } from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import Routes from 'utils/Route';

import useStyles from './styles';

const TitleArtworkCol = ({ title, isLink, id, code }) => {
  const classes = useStyles();

  return (
    <>
      {isLink ? (
        <Link
          to={Routes.ArtworkDetail(code || id)}
          className={classes.title}
        >
          {title}
        </Link>
      ) : (
        <div className={clsx(classes.title, classes.titleNotUnderLine)}>{title}</div>
      )}
    </>
  );
};

export default TitleArtworkCol;
