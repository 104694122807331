import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CheckBoxGroup from 'commons/FilterArtwork/CheckBoxGroup';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, MEDIUM_KEY } from 'configs/FilterArtwork';

const { MEDIUM } = ARTWORK_FILTER_PARAMS;

const FilterMedium = () => {
  const { data: mediums = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.MEDIUM, { by_artwork_upload: true }],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedMediumParams = searchParams.get(MEDIUM)?.split(',') || [];

  const handleCheckboxChange = (medium) => {
    const isChecked = selectedMediumParams.find(
      (item) => item === medium[MEDIUM_KEY.CHECKED]?.toString(),
    );

    searchParams.delete('page');

    if (!isChecked) {
      searchParams.set(MEDIUM, [...selectedMediumParams, medium[MEDIUM_KEY.CHECKED]]);
      setSearchParams(searchParams);
      return;
    }

    const newSelectedMediumParams = [...selectedMediumParams].filter(
      (item) => item !== medium[MEDIUM_KEY.CHECKED]?.toString(),
    );

    if (newSelectedMediumParams.length) {
      searchParams.set(MEDIUM, newSelectedMediumParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(MEDIUM);
      setSearchParams(searchParams);
    }
  };

  return (
    <CheckBoxGroup
      listCheckBox={mediums}
      selectedFilters={selectedMediumParams}
      onCheckboxChange={handleCheckboxChange}
      textDisplayKey={MEDIUM_KEY.DISPLAY}
      textDisplayViKey={MEDIUM_KEY.DISPLAY_VI}
      checkedKey={MEDIUM_KEY.CHECKED}
    />
  );
};

export default FilterMedium;
