import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import useDisplayText from 'commons/FilterArtwork/hooks/useDisplayText';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, ARTIST_KEY } from 'configs/FilterArtwork';

import CommonTag from '../CommonTag';

const { ARTIST } = ARTWORK_FILTER_PARAMS;

const ArtistTag = () => {
  const user = useSelector((state) => state.auth.account.user);
  const { data: artists = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.ARTIST_BY_USER, { user_id: user?.id }],
  });
  const getDisplayText = useDisplayText(ARTIST_KEY.DISPLAY, null, true);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedArtistParams = searchParams.get(ARTIST)?.split(',') || [];
  const selectedArtist = artists.filter((artist) =>
    selectedArtistParams.includes(artist[ARTIST_KEY.CHECKED]?.toString()),
  );

  const handleRemoveArtist = (artist) => {
    const newSelectedArtistParams = [...selectedArtistParams].filter(
      (item) => item !== artist[ARTIST_KEY.CHECKED]?.toString(),
    );

    if (newSelectedArtistParams.length) {
      searchParams.set(ARTIST, newSelectedArtistParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(ARTIST);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      {selectedArtist.map((artist) => (
        <CommonTag
          key={artist[ARTIST_KEY.CHECKED]}
          text={getDisplayText(artist)}
          onRemoveFilter={() => handleRemoveArtist(artist)}
        />
      ))}
    </>
  );
};

export default ArtistTag;
