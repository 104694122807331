import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 0,
  },
  labelStyle: {
    padding: 0,
  },
}));

export default useStyles;
