import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapForm: {
    width: 464,
    background: Colors.White,
    borderRadius: 16,
    padding: 40,
  },

  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0, // Removes borderRadius
    },
  },

  button: {
    '&.MuiButton-root': {
      marginTop: 16,
      padding: '12px 16px',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
  },

  [mediaQueries.laptopLarge]: {
    wrapForm: {
      padding: 30,
    },
  },

  [mediaQueries.tabletMedium]: {
    wrapForm: {
      width: '100%',
    },
  },
}));

export default useStyles;
