import { Stack } from '@mui/material';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const VisibleOption = ({ isActive, icon, title, description, onClick }) => {
  const classes = useStyles();

  return (
    <Stack
      onClick={onClick}
      direction="row"
      spacing={1}
      className={classes.wrapper}
    >
      {icon}

      <div style={{ flex: 1 }}>
        <Text fontWeightBold>{title}</Text>
        <Text
          className={classes.textArtworkIsView}
          type="sm"
        >
          {description}
        </Text>
      </div>

      {isActive ? (
        <div className={classes.iconRadioButtonFill} />
      ) : (
        <div className={classes.iconRadioButton} />
      )}
    </Stack>
  );
};

export default VisibleOption;
