import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  textArtworkInformation: {
    letterSpacing: '0.72px',
  },
  formInputLabel: {
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: '0.56px',
    color: Colors.Grey10,
  },
  buttonUpdate: {
    '&.MuiButton-root': {
      marginTop: 16,
      padding: '8px 16px',
      width: 128,
    },
  },
  textTitleMedium: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.56px',
  },
  formFieldBackground: {
    '& .MuiInputBase-input': {
      backgroundColor: Colors.White,
    },
  },
  disableTextFieldStyle: {
    '& .MuiInputBase-input': {
      backgroundColor: Colors.GrayScale10,
    },
  },
  textTitlePeriodYear: {
    fontSize: 14,
    marginTop: 15,
  },
  autocompleteStyle: {
    '&.MuiAutocomplete-root': {
      '& .MuiOutlinedInput-root': {
        padding: 3.5,
        background: Colors.White,
      },
    },
  },
  itemMeasurementStyle: {
    padding: 9.5,
  },
  customSelect: {
    '& .MuiSelect-select': {
      padding: '11px',
    },
  },
  inputLabel: {
    marginTop: 16,
    marginBottom: 4,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.56px',
  },
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
    fontWeight: 500,
  },
}));

export default useStyles;
