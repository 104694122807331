import { TextField } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const DecimalInput = ({
  textTitle,
  placeholder,
  value,
  onChange,
  isRequired,
  textTitleStyle,
  decimalInputStyle,
  inputProps = {
    type: 'number',
    step: '0.01',
  },
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <Text className={clsx(classes.textTitle, textTitleStyle)}>
        {textTitle}
        {isRequired && <span className={classes.require}>*</span>}
      </Text>

      <TextField
        value={value}
        onChange={onChange}
        id="outlined-basic"
        placeholder={placeholder}
        variant="outlined"
        InputProps={{
          inputProps: inputProps,
        }}
        className={clsx(classes.textFieldStyle, decimalInputStyle)}
        {...props}
      />
    </>
  );
};

export default DecimalInput;
