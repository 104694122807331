import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import useDisplayText from 'commons/FilterArtwork/hooks/useDisplayText';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, ORIENTATION_KEY } from 'configs/FilterArtwork';

import CommonTag from '../CommonTag';

const { ORIENTATION } = ARTWORK_FILTER_PARAMS;

const OrientationTag = () => {
  const { data: orientations = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.ORIENTATION],
  });
  const getDisplayText = useDisplayText(ORIENTATION_KEY.DISPLAY, ORIENTATION_KEY.DISPLAY_VI);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedOrientationParams = searchParams.get(ORIENTATION)?.split(',') || [];
  const selectedOrientations = orientations.filter((orientation) =>
    selectedOrientationParams.includes(orientation[ORIENTATION_KEY.CHECKED]?.toString()),
  );

  const handleRemoveCategory = (orientation) => {
    const newSelectedStyleParams = [...selectedOrientationParams].filter(
      (item) => item !== orientation[ORIENTATION_KEY.CHECKED]?.toString(),
    );

    if (newSelectedStyleParams.length) {
      searchParams.set(ORIENTATION, newSelectedStyleParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(ORIENTATION);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      {selectedOrientations.map((orientation) => (
        <CommonTag
          key={orientation[ORIENTATION_KEY.CHECKED]}
          text={getDisplayText(orientation)}
          onRemoveFilter={() => handleRemoveCategory(orientation)}
        />
      ))}
    </>
  );
};

export default OrientationTag;
