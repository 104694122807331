import { Box } from '@mui/material';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const ContentGroup = ({ heading, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapContentSection}>
      <Text
        type="lg"
        fontWeightBold
      >
        {heading}
      </Text>

      <Box sx={{ pt: 2 }}>{children}</Box>
    </div>
  );
};

export default ContentGroup;
