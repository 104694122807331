import CloseIcon from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Divider, Grid, Link } from '@mui/material';
import Icon from '@mui/material/Icon';
import React from 'react';

import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';

import useStyles from './styles';

const ExhibitionListGrid = ({ listExhibition, onClickOpenDialogDelete, onClickOpenDialogEdit }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      rowSpacing={1}
    >
      {listExhibition.map((item, index) => (
        <Grid
          key={index}
          item
          lg={12}
          xs={12}
        >
          <div className={classes.listItemExhibition}>
            <div className={classes.wrapYearAward}>
              {item?.is_public ? (
                <VisibilityOutlinedIcon className={classes.iconVisibility} />
              ) : (
                <VisibilityOffOutlinedIcon className={classes.iconVisibility} />
              )}
              <Text className={classes.textYearAward}>{item?.year}</Text>
            </div>
            <div className={classes.wrapDescriptionAndIcon}>
              <div className={classes.wrapDescriptionAndLink}>
                <Text>{item?.description}</Text>
                <Link
                  key={index}
                  href={item?.exhibition_link}
                  underline="none"
                  target="_blank"
                  className={classes.linkItem}
                >
                  {item?.exhibition_link}
                </Link>
              </div>

              <div>
                <Icon
                  onClick={() => onClickOpenDialogEdit(item)}
                  className={classes.editIcon}
                  testid={TESTID.EDIT_ICON_EXHIBITION_LIST}
                >
                  <EditOutlinedIcon />
                </Icon>
                <Icon
                  onClick={() => onClickOpenDialogDelete(item)}
                  testid={TESTID.CLOSE_ICON_EXHIBITION_LIST}
                >
                  <CloseIcon />
                </Icon>
              </div>
            </div>
          </div>
          <Divider className={classes.dividerStyle} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ExhibitionListGrid;
