import React from 'react';

import ArtworkInformation from '../ArtworkInformation';
import OwnerInformation from '../OwnerInformation';

const ReviewCertificateMainContent = ({ data }) => {
  return (
    <>
      <ArtworkInformation data={data} />
      <OwnerInformation
        ownerInfo={data?.owner_info}
        requestBy={data?.request_by}
      />
    </>
  );
};

export default ReviewCertificateMainContent;
