import * as yup from 'yup';

export const locationSchema = ({ locationOptions }) => {
  return yup.object().shape({
    location: yup
      .string()
      .required('Location is required')
      .notOneOf(locationOptions, 'This location name already exists'),
  });
};
