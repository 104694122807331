import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import API from 'configs/API';
import Routes from 'utils/Route';

const useArtwork = (id, options = {}) => {
  const navigate = useNavigate();

  const {
    data: artwork,
    error,
    isPending,
    ...query
  } = useQuery({
    queryKey: [API.ARTWORK.ARTWORK_DETAIL(id)],
    ...options,
  });

  useEffect(() => {
    if (error && error?.error?.response?.status === 404) {
      navigate(Routes.PageNotFound, { replace: true });
    }
  }, [error, navigate]);

  useEffect(() => {
    if (!isPending && !artwork?.is_owner) {
      navigate(Routes.PageNotFound, { replace: true });
    }
  }, [artwork, isPending, navigate]);

  return { artwork, isPending, ...query };
};

export default useArtwork;
