import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  textArtworkCount: {
    color: Colors.Grey8,
  },
}));

export default useStyles;
