import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  iconOutLine: {
    '&.MuiSvgIcon-root': {
      width: 20,
      height: 20,
      cursor: 'pointer',
    },
  },
  statusDenied: {
    '&.MuiTableCell-body': {
      color: Colors.Error,
    },
  },
}));

export default useStyles;
