import React from 'react';
import { useTranslation } from 'react-i18next';

import ReviewArtworkImages from 'pages/ReviewTagRequest/components/ReviewArtworkImages';
import ReviewArtworkInformation from 'pages/ReviewTagRequest/components/ReviewArtworkInformation';
import ReviewRequestor from 'pages/ReviewTagRequest/components/ReviewRequestor';
import ReviewStatusArtwork from 'pages/ReviewTagRequest/components/ReviewStatusArtwork';

const ReviewTagRequestMainContent = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <ReviewRequestor
        title={t('requestor')}
        data={data}
        requestUser={data?.request_by}
      />
      <ReviewStatusArtwork data={data} />
      <ReviewArtworkInformation data={data} />
      <ReviewArtworkImages data={data} />
    </>
  );
};

export default ReviewTagRequestMainContent;
