import { Dialog, useMediaQuery } from '@mui/material';
import React from 'react';

import CertificateAutoView from 'commons/CertificateAutoView';
import { BREAKPOINTS } from 'configs/Constant';

import useStyles from './styles';

const CertificateDialog = ({ artwork, certificate, open, onClose, ...props }) => {
  const isLaptop = useMediaQuery(BREAKPOINTS.laptop);
  const scaleMode = isLaptop ? 'yAxis' : 'xAxis';

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={isLaptop ? false : 'xs'}
      {...props}
    >
      <CertificateAutoView
        artwork={artwork}
        certificate={certificate}
        scaleMode={scaleMode}
        rootStyle={classes.contentDialog}
      />
    </Dialog>
  );
};

export default CertificateDialog;
