import React from 'react';

import FormInput from 'commons/Form/FormInput';

import useStyles from './styles';

const FormArtistByRoleArtist = ({ artworkFormState }) => {
  const classes = useStyles();
  const { register, formState } = artworkFormState;

  return (
    <FormInput
      {...register('artistName')}
      wrapFormInputStyle={classes.formControl}
      labelStyle={classes.labelStyle}
      error={!!formState?.errors.artistName}
      helperText={formState?.errors.artistName?.message}
      disabled
    />
  );
};

export default FormArtistByRoleArtist;
