import React from 'react';
import { Controller } from 'react-hook-form';

import AutocompleteCustom from 'commons/AutocompleteCustom';

const FormAutocompleteController = ({
  name,
  control,
  rules,
  options,
  label,
  isRequired,
  helperText,
  error,
  onChange,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const { value, onChange: onChangeField } = field;

        const handleChange = (_, data) => {
          onChangeField(data);

          if (onChange) {
            onChange(data);
          }
        };

        return (
          <AutocompleteCustom
            value={value || null}
            options={options}
            label={label}
            isRequired={isRequired}
            onChange={handleChange}
            helperText={helperText}
            error={error}
            {...props}
          />
        );
      }}
    />
  );
};

export default FormAutocompleteController;
