import React from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import FormInput from 'commons/Form/FormInput';

import useStyles from './style';

const DialogLocation = ({
  isShowDialog,
  disabledButton,
  onCancel,
  onSubmit,
  value,
  onChange,
  loading,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <DialogCustom
      isShowDialog={isShowDialog}
      disabledButton={disabledButton}
      loading={loading}
      dialogTitle={t('create_new_artwork_location')}
      textSubmit={t('create')}
      onCancel={onCancel}
      onSubmit={onSubmit}
    >
      <FormInput
        value={value}
        isRequired={true}
        onChange={onChange}
        label={t('location_name')}
        placeholder={t('please_input_location_name')}
        labelStyle={classes.formInputLabel}
        textFieldStyle={classes.textFieldStyle}
        wrapFormInputStyle={classes.wrapFormInput}
        {...props}
      />
    </DialogCustom>
  );
};

export default DialogLocation;
