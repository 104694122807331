import { makeStyles } from '@mui/styles';

import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  background: {
    width: '100%',
    height: '100vh',
    background: '#e9e6e6',
  },
  container: {
    '&.MuiContainer-root': {
      maxWidth: '83% !important',
    },
  },
  row: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '100vh',
  },

  [mediaQueries.tabletMedium]: {
    container: {
      '&.MuiContainer-root': {
        maxWidth: '80% !important',
      },
    },
  },

  [mediaQueries.mobile]: {
    container: {
      '&.MuiContainer-root': {
        maxWidth: '94% !important',
      },
    },
  },
}));

export default useStyles;
