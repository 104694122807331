import SearchIcon from '@mui/icons-material/Search';
import { Box, InputBase } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import useStyles from './styles';

const SearchInputIcon = ({ value, onChange, placeholder, fullWidth, wrapStyle, disableInput }) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.wrapper, wrapStyle, {
        [classes.fullWidth]: fullWidth,
      })}
    >
      <InputBase
        value={value}
        onChange={onChange}
        className={classes.inputBase}
        placeholder={placeholder}
        disabled={disableInput}
      />

      <SearchIcon />
    </Box>
  );
};

export default SearchInputIcon;
