import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  formInputControl: {
    display: 'flex',
    gap: 40,
    marginTop: 16,
  },
  formInputLabel: {
    color: Colors.Grey8,
    fontSize: '1.6rem',
    fontWeight: 200,
    lineHeight: '150%',
    letterSpacing: 0.64,
    minWidth: 200,
    padding: '0px 4px',
  },
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
  },
  inputLabel: {
    fontWeight: 'bold',
  },
}));

export default useStyles;
