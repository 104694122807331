import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LinkRef from 'commons/LinkRef';
import ManageWrapper from 'commons/ManageWrapper';
import Text from 'commons/Text';
import Routes from 'utils/Route';

import ManageCollectionPanel from './components/ManageCollectionPanel';
import TableManager from './components/TableManager';

const ManageCollection = () => {
  const { t } = useTranslation();

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      text: t('manage_artworks'),
      active: true,
    },
  ];

  return (
    <ManageWrapper
      breadCrumbs={breadCrumbs}
      heading={t('collections')}
      searchPlaceholder={t('search_by_collection_name')}
      actionUi={
        <Button
          component={LinkRef}
          to={Routes.CreateCollection}
          variant="contained"
        >
          <AddIcon />
          <Text ml={8}>{t('create_collection')}</Text>
        </Button>
      }
    >
      <ManageCollectionPanel />

      <TableManager />
    </ManageWrapper>
  );
};

export default ManageCollection;
