import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from './styles';

const ShareSocialHeader = ({ onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Stack
      direction="row"
      sx={{ alignItems: 'center', justifyContent: 'space-between' }}
    >
      <Text
        fontWeightBold
        type="lg"
      >
        {t('share')}
      </Text>

      <CloseIcon
        onClick={onClose}
        className={classes.closeIcon}
      />
    </Stack>
  );
};

export default ShareSocialHeader;
