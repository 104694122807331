import { Box, FormLabel } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BoxSection from 'commons/BoxSection';
import FormInput from 'commons/Form/FormInput';
import GroupActionButton from 'commons/GroupActionButton';
import Text from 'commons/Text';
import TextArea from 'commons/TextArea';

import CollectionVisible from '../CollectionVisible';
import useStyles from './styles';

const CollectionDetailBlock = ({
  collectionFormState,
  textButtonRight,
  handleSubmitCollection,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const { register, formState, watch, handleSubmit } = collectionFormState;

  const handleCancel = () => {
    navigate(-1);
  };
  return (
    <Box>
      <BoxSection heading={t('collection_detail')}>
        <FormInput
          {...register('title')}
          label={t('collection_title')}
          placeholder={t('give_your_collection_title')}
          isRequired
          wrapFormInputStyle={classes.formControl}
          labelStyle={classes.labelStyle}
          error={!!formState?.errors.title}
          helperText={formState?.errors.title?.message}
        />

        <Box sx={{ mt: 2 }}>
          <FormLabel>
            <Text
              variant="span"
              type="sm"
            >
              {t('description')}
            </Text>
          </FormLabel>

          <TextArea
            {...register('description')}
            value={watch('description')}
            textMaxLength={300}
            placeholder={t('write_your_description_about_the_artwork')}
            helperText={formState?.errors.description?.message}
            error={!!formState?.errors.description}
          />
        </Box>
      </BoxSection>

      <CollectionVisible collectionFormState={collectionFormState} />

      <BoxSection wrapperStyle={classes.wrapperStyle}>
        <GroupActionButton
          subButtonLeftText={t('cancel')}
          subButtonRightText={textButtonRight}
          variantButtonRight={'contained'}
          onSubButtonRight={handleSubmit(handleSubmitCollection)}
          onSubButtonLeft={handleCancel}
          groupActionButtonWrapStyle={classes.groupActionWrapper}
          subButtonRightStyle={classes.subButtonRightStyle}
        />
      </BoxSection>
    </Box>
  );
};

export default CollectionDetailBlock;
