import { makeStyles } from '@mui/styles';

import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapActionButton: {
    marginTop: 32,
    justifyContent: 'space-between',
  },
  button: {
    '&.MuiButton-root': {
      width: 128,
      padding: '8px 16px',
    },
  },
  buttonExtend: {
    '&.MuiButton-root': {
      width: 175,
    },
  },

  [mediaQueries.mobile]: {
    button: {
      '&.MuiButton-root': {
        width: '100%',
      },
    },
  },
}));

export default useStyles;
