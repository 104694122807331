import { Box, ImageList, useMediaQuery } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BREAKPOINTS } from 'configs/Constant';
import GalleryArtworkItem from 'pages/Gallery/components/GalleryArtworkItem';
import useStatusCanRequestCertificate from 'react-query/hooks/queries/useStatusCanRequestCertificate';

const ListArtwork = ({ artworks }) => {
  const isLaptop = useMediaQuery(BREAKPOINTS.laptop);
  const isMobile = useMediaQuery(BREAKPOINTS.mobile);
  const { id } = useParams();

  const { data: status } = useStatusCanRequestCertificate();

  const user = useSelector((state) => state.auth.account.user);

  const isOwner = user?.uuid === id;

  const getColumns = () => {
    if (isLaptop) {
      return 4;
    }
    if (isMobile) {
      return 1;
    }
    return 2; //tablet
  };

  return (
    <Box sx={{ mt: 3 }}>
      <ImageList
        variant="masonry"
        cols={getColumns()}
        gap={40}
      >
        <>
          {artworks?.map((artwork) => (
            <GalleryArtworkItem
              artwork={artwork}
              status={status}
              key={artwork?.id}
              isOwner={isOwner}
            />
          ))}
        </>
      </ImageList>
    </Box>
  );
};

export default ListArtwork;
