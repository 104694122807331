import API from 'configs/API';
import { axiosGet, axiosPatch } from 'utils/apis/axios';

const useUserProfile = () => {
  const getUserProfile = async (onSuccess, options) => {
    const { success, data } = await axiosGet(API.AUTH.PROFILE_INFO, options);

    if (success) {
      onSuccess(data);
    }
  };

  const updateUserProfile = async (profileData, onSuccess) => {
    const { success, data } = await axiosPatch(API.AUTH.PROFILE_INFO, profileData);

    if (success) {
      onSuccess(data);
    }
  };

  return { getUserProfile, updateUserProfile };
};

export default useUserProfile;
