import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import CheckBoxCustom from 'commons/CheckBoxCustom';

import useDisplayText from '../hooks/useDisplayText';
import useStyles from './styles';

const CheckBoxGroup = ({
  onCheckboxChange,
  selectedFilters,
  listCheckBox,
  textDisplayKey,
  textDisplayViKey,
  checkedKey,
  frontEndTranslate,
}) => {
  const classes = useStyles();
  const checkBoxRefs = useRef([]);
  const [longestWidth, setLongestWidth] = useState(160);
  const language = useSelector((state) => state?.language?.currentLanguage);
  const getDisplayText = useDisplayText(textDisplayKey, textDisplayViKey, frontEndTranslate);

  useLayoutEffect(() => {
    let max = 160;
    checkBoxRefs.current.forEach((ref) => {
      if (ref) {
        const width = ref.getBoundingClientRect().width;
        if (width > max) {
          max = width;
        }
      }
    });
    setLongestWidth(max);
  }, [listCheckBox, language]);

  return (
    <div className={classes.wrapper}>
      {listCheckBox.map((checkBoxItem, index) => (
        <div
          key={checkBoxItem[checkedKey]}
          ref={(el) => (checkBoxRefs.current[index] = el)}
          style={{
            minWidth: `max(160px, ${longestWidth}px)`,
          }}
        >
          <CheckBoxCustom
            checked={
              !!selectedFilters.find((item) => item === checkBoxItem[checkedKey]?.toString())
            }
            onChange={() => onCheckboxChange(checkBoxItem)}
            textCheckBox={getDisplayText(checkBoxItem)}
          />
        </div>
      ))}
    </div>
  );
};

export default CheckBoxGroup;
