import { Avatar, Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSearchParams, useParams } from 'react-router-dom';

import SocialMedia from 'commons/SocialMedia';
import Text from 'commons/Text';
import API from 'configs/API';
import Colors from 'configs/Colors';
import { CHAT_USER_PARAMS, USER_ROLE_BY_ID } from 'configs/Constant';
import useRole from 'hooks/useRole';
import Routes from 'utils/Route';
import { axiosGet } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

import BoxInfoGroupButtonAction from '../BoxInfoGroupButtonAction';
import ChatButton from '../ChatButton';
import useStyles from './styles';

const BoxInfo = ({ userProfile }) => {
  const { isArtist, isCollectorOrGalleryOwner } = useRole(userProfile?.role);
  const { artistId } = useParams();
  const user = useSelector((state) => state.auth.account.user);

  const isOwner = artistId === user?.uuid;

  const classes = useStyles();
  const { t } = useTranslation();

  const { visible_setting } = userProfile || {};

  const handleCreateLink = async () => {
    const { success, data } = await axiosGet(API.ARTWORK.CREATE_SHARE_LINK, {
      params: { user_uuid: user?.uuid },
    });

    if (success) {
      const currentUrl = `${window.location.origin}${window.location.pathname}`;
      const shareLink = `${currentUrl}?token=${data}`;

      navigator.clipboard.writeText(shareLink).then(() => {
        ToastTopHelper.success(t('link_has_been_copied'));
      });
    }
  };

  return (
    <Box className={classes.wrapBoxInfo}>
      <Avatar
        src={userProfile?.avatar || ''}
        className={classes.avatar}
      />

      <div className={classes.wrapNameUserProfile}>
        <Text
          type="lg"
          mt={16}
          fontWeightBold
        >
          {userProfile?.name || ''}
        </Text>
        {userProfile?.legal_name && (
          <Text
            className={classes.nickNameUserProfile}
            type="lg"
            mt={16}
          >{`[${userProfile?.legal_name}]`}</Text>
        )}
      </div>

      <Text
        className={classes.text}
        type="sm"
      >
        {t(`${USER_ROLE_BY_ID[userProfile?.role] || ''}`)}
      </Text>

      {userProfile?.live_at && (
        <Box className={classes.wrapLocation}>
          <Text
            fontWeightRegular
            color={Colors.Grey8}
            type="xs"
            ml={8}
          >
            {userProfile?.live_at}
          </Text>
        </Box>
      )}

      {visible_setting?.is_public_social_media && <SocialMedia socials={userProfile?.socials} />}

      {isArtist && <BoxInfoGroupButtonAction visibleSetting={visible_setting} />}

      {!isOwner && (
        <ChatButton
          to={{
            pathname: Routes.Chat,
            search: createSearchParams({ [CHAT_USER_PARAMS]: artistId }).toString(),
          }}
        />
      )}

      {isCollectorOrGalleryOwner && isOwner && (
        <Button
          onClick={handleCreateLink}
          variant="outlined"
          fullWidth
          className={classes.buttonShareLink}
          sx={{ mt: 1 }}
        >
          {t('share_link_show')}
        </Button>
      )}
    </Box>
  );
};

export default BoxInfo;
