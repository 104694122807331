import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 16,
    display: 'flex',
    gap: 10,
    alignItems: 'flex-end',
  },
  wrapFormInput: {
    minWidth: 200,
  },
  button: {
    '&.MuiButton-root': {
      paddingTop: 10,
      paddingBottom: 10,
    },
  },
}));

export default useStyles;
