import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormUserByRoleCollector from 'commons/FormUserByRoleCollector';
import API from 'configs/API';
import Section from 'features/Certificate/Section';
import useDebounce from 'hooks/useDebounce';

import useStyles from './styles';

const SelectUserCustomRequestCer = ({ artworkFormState, requestUser }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.account.user);

  const { setValue, watch } = artworkFormState;
  const { inputSearchUser } = watch();
  const inputSearchUserDebounce = useDebounce(inputSearchUser);

  const { data: userFilter = [] } = useQuery({
    queryKey: [API.AUTH.SEARCH_USER, { search: inputSearchUserDebounce, exclude__in: user?.id }],
  });

  const handleClickPaper = () => () => {};

  const handleInputSearchUser = (e) => {
    const value = e?.target?.value || '';
    setValue('inputSearchUser', value);
  };

  const handleCheckArtist = (e) => {
    const checked = e.target.checked;
    if (checked) {
      handleClearData();
    }
  };

  const handleClearData = () => {
    setValue('selectedUser', null, { shouldValidate: true });
    setValue('inputSearchUser', '');
  };

  const handleSelectUser = (e, newValue) => {
    setValue('selectedUser', newValue || null, { shouldValidate: true });
  };

  const handleSearchAgain = () => {
    setValue('selectedUser', null, { shouldValidate: true });
    setValue('inputSearchUser', '');
  };

  const handleClearIcon = () => {
    if (requestUser) {
      return;
    }

    handleSearchAgain();
  };

  return (
    <Section
      title={t('request_to')}
      required
      wrapperStyle={classes.wrapper}
    >
      <div className={classes.wrapFormUser}>
        <FormUserByRoleCollector
          artworkFormState={artworkFormState}
          titleListUser={t('active_users')}
          userFilter={userFilter}
          handleCheckArtist={handleCheckArtist}
          handleInputSearchUser={handleInputSearchUser}
          handleClickPaper={handleClickPaper}
          handleSelectUser={handleSelectUser}
          handleSearchAgain={handleSearchAgain}
          handleClickClearIon={handleClearIcon}
          handleClickSelectSaveUser={() => () => null}
          isShowCustomPaper={false}
          showText={!requestUser}
        />
      </div>
    </Section>
  );
};

export default SelectUserCustomRequestCer;
