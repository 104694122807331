import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapArtworkImage: {
    paddingTop: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapLoading: {
    position: 'relative',
  },
  viewImage: {
    height: 'calc(100vh - 351px)',
    width: '50%',
    position: 'relative',
  },
  skeleton: {
    '&.MuiSkeleton-root': {
      height: '100%',
      width: '100%',
    },
  },
  imageIcon: {
    '&.MuiSvgIcon-root': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: Colors.Grey2,
      width: 100,
      height: 100,
    },
  },
  blankItem: {
    marginTop: 16,
    height: 100,
  },

  [mediaQueries.tabletLarge]: {
    viewImage: {
      height: 'calc(100vh - 351px * 1.5)',
      width: '100%',
    },
  },
}));

export default useStyles;
