import React from 'react';

const Stepper = ({ children, active }) => {
  if (!active) {
    return null;
  }

  return <>{children}</>;
};

export default Stepper;
