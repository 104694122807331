import { useMediaQuery } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BREAKPOINTS } from 'configs/Constant';
import { extendMenu } from 'redux/reducer/authSlice';

import ListMenu from './ListMenu';
import ToggleSideBar from './ToggleSideBar';
import useStyles from './styles';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    height: 500,
    position: 'sticky',
    top: 8,
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const LayoutSideBar = () => {
  const isCheckExtendMenu = useSelector((state) => state?.auth?.isCheckExtendMenu);
  const dispatch = useDispatch();
  const classes = useStyles();
  const isLaptop = useMediaQuery(BREAKPOINTS.laptop);

  const handleToggleSideBar = () => {
    dispatch(extendMenu());
  };

  const open = isLaptop ? isCheckExtendMenu : false;

  return (
    <Drawer
      variant="permanent"
      open={open}
      className={classes.drawer}
    >
      <ToggleSideBar
        open={open}
        onToggle={handleToggleSideBar}
      />

      <ListMenu open={open} />
    </Drawer>
  );
};

export default LayoutSideBar;
