import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { USER_ROLE_BY_KEY } from 'configs/Constant';

import EditArtworkSection from '../../../../commons/BoxSection';
import FormPriceArtist from './FormPriceArtist';
import FormPriceCollector from './FormPriceCollector';

const { ARTIST, COLLECTOR } = USER_ROLE_BY_KEY;

const OPTION_PRICE_BY_ROLE = {
  [ARTIST]: FormPriceArtist,
  [COLLECTOR]: FormPriceCollector,
};

const EditArtworkPrice = ({ artworkFormState }) => {
  const { t } = useTranslation();
  const userRole = useSelector((state) => state.auth.account.user.role);
  const DisplayFormPrice = OPTION_PRICE_BY_ROLE[userRole];

  if (!DisplayFormPrice) {
    return;
  }

  return (
    <EditArtworkSection
      heading={t('price')}
      sx={{ mb: 2 }}
    >
      <DisplayFormPrice artworkFormState={artworkFormState} />
    </EditArtworkSection>
  );
};

export default EditArtworkPrice;
