import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  listItemPublication: {
    cursor: 'pointer',
    marginTop: 16,
    display: 'flex',
    width: '100%',
  },
  wrapYearAward: {
    display: 'flex',
    width: '15%',
    marginBottom: 3,
  },
  wrapDescriptionAndIcon: {
    display: 'flex',
    width: '85%',
    justifyContent: 'space-between',
  },
  textYearAward: {
    marginLeft: 24,
    color: Colors.Grey8,
    width: 38,
  },
  wrapDescriptionAndLink: {
    display: 'block',
    width: '85%',
    color: Colors.Grey8,
    wordWrap: 'break-word',
  },
  editIcon: {
    '&.MuiIcon-root': {
      color: Colors.Black,
      marginRight: 8,
    },
  },
  wrapImagItemAward: {
    display: 'flex',
    width: '90%',
    marginTop: 8,
  },
  dividerStyle: {
    '&.MuiDivider-root': {
      color: Colors.Grey3,
      marginTop: 16,
      width: '100%',
    },
  },
  iconVisibility: {
    '&.MuiSvgIcon-root': {
      marginTop: -1,
    },
  },
  linkItem: {
    '&.MuiLink-root': {
      color: Colors.Blue6,
    },
  },
}));

export default useStyles;
