import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import Section from 'features/Certificate/Section';

import useStyles from './styles';

const OwnerInfoRequestCer = ({ ownerName, yearOfBirth, address }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Section title={t('owner_information')}>
      <div className={classes.formInputControl}>
        <Text className={classes.formInputLabel}>
          {t('owner_name')}
          <span className={classes.require}>*</span>
        </Text>

        <Text className={classes.inputLabel}>{ownerName}</Text>
      </div>

      <div className={classes.formInputControl}>
        <Text className={classes.formInputLabel}>
          {t('year_of_birth')}
          <span className={classes.require}>*</span>
        </Text>

        <Text className={classes.inputLabel}>{yearOfBirth}</Text>
      </div>

      <div className={classes.formInputControl}>
        <Text className={classes.formInputLabel}>
          {t('address')}
          <span className={classes.require}>*</span>
        </Text>

        <Text className={classes.inputLabel}>{address}</Text>
      </div>
    </Section>
  );
};

export default OwnerInfoRequestCer;
