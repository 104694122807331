import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapItemSpeedDial: {
    position: 'absolute',
    bottom: '130%',
    right: 0,
    whiteSpace: 'nowrap',
    opacity: 0,
    transition: 'all 0.4s',
  },
  speedDialIcon: {
    transition: 'all 0.4s',
  },
  speedDialIconOpen: {
    transform: 'rotate(45deg)',
  },
  openSpeedDial: {
    opacity: 1,
  },
  itemSpeedDial: {
    display: 'flex',
    padding: '16px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '99px',
    border: `1px solid ${Colors.Gray3}`,
    background: Colors.White,
    boxShadow: '0px 32px 54px -12px rgba(16, 24, 40, 0.20)',
    letterSpacing: '0.64px',
    color: Colors.Primary,
    cursor: 'pointer',
    marginBottom: 8,
  },
  itemSpeedDialFirst: {
    marginBottom: -8,
  },

  button: {
    '&.MuiButton-root': {
      width: 56,
      height: 56,
      minWidth: 0,
      padding: 0,
      fontWeight: 400,
      fontSize: '1.6rem',
      borderRadius: '50%',
      position: 'fixed',
      bottom: '3%',
      right: '3%',
    },
  },
}));

export default useStyles;
