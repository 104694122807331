import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapFormInput: {
    marginTop: 16,
  },
  inputLabel: {
    padding: '0px 4px',
  },
  labelMargin: {
    marginBottom: 4,
  },
  textField: {
    '& .MuiInputBase-input': {
      height: 'unset',
      padding: '12px 8px',
      fontSize: 14,
      lineHeight: 1.42858,
      '&.Mui-disabled': {
        background: Colors.Grey1,
      },
    },
    '& .MuiFormHelperText-root': {
      marginBottom: 0,
      marginLeft: 5,
    },
  },
  iconEye: {
    height: 24,
  },
  helperText: {
    '&.MuiFormHelperText-root': {
      marginLeft: 4,
    },
  },

  // FormInputRadio
  formControlLabel: {
    '&.MuiFormControlLabel-root': {
      marginTop: 7,
      '&:first-child': {
        marginTop: 0,
      },
    },
    '& .MuiFormControlLabel-label': {
      marginLeft: 3,
    },
  },

  // FormSelect
  select: {
    width: '100%',
    '& .MuiSelect-root': {
      background: 'red',
    },

    '& .MuiSelect-outlined': {
      padding: '12px 8px',
      fontSize: 14,
    },
  },
}));

export default useStyles;
