import React from 'react';

import { STATUS_REQUEST_KEY } from 'configs/Constant';

import IconActionStatusDeny from './IconActionStatusDeny';
import IconActionView from './IconActionView';
import useStyles from './styles';

const STATUS_MAP = {
  [STATUS_REQUEST_KEY.REQUEST_RECEIVED]: IconActionView,
  [STATUS_REQUEST_KEY.REQUEST_APPROVED]: IconActionView,
  [STATUS_REQUEST_KEY.REQUEST_DENIED]: IconActionStatusDeny,
};

const ColumnAction = ({ status, onClick }) => {
  const classes = useStyles();
  const DisplayIconAction = STATUS_MAP[status];

  return (
    <div className={classes.wrapIcon}>
      {DisplayIconAction && <DisplayIconAction onClick={onClick} />}
    </div>
  );
};

export default ColumnAction;
