import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ARTWORK_FILTER_PARAMS } from 'configs/FilterArtwork';

import CommonTag from '../CommonTag';

const { YEAR } = ARTWORK_FILTER_PARAMS;

const CreationYearTag = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const year = searchParams.get(YEAR)?.split(',') || [];
  const [fromYear, toYear] = year;

  const handleRemoveCategory = () => {
    searchParams.delete(YEAR);
    setSearchParams(searchParams);
  };

  const getTextMaxToYear = () => {
    if (!toYear) {
      return t('now');
    }

    return toYear;
  };

  const getTextTag = `${fromYear} - ${getTextMaxToYear()}`;

  if (!fromYear) {
    return;
  }

  return (
    <CommonTag
      text={getTextTag}
      onRemoveFilter={handleRemoveCategory}
    />
  );
};

export default CreationYearTag;
