import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import CheckBoxGroup from 'commons/FilterArtwork/CheckBoxGroup';
import API from 'configs/API';
import { ARTWORK_FILTER_PARAMS, LOCATION_KEY } from 'configs/FilterArtwork';

const { LOCATION } = ARTWORK_FILTER_PARAMS;

const FilterLocation = () => {
  const userId = useSelector((state) => state.auth.account.user.id);

  const { data: locations = [] } = useQuery({
    queryKey: [API.ARTWORK.FILTER.LOCATION, { user: userId }],
  });
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedLocationParams = searchParams.get(LOCATION)?.split(',') || [];

  const handleCheckboxChange = (location) => {
    const isChecked = selectedLocationParams.find(
      (item) => item === location[LOCATION_KEY.CHECKED]?.toString(),
    );

    searchParams.delete('page');

    if (!isChecked) {
      searchParams.set(LOCATION, [...selectedLocationParams, location[LOCATION_KEY.CHECKED]]);
      setSearchParams(searchParams);
      return;
    }

    const newSelectedLocationParams = [...selectedLocationParams].filter(
      (item) => item !== location[LOCATION_KEY.CHECKED]?.toString(),
    );

    if (newSelectedLocationParams.length) {
      searchParams.set(LOCATION, newSelectedLocationParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(LOCATION);
      setSearchParams(searchParams);
    }
  };

  return (
    <CheckBoxGroup
      listCheckBox={locations}
      selectedFilters={selectedLocationParams}
      onCheckboxChange={handleCheckboxChange}
      textDisplayKey={LOCATION_KEY.DISPLAY}
      checkedKey={LOCATION_KEY.CHECKED}
    />
  );
};

export default FilterLocation;
