import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Popover } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TESTID } from 'configs/Constant';

import useStyles from './styles';

const SettingDotPopover = ({ anchorEl, onOpenPopover, onClosePopover, onOpenDialogSetting }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div
        className={classes.wrapDot}
        onClick={onOpenPopover}
        data-testid={TESTID.USER_PROFILE_SETTING_DOT}
      >
        <MoreHorizIcon />
      </div>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        <div
          className={classes.settingText}
          onClick={onOpenDialogSetting}
        >
          {t('visibility_settings')}
        </div>
      </Popover>
    </>
  );
};

export default SettingDotPopover;
