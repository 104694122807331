import { Table } from '@mui/material';
import React from 'react';

import RequestCertificateTableBodyArtist from './components/RequestCertificateTableBodyArtist';
import RequestCertificateTableHeadArtist from './components/RequestCertificateTableHeadArtist';
import useStyles from './styles';

const RequestCertificateTableArtist = ({ listRequestCertificate, page, pageSize }) => {
  const classes = useStyles();

  return (
    <div className={classes.tableContainer}>
      <Table className={classes.table}>
        <RequestCertificateTableHeadArtist />
        <RequestCertificateTableBodyArtist
          listRequestCertificate={listRequestCertificate}
          page={page}
          pageSize={pageSize}
        />
      </Table>
    </div>
  );
};

export default RequestCertificateTableArtist;
