import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  editionItemWrapper: {
    marginTop: 24,
  },
  statusNumberWidth: {
    width: '9%',
    padding: '8px 14px',
    background: Colors.Grey2,
    display: 'flex',
    alignItems: 'center',
  },
  formSelectControl: {
    '&.MuiFormControl-root': {
      marginTop: 0,
      flex: 1,
    },
  },
  selectDisable: {
    '&.Mui-disabled': {
      background: Colors.Grey2,
    },
  },
}));

export default useStyles;
