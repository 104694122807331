import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import { ReactComponent as Logo } from 'images/logo-gladiusart-white.svg';
import { ReactComponent as TextGladiusArt } from 'images/text-gladiusart-white.svg';

import ContactUs from './components/ContactUs';
import MainFeatures from './components/MainFeatures';
import OurMission from './components/OurMission';
import OurService from './components/OurService';
import useStyles from './styles';

const AboutUs = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box className={classes.background}>
        <Logo className={classes.logo} />
        <Text className={classes.textWelcomeTo}>{t('welcome_to')}</Text>
        <TextGladiusArt className={classes.textGladiusArt} />
        <Text className={classes.textVNleading}>
          {t('the_vietnam_leading_online_art_platform')}
        </Text>
      </Box>

      <Box className={classes.wrapContentMoreThanPlatform}>
        <Text className={classes.textMoreThanOnlinePlatform}>
          {t('gladius_art_is_more_than_an_online_platform')}{' '}
        </Text>
      </Box>
      <Box className={classes.wrapTab}>
        <TabContext value={value}>
          <Box>
            <TabList
              onChange={handleChange}
              className={classes.tabList}
            >
              <Tab
                label={t('our_mission')}
                value="1"
                className={classes.textItemTab}
              />
              <Tab
                label={t('our_services')}
                value="2"
                className={classes.textItemTab}
              />
              <Tab
                label={t('main_features')}
                value="3"
                className={classes.textItemTab}
              />
            </TabList>
          </Box>
          <TabPanel
            className={classes.tabPanel}
            value="1"
          >
            <OurMission />
          </TabPanel>
          <TabPanel
            className={classes.tabPanel}
            value="2"
          >
            <OurService />
          </TabPanel>
          <TabPanel
            className={classes.tabPanel}
            value="3"
          >
            <MainFeatures />
          </TabPanel>
        </TabContext>
      </Box>

      <ContactUs />
    </div>
  );
};

export default AboutUs;
