import * as yup from 'yup';

import { onlyLetterRegExp, phoneRegExp } from 'utils/Validation';

export const artworkInfoSchema = yup.object().shape(
  {
    titleArtwork: yup.string().required('This field is required').max(50),
    height: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Height field is required')
      .positive('Must be a positive number')
      .max(99999999, 'Maximum height is 8 digits')
      .test('max-two-decimals', 'No more than 2 decimal places allowed', (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      ),
    width: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Width field is required')
      .positive('Must be a positive number')
      .max(99999999, 'Maximum width is 8 digits')
      .test('max-two-decimals', 'No more than 2 decimal places allowed', (number) =>
        /^\d+(\.\d{1,2})?$/.test(String(number)),
      ),
    depth: yup
      .string()
      .notRequired()
      .test('max-two-decimals', 'No more than 2 decimal places allowed', (number) => {
        if (!number) {
          return true;
        }
        return /^\d+(\.\d{1,2})?$/.test(String(number));
      }),
    yearCreated: yup.number('invalid number').when('periodYear', {
      is: (periodYear) => !!periodYear,
      then: (schema) =>
        schema.transform((value) => (Number.isNaN(value) ? null : value)).notRequired(),
      otherwise: (schema) =>
        schema
          .transform((value) => (Number.isNaN(value) ? null : value))
          .typeError('Amount must be a number')
          .required('This field is required')
          .positive('Year must be a positive number')
          .integer('Year must be an integer')
          .test('valid-year', 'Invalid year', (value) => {
            const currentYear = new Date().getFullYear();
            return value >= 1 && value <= currentYear;
          }),
    }),
    periodYear: yup.string().when('yearCreated', {
      is: (yearCreated) => !!yearCreated,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) =>
        schema.required().max(30, 'Period Year must be at most 30 characters long'),
    }),
    mediumArtWork: yup.object().required('This field is required'),
    edition: yup.object().when('editionVolume', ([editionVolume], schema) => {
      return editionVolume
        ? schema.shape({
            edition_number: yup
              .number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .required('Edition number is required')
              .integer('Edition number must be an integer')
              .positive('Edition number must be a positive number')
              .max(editionVolume, 'Edition number must be less than edition volume'),
          })
        : schema;
    }),
    editionVolume: yup
      .number()
      .typeError('Edition volume must be a number')
      .required('Edition volume is required')
      .positive('Edition volume must be a positive number')
      .integer('Edition volume must be an integer')
      .max(20, 'Edition volume must be less than 20'),
    recipient: yup
      .string()
      .matches(onlyLetterRegExp, 'Recipient must only letters and not special character')
      .required('This field is required')
      .max(50),
    address: yup.string().required('This field is required').max(60),
    phoneNumber: yup
      .string()
      .required('This field is required')
      .matches(phoneRegExp, 'Please enter a valid phone number'),
    ownerName: yup
      .string()
      .required('This field is required')
      .matches(onlyLetterRegExp, 'Owner name must only letters and not special character')
      .max(30),
    yearOfBirth: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required('Year of birth is required')
      .integer('Year of birth must be an integer')
      .test('reasonable-year', 'Year of birth must be a reasonable value', (value) => {
        const currentYear = new Date().getFullYear();
        return value >= 1900 && value <= currentYear;
      }),
    ownerAddress: yup
      .string()
      .required('Address is required')
      .max(80, 'Address must be no more than 80 characters long'),
    inputSearchUser: yup.string().notRequired(),
    selectedUser: yup
      .object()
      .shape({
        name: yup.string().required('User name is required'),
      })
      .required('User is required'),
  },
  [['periodYear', 'yearCreated']],
);
