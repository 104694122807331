import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 24,
    width: '100%',
    background: Colors.White,
  },
  breadcrumbs: {
    '&.MuiBreadcrumbs-root': {
      marginTop: 16,
    },
  },
}));

export default useStyles;
