import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPatch } from 'utils/apis/axios';

const updateArtwork = async (artworkId, formData) => {
  const { data } = await axiosPatch(false, API.ARTWORK.ARTWORK_DETAIL(artworkId), formData);

  return data;
};

const useUpdateArtwork = (artworkId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => updateArtwork(artworkId, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API.ARTWORK.ARTWORK_DETAIL(artworkId)] });
    },
  });
};

export default useUpdateArtwork;
