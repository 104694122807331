import React from 'react';
import { useSearchParams } from 'react-router-dom';

import useDisplayText from 'commons/FilterArtwork/hooks/useDisplayText';
import { ARTWORK_FILTER_PARAMS, STATUS_KEY, FILTER_STATUS_OPTION } from 'configs/FilterArtwork';

import CommonTag from '../CommonTag';

const { STATUS } = ARTWORK_FILTER_PARAMS;

const StatusTag = () => {
  const getDisplayText = useDisplayText(STATUS_KEY.CHECKED, null, true);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedStatusParams = searchParams.get(STATUS)?.split(',') || [];
  const selectedStatus = FILTER_STATUS_OPTION.filter((status) =>
    selectedStatusParams.includes(status[STATUS_KEY.CHECKED]),
  );

  const handleRemoveCategory = (status) => {
    const newSelectedCategoryParams = [...selectedStatusParams].filter(
      (item) => item !== status[STATUS_KEY.CHECKED],
    );

    if (newSelectedCategoryParams.length) {
      searchParams.set(STATUS, newSelectedCategoryParams);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(STATUS);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      {selectedStatus.map((status) => (
        <CommonTag
          key={status[STATUS_KEY.CHECKED]}
          text={getDisplayText(status)}
          onRemoveFilter={() => handleRemoveCategory(status)}
        />
      ))}
    </>
  );
};

export default StatusTag;
