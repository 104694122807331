import { SOCIAL_ICON, TESTID } from 'configs/Constant';

import useStyles from './styles';

const SocialItem = ({ social }) => {
  const classes = useStyles();

  const platform = social?.platform?.toUpperCase();
  const IconComponent = SOCIAL_ICON[platform];

  if (!IconComponent) {
    return;
  }

  return (
    <a
      href={social?.url}
      target="_blank"
      className={classes.socialItem}
      rel="noreferrer"
      testid={TESTID.SOCIAL_ITEM}
    >
      <IconComponent className={classes.icon} />
    </a>
  );
};

export default SocialItem;
