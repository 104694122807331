import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  measureTypeWrap: {
    width: 42,
    background: Colors.Grey2,
    flexShrink: 0,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    border: `1px solid ${Colors.Grey3}`,
    borderRight: 'none',
  },
  measureText: {
    '&.MuiFormLabel-root': {
      color: Colors.Grey10,
      fontSize: '1.4rem',
      fontWeight: 500,
      lineHeight: '142.857%',
      letterSpacing: 0.56,
    },
  },
  textField: {
    '& .MuiOutlinedInput-input': {
      height: 'unset',
      padding: '12px 8px',
      fontSize: '1.4rem',
      lineHeight: 1.42858,
    },
    '& .MuiOutlinedInput-root fieldset': {
      border: `1px solid ${Colors.Grey3}`,
      borderRadius: 0,
    },
  },
}));

export default useStyles;
