import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPost } from 'utils/apis/axios';

const requestCertificate = async (artworkId, formData) => {
  const { data } = await axiosPost(
    false,
    API.ARTWORK.COLLECTOR_REQUEST_CERTIFICATE(artworkId),
    formData,
  );
  return data;
};

const useRequestCertificate = (artworkId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (formData) => requestCertificate(artworkId, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API.ARTWORK.LIST_CERTIFICATE_REQUEST] });
    },
  });
};

export default useRequestCertificate;
