import React from 'react';

import LogoPlatForm from 'images/logo-platform.svg';

import useStyles from '../styles';

const HeaderLeft = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapHeaderLeft}>
      <div className={classes.wrapGroupItem}>
        <img
          src={LogoPlatForm}
          className={classes.logoPlatForm}
        />
      </div>
    </div>
  );
};
export default HeaderLeft;
