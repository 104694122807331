import React from 'react';

import CertificateAutoView from 'commons/CertificateAutoView';

const CertificateInfo = ({ artworkFormState, artwork }) => {
  const { getValues } = artworkFormState;
  const data = getValues();

  const artworkReview = {
    ...artwork,
    title: data?.titleArtwork,
    size: {
      ...artwork.size,
      height: data?.height,
      width: data?.width,
      depth: data?.depth,
    },
    medium: data?.mediumArtWork,
    period_year: data?.periodYear,
    year_created: data?.yearCreated,
    total_edition: data?.editionVolume,
  };

  const certificate = {
    edition_number: data?.edition.edition_number,
    issued_by: data?.selectedUser?.id
      ? {
          ...data?.selectedUser,
        }
      : {},
  };

  return (
    <CertificateAutoView
      artwork={artworkReview}
      certificate={certificate}
    />
  );
};

export default CertificateInfo;
