import { Tab, Tabs } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import TabPanel from 'commons/TabPanel';
import API from 'configs/API';
import { USER_ROLE_BY_KEY } from 'configs/Constant';

import ArtworkCollection from '../ArtworkCollection';
import GalleryArtist from '../GalleryArtist';
import GalleryArtwork from '../GalleryArtwork';
import SpeedDialNavigation from '../SpeedDialNavigation';
import useStyles from './styles';

const GalleryTabContent = ({ visibleSetting, roleUser }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const user = useSelector((state) => state.auth.account.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const tabValue = Number(searchParams.get('tab') || 1);
  const { artistId } = useParams();

  const isCollectorOrGalleryOwner = [
    USER_ROLE_BY_KEY.COLLECTOR,
    USER_ROLE_BY_KEY.GALLERY_OWNER,
  ].includes(roleUser);

  const { data: artworksCount } = useQuery({
    queryKey: [API.ARTWORK.ARTWORK_COUNT, { owner_uuid: artistId }],
  });
  const { data: collectionsCount } = useQuery({
    queryKey: [API.ARTWORK.COLLECTION_COUNT, { owner_uuid: artistId }],
  });

  const { data: listArtist, isPending: isPendingListArtist } = useQuery({
    queryKey: [API.ARTWORK.LIST_ARTIST_NAME_FOR_COLLECTOR, { user_uuid: artistId, token: token }],
  });

  const isOwner = user?.uuid === artistId;

  const handleTabChange = (e, newValue) => {
    searchParams.set('tab', newValue);
    searchParams.delete('search');
    searchParams.delete('page');
    setSearchParams(searchParams, { replace: true });
  };

  const getLabel = ({ text, number, isShowNumber }) => {
    if (!isShowNumber) {
      return text;
    }

    return `${text} ${number}`;
  };

  const tokenExpiresAt = new Date().getTime() + 30 * 60 * 1000;
  const currentTime = new Date().getTime();
  const timeLeft = tokenExpiresAt - currentTime;

  useEffect(() => {
    if (timeLeft > 0) {
      setTimeout(() => {
        window.location.reload();
      }, timeLeft);
    }
  }, [timeLeft]);

  if (isPendingListArtist) {
    return;
  }

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={classes.tabs}
      >
        <Tab
          label={getLabel({
            text: t('all_works'),
            number: artworksCount || '',
            isShowNumber: visibleSetting?.is_public_artwork,
          })}
          value={1}
          className={classes.tab}
        />

        {isCollectorOrGalleryOwner && (
          <Tab
            label={getLabel({
              text: t('artists'),
              number: listArtist?.length || '',
              isShowNumber: visibleSetting?.is_public_artwork,
            })}
            value={2}
            className={classes.tab}
          />
        )}

        <Tab
          label={getLabel({
            text: t('collection'),
            number: collectionsCount || '',
            isShowNumber: visibleSetting?.is_public_artwork,
          })}
          value={3}
          className={classes.tab}
        />
      </Tabs>

      <TabPanel
        value={tabValue}
        index={1}
      >
        <GalleryArtwork token={token} />
      </TabPanel>

      {isCollectorOrGalleryOwner && (
        <TabPanel
          value={tabValue}
          index={2}
        >
          <GalleryArtist listArtist={listArtist} />
        </TabPanel>
      )}

      <TabPanel
        value={tabValue}
        index={3}
      >
        <ArtworkCollection />
      </TabPanel>
      {isOwner && <SpeedDialNavigation />}
    </>
  );
};

export default GalleryTabContent;
