import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Popover } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DialogCustom from 'commons/DialogCustom';
import Text from 'commons/Text';
import API from 'configs/API';
import Colors from 'configs/Colors';
import { TESTID } from 'configs/Constant';
import useDeleteArtwork from 'hooks/useDeleteArtwork';
import useRole from 'hooks/useRole';
import Routes from 'utils/Route';
import { ToastTopHelper } from 'utils/utils';

import useStyles from './styles';

const ArtworkItemPopover = ({ artwork, status, dotStyle }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate: deleteArtwork } = useDeleteArtwork();
  const [isShowDialog, setIsShowDialog] = useState(false);
  const { isArtist, isCollector } = useRole();
  const queryClient = useQueryClient();

  const canRequestCertificate =
    Array.isArray(status) && status?.find((item) => item?.value === artwork?.status?.value);

  const certificateText = isArtist
    ? t('create_certificate')
    : isCollector
      ? t('request_certificate')
      : null;

  const handleOpenPopover = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleRequestCertificate = (e) => {
    e.stopPropagation();
    if (isArtist) {
      navigate(Routes.CreateCertificate(artwork?.id));
    } else if (isCollector) {
      navigate(Routes.RequestCertificateId(artwork?.id));
    }
  };

  const handleShowPopUpConfirmDelete = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    setIsShowDialog(true);
  };

  const handleDeleteArtwork = (e) => {
    e.stopPropagation();
    deleteArtwork(artwork?.id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [API.ARTWORK.LIST_GALLERY_ARTWORK] });
        ToastTopHelper.success(
          t(
            artwork?.has_certificate ? 'text_move_artwork_to_store' : 'text_delete_artwork_success',
          ),
        );
      },
    });

    setIsShowDialog(false);
  };

  const handleCancelDelete = (e) => {
    e.stopPropagation();
    setIsShowDialog(false);
  };

  const handleNavigateEditArtwork = (e) => {
    e.stopPropagation();
    navigate(Routes.EditArtwork(artwork?.id));
  };

  return (
    <>
      <div
        className={clsx(
          classes.wrapdots,
          {
            [classes.dotActive]: !!anchorEl,
          },
          dotStyle,
        )}
        onClick={handleOpenPopover}
        testid={TESTID.BUTTON_ARTWORK_ITEM_POPOVER}
      >
        <MoreHorizIcon className={classes.iconDots} />
      </div>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.popover}
        testid={TESTID.POPOVER}
      >
        {certificateText && (
          <div
            className={clsx(classes.wrapPopoverContent, {
              [classes.disable]: !canRequestCertificate,
            })}
            onClick={canRequestCertificate ? handleRequestCertificate : undefined}
            testid={`${TESTID.REQUEST_CERTIFICATE}-${artwork?.id}`}
          >
            <Text>{certificateText}</Text>
          </div>
        )}

        <div
          className={classes.wrapPopoverContent}
          onClick={handleNavigateEditArtwork}
        >
          <Text>{t('edit_artwork')}</Text>
        </div>

        <div
          className={classes.wrapPopoverContent}
          onClick={handleShowPopUpConfirmDelete}
        >
          <Text color={Colors.Error}>{t('delete_artwork')}</Text>
        </div>
      </Popover>

      <DialogCustom
        isShowDialog={isShowDialog}
        dialogTitle={t('delete_artwork')}
        textCancel={t('cancel')}
        textSubmit={t('delete')}
        onCancel={handleCancelDelete}
        onSubmit={handleDeleteArtwork}
        submitButtonStyle={clsx(classes.buttonDialog, classes.buttonDanger)}
        cancelButtonStyle={clsx(classes.buttonDialog)}
      >
        <div className={classes.wrapDialogContent}>
          {t(
            artwork?.has_certificate
              ? 'text_confirm_delete_artwork_has_certificate'
              : 'text_confirm_delete_artwork_no_certificate',
          )}
        </div>
      </DialogCustom>
    </>
  );
};

export default ArtworkItemPopover;
