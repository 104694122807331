import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapPrivateLayout: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default useStyles;
