import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Stack, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { STATUS_REQUEST_KEY } from 'configs/Constant';
import Routes from 'utils/Route';

import useStyles from './styles';

const TableArtistTagRequestAction = ({ tagRequest }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleNavigatePageTagRequest = () => {
    navigate(Routes.ReviewTagRequest(tagRequest?.id));
  };
  const isStatusCanceled = tagRequest?.status === STATUS_REQUEST_KEY.REQUEST_CANCELED;

  return (
    <Stack>
      <Tooltip
        title={t(isStatusCanceled ? 'this_request_cancelled' : 'view_detail')}
        arrow
      >
        {isStatusCanceled ? (
          <VisibilityOffOutlinedIcon className={classes.iconOutLine} />
        ) : (
          <VisibilityOutlinedIcon
            onClick={handleNavigatePageTagRequest}
            className={classes.iconOutLine}
          />
        )}
      </Tooltip>
    </Stack>
  );
};

export default TableArtistTagRequestAction;
