import { useSelector } from 'react-redux';

import { USER_ROLE_BY_KEY } from 'configs/Constant';

const useRole = (role) => {
  const _userRole = useSelector((state) => state.auth.account.user.role);
  const userRole = role || _userRole;

  const isArtist = userRole === USER_ROLE_BY_KEY['ARTIST'];
  const isCollector = userRole === USER_ROLE_BY_KEY['COLLECTOR'];
  const isGallery = userRole === USER_ROLE_BY_KEY['GALLERY_OWNER'];
  const isCollectorOrGalleryOwner = isCollector || isGallery;

  return { isArtist, isCollector, isGallery, isCollectorOrGalleryOwner };
};

export default useRole;
