import { useDispatch } from 'react-redux';

import API from 'configs/API';
import { updateAccount } from 'redux/reducer/authSlice';
import { axiosGet, axiosPatch } from 'utils/apis/axios';

const useUserAccount = () => {
  const dispatch = useDispatch();

  const getUserAccount = async () => {
    const { success, data } = await axiosGet(API.AUTH.ACCOUNT_INFO);

    if (success) {
      dispatch(updateAccount(data));
    }
  };

  const updateUserAccount = async (profileData, onSuccess) => {
    const { success, data } = await axiosPatch(API.AUTH.ACCOUNT_INFO, profileData);

    if (success) {
      dispatch(updateAccount(data));
      onSuccess(data);
    }
  };

  return { getUserAccount, updateUserAccount };
};

export default useUserAccount;
