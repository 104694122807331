import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapEditionForm: {
    marginBottom: 24,
  },
  autocompleteStyle: {
    '&.MuiAutocomplete-root': {
      '& .MuiOutlinedInput-root': {
        padding: 3.5,
      },
    },
  },
}));

export default useStyles;
