import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from '../styles';

const ImageError = ({ imageStyle, errorStyle, customeError }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (customeError) {
    return customeError;
  }

  return (
    <div className={clsx(classes.imageError, imageStyle, errorStyle)}>
      {t('image_could_not_be_loaded')}
    </div>
  );
};

export default ImageError;
