import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Text from 'commons/Text';
import Colors from 'configs/Colors';
import Routes from 'utils/Route';

import CollectionLikeStat from '../CollectionLikeStat';
import ImageSection from './ImageSection';

const CollectionItem = ({ collection, redirectTo }) => {
  const { t } = useTranslation();

  return (
    <Link to={Routes.CollectionDetail(collection?.uuid)}>
      <ImageSection artworks={collection?.artworks} />

      <Text
        type="md"
        color={Colors.Grey10}
        fontWeightMedium
        mt={8}
      >
        {collection?.title}
      </Text>

      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Text
          type="xs"
          color={Colors.Grey10}
        >
          {`${collection?.artworks?.length}`} {t('artworks')}
        </Text>

        <CollectionLikeStat
          collection={collection}
          redirectTo={redirectTo}
        />
      </Stack>
    </Link>
  );
};

export default CollectionItem;
