import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 16,
  },
  image: {
    height: 200,
    borderRadius: 0,
  },
}));

export default useStyles;
