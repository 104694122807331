import AddIcon from '@mui/icons-material/Add';
import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DialogLocation from 'commons/DialogLocation';
import Text from 'commons/Text';
import API from 'configs/API';
import useCreateLocation from 'hooks/useCreateLocation';
import { ToastTopHelper } from 'utils/utils';

import useUpdateLocation from './hooks/useUpdateLocation';
import useStyles from './styles';

const EditionLocation = ({ locationOption, edition }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.account.user);
  const { mutate: updateLocation } = useUpdateLocation();
  const { mutate: createLocation } = useCreateLocation();
  const queryClient = useQueryClient();

  const [locationName, setLocationName] = useState('');
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [location, setLocation] = useState(edition?.location);
  const [openSelectLocation, setOpenSelectLocation] = useState(false);

  const handleChangeLocation = (e) => {
    const value = e.target.value;
    setLocation(value);
    updateLocation({ id: edition?.id, location: value });
  };

  const handleSelectLocation = () => {
    setOpenSelectLocation(true);
  };

  const handleCloseLocation = () => {
    setOpenSelectLocation(false);
  };

  const handleOpenDialogLocation = () => {
    setOpenSelectLocation(false);
    setIsShowDialog(true);
  };

  const truncateString = (str) => {
    if (str.length > 30) {
      return str.substring(0, 30) + '...';
    }
    return str;
  };

  const handleChangeLocationName = (e) => {
    const value = e.target.value;
    if (value?.length > 50) {
      return;
    }
    setLocationName(value);
  };

  const handleCancelDialog = () => {
    setLocationName('');
    setIsShowDialog(false);
  };

  const handleSubmitLocation = async () => {
    const filterLocation = locationOption.map((item) => item.location);
    const isNameInFilter = filterLocation.includes(locationName);
    if (isNameInFilter) {
      ToastTopHelper.error(t('the_location_name_already_exists'));
      return;
    }
    createLocation(
      { user: user?.id, location: locationName },
      {
        onSuccess: () => {
          setLocationName('');
          queryClient.invalidateQueries({ queryKey: [API.ARTWORK.EDITION] });
          queryClient.invalidateQueries({ queryKey: [API.AUTH.LOCATION] });
        },
      },
    );
    setIsShowDialog(false);
  };

  return (
    <div>
      <FormControl fullWidth>
        <Select
          value={location || ''}
          open={openSelectLocation}
          onChange={handleChangeLocation}
          onOpen={handleSelectLocation}
          onClose={handleCloseLocation}
          className={classes.select}
        >
          <MenuItem value="">
            <div className={classes.valueLocationNone} />
          </MenuItem>
          {(locationOption || []).map((option, index) => (
            <MenuItem
              key={index}
              value={option.id}
              className={classes.menuItem}
            >
              <Typography
                variant="inherit"
                noWrap
              >
                {truncateString(option.location)}
              </Typography>
            </MenuItem>
          ))}
          <div>
            <div
              onClick={handleOpenDialogLocation}
              className={classes.wrapIconLocation}
            >
              <AddIcon className={classes.iconAddLocation} />
              <Text
                bold
                className={classes.textCreateLocation}
              >
                {t('create_new_artwork_location')}
              </Text>
            </div>
          </div>
        </Select>
      </FormControl>

      <DialogLocation
        isShowDialog={isShowDialog}
        value={locationName}
        disabledButton={!locationName}
        onChange={handleChangeLocationName}
        onSubmit={handleSubmitLocation}
        onCancel={handleCancelDialog}
      />
    </div>
  );
};

export default EditionLocation;
