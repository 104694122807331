import { Grid } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { ALPHABET } from 'configs/Constant';

import useStyles from './styles';

const FilterAlphabet = () => {
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedAlphabet = searchParams.get('name_startswith') || 'ALL';

  const handleClickItemAlphabet = (value) => {
    if (value === 'ALL') {
      setSearchParams();
    } else {
      searchParams.set('name_startswith', value);
      searchParams.delete('page');
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  };

  return (
    <Grid
      container
      className={classes.gridAlphabetContainer}
    >
      {ALPHABET.map((letter, index) => (
        <Grid
          key={index}
          item
          xs={1}
          lg={0.444}
        >
          <button
            onClick={() => handleClickItemAlphabet(letter)}
            className={clsx(classes.itemAlphabet, {
              [classes.active]: selectedAlphabet === letter,
            })}
          >
            {letter}
          </button>
        </Grid>
      ))}
    </Grid>
  );
};

export default FilterAlphabet;
