import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  shareSocialList: {
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 24,
  },
}));

export default useStyles;
