import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapSmallUpload: {
    width: '20%',
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${Colors.Primary}`,
    marginTop: 16,
    marginRight: 38,
  },
  disabledUpload: {
    cursor: 'not-allowed',
    color: Colors.Grey6,
  },
  iconAddImage: {
    '&.MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  textUploadMedia: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 8,
  },
  wrapIosSwitch: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textPublicProfile: {
    fontSize: 14,
  },
}));

export default useStyles;
