import clsx from 'clsx';
import React from 'react';

import useStyles from './styles';

const RadioCustom = ({
  item,
  selectedOption,
  onOptionChange,
  keyLabel,
  value,
  isEdit,
  disabled,
}) => {
  const classes = useStyles();

  const isChecked = isEdit
    ? item?.id === selectedOption?.id
    : JSON.stringify(selectedOption) === JSON.stringify(item);

  return (
    <label
      key={keyLabel}
      className={clsx(classes.wrapRadioCustom, {
        [classes.customRadioChecked]: isChecked,
      })}
    >
      <input
        type="radio"
        name="customRadio"
        value={value}
        checked={isChecked}
        onChange={onOptionChange}
        className={classes.customRadioInput}
        disabled={disabled}
      />
      <span
        className={clsx(classes.customRadioIcon, {
          [classes.disabled]: disabled,
        })}
      ></span>
      <span className={clsx({ [classes.disabled]: disabled })}> {value}</span>
    </label>
  );
};

export default RadioCustom;
