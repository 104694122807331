import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import Section from 'features/Certificate/Section';

const OwnerInformation = ({ ownerInfo, requestBy }) => {
  const { t } = useTranslation();

  return (
    <Section title={t('owner_information')}>
      <Stack
        direction="row"
        sx={{ mt: 2 }}
        spacing={5}
      >
        <div>
          <Text type="H4">{t('owner_name')}</Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('year_of_birth')}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {t('address')}
          </Text>
        </div>

        <div>
          <Text type="H4">{requestBy?.name}</Text>
          <Text
            type="H4"
            mt={16}
          >
            {ownerInfo?.year_of_birth}
          </Text>
          <Text
            type="H4"
            mt={16}
          >
            {ownerInfo?.address}
          </Text>
        </div>
      </Stack>
    </Section>
  );
};

export default OwnerInformation;
