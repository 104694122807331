import React from 'react';

import ArtworkInfo from '../ArtworkInfo';
import OwnerInfoArtistCreateCer from '../OwnerInfoArtistCreateCer';

const UpdateCertificateInfo = ({ artwork, isPending }) => {
  return (
    <>
      {!isPending && <ArtworkInfo artwork={artwork} />}
      <OwnerInfoArtistCreateCer />
    </>
  );
};

export default UpdateCertificateInfo;
