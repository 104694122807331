import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  tableHead: {
    '&.MuiTableHead-root': {
      background: Colors.Grey2,
    },
  },

  columnMini: {
    '&.MuiTableCell-head': {
      minWidth: 55,
    },
  },
  columnLongWidth: {
    '&.MuiTableCell-head': {
      minWidth: 246,
    },
  },
  columnWidth: {
    '&.MuiTableCell-head': {
      minWidth: 97,
    },
  },
}));

export default useStyles;
