import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  btn: {
    '&.MuiButton-root': {
      minWidth: 93,
      padding: '7px 16px',
    },
  },
  avatar: {
    '&.MuiAvatar-root': {
      width: 24,
      height: 24,
    },
  },
  textUserName: {
    textDecoration: 'underline',
  },
  wrapInfo: {
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
  },
  infoItem: {
    '&::after': {
      content: '"\\2022"',
      width: 20,
      margin: '0 8px',
      color: Colors.Grey10,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&:last-child': {
      '&::after': {
        display: 'none',
      },
    },
  },
}));

export default useStyles;
