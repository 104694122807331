import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import Text from 'commons/Text';
import useResendTagRequest from 'pages/ReviewTagRequest/hooks/useResendTagRequest';
import Routes from 'utils/Route';

import useStyles from './styles';

const GroupButtonRejectTagRequest = ({ message }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const { mutate: resendRequest } = useResendTagRequest(id);

  const handleCancel = () => {
    navigate({
      pathname: Routes.ManageArtwork,
      search: createSearchParams({ tab: 1 }).toString(),
    });
  };

  const handleResend = () => {
    resendRequest();
  };

  return (
    <div>
      <Box className={classes.boxGroupButton}>
        <h2 className={classes.textReject}>{t('request_rejected')}</h2>
        <Text className={classes.textMessage}>{message}</Text>
      </Box>
      <Box className={classes.boxGroupButton}>
        <Button
          variant="contained"
          fullWidth
          className={classes.button}
          onClick={handleResend}
        >
          {t('resend')}
        </Button>
        <Button
          variant="outlined"
          fullWidth
          className={classes.button}
          onClick={handleCancel}
        >
          {t('cancel')}
        </Button>
      </Box>
    </div>
  );
};

export default GroupButtonRejectTagRequest;
