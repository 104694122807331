import React from 'react';
import { useTranslation } from 'react-i18next';

// import { Link } from 'react-router-dom';
import Text from 'commons/Text';

// import Routes from 'utils/Route';
import HeaderRedirect from './HeaderRedirect';
import useStyles from './styles';

const LoginHeader = ({ redirectTo }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {redirectTo ? (
        <HeaderRedirect />
      ) : (
        <Text
          type="3xl"
          title
          className={classes.textHeader}
        >
          {t('login_to_your_account')}
        </Text>
      )}

      {/* <div className={classes.headerWrapper}>
        <Text
          type="md"
          className={classes.textResigsterAccount}
        >
          {t('dont_have_an_account')}
        </Text>
        <Link
          className={classes.subHeaderTextLink}
          to={Routes.SignUp}
        >
          {t('sign_up')}
        </Link>
      </div> */}
    </>
  );
};

export default LoginHeader;
