import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

import FormInput from 'commons/Form/FormInput';
import Text from 'commons/Text';
import { useRequestCertificateContext } from 'contexts/RequestCertificateContext';

import SelectUserReceiveCertificate from '../SelectUserReceiveCertificate';
import useStyles from './styles';
import { ownerSchema } from './validate';

const OwnerInfoArtistCreateCer = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { t } = useTranslation();

  const { setOwnerInfor } = useRequestCertificateContext();
  const storedData = reactLocalStorage.get(`ownerInfo-${id}`);

  const ownerHookForm = useForm({
    defaultValues: storedData ? JSON.parse(storedData) : {},
    mode: 'onChange',
    resolver: yupResolver(ownerSchema),
  });
  const { register, handleSubmit, formState, watch, setValue } = ownerHookForm;

  const valuesOwner = watch();
  const [isYearOfBirthDisabled, setIsYearOfBirthDisabled] = useState(!!valuesOwner?.yearOfBirth);
  const [isAddressDisabled, setIsAddressDisabled] = useState(!!valuesOwner?.address);

  const onSubmit = (data) => {
    setOwnerInfor((prev) => ({ ...prev, ...data }));
    reactLocalStorage.set(`ownerInfo-${id}`, JSON.stringify(data));
  };

  const handleUserChange = (newValue, shouldValidate = true) => {
    setValue('owner', newValue, { shouldValidate });

    setValue('yearOfBirth', newValue?.year_of_birth, { shouldValidate });
    setIsYearOfBirthDisabled(!!newValue?.year_of_birth);

    setValue('address', newValue?.place_of_birth, { shouldValidate });
    setIsAddressDisabled(!!newValue?.year_of_birth);
  };

  useEffect(() => {
    setOwnerInfor((prev) => ({ ...prev, ...valuesOwner }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.defaultValues]);

  return (
    <Box>
      <Text
        fontWeightBold
        type="lg"
        className={classes.textOwnerInformation}
      >
        {t('owner_information')}
      </Text>

      <SelectUserReceiveCertificate
        ownerHookForm={ownerHookForm}
        onUserChange={handleUserChange}
      />

      <FormInput
        {...register('yearOfBirth')}
        label={t('year_of_birth')}
        placeholder={t('please_enter')}
        isRequired
        error={!!formState?.errors.yearOfBirth}
        helperText={formState?.errors.yearOfBirth?.message}
        labelStyle={classes.formInputLabel}
        disabled={isYearOfBirthDisabled}
        textFieldStyle={isYearOfBirthDisabled && classes.textFieldStyleDisable}
      />

      <FormInput
        {...register('address')}
        label={t('address')}
        placeholder={t('please_enter')}
        isRequired
        error={!!formState?.errors.address}
        helperText={formState?.errors.address?.message}
        labelStyle={classes.formInputLabel}
        disabled={isAddressDisabled}
        textFieldStyle={isAddressDisabled && classes.textFieldStyleDisable}
      />

      <FormInput
        {...register('contractNumber')}
        label={t('contract_number')}
        placeholder={t('please_enter')}
        error={!!formState?.errors.contractNumber}
        helperText={formState?.errors.contractNumber?.message}
        labelStyle={classes.formInputLabel}
      />

      <Button
        onClick={handleSubmit(onSubmit)}
        variant="contained"
        className={classes.buttonUpdate}
      >
        <Text>{t('update')}</Text>
      </Button>
    </Box>
  );
};

export default OwnerInfoArtistCreateCer;
