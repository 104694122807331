import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formInputLabel: {
    padding: 0,
    marginBottom: 8,
  },
  wrapFormInput: {
    marginTop: -5,
  },
  iconAvatar: {
    '&.MuiAvatar-root': {
      width: 24,
      height: 24,
    },
  },
  icon: {
    '&.MuiSvgIcon-root': {
      width: 24,
      height: 24,
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
