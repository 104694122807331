import { Stack } from '@mui/material';
import moment from 'moment';
import React from 'react';

import Section from 'features/Certificate/Section';

import InfoUserTagRequest from '../InfoUserTagRequest';
import useStyles from './styles';

const ReviewRequestor = ({ data, requestUser, title }) => {
  const classes = useStyles();

  return (
    <Section
      title={title}
      wrapperStyle={classes.wrapper}
    >
      <Stack
        direction="row"
        sx={{ mt: 2 }}
        spacing={5}
      >
        <InfoUserTagRequest
          user={requestUser}
          isShowClearIcon={false}
          requestDate={moment(data?.created_at).format('DD/MM/YYYY')}
        />
      </Stack>
    </Section>
  );
};

export default ReviewRequestor;
