import { Grid } from '@mui/material';
import React, { Children } from 'react';

const GridLayout = ({ children, spacing = 3, leftColumnProps, rightColumnProps }) => {
  const [leftComponent, rightComponent] = Children.toArray(children);

  return (
    <Grid
      container
      spacing={spacing}
    >
      <Grid
        item
        xs={12}
        lg={8}
        {...leftColumnProps}
      >
        {leftComponent}
      </Grid>

      <Grid
        item
        xs={12}
        lg={4}
        {...rightColumnProps}
      >
        {rightComponent}
      </Grid>
    </Grid>
  );
};

export default GridLayout;
