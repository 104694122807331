import { Box } from '@mui/material';

const TabPanel = ({ value, index, children, ...props }) => {
  if (value !== index) {
    return null;
  }

  return <Box {...props}>{children}</Box>;
};

export default TabPanel;
