import { Container, Grid, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { BREAKPOINTS, TESTID } from 'configs/Constant';

import HeaderLeft from './components/HeaderLeft';
import HeaderLeftSmartPhone from './components/HeaderLeftSmartPhone';
import HeaderRight from './components/HeaderRight';
import useStyles from './styles';

const Header = ({ isPageLogin, wrapHeaderStyle }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(BREAKPOINTS.mobile);

  return (
    <div
      className={clsx(classes.wrapHeader, wrapHeaderStyle)}
      data-testid={TESTID.MAIN_HEADER}
    >
      <Container>
        <Grid
          container
          className={classes.row}
        >
          {isMobile && <HeaderLeftSmartPhone />}
          {!isMobile && <HeaderLeft />}
          <HeaderRight isPageLogin={isPageLogin} />
        </Grid>
      </Container>
    </div>
  );
};

export default Header;
