import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import CertificateAutoView from 'commons/CertificateAutoView';
import DialogCustom from 'commons/DialogCustom';
import GroupActionButton from 'commons/GroupActionButton';
import MatchingBox from 'commons/MatchingBox';
import Text from 'commons/Text';
import TextArea from 'commons/TextArea';
import { STATUS_REQUEST_KEY } from 'configs/Constant';
import Routes from 'utils/Route';

import useApproveCertificate from './hooks/useApproveCertificate';
import useRejectCertificate from './hooks/useRejectCertificate';
import useStyles from './styles';

const rejectSchema = yup.object().shape({
  rejectReason: yup.string().max(200, 'Reject reason must be at most 200 characters.'),
});

const approveSchema = yup.object().shape({
  artworkValue: yup.object().required('Artwork is require field'),
  editionValue: yup.object().required('Edition is require field'),
});

const GroupButtonReviewCertificate = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const { id } = useParams();
  const { mutate: approveRequest } = useApproveCertificate(id);
  const { mutate: rejectRequest } = useRejectCertificate(id);
  const [isShowRejectDialog, setIshowRejectDialog] = useState(false);
  const [isShowApproveDialog, setIshowApproveDialog] = useState(false);
  const { artwork, edition, request_by, request_to } = data;

  const { register, formState, watch, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(rejectSchema),
  });

  const approveRequestHookForm = useForm({
    defaultValues: {
      artworkValue: artwork?.linked_artwork,
      editionValue: edition?.linked_edition,
    },
    mode: 'onChange',
    resolver: yupResolver(approveSchema),
  });
  const { setValue, handleSubmit: handleSubmitApprove } = approveRequestHookForm;

  const handleApproveCertificateRequest = () => {
    setIshowApproveDialog(true);
  };
  const handleCancelDialogApprove = () => setIshowApproveDialog(false);

  const onSubmitApprove = (data) => {
    const formData = {
      artwork_link_id: data?.artworkValue?.id,
      edition_link_number: data?.editionValue?.edition_number,
    };

    approveRequest(formData);
    setIshowApproveDialog(false);
  };

  const handleRejectCertificateRequest = () => setIshowRejectDialog(true);

  const handleCancel = () => {
    navigate(Routes.ManageCertificate);
  };

  const handleCancelDialogReject = () => setIshowRejectDialog(false);

  const onSubmitReject = (data) => {
    rejectRequest({
      message: data?.rejectReason,
    });
  };

  const handleArtworkChange = () => {
    setValue('editionValue', null);
  };

  return (
    <>
      {data?.status === STATUS_REQUEST_KEY['REQUEST_RECEIVED'] && (
        <GroupActionButton
          mainButtonText={t('approve_request')}
          onMainButtonClick={handleSubmitApprove(handleApproveCertificateRequest)}
          subButtonLeftText={t('cancel')}
          onSubButtonRight={handleRejectCertificateRequest}
          onSubButtonLeft={handleCancel}
          subButtonRightText={t('reject_request')}
          subButtonRightStyle={classes.subButtonRightStyle}
          groupActionButtonWrapStyle={classes.wrapGroupButton}
          extendUi={
            <MatchingBox
              artworkTitle={artwork?.title}
              totalEdition={artwork?.total_edition}
              artworkFormName={'artworkValue'}
              editionFormName={'editionValue'}
              onArtworkChange={handleArtworkChange}
              hookForm={approveRequestHookForm}
              disabled={artwork?.linked_artwork}
            />
          }
        />
      )}

      {data?.status !== STATUS_REQUEST_KEY['REQUEST_RECEIVED'] && (
        <Button
          onClick={handleCancel}
          variant="outlined"
          fullWidth
          className={classes.btn}
        >
          {t('cancel')}
        </Button>
      )}

      <CertificateAutoView
        artwork={artwork}
        certificate={{
          edition_number: edition?.edition_number,
          issued_by: request_to,
        }}
        rootStyle={classes.certificateRootStyle}
      />

      <DialogCustom
        isShowDialog={isShowApproveDialog}
        dialogTitle={t('approve_request')}
        textCancel={t('cancel')}
        textSubmit={t('approve')}
        onCancel={handleCancelDialogApprove}
        onSubmit={handleSubmitApprove(onSubmitApprove)}
        maxWidth={'md'}
        submitButtonStyle={clsx(classes.buttonDialog, classes.buttonSizeMedium)}
        cancelButtonStyle={clsx(classes.buttonDialog, classes.buttonSizeMedium)}
      >
        <div className={classes.wrapDialogContent}>
          <Trans
            i18nKey="text_confirm_approve_certificate_request"
            values={{ colectorName: request_by?.name || '--' }}
          />
        </div>
      </DialogCustom>

      <DialogCustom
        isShowDialog={isShowRejectDialog}
        dialogTitle={t('reject_request')}
        textCancel={t('cancel')}
        textSubmit={t('reject')}
        onCancel={handleCancelDialogReject}
        onSubmit={handleSubmit(onSubmitReject)}
        maxWidth={'md'}
        submitButtonStyle={clsx(classes.buttonDialog, classes.submitButtonRejectStyle)}
        cancelButtonStyle={classes.buttonDialog}
      >
        <div className={classes.wrapDialogContent}>
          <Text>{t('reject_reason')}</Text>
          <TextArea
            {...register('rejectReason')}
            value={watch('rejectReason')}
            textMaxLength={200}
            placeholder={t('text_placeholder_reject_certificate_request')}
            error={!!formState?.errors.rejectReason}
            helperText={formState?.errors.rejectReason?.message}
            wrapTextStyle={classes.wrapTextArea}
          />
        </div>
      </DialogCustom>
    </>
  );
};

export default GroupButtonReviewCertificate;
