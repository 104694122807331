import { Container } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Text from 'commons/Text';
import useArtwork from 'react-query/hooks/queries/useArtwork';

import EditArtworkMainContent from './components/EditArtworkMainContent';
import useStyles from './styles';

const EditArtwork = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id: artworkId } = useParams();
  const { artwork, isSuccess } = useArtwork(artworkId);

  return (
    <div className={classes.wrapper}>
      <Container>
        <Text
          variant="h1"
          type="2xl"
          fontWeightBold
        >
          {t('edit_artwork')} / {t('modify_attributes')}
        </Text>

        {isSuccess && (
          <EditArtworkMainContent
            artwork={artwork}
            key={artwork?.id}
          />
        )}
      </Container>
    </div>
  );
};

export default EditArtwork;
