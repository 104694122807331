import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: 320,
    padding: '12px 8px',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${Colors.Grey3}`,
  },
  inputBase: {
    flex: 1,
    marginRight: 8,
    '& .MuiInputBase-input': {
      padding: 0,
    },
  },
  fullWidth: {
    width: '100%',
  },

  [mediaQueries.tabletLarge]: {
    wrapper: {
      width: 200,
    },
  },
}));

export default useStyles;
