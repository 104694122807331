import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import API from 'configs/API';

const useGalleryArtworks = (artistId, token) => {
  const [searchParams] = useSearchParams();
  const objectParamsFromSearchParams = Object.fromEntries(searchParams);

  const params = {
    ...objectParamsFromSearchParams,
    user_uuid: artistId,
    token: token,
  };

  return useQuery({
    queryKey: [API.ARTWORK.LIST_GALLERY_ARTWORK, params],
  });
};

export default useGalleryArtworks;
