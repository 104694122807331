import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  item: {
    width: '50%',
  },
  commonText: {
    letterSpacing: '0.56px',
  },
  letterSpacing48: {
    letterSpacing: '0.48px',
  },
  imageQrCode: {
    width: 136,
    height: 136,
    position: 'absolute',
    top: 60,
    right: 0,
  },
}));

export default useStyles;
