import { Box, Button, Container } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { STATUS_REQUEST_KEY } from 'configs/Constant';
import GridLayout from 'layouts/GridLayout';
import Routes from 'utils/Route';

import ReviewArtworkImages from '../components/ReviewArtworkImages';
import ReviewArtworkInformation from '../components/ReviewArtworkInformation';
import ReviewRequestor from '../components/ReviewRequestor';
import ReviewStatusArtwork from '../components/ReviewStatusArtwork';
import GroupButtonRejectTagRequest from './GroupButtonRejectTagRequest';
import useStyles from './styles';

const CollectorReviewTagRequest = ({ data, user }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const isUserRequestBy = data?.request_by?.id === user?.id;

  if (!isUserRequestBy) {
    navigate(Routes.PageNotFound, { replace: true });
  }

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <main className={classes.wrapPage}>
      <Container>
        <h1 className={classes.heading}>{t('tagged_request_details')}</h1>

        <Box sx={{ pt: 3 }}>
          <GridLayout>
            {!!data && (
              <div>
                <ReviewRequestor
                  title={t('receiver')}
                  data={data}
                  requestUser={data?.request_to}
                />
                <ReviewStatusArtwork data={data} />
                <ReviewArtworkInformation data={data} />
                <ReviewArtworkImages data={data} />
              </div>
            )}
            {data?.status !== STATUS_REQUEST_KEY['REQUEST_DENIED'] && (
              <Button
                variant="outlined"
                fullWidth
                className={classes.buttonCancel}
                onClick={handleCancel}
              >
                {t('cancel')}
              </Button>
            )}

            {data?.status === STATUS_REQUEST_KEY['REQUEST_DENIED'] && (
              <GroupButtonRejectTagRequest message={data?.message} />
            )}
          </GridLayout>
        </Box>
      </Container>
    </main>
  );
};

export default CollectorReviewTagRequest;
