import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  boxAddArtwork: {
    marginTop: 24,
    width: '25%',
    border: `2px dashed ${Colors.Gray5}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 100,
    textAlign: 'center',
    backgroundColor: Colors.White,
    cursor: 'pointer',
  },
  addArtworkText: {
    marginTop: 8,
    whiteSpace: 'nowrap',
  },
  formControl: {
    marginTop: 4,
  },
  labelStyle: {
    padding: 0,
  },
  wrapperStyle: {
    marginTop: 16,
  },
  groupActionWrapper: {
    padding: 6,
  },
}));

export default useStyles;
