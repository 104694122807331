import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  dialogContent: {
    '&.MuiDialogContent-root': {
      padding: 24,
    },
  },
}));

export default useStyles;
