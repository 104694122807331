import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  tableRowBorder: {
    borderBottom: `1px solid ${Colors.Grey3}`,
  },
  wrapIcon: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  iconOutLine: {
    '&.MuiSvgIcon-root': {
      width: 20,
      height: 20,
      cursor: 'pointer',
      marginRight: 45,
    },
  },
  textCodeCertificate: {
    color: Colors.Gray34,
  },
}));

export default useStyles;
