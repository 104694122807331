import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  select: {
    '&.MuiInputBase-root': {
      borderRadius: '99px',
      background: Colors.Grey2,
      textAlign: 'center',
    },
    '& .MuiSelect-select': {
      padding: 10,
    },
  },
  valueLocationNone: {
    height: 15,
  },
  wrapIconLocation: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconAddLocation: {
    '&.MuiSvgIcon-root': {
      width: 24,
      height: 24,
      marginLeft: 10,
    },
  },
  textCreateLocation: {
    fontSize: 16,
    marginLeft: 5,
    width: '100%',
    paddingRight: 35,
    paddingLeft: 5,
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      width: 250,
    },
  },
}));

export default useStyles;
