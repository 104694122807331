import React from 'react';
import { useSelector } from 'react-redux';

import useStyles from '../styles';
import ButtonGroup from './ButtonGroup';
import MenuLanguage from './MenuLanguage';
import MenuProfile from './MenuProfile';
import MessageNotification from './MessageNotification';

const HeaderRight = ({ isPageLogin }) => {
  const classes = useStyles();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <div className={classes.wrapGroupItem}>
      <MenuLanguage />

      {!isPageLogin && (
        <>
          {isLoggedIn ? (
            <>
              <MessageNotification />
              <MenuProfile />
            </>
          ) : (
            <ButtonGroup />
          )}
        </>
      )}
    </div>
  );
};

export default HeaderRight;
