import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapImage: {
    position: 'relative',
  },
  wrapIconPrivate: {
    width: 22,
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 8,
    right: 8,
    borderRadius: 2,
  },
}));

export default useStyles;
