import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import LinkRef from 'commons/LinkRef';
import Text from 'commons/Text';

import useStyles from './styles';

const ListMenuItem = ({ text, open, icon, to, isActive }) => {
  const classes = useStyles();

  return (
    <ListItem sx={{ p: 0 }}>
      <ListItemButton
        component={LinkRef}
        to={to}
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
        className={clsx({
          [classes.menuItemActive]: !!isActive,
        })}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {icon}
        </ListItemIcon>

        {open && <Text>{text}</Text>}
      </ListItemButton>
    </ListItem>
  );
};

export default ListMenuItem;
