import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  likeStatWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    position: 'absolute',
    right: 12,
    bottom: 12,
    zIndex: 2,
  },
  likeIconWrapper: {
    display: 'inline-flex',
    padding: 8,
    cursor: 'pointer',
  },
  overlay: {
    width: '100%',
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: '2.5rem',
    background: 'linear-gradient(rgba(0, 0, 0, 0.00), rgba(0, 0, 0, 0.80))',
    opacity: 0.8,
    zIndex: 1,
  },
}));

export default useStyles;
