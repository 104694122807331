import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  shareSocialWarningWrapper: {
    marginTop: 16,
    padding: 8,
    background: Colors.Grey2,
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
