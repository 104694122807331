import { Container } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'commons/Breadcrumbs';
import useArtwork from 'react-query/hooks/queries/useArtwork';
import Routes from 'utils/Route';

import CollectorRequestCertificate from './CollectorRequestCertificate';
import useStyles from './styles';

const RequestCertificate = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.account.user);
  const { artworkId } = useParams();

  const { artwork, isSuccess } = useArtwork(artworkId);

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      to: Routes.GalleryDetail(user?.uuid),
      text: t('gallery'),
    },
    {
      text: t('request_certificate'),
      active: true,
    },
  ];

  return (
    <main className={classes.wrapPage}>
      <Container>
        <Breadcrumbs items={breadCrumbs} />
        <h1 className={classes.heading}>{t('request_certificate')}</h1>
        {isSuccess && <CollectorRequestCertificate artwork={artwork} />}
      </Container>
    </main>
  );
};

export default RequestCertificate;
