import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 24,
  },
  wrapTermBox: {
    padding: 24,
    border: `1px solid ${Colors.Grey2}`,
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
    borderColor: Colors.Grey6,
  },
  listTerm: {
    marginTop: 16,
    marginLeft: 24,
  },
  listTermItem: {
    marginTop: 8,
  },
}));

export default useStyles;
