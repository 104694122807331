import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Popover, TableCell } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from './styles';

const TableCertificateAction = ({ setIsShowCertificateView, setIsShowCertificateLog }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleOpenPopover = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleShowCertificateView = () => {
    setIsShowCertificateView(true);
    setAnchorEl(null);
  };

  const handleShowCertificateLog = () => {
    setIsShowCertificateLog(true);
    setAnchorEl(null);
  };

  return (
    <TableCell className={classes.tableCell}>
      <MoreHorizOutlinedIcon
        onClick={handleOpenPopover}
        className={classes.iconOutLine}
      />

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className={classes.popover}
      >
        <Text
          type="sm"
          clickAble
          className={clsx(classes.textItemPopover)}
          onClick={handleShowCertificateView}
        >
          {t('view_certificate')}
        </Text>

        <Text
          type="sm"
          clickAble
          className={classes.textItemPopover}
          onClick={handleShowCertificateLog}
        >
          {t('check_log')}
        </Text>
      </Popover>
    </TableCell>
  );
};

export default TableCertificateAction;
