import { FormControlLabel, Switch } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const IosSwitch = ({
  textSwitch,
  isChecked,
  onSwitchChange,
  wrapFormLabelStyle,
  textSwitchStyle,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapFormLabel, wrapFormLabelStyle)}>
      <FormControlLabel
        className={classes.formLabel}
        control={
          <Switch
            checked={isChecked || false}
            onChange={onSwitchChange}
            className={classes.switch}
          />
        }
      />
      <Text className={textSwitchStyle}>{textSwitch}</Text>
    </div>
  );
};

export default IosSwitch;
