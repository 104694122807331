import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  linkItem: {
    color: Colors.Grey6,
    fontSize: 14,
    fontWeight: 200,
    textDecoration: 'none',
    '&:hover': {
      color: Colors.Grey10,
    },
  },
  linkItemActive: {
    color: Colors.Grey10,
  },

  [mediaQueries.mobile]: {
    textLink: {
      fontSize: 12,
    },
  },
}));

export default useStyles;
