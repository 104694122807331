import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapItem: {
    display: 'flex',
    cursor: 'pointer',
  },
  text: {
    marginLeft: 8,
  },
  wrapBoxContent: {
    paddingLeft: 12,
    paddingTop: 6,
    paddingBottom: 12,
  },
  titleSavedUser: {
    marginLeft: 20,
  },
  wrapSavedUser: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 8,
    cursor: 'pointer',
  },
  divier: {
    '&.MuiDivider-root': {
      marginTop: 8,
    },
  },
  textEmailOrPhone: {
    fontSize: 14,
    color: Colors.Grey6,
  },
}));

export default useStyles;
