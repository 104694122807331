import React from 'react';
import { useTranslation } from 'react-i18next';

import DecimalInput from 'commons/DecimalInput';
import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';

import useStyles from './styles';

const ArtistEditionUIView = ({ totalEdition, onTotalEditionChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Text
        fontWeightBold
        type="lg"
        mb={5}
        mt={16}
      >
        {t('edition_volume')}
        <span className={classes.require}>*</span>
      </Text>

      <DecimalInput
        value={totalEdition}
        onChange={onTotalEditionChange}
        inputProps={{
          type: 'text',
        }}
        testid={TESTID.EDITION_NUMBER_ARTWORK}
      />
    </>
  );
};

export default ArtistEditionUIView;
