import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  stack: {
    alignItems: 'center',
    padding: '12px',
  },
}));

export default useStyles;
