import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useIsFetching } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import FilterArtwork from 'commons/FilterArtwork';
import FilterTags from 'commons/FilterArtwork/FilterTags';
import LinkRef from 'commons/LinkRef';
import ManageWrapper from 'commons/ManageWrapper';
import Text from 'commons/Text';
import Routes from 'utils/Route';

import ManageArtworkPanel from './components/ManageArtworkPanel';
import TableManager from './components/TableManager';
import useStyles from './styles';

const ManageArtwork = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const isFetching = useIsFetching();
  const [searchParams] = useSearchParams();

  const [numberFilter, setNumberFilter] = useState(0);

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      text: t('manage_artworks'),
      active: true,
    },
  ];

  const handleShowFilter = () => setShowFilter(!showFilter);

  useEffect(() => {
    const numberFilter = document.querySelectorAll('[data-group=filter-tag]')?.length || 0;
    setNumberFilter(numberFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, searchParams.toString()]);

  return (
    <ManageWrapper
      breadCrumbs={breadCrumbs}
      heading={t('manage_artworks')}
      searchPlaceholder={t('search_by_artwork_name')}
      actionUi={
        <Button
          component={LinkRef}
          to={Routes.UploadArtwork}
          variant="contained"
        >
          <AddIcon />
          <Text ml={8}>{t('upload_artwork')}</Text>
        </Button>
      }
    >
      <ManageArtworkPanel
        showFilter={showFilter}
        onShowFilterChange={handleShowFilter}
        numberFilter={numberFilter}
      />

      {showFilter && <FilterArtwork numberFilter={numberFilter} />}

      {!showFilter && (
        <FilterTags
          numberFilter={numberFilter}
          wrapFilterStyle={clsx(classes.wrapFilterTag, {
            [classes.hiddenTag]: !numberFilter,
          })}
        />
      )}

      <TableManager />
    </ManageWrapper>
  );
};

export default ManageArtwork;
