import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 24,
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:first-child': {
      marginTop: 0,
    },
  },
}));

export default useStyles;
