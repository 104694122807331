import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

import AuthLayout from 'commons/Auth/AuthLayout';
import FormInput from 'commons/Form/FormInput';
import Text from 'commons/Text';
import useUserAccount from 'hooks/useUserAccount';
import { ReactComponent as CalendarIcon } from 'images/calendar.svg';
import Header from 'pages/ChooseAccountType/components/Header';
import Routes from 'utils/Route';
import { capitalizeLetterFullName, isUserInformationVerified } from 'utils/utils';

import useStyles from './styles';
import { userSchema } from './validations';

const VerifyInformation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { updateUserAccount } = useUserAccount();

  const user = useSelector((state) => state.auth.account.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const isVerifyInformation = isUserInformationVerified(user);

  const { register, handleSubmit, formState, setValue } = useForm({
    values: {
      legalName: user?.legal_name,
      nickname: user?.name,
      placeOfBirth: user?.place_of_birth || '',
      yearOfBirth: user?.year_of_birth,
      email: user?.email,
      phoneNumber: user?.phone_number,
    },
    mode: 'onChange',
    resolver: yupResolver(userSchema),
  });

  const handleChangeNameLegal = (e) => {
    const { value } = e.target;
    setValue('legalName', capitalizeLetterFullName(value), { shouldValidate: true });
  };

  const handleUpdateAccountSuccess = () => {
    navigate(Routes.ChooseAccountType);
  };

  const handleUpdateInfomation = async (data) => {
    const dataUser = {
      legal_name: data?.legalName,
      name: data?.nickname,
      place_of_birth: data?.placeOfBirth,
      year_of_birth: data?.yearOfBirth,
      email: data?.email,
      phone_number: data?.phoneNumber,
    };

    updateUserAccount(dataUser, handleUpdateAccountSuccess);
  };

  if (!isLoggedIn) {
    return (
      <Navigate
        to={Routes.Login}
        replace
      />
    );
  }

  if (isLoggedIn && isVerifyInformation) {
    return (
      <Navigate
        to={Routes.ChooseAccountType}
        replace
      />
    );
  }

  return (
    <div>
      <Header />
      <AuthLayout
        wrapLayoutStyle={classes.wrapLayoutStyle}
        rowStyle={classes.rowStyle}
      >
        <div className={classes.wrapForm}>
          <Text
            type="xl"
            fontWeightBold
            className={classes.title}
          >
            {t('please_verify_your_infomation_here')}
          </Text>

          <FormInput
            {...register('legalName')}
            isRequired
            label={t('legal_name')}
            placeholder={t('please_enter')}
            onChange={handleChangeNameLegal}
            error={!!formState.errors.legalName}
            helperText={formState.errors.legalName?.message}
            wrapFormInputStyle={classes.formInputControl}
            labelStyle={classes.formInputLabel}
            textFieldStyle={classes.textField}
          />

          <FormInput
            {...register('nickname')}
            isRequired
            label={t('nick_name')}
            placeholder={t('please_enter')}
            error={!!formState.errors.nickname}
            helperText={formState.errors.nickname?.message}
            wrapFormInputStyle={classes.formInputControl}
            labelStyle={classes.formInputLabel}
            textFieldStyle={classes.textField}
          />

          <FormInput
            {...register('placeOfBirth')}
            isRequired
            label={t('place_of_birth')}
            placeholder={t('please_enter')}
            error={!!formState.errors.placeOfBirth}
            helperText={formState.errors.placeOfBirth?.message}
            wrapFormInputStyle={classes.formInputControl}
            labelStyle={classes.formInputLabel}
            textFieldStyle={classes.textField}
          />

          <FormInput
            {...register('yearOfBirth')}
            isRequired
            label={t('year_of_birth')}
            placeholder={t('please_enter')}
            error={!!formState.errors.yearOfBirth}
            helperText={formState.errors.yearOfBirth?.message}
            iconEndAdorment={<CalendarIcon />}
            wrapFormInputStyle={classes.formInputControl}
            labelStyle={classes.formInputLabel}
            textFieldStyle={classes.textField}
          />

          <FormInput
            {...register('email')}
            label={t('email')}
            isRequired
            placeholder={t('please_enter')}
            error={!!formState.errors.email}
            helperText={formState.errors.email?.message}
            wrapFormInputStyle={classes.formInputControl}
            labelStyle={classes.formInputLabel}
            textFieldStyle={classes.textField}
          />

          <FormInput
            {...register('phoneNumber')}
            label={t('phone_number')}
            isRequired
            placeholder={t('please_enter')}
            error={!!formState.errors.phoneNumber}
            helperText={formState.errors.phoneNumber?.message}
            wrapFormInputStyle={classes.formInputControl}
            labelStyle={classes.formInputLabel}
            textFieldStyle={classes.textField}
          />

          <Button
            onClick={handleSubmit(handleUpdateInfomation)}
            variant="contained"
            fullWidth
            className={classes.button}
          >
            <Text className={classes.textButton}>{user?.role ? t('update') : t('continue')}</Text>
          </Button>
        </div>
      </AuthLayout>
    </div>
  );
};

export default VerifyInformation;
