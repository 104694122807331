import { TableCell, TableRow } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import useStyles from './styles';

const ExpandCollapse = ({ remainingEditions, isEvenRow, isExpanded, onToggleExpand }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableRow
      className={clsx({
        [classes.tableEvenRow]: isEvenRow,
      })}
    >
      <TableCell />

      <TableCell
        colSpan="5"
        sx={{ textAlign: 'center' }}
        className={classes.tableCell}
      >
        <span
          className={clsx(classes.viewMore, {
            [classes.backgroundEvenRow]: isEvenRow,
          })}
          onClick={onToggleExpand}
        >
          {isExpanded ? t('show_less') : t('more_editions', { number: remainingEditions })}
        </span>

        <div className={classes.line} />
      </TableCell>

      <TableCell />
    </TableRow>
  );
};

export default ExpandCollapse;
