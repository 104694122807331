import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import Colors from 'configs/Colors';
import { TESTID } from 'configs/Constant';

import useStyles from './styles';

const DisplayStatement = ({ statement }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const numberOfLines = statement?.split('\n').length;
  const maxLines = 3;

  return (
    <>
      <Text
        color={Colors.Grey8}
        type="sm"
        mt={8}
        className={classes.textStatement}
        testid={TESTID.TEXT_STATEMENT}
      >
        {showMore || numberOfLines <= maxLines
          ? statement
          : `${statement?.split('\n').slice(0, maxLines).join('\n')}...`}
      </Text>

      {numberOfLines > maxLines && (
        <div className={classes.textSeeMore}>
          <Text
            onClick={toggleShowMore}
            testid={TESTID.TEXT_SEEMORE_STATEMENT}
          >
            {showMore ? t('show_less') : t('see_more')}
          </Text>
        </div>
      )}
    </>
  );
};

export default DisplayStatement;
