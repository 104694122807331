import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  boxAddArtwork: {
    border: `2px dashed ${Colors.Gray5}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 175,
    textAlign: 'center',
    backgroundColor: Colors.White,
    cursor: 'pointer',
  },
  addArtworkText: {
    marginTop: 8,
    whiteSpace: 'nowrap',
  },
  dialogPaper: {
    width: '100%',
  },
  wrapSearchInput: {
    width: '30%',
  },
  image: {
    height: 145,
    width: '100%',
    objectFit: 'cover',
  },
  wrapArtworksSelection: {
    marginTop: 24,
  },
  titleArtwork: {
    marginTop: 5,
  },
  wrapDialogContent: {
    overflowY: 'auto',
    paddingBottom: 20,
    height: 'calc(90vh - 250px)',
    padding: '20px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: Colors.Gray5,
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: Colors.Gray2,
    },
  },
  imageContainer: {
    position: 'relative',
    cursor: 'pointer',
  },
  customCheckbox: {
    position: 'absolute',
    top: '35px',
    right: 5,
    zIndex: 1,
  },

  closeIcon: {
    '&.MuiSvgIcon-root': {
      position: 'absolute',
      right: 2,
      top: 2,
      color: 'white',
    },
  },
  helperText: {
    '&.MuiFormHelperText-root': {
      textAlign: 'center',
    },
  },
  wrapTextNoResult: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    textAlign: 'center',
  },
  wrapSearchAndSort: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
  },
}));

export default useStyles;
