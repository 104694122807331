import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

import CheckBoxCustom from 'commons/CheckBoxCustom';
import FormInput from 'commons/Form/FormInput';
import FormAutocompleteController from 'commons/FormHook/FormAutocompleteController';
import ListItemMeasurement from 'commons/ListItemMeasurement';
import Text from 'commons/Text';
import API from 'configs/API';
import { OPTIONS_DIMENSION_UNITS, TESTID } from 'configs/Constant';
import { useRequestCertificateContext } from 'contexts/RequestCertificateContext';
import AutocompleteWithOptionExtendController from 'features/Artwork/AutocompleteWithOptionExtendController';
import useUpdateArtwork from 'hooks/useUpdateArtwork';

import useStyles from './styles';
import { artworkSchema } from './validations';

const ArtworkInfo = ({ artwork }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();

  const { setArtworkInfo } = useRequestCertificateContext();
  const { title, size, medium, year_created, period_created, category, has_certificate } =
    artwork || {};

  const [checkedPeriod, setCheckedPeriod] = useState(
    year_created || period_created ? !year_created : false,
  );

  const storedData = reactLocalStorage.get(`edition-${id}`);

  const { data: editionDropDown } = useQuery({
    queryKey: [API.ARTWORK.EDITIONS_CAN_REQUEST_CERTIFICATE(id)],
    enabled: !!id,
  });
  const { data: mediumDropdown } = useQuery({
    queryKey: [
      API.ARTWORK.FILTER.MEDIUM,
      {
        category: artwork?.category?.key,
      },
    ],
    enabled: !!artwork?.category?.key,
  });

  const { mutate: updateArtwork } = useUpdateArtwork(id);

  const { control, register, handleSubmit, formState, watch, setValue } = useForm({
    values: {
      titleArtwork: title,
      height: size?.height,
      width: size?.width,
      depth: size?.depth,
      yearCreated: year_created,
      periodYear: period_created,
      mediumArtWork: medium,
      edition: storedData ? JSON.parse(storedData) : null,
    },
    mode: 'onChange',
    resolver: yupResolver(artworkSchema),
  });

  const handleChangeYearCreated = (e) => {
    const { value } = e.target;
    setValue('yearCreated', value, { shouldValidate: true });
    setValue('periodYear', '', { shouldValidate: false });
  };

  const handleChangePeriodYear = (e) => {
    const { value } = e.target;
    setValue('periodYear', value, { shouldValidate: true });
    setValue('yearCreated', '', { shouldValidate: false });
  };

  const handleCheckBox = (event) => {
    const checked = event.target.checked;
    setCheckedPeriod(checked);
    if (checked) {
      setValue('yearCreated', '', { shouldValidate: false });
      setValue('periodYear', period_created, { shouldValidate: true });
    } else {
      setValue('periodYear', '', { shouldValidate: false });
      setValue('yearCreated', year_created, { shouldValidate: true });
    }
  };

  const handleUpdateArtwork = async (data) => {
    if (!has_certificate) {
      updateArtwork({
        title: data?.titleArtwork,
        size: {
          height: data?.height,
          width: data?.width,
          depth: data?.depth,
        },
        medium: data?.mediumArtWork,
        year_created: data?.yearCreated,
        period_created: data?.periodYear,
        total_edition: data?.totalEdition,
      });
    }

    setArtworkInfo((prev) => ({ ...prev, ...data }));
    reactLocalStorage.set(`edition-${id}`, JSON.stringify(data?.edition));
  };

  useEffect(() => {
    const values = watch();
    setArtworkInfo((prev) => ({ ...prev, ...values }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.defaultValues]);

  return (
    <Box>
      <Text
        fontWeightBold
        type="lg"
        className={classes.textArtworkInformation}
      >
        {t('artwork_information')}
      </Text>

      <FormInput
        {...register('titleArtwork')}
        label={t('artwork_title')}
        placeholder={t('please_enter')}
        isRequired
        error={!!formState?.errors.titleArtwork}
        helperText={formState?.errors.titleArtwork?.message}
        labelStyle={classes.formInputLabel}
        textFieldStyle={classes.formFieldBackground}
        disabled={has_certificate}
      />

      <Text className={classes.inputLabel}>
        {t('measurement')}
        <span className={classes.require}>*</span>
      </Text>
      <ListItemMeasurement
        heightProps={{
          ...register('height'),
          disabled: has_certificate,
        }}
        widthProps={{
          ...register('width'),
          disabled: has_certificate,
        }}
        depthProps={{
          ...register('depth'),
          disabled: has_certificate,
        }}
        optionsDimensionUnits={OPTIONS_DIMENSION_UNITS}
        unitDimension={OPTIONS_DIMENSION_UNITS[0].key}
        formState={formState}
        textFieldStyle={classes.formFieldBackground}
        itemMeasurementStyle={classes.itemMeasurementStyle}
        selectStyle={classes.customSelect}
      />

      {mediumDropdown && (
        <AutocompleteWithOptionExtendController
          control={control}
          name={'mediumArtWork'}
          options={mediumDropdown || []}
          categoryProp={category}
          apiOption={API.ARTWORK.FILTER.MEDIUM}
          helperText={formState?.errors.mediumArtWork?.message}
          error={!!formState?.errors.mediumArtWork}
          autocompleteStyle={classes.autocompleteStyle}
          placeholder={t('select_medium_on_material')}
          label={t('medium_on_material')}
          textTitleCustom={classes.textTitleMedium}
          fontWeightTitle={200}
          disabled={has_certificate}
        />
      )}

      <FormInput
        {...register('yearCreated', { valueAsNumber: true })}
        onChange={handleChangeYearCreated}
        label={t('creation_year')}
        isRequired={!checkedPeriod}
        error={!!formState?.errors?.yearCreated}
        helperText={!checkedPeriod && formState?.errors.yearCreated?.message}
        labelStyle={classes.formInputLabel}
        textFieldStyle={clsx(classes.formFieldBackground, {
          [classes.disableTextFieldStyle]: checkedPeriod,
        })}
        disabled={has_certificate || checkedPeriod}
        inputProps={{ type: 'number', step: '1' }}
      />

      <CheckBoxCustom
        textCheckBox={t('show_creation_year_as_period')}
        checked={checkedPeriod}
        onChange={handleCheckBox}
        disabled={has_certificate}
      />

      {checkedPeriod && (
        <FormInput
          {...register('periodYear')}
          onChange={handleChangePeriodYear}
          label={t('period_year')}
          isRequired={checkedPeriod}
          error={!!formState?.errors?.periodYear}
          helperText={formState?.errors.periodYear?.message}
          labelStyle={classes.formInputLabel}
          textFieldStyle={classes.formFieldBackground}
          disabled={has_certificate}
        />
      )}

      <FormAutocompleteController
        control={control}
        name={'edition'}
        options={editionDropDown || []}
        label={t('edition_number')}
        placeholder={t('select_edition_number')}
        getOptionLabel={(option) => option?.edition_number?.toString()}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        isRequired
        helperText={formState?.errors.edition?.message}
        error={!!formState?.errors.edition}
        fontWeightTitle={200}
        autocompleteStyle={classes.autocompleteStyle}
        textTitleCustom={classes.textTitleMedium}
      />

      <Button
        onClick={handleSubmit(handleUpdateArtwork)}
        variant="contained"
        className={classes.buttonUpdate}
        data-testid={TESTID.BUTTON_UPDATE_ARTWORK}
      >
        <Text>{t('update')}</Text>
      </Button>
    </Box>
  );
};

export default ArtworkInfo;
