import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  button: {
    '&.MuiButton-root': {
      padding: '12px 20px',
      display: 'flex',
      alignItems: 'center',
      width: '18%',
      whiteSpace: 'nowrap',
    },
  },
  pending: {
    '&.MuiButton-root': {
      opacity: 0.5,
      cursor: 'wait',
    },
  },
}));

export default useStyles;
