import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import API from 'configs/API';
import useRole from 'hooks/useRole';
import Routes from 'utils/Route';

import ArtistReviewCertificateRequest from './ArtistReviewCertificateRequest';
import CollectorReviewCertificateRequest from './CollectorReviewCertificateRequest';

const ReviewCertificateRequest = () => {
  const { isArtist, isCollector } = useRole();
  const { id } = useParams();
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.account.user);

  const { data, isPending } = useQuery({
    queryKey: [API.ARTWORK.REVIEW_CERTIFICATE_REQUEST(id)],
    gcTime: 0,
  });

  if (isPending) {
    return;
  }

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      to: Routes.ManageCertificate,
      text: t('manage_certificates'),
    },
    {
      text: t('review_certificate_request'),
      active: true,
    },
  ];

  return (
    <>
      {isArtist && (
        <ArtistReviewCertificateRequest
          data={data}
          user={user}
          breadCrumbs={breadCrumbs}
        />
      )}
      {isCollector && (
        <CollectorReviewCertificateRequest
          data={data}
          user={user}
          breadCrumbs={breadCrumbs}
        />
      )}
    </>
  );
};

export default ReviewCertificateRequest;
