import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  textTitle: {
    marginTop: 70,
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontWeight: 300,
  },
  linkCustom: {
    textDecoration: 'none',
  },
  linkCustom2: {
    textDecoration: 'underline',
  },
  wrapAttributeField: {
    marginTop: 30,
  },
  attributeField: {
    fontWeight: 300,
  },
  wrapBoxPriceAndIcon: {
    marginTop: 30,
    paddingRight: 40,
  },
  wrapPrice: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  price: {
    textTransform: 'uppercase',
  },
  button1: {
    '&.MuiButton-root': {
      padding: '12px 20px',
      display: 'flex',
      alignItems: 'center',
      width: '18%',
      whiteSpace: 'nowrap',
    },
  },
  button2: {
    '&.MuiButton-root': {
      padding: '12px 20px',
      display: 'flex',
      alignItems: 'center',
      width: '60%',
      whiteSpace: 'nowrap',
    },
  },
  askPrice: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  wrapIcon: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textInquire: {
    marginLeft: 8,
  },
  breadcrumbsStyle: {
    '&.MuiBreadcrumbs-root': {
      marginTop: 16,
    },
  },
  linkNotUnderLine: {
    cursor: 'pointer',
    fontWeight: 300,
  },
}));

export default useStyles;
