import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import FormInput from 'commons/Form/FormInput';
import { TESTID } from 'configs/Constant';

const FormArtistByRoleArtist = ({ classes }) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.account.user);

  return (
    <FormInput
      value={user?.name}
      label={t('artist')}
      labelStyle={classes.formInputLabel}
      textFieldStyle={clsx(classes.textFieldStyle, classes.textFieldDisabled)}
      testid={`${TESTID.TEXT_FIELD}_USER_NAME`}
      disabled
      labelProps={{
        type: 'lg',
        fontWeightBold: true,
      }}
    />
  );
};

export default FormArtistByRoleArtist;
