import { Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import API from 'configs/API';

import CollectionItemSameArtist from '../CollectionItemSameArtist';
import useStyles from './styles';

const CollectionsSameArtist = ({ ownerId, collectionId }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const params = {
    owner_id: ownerId,
    collection_id: collectionId,
  };

  const { data: listCollection } = useQuery({
    queryKey: [API.ARTWORK.LIST_COLLECTION_SAME_ARTIST, params],
  });

  if (!listCollection?.length) {
    return;
  }

  return (
    <div className={classes.wrapper}>
      <Text
        type="xl"
        fontWeightBold
      >
        {t('other_collections_same_artist')}
      </Text>

      <Grid
        container
        spacing={5}
        className={classes.gridItem}
      >
        {listCollection?.map((collection, index) => (
          <Grid
            key={index}
            item
            xs={12}
            lg={3}
          >
            <CollectionItemSameArtist collection={collection} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CollectionsSameArtist;
