import { v4 as uuidv4 } from 'uuid';

import API from 'configs/API';
import { axiosGet, axiosPost } from 'utils/apis/axios';

const useUploadImageS3 = () => {
  const getS3PresignedUrl = async (imageKey) => {
    const { success, data } = await axiosGet(API.AWS.S3_PRESIGNED, {
      params: {
        key: imageKey,
      },
    });
    if (success) {
      return data;
    }

    return false;
  };

  const uploadImageToS3 = async (s3PresignedData, uploadFile) => {
    const formData = new FormData();
    Object.entries(s3PresignedData.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });

    formData.append('file', uploadFile);

    const response = await axiosPost(s3PresignedData.url, formData, {
      transformRequest: (req, headers) => {
        delete headers.Authorization;
        return req;
      },
    });

    return response;
  };

  const uploadImage = async ({ imageKey, uploadFile }) => {
    const s3PresignedData = await getS3PresignedUrl(imageKey);

    if (!s3PresignedData && !s3PresignedData.fields) {
      return { success: false };
    }

    const uploadResponse = await uploadImageToS3(s3PresignedData, uploadFile);

    return uploadResponse;
  };

  const uploadListImage = async (listImage) => {
    const uploadPromises = listImage.map(async (image) => {
      const uploadResponse = await uploadImage(image);
      return uploadResponse;
    });

    const responses = await Promise.all(uploadPromises);
    return responses;
  };

  const generateImageKey = (file) => {
    const [, typeImage] = file.type.split('/');
    const key = `${uuidv4()}.${typeImage}`;
    return key;
  };

  return { generateImageKey, uploadImage, uploadListImage };
};

export default useUploadImageS3;
