import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  statusNumberWidth: {
    width: '9%',
  },
  columnWdith: {
    flex: 1,
  },
}));

export default useStyles;
