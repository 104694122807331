import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapHeaderStyle: {
    position: 'fixed',
    width: '100%',
    backgroundColor: Colors.White,
  },
  wrapForm: {
    width: 464,
    background: Colors.White,
    borderRadius: 16,
    padding: 40,
  },
  linkForgotPassword: {
    color: Colors.Grey10,
    marginTop: 8,
    display: 'flex',
    justifyContent: 'end',
    lineHeight: '150%',
  },
  buttonSignUp: {
    '&.MuiButton-root': {
      textTransform: 'none',
      marginTop: 16,
      padding: 12,
    },
  },
  line: {
    border: `1px solid ${Colors.Grey4}`,
    marginTop: 28,
  },

  [mediaQueries.tabletMedium]: {
    wrapForm: {
      width: '100%',
    },
  },

  [mediaQueries.mobile]: {
    wrapForm: {
      padding: 30,
    },
  },
}));

export default useStyles;
