import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  autocompleteStyle: {
    '&.MuiAutocomplete-root': {
      '& .MuiOutlinedInput-root': {
        padding: 3.5,
      },
    },
  },
}));

export default useStyles;
