import { Box, Button, Grid } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import FormInput from 'commons/Form/FormInput';
import Text from 'commons/Text';
import API from 'configs/API';
import { TESTID, USER_ROLE_BY_ID } from 'configs/Constant';
import useUserAccount from 'hooks/useUserAccount';
import { isDataEqual } from 'utils/Validation';
import { axiosGet, axiosPost } from 'utils/apis/axios';
import { ToastTopHelper, capitalizeLetterFullName } from 'utils/utils';

import useStyles from './styles';

const MyAccountForm = ({ user, userFormState }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { updateUserAccount } = useUserAccount();

  const { register, handleSubmit, formState, setValue, watch, getValues } = userFormState;

  let imageKey;

  const isDataNotChange = isDataEqual(formState.defaultValues, watch());
  const disabledButton = !formState.isValid || isDataNotChange;

  const handleChangeNameLegal = (e) => {
    const { value } = e.target;
    setValue('name', capitalizeLetterFullName(value), { shouldValidate: true });
  };

  const handleUploadProfileSuccess = () => {
    ToastTopHelper.success(t('update_successfully'));
  };

  const getS3PresignedUrl = async (image) => {
    const [, typeImage] = image.type.split('/');

    const randomKey = `${uuidv4()}.${typeImage}`;

    const { success, data } = await axiosGet(API.AWS.S3_PRESIGNED, {
      params: {
        key: randomKey,
      },
    });
    if (success) {
      imageKey = randomKey;
      return data;
    }
  };

  const handleSubmitProfile = async (data) => {
    const formData = new FormData();
    let success = false;

    const avatarUploadFile = getValues('avatarUploadFile');

    if (avatarUploadFile) {
      const dataS3 = await getS3PresignedUrl(avatarUploadFile);

      if (dataS3 && dataS3.fields) {
        const formDataS3 = new FormData();
        Object.entries(dataS3.fields).forEach(([k, v]) => {
          formDataS3.append(k, v);
        });

        formDataS3.append('file', avatarUploadFile);

        const { success: uploadSuccess } = await axiosPost(dataS3.url, formDataS3, {
          transformRequest: (req, headers) => {
            delete headers.Authorization;
            return req;
          },
        });
        success = uploadSuccess;
      }
    }

    if (success) {
      formData.append('avatar', imageKey);
      setValue('avatarUploadFile', null);
    }
    formData.append('name', data?.nickname || '');
    formData.append('place_of_birth', data?.placeOfBirth || '');
    formData.append('year_of_birth', data?.yearOfBirth || '');

    updateUserAccount(formData, handleUploadProfileSuccess);
  };

  return (
    <Box className={classes.wrapBox}>
      <Text
        type="2xl"
        title
        ucfirst
        className={classes.heading}
      >
        {t('account')}
      </Text>

      <Box sx={{ pt: 3 }}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <FormInput
              value={t(`${USER_ROLE_BY_ID[user?.role]}`)}
              label={t('role')}
              disabled
              wrapFormInputStyle={classes.formInputControl}
              labelStyle={classes.formInputLabel}
              textFieldStyle={clsx(classes.textField, {
                [classes.textFieldDisabled]: true,
              })}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <FormInput
              {...register('idAccount')}
              label={t('id_account')}
              disabled
              error={!!formState.errors.idAccount}
              helperText={formState.errors.idAccount?.message}
              labelStyle={classes.formInputLabel}
              wrapFormInputStyle={classes.formInputControl}
              textFieldStyle={clsx(classes.textField, {
                [classes.textFieldDisabled]: true,
              })}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <FormInput
              {...register('legalName')}
              label={t('legal_name')}
              onChange={handleChangeNameLegal}
              disabled
              error={!!formState.errors.legalName}
              helperText={formState.errors.legalName?.message}
              wrapFormInputStyle={classes.formInputControl}
              labelStyle={classes.formInputLabel}
              textFieldStyle={clsx(classes.textField, {
                [classes.textFieldDisabled]: true,
              })}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <FormInput
              {...register('nickname')}
              label={t('nick_name_account_name')}
              placeholder={t('please_enter')}
              error={!!formState.errors.nickname}
              helperText={formState.errors.nickname?.message}
              wrapFormInputStyle={classes.formInputControl}
              labelStyle={classes.formInputLabel}
              textFieldStyle={classes.textField}
              testid={`${TESTID.INPUT_FIELD}_NAME`}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <FormInput
              {...register('placeOfBirth')}
              label={t('place_of_birth')}
              placeholder={t('please_enter')}
              error={!!formState.errors.placeOfBirth}
              helperText={formState.errors.placeOfBirth?.message}
              wrapFormInputStyle={classes.formInputControl}
              labelStyle={classes.formInputLabel}
              textFieldStyle={classes.textField}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <FormInput
              {...register('yearOfBirth')}
              label={t('year_of_birth')}
              placeholder={t('please_enter')}
              error={!!formState.errors.yearOfBirth}
              helperText={formState.errors.yearOfBirth?.message}
              wrapFormInputStyle={classes.formInputControl}
              labelStyle={classes.formInputLabel}
              textFieldStyle={classes.textField}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <FormInput
              {...register('email')}
              label={t('email')}
              error={!!formState.errors.email}
              disabled
              helperText={formState.errors.email?.message}
              wrapFormInputStyle={classes.formInputControl}
              labelStyle={classes.formInputLabel}
              textFieldStyle={clsx(classes.textField, {
                [classes.textFieldDisabled]: true,
              })}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <FormInput
              {...register('phoneNumber')}
              label={t('phone_number')}
              disabled
              error={!!formState.errors.phoneNumber}
              helperText={formState.errors.phoneNumber?.message}
              wrapFormInputStyle={classes.formInputControl}
              labelStyle={classes.formInputLabel}
              textFieldStyle={clsx(classes.textField, {
                [classes.textFieldDisabled]: true,
              })}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Button
          variant="contained"
          testid={TESTID.BUTTON_SAVE}
          className={classes.btn}
          onClick={handleSubmit(handleSubmitProfile)}
          disabled={disabledButton}
        >
          {t('save_changes')}
        </Button>
      </Box>
    </Box>
  );
};

export default MyAccountForm;
