import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  textCanUploadFile: {
    marginTop: 16,
    fontSize: 12,
    color: Colors.Grey8,
  },
}));

export default useStyles;
