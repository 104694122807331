import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  tableHead: {
    '&.MuiTableHead-root': {
      background: Colors.Grey2,
    },
  },
  columnWidth: {
    '&.MuiTableCell-head': {
      width: '19%',
      minWidth: 260,
    },
  },
  columnMedium: {
    '&.MuiTableCell-head': {
      width: '14.7%',
      minWidth: 200,
    },
  },
  columnSmall: {
    '&.MuiTableCell-head': {
      width: '11.8%',
      minWidth: 160,
    },
  },
}));

export default useStyles;
