import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import PersonIcon from '@mui/icons-material/Person';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Box, Button, Divider } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SocialMedia from 'commons/SocialMedia';
import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import { ReactComponent as IconPhone } from 'images/icon_phone.svg';
import Routes from 'utils/Route';
import { ToastTopHelper } from 'utils/utils';

import ArtistWebsites from './components/ArtistWebsites';
import useStyles from './styles';

const ArtistProfileColLeft = ({ userProfile, downloadPDF }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();

  const handleClickEditArtistProfile = () => {
    navigate(Routes.EditArtistProfile);
  };

  const copyToClipboard = () => {
    const currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl).then(() => {
      ToastTopHelper.success(t('link_has_been_copied'));
    });
  };

  return (
    <div className={classes.wrapColLeft}>
      <Box>
        <div className={classes.boxImage}>
          {userProfile?.image_portrait ? (
            <img
              src={userProfile?.image_portrait}
              className={classes.reviewAvatar}
              testid={TESTID.IMAGE_AVATAR_PROFILE}
            />
          ) : (
            <PersonIcon className={classes.avatarIcon} />
          )}
        </div>
        <div className={classes.wrapNameUserProfile}>
          <Text
            type="2xl"
            fontWeightBold
          >
            {userProfile?.legal_name}
          </Text>
          {userProfile?.name && (
            <Text
              className={classes.nickNameUserProfile}
              type="2xl"
            >{`[${userProfile?.name}]`}</Text>
          )}
        </div>
        {userProfile?.live_at && (
          <div className={classes.wrapTextLiveAndWork}>
            <Text
              className={classes.textLiveAndWork}
              type="md"
            >{`${t('lives_and_works_in')} ${userProfile?.live_at}`}</Text>
          </div>
        )}

        <SocialMedia socials={userProfile?.socials} />

        {userProfile?.is_owner && (
          <Button
            variant="contained"
            fullWidth
            onClick={handleClickEditArtistProfile}
            className={classes.buttonEdit}
            testid={TESTID.BUTTON_EDIT_PROFILE}
          >
            <EditOutlinedIcon className={classes.iconAddImage} />
            <Text
              className={classes.textButton}
              fontWeightMedium
              type="md"
            >
              {t('edit_profile')}
            </Text>
          </Button>
        )}

        <div className={classes.wrapButton}>
          <Button
            variant="outlined"
            fullWidth
            className={classes.button}
            onClick={downloadPDF}
          >
            <FileDownloadOutlinedIcon className={classes.iconAddImage} />
            <Text className={classes.textButton}>{t('download_pdf')}</Text>
          </Button>
          <Button
            variant="outlined"
            fullWidth
            className={classes.button}
            onClick={copyToClipboard}
          >
            <ShareOutlinedIcon className={classes.iconAddImage} />
            <Text className={classes.textButton}>{t('share')}</Text>
          </Button>
        </div>
        <Divider className={classes.dividerStyle} />
        <Text
          mt={16}
          type="xl"
          fontWeightBold
        >
          {t('contact')}
        </Text>
        {userProfile?.phone_number && (
          <div className={classes.wrapItemContact}>
            <IconPhone />
            <Text className={classes.textItemContact}>{userProfile?.phone_number}</Text>
          </div>
        )}
        {userProfile?.address && (
          <div className={classes.wrapItemContact}>
            <FmdGoodOutlinedIcon />
            <Text className={classes.textItemContact}>{userProfile?.address}</Text>
          </div>
        )}
        {userProfile?.email && (
          <div className={classes.wrapItemContact}>
            <EmailOutlinedIcon />
            <Text className={classes.textItemContact}>{userProfile?.email}</Text>
          </div>
        )}
        <ArtistWebsites listWebsite={userProfile?.websites} />
      </Box>
    </div>
  );
};

export default ArtistProfileColLeft;
