import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapFilterSelect: {
    padding: '8px 0',
    display: 'flex',
    alignItems: 'center',
  },
  wrapTag: {
    marginLeft: 11,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 11,
  },
}));

export default useStyles;
