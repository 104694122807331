import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AutocompleteCustom from 'commons/AutocompleteCustom';
import { VisibilityStatusOption } from 'configs/Constant';

import useStyles from './styles';

const VisibilityOption = ({ value, onChange, disabled }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box sx={{ mt: 2 }}>
      <AutocompleteCustom
        value={value}
        onChange={onChange}
        options={VisibilityStatusOption}
        placeholder={t('select_a_status')}
        getOptionLabel={(option) => t(option.key)}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        required
        label={t('visibility')}
        labelProps={{
          fontWeightBold: true,
          type: 'lg',
        }}
        disableClearable={true}
        autocompleteStyle={classes.autocompleteMarginTopAdjustment}
        disabled={disabled}
      />
    </Box>
  );
};

export default VisibilityOption;
