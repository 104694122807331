import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  btnFilter: {
    '&.MuiButton-root': {
      marginLeft: 8,
      padding: '4px 8px',
      gap: '4px',
      border: `1px solid ${Colors.Primary}`,
    },
  },

  btnFilterActive: {
    '&.MuiButton-root': {
      background: Colors.Gray5,
    },
  },

  numberFilter: {
    backgroundColor: Colors.Red,
    width: 23,
    height: 23,
    borderRadius: '50%',
    color: Colors.White,
  },
}));

export default useStyles;
