import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

import LayoutSideBar from 'pages/LayoutSideBar';

const ManageStorage = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <LayoutSideBar />

      <Box sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default ManageStorage;
