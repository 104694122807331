import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import FormInput from 'commons/Form/FormInput';
import Text from 'commons/Text';

import Section from '../../../features/Certificate/Section';
import useStyles from './styles';

const ShippingInfo = ({ register = () => {}, errors, wrapperStyle }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Section
      title={t('shipping_info')}
      wrapperStyle={wrapperStyle}
    >
      <Text
        mt={16}
        type="lg"
      >
        <Trans>{t('text_cetificate_delivery')}</Trans>
      </Text>

      <FormInput
        {...register('recipient')}
        label={t('recipient')}
        isRequired
        wrapFormInputStyle={classes.wrapFormInput}
        error={!!errors?.recipient}
        helperText={errors?.recipient?.message}
      />
      <FormInput
        {...register('address')}
        label={t('address')}
        isRequired
        wrapFormInputStyle={classes.wrapFormInput}
        error={!!errors?.address}
        helperText={errors?.address?.message}
      />
      <FormInput
        {...register('phoneNumber')}
        label={t('phone_number')}
        isRequired
        wrapFormInputStyle={classes.wrapFormInput}
        error={!!errors?.phoneNumber}
        helperText={errors?.phoneNumber?.message}
      />
    </Section>
  );
};

export default ShippingInfo;
