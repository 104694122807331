import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import ArtworkImage from '../ArtworkImage';
import ArtworkImageLoading from '../Loading/components/ArtworkImageLoading';
import SameCollectionArtworks from './components/SameCollectionArtworks';
import useStyles from './styles';

const ArtworkDetailRight = ({ loading, artwork }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { description, artist, year_created, id, artist_tag_request, owner } = artwork;

  const { request_to, status: statusTatRequest } = artist_tag_request || {};

  return (
    <Box className={classes.wrapArtworkDetail}>
      <Box className={classes.artworkImage}>
        {loading ? <ArtworkImageLoading /> : <ArtworkImage artwork={artwork} />}
      </Box>
      {description && (
        <div>
          <Text className={classes.textAboutWork}>{t('about_artwork')}</Text>
          <Text className={classes.textDescription}>{description}</Text>
        </div>
      )}
      <SameCollectionArtworks
        artistId={artist?.id}
        yearCreated={year_created}
        artworkId={id}
        requestToId={request_to?.id}
        owner={owner}
        statusTatRequest={statusTatRequest}
      />
    </Box>
  );
};

export default ArtworkDetailRight;
