import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: 899,
    minWidth: 899,
    padding: 64,
    paddingBottom: 47,

    border: `1px solid ${Colors.Gray3}`,
    background: Colors.White,
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10)',
  },
}));

export default useStyles;
