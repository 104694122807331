import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapFormInputExtendWith: {
    width: '70%',
    marginTop: 0,
  },
  wrapFormInput: {
    width: '100%',
    marginTop: 8,
  },
  textFieldStyle: {
    '&.MuiTextField-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      padding: '12px 8px',
    },
  },
  selectFormControl: {
    '&.MuiFormControl-root': {
      width: 'none',
      marginTop: 0,
      flex: 1,
    },
  },
  select: {
    '& .MuiSelect-select': {
      padding: '10px 8px',
    },
  },
}));

export default useStyles;
