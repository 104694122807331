import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  likeIconWrapper: {
    padding: 8,
    cursor: 'pointer',
    display: 'inline-flex',
  },
  pending: {
    opacity: 0.5,
    cursor: 'wait',
  },
}));

export default useStyles;
