import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormInput from 'commons/Form/FormInput';
import { TESTID } from 'configs/Constant';
import AddRowInput from 'pages/EditArtistProfile/commons/AddRowInput';

import ContentGroup from '../ContentGroup';
import useStyles from './styles';

const TrainingBackground = ({ trainingBackground, setTrainingBackground, errorYear }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClickAdd = () => {
    setTrainingBackground([...trainingBackground, { year: '', description: '' }]);
  };

  const handleChangeYear = (e, index) => {
    const newPeriodTime = JSON.parse(JSON.stringify(trainingBackground));
    newPeriodTime[index].year = e?.target?.value;
    setTrainingBackground(newPeriodTime);
  };

  const handleChangeDescription = (e, index) => {
    const newPeriodTime = JSON.parse(JSON.stringify(trainingBackground));
    newPeriodTime[index].description = e?.target?.value;
    setTrainingBackground(newPeriodTime);
  };

  return (
    <ContentGroup heading={t('training_background')}>
      <Grid
        container
        spacing={2}
      >
        {trainingBackground.map((item, index) => (
          <React.Fragment key={index}>
            <Grid
              item
              xs={12}
              lg={3}
            >
              <FormInput
                value={item?.year}
                onChange={(e) => handleChangeYear(e, index)}
                label={t('year')}
                placeholder={t('enter_a_year')}
                wrapFormInputStyle={classes.wrapFormInput}
                InputProps={{
                  inputProps: { type: 'number', step: '1' },
                }}
                error={!!errorYear(item)}
                helperText={errorYear(item)}
                testid={`${TESTID.INPUT_FIELD}_YEAR`}
              />
            </Grid>

            <Grid
              item
              xs={12}
              lg={9}
            >
              <FormInput
                value={item?.description}
                onChange={(e) => handleChangeDescription(e, index)}
                label={t('description')}
                placeholder={t('text_placeholder_training_background')}
                wrapFormInputStyle={classes.wrapFormInput}
                testid={`${TESTID.INPUT_FIELD}_DESCRIPTION`}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>

      <AddRowInput
        text={t('add_training_background')}
        onClick={handleClickAdd}
      />
    </ContentGroup>
  );
};

export default TrainingBackground;
