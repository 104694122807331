import { useMutation, useQueryClient } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosDelete } from 'utils/apis/axios';

const deleteArtwork = async (id) => {
  const { data } = await axiosDelete(false, API.ARTWORK.DELETE_ARTWORK(id));

  return data;
};

const useDeleteArtwork = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteArtwork,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API.ARTWORK.ARTWORK] });
    },
  });
};

export default useDeleteArtwork;
