export default {
  Primary: '#000000',
  Secondary: '#EDE7F6',
  Text: '#363636',
  Golden: '#b2973f',
  White: '#FFFFFF',
  Red: 'red',
  Green: 'green',
  Gray: '#838383',
  LightGray: '#989898',
  Error: '#F5222D',
  DarkLayer: '#00000080',
  LightDarkLayer: '#0000004C',
  LightingDarkLayer: '#00000033',
  LightWhiteLayer: '#F7F7F7D9',
  CoinTagBG: '#F7F7F7',
  Black: '#000000',
  Disabled: '#f8f8f8',
  Border: '#BFBFBF',
  ButtonTag: '#989898',
  WrapGray: '#F5F5F5',
  SwitchButton: '#909090',
  Read: '#F3F3F3',
  Orange: '#F08229',
  BG: '#F0F2F5',
  BGImage: '#F4F8F8',
  Transparent: '#00000000',
  TransparentWhite: '#FFFFFF00',
  BGMapCircle: '#E6F7FF30',
  BGNotRain: '#FEFFE6',
  BorderRain: '#36CFC9',
  Shadow: '#303133',
  TextTransparent: 'transparent',
  PopupShadowCard: '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 12px 8px 2px rgba(0, 0, 0, 0.16)',
  TextGray: 'gray',
  LightOrange: '#FEEFE7',
  Link: '#007bff',
  Azure: '#EDF9FA',

  // Range Cyan
  Cyan1: '#E6FFFB',
  Cyan2: '#B5F5EC',
  Cyan6: '#13C2C2',
  Cyan7: '#08979C',
  Cyan2opa30: '#B5F5EC4D',

  // Range GeekBlue
  GeekBlue3: '#ADC6FF',

  //Teal
  Teal1: '#d1ebec',
  Teal2: '#16989c',

  // Range Gray
  Gray1: '#FFFFFF',
  Gray2: '#FAFAFA',
  Gray3: '#F5F5F5',
  Gray4: '#E8E8E8',
  Gray5: '#D9D9D9',
  Gray6: '#BFBFBF',
  Gray7: '#8C8C8C',
  Gray8: '#595959',
  Gray9: '#262626',
  Gray11: '#3031331a',
  Gray12: '#DDD',
  Gray13: '#CCC',
  Gray14: '#EEE',
  Gray16: '#333',
  Gray17: '#D3D3D3',
  Gray18: '#9B9A9B',
  Gray19: '#E5E5E5',
  Gray20: '#F9F9FB',
  Gray21: '#EAEAEC',
  Gray22: '#828586',
  Gray23: '#A1A1A1',
  Gray24: '#EBEBED',
  Gray25: '#A5A5A5',
  Gray26: '#EBEEF1',
  Gray27: '#F9FAFB',
  Gray28: '#7F9798',
  Gray29: '#6F6F6F',
  Gray30: '#DAE4E4',
  Gray31: '#EFEFEF',
  Gray32: '#C4C4C4',
  Gray33: '#D4D4D4',
  Gray34: '#737373',

  GrayScale10: '#F5F7FA',
  GrayScaleBluePalette30: '#DEE3ED',

  // Range Grey
  Grey1: '#FCFCFD',
  Grey2: '#F5F5F5',
  Grey3: '#E5E5E5',
  Grey4: '#F0F0F0',
  Grey6: '#A3A3A3',
  Grey7: '#737373',
  Grey8: '#404040',
  Grey10: '#0F0F0F',
  Grey11: '#DEDEDF',

  // Range Green
  Green1: '#F6FFED',
  Green2: '#8AE599',
  Green3: '#B7EB8F',
  Green4: '#008040',
  Green6: '#52C41A',
  Green7: '#00979D',
  Green8: '#D9F7BE',
  Green9: '#389E0D',
  Green10: '#237804',
  Green11: '#8BBF56',
  Green12: '#D1EBEC',
  Green13: '#008040',
  Green14: '#8AE599',
  Green15: '#8AE699',

  // Yellow
  Yellow1: '#FEFFE6',
  Yellow4: '#FFF566',
  Yellow6: '#FADB14',
  Yellow7: '#D9890D',
  CalendulaGold: '#D48806',

  // gold
  Gold1: '#FFFBE6',
  Gold3: '#FFE58F',

  // Orange
  Orange1: '#FFF7E6',
  Orange3: '#FFD591',
  Orange4: '#FFC069',
  Orange6: '#FA8C16',
  Orange7: '#FAAD14',
  Orange8: '#DD7F3E',
  Orange9: '#FFEECC',
  Orange10: '#FFCA80',
  Orange11: '#CC4400',

  // Red
  Red1: '#FFF1F0',
  Red2: '#FFCCC7',
  Red3: '#FFA39E',
  Red4: '#FF7875',
  Red5: '#FF4D4F',
  Red6: '#F5222D',
  Red7: '#FA541C',
  Red8: '#CF1322',
  Red9: '#A8071A',
  Red10: '#FE1C00',
  Red11: '#E50000',
  Red12: '#B20000',
  Red13: '#D74242',
  Red14: '#FFAAAE1A',

  // Purple
  Purple1: '#F9F0FF',
  Purple3: '#D3ADF7',
  Purple6: '#722ED1',

  // Pink
  Pink1: '#EFDBFF',

  // Blue
  Blue1: '#E6F7FF',
  Blue2: '#BAE7FF',
  Blue3: '#91D5FF',
  Blue4: '#85A5FF',
  Blue5: '#597EF7',
  Blue6: '#1890FF',
  Blue7: '#096DD9',
  Blue8: '#3EB1D7',
  Blue9: '#1D90FF',
  Blue10: '#0A8BE6',
  Blue11: '#1A4446',
  Blue12: '#0A8BE5',
  Blue13: '#82D2D9',
  Blue14: '#00464D',
  Blue15: '#0080FF',
  Blue16: '#40A9FF',
  Blue17: '#0059B2',
  Blue18: '#48FFE9',

  // Neutral:
  Neutral: '#E6E6E6',
  Neutral3: '#CCCCCC',
  Neutral4: '#B3B3B3',
  Neutral5: '#808080',
  Neutral6: '#262626',

  // Range Volcano:
  Volcano2: '#FFD8BF',
  Volcano3: '#FFBB96',

  // lime
  Lime6: '#A0D911',

  LightPrimary: '#EBF7F8',

  BlackTransparent1: 'rgba(0, 0, 0, 0.1)',
  BlackTransparent2: 'rgba(0, 0, 0, 0.2)',
  BlackTransparent3: 'rgba(0, 0, 0, 0.3)',
  BlackTransparent4: 'rgba(0, 0, 0, 0.4)',
  BlackTransparent5: 'rgba(0, 0, 0, 0.5)',
  BlackTransparent6: 'rgba(0, 0, 0, 0.6)',
  WhiteTransparent8: 'rgba(255, 255, 255, 0.8)',
  BlackTransparent16: 'rgba(0, 0, 0, 0.16)',
  PrimaryTransparent2: 'rgba(0, 151, 157, 0.2)',

  YellowTransparent1: 'rgba(255, 205, 26, 0.2)', // Yellow7
  WhiteSmoke: '#F2F2F2',
  Submarine: '#8A9697',
  Cosmos: '#FFD0CC',
  Burgundy: '#80081C',
  EasternBlue: '#008299',
  Zircon: '#DAE4E4',
  DodgerBlue: '#1790FF',
  RedPalette: '#FFDCD9',
  RedPalette2: '#FFADA6',
  LightShade: '#CEEFF2',

  GreenPrimary: '#00A4B3',
  Palette: {
    Blue2: '#99CCFF',
    Blue3: '#D9ECFF',
    Green1: '#008040',
    Green2: '#8AE599',
  },
  PigmentGreen: '#00B359',
  TangerineYellow: '#FFCC00',
  DarkGreen: '#03030a05',

  PricingShadow1: '0px 2px 3px rgb(0 0 0 / 10%)',
  PricingShadow2: '0px 2px 3px rgba(0, 0, 0, 0.1)',

  IrisBlue: '#00A9BE',
  Cerulean: '#0287B0',
  Turquoise: '#2ADEED',
  Pelorous: '#1E91AD',

  Black1: '#2C2C2C',
};
