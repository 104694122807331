import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  closeIcon: {
    '&.MuiSvgIcon-root': {
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
