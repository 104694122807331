import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import API from 'configs/API';
import { axiosDelete } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

const deleteEdition = async (editionId) => {
  const { data } = await axiosDelete(false, API.ARTWORK.DELETE_ARTWORK_EDITION(editionId));

  return data;
};

const useDeleteEdition = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: deleteEdition,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API.ARTWORK.EDITION] });
      ToastTopHelper.success(t('delete_success'));
    },
  });
};

export default useDeleteEdition;
