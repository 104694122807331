import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Checkbox, FormControl, Select } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Image from 'commons/Image';
import SearchInputIcon from 'commons/Search/SearchInputIcon';
import Text from 'commons/Text';

import useStyles from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectCollections = ({
  selectedCollections = [],
  optionCollection,
  handleCheckboxChange,
  handleDeleteItem,
  fontWeightBold,
  typeText,
  mbText,
  mtText,
  setIsShowDialog,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState('');

  const handleOpenDialogCollection = () => {
    setIsShowDialog(true);
  };

  const filteredCollections = optionCollection.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div>
      <Text
        fontWeightBold={fontWeightBold}
        type={typeText}
        mb={mbText}
        mt={mtText}
      >
        {t('collection')}
      </Text>
      <FormControl className={classes.formControlStyle}>
        <Select
          multiple
          MenuProps={MenuProps}
          displayEmpty
          value={selectedCollections}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <Text className={classes.textPlacehold}>{t('select_collection')}</Text>;
            }

            return (
              <Box className={classes.boxSelect}>
                {selected?.map((id) => {
                  const selectedCollection = optionCollection?.find((s) => s?.id === id);
                  return (
                    <div
                      key={selectedCollection?.id}
                      className={classes.wrapItemSelected}
                    >
                      <div className={classes.itemSelected}>{selectedCollection?.title}</div>
                      <ClearIcon
                        onMouseDown={(event) => {
                          event.stopPropagation();
                          handleDeleteItem(id);
                        }}
                      />
                    </div>
                  );
                })}
              </Box>
            );
          }}
        >
          <div className={classes.wrapSearch}>
            <SearchInputIcon
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder={t('search_collection')}
              fullWidth
            />
          </div>
          {filteredCollections.map((item) => (
            <Box
              key={item.id}
              className={classes.boxDropdown}
              onMouseDown={() => handleCheckboxChange(item?.id)}
            >
              <Checkbox checked={selectedCollections?.includes(item?.id)} />
              {item?.image ? (
                <Image
                  src={item?.image}
                  imageStyle={classes.image}
                />
              ) : (
                <div className={classes.imageCustom} />
              )}
              {item.title}
            </Box>
          ))}

          <div>
            <div
              onClick={handleOpenDialogCollection}
              className={classes.wrapIcon}
            >
              <AddIcon className={classes.iconAdd} />
              <Text className={classes.textCreateItem}>{t('create_new_collection')}</Text>
            </div>
          </div>
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectCollections;
