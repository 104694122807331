import { makeStyles } from '@mui/styles';

import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  boxButtonBottom: {
    marginTop: 24,
  },
  wrapTextImportant: {
    display: 'flex',
    alignItems: 'center',
  },
  textImportant: {
    letterSpacing: '0.64px',
    marginLeft: 16,
  },
  groupButton: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16,
  },
  buttonNext: {
    '&.MuiButton-root': {
      padding: '8px 16px',
      width: 128,
    },
  },
  buttonCancel: {
    '&.MuiButton-root': {
      padding: '7px 16px',
      width: 128,
    },
  },

  [mediaQueries.tabletMedium]: {
    wrapCol: {
      flexWrap: 'wrap',
    },
    boxButtonBottom: {
      width: '100%',
    },
  },
}));

export default useStyles;
