import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AutocompleteSearchUser from 'commons/AutocompleteSearchUser';
import Text from 'commons/Text';
import API from 'configs/API';
import { USER_ROLE_BY_KEY } from 'configs/Constant';
import CustomPaperSearchUser from 'features/Artwork/CustomPaperSearchUser';
import useDebounce from 'hooks/useDebounce';
import useSavedUser from 'hooks/useSavedUser';

import AddUserInfo from '../AddUserInfo';
import DialogOwnerInfo from '../DialogOwnerInfo';
import useStyles from './styles';

const SelectUserReceiveCertificate = ({ ownerHookForm, onUserChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const user = useSelector((state) => state.auth.account.user);
  const queryClient = useQueryClient();

  const [inputUser, setInputUser] = useState('');
  const [isShowDialog, setIsShowDialog] = useState(false);
  const debounceInputUser = useDebounce(inputUser, 500);

  const { formState, watch, setValue } = ownerHookForm;

  const valuesForm = watch();
  const { GALLERY_OWNER, COLLECTOR } = USER_ROLE_BY_KEY;

  const { data: userFilter } = useQuery({
    queryKey: [
      API.AUTH.SEARCH_USER,
      {
        search: debounceInputUser,
        role__in: `${GALLERY_OWNER}, ${COLLECTOR}`,
        include_me: true,
      },
    ],
    placeholderData: (previousData) => previousData,
  });

  const { data: listSavedUser = [] } = useQuery({
    queryKey: [API.AUTH.SAVED_USER],
  });

  const { mutate: savedUser } = useSavedUser();

  const handleInputSearchUser = (e, value, reason) => {
    if (reason === 'input') {
      setInputUser(value);
    }

    if (reason === 'clear') {
      setInputUser('');
      onUserChange(null);
    }
  };

  const handleClickPaper = () => () => {
    setIsShowDialog(true);
  };

  const handleCloseDialog = () => {
    setIsShowDialog(false);
  };

  const handleAddNewUser = (data) => {
    onUserChange(null, false);

    setValue(
      'newOwner',
      {
        name: data?.name,
        contactInfo: data.contactInfo,
        role: 4,
      },
      { shouldValidate: true },
    );

    handleCloseDialog();
  };

  const handleClickSelectSaveUser = () => (item) => {
    onUserChange(item?.saved_user);
  };

  const handleUserChange = (e, newValue, reason) => {
    if (reason === 'selectOption') {
      onUserChange(newValue);
      savedUser(
        { user: user?.id, saved_user: newValue?.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [API.AUTH.SAVED_USER] });
          },
        },
      );
    }
  };

  const handleSearchUserAgain = () => {
    setValue('newOwner', null, { shouldValidate: true });
  };

  return (
    <>
      <Text
        type="sm"
        mb={6}
        mt={12}
      >
        {t('owner_name')}
        <span className={classes.require}>*</span>
      </Text>

      {!valuesForm?.newOwner ? (
        <AutocompleteSearchUser
          userFilter={userFilter}
          value={valuesForm?.owner || null}
          onInputChange={handleInputSearchUser}
          placeholder={t('enter_the_legal_name_or_nick_name')}
          onChange={handleUserChange}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          error={!!formState.errors.owner}
          helperText={formState.errors?.owner?.message}
          PaperComponent={(props) => (
            <CustomPaperSearchUser
              {...props}
              titleSaveUser={t('saved_owners')}
              onClickPaper={handleClickPaper()}
              onClickSelectSaveUser={handleClickSelectSaveUser()}
              listSavedUser={listSavedUser}
            />
          )}
          className={classes.autocompleteSearchUser}
        />
      ) : (
        <AddUserInfo
          ownerHookForm={ownerHookForm}
          onSearchAgain={handleSearchUserAgain}
        />
      )}

      <DialogOwnerInfo
        isShowDialog={isShowDialog}
        onCancel={handleCloseDialog}
        onSubmit={handleAddNewUser}
      />
    </>
  );
};

export default SelectUserReceiveCertificate;
