import { Container, Stack } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import useStyles from './styles';

const AuthLayout = ({ children, wrapLayoutStyle, rowStyle }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.background, wrapLayoutStyle)}>
      <Container className={classes.container}>
        <Stack
          direction="row"
          className={clsx(classes.row, rowStyle)}
        >
          {children}
        </Stack>
      </Container>
    </div>
  );
};

export default AuthLayout;
