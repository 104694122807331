import { TableBody } from '@mui/material';
import React from 'react';

import RequestCertificateRowCollector from './components/RequestCertificateRowCollector';

const RequestCertificateTableBodyCollector = ({ listRequestCertificate, page, pageSize }) => {
  return (
    <TableBody>
      {(listRequestCertificate || []).map((certificate, index) => (
        <RequestCertificateRowCollector
          key={index}
          index={index}
          certificate={certificate}
          page={page}
          pageSize={pageSize}
        />
      ))}
    </TableBody>
  );
};

export default RequestCertificateTableBodyCollector;
