import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useArtworkLikeStatContext } from 'commons/ArtworkLikeStatProvider';
import Text from 'commons/Text';
import Colors from 'configs/Colors';
import { ReactComponent as HitLikeIcon } from 'images/hit_like_white.svg';
import { ReactComponent as LikeIcon } from 'images/like_white.svg';
import Routes from 'utils/Route';

import useStyles from './styles';

const ArtworkLikeUI = ({ wrapStyle }) => {
  const classes = useStyles();
  const { artistId } = useParams();

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();
  const { isLiked, onClick, isPending, numberOfLike } = useArtworkLikeStatContext();

  const handleLike = () => {
    if (!isLoggedIn) {
      navigate(Routes.Login, { state: { redirectTo: Routes.GalleryDetail(artistId) } });
      return;
    }
    if (isPending) {
      return;
    }

    onClick();
  };

  return (
    <div className={wrapStyle}>
      <div className={classes.likeStatWrapper}>
        <div
          className={clsx(classes.likeIconWrapper, { [classes.pending]: isPending })}
          onClick={handleLike}
        >
          {isLiked ? <HitLikeIcon /> : <LikeIcon />}
        </div>

        <Text
          variant="span"
          type="xs"
          color={Colors.White}
        >
          {numberOfLike}
        </Text>
      </div>

      <div className={classes.overlay} />
    </div>
  );
};

export default ArtworkLikeUI;
