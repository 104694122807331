import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DialogCustom from 'commons/DialogCustom';
import GridLayout from 'layouts/GridLayout';

import MainContentWrapper from '../MainContentWrapper';
import useStyles from './styles';

const UpdateInfoStep = ({
  onNextStep,
  artwork,
  artworkFormState,
  listUploadedImages,
  setListUploadedImages,
  requestUser,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [isShowDialog, setIsShowDialog] = useState(false);

  const { handleSubmit } = artworkFormState;

  const handleUpdateArtwork = () => {
    onNextStep();
  };

  const handleCancel = () => {
    setIsShowDialog(true);
  };

  const handleCancelDialog = () => {
    setIsShowDialog(false);
  };

  const handleSubmitlDialog = () => {
    setIsShowDialog(false);
    navigate(-1);
  };

  return (
    <Box sx={{ pt: 3 }}>
      <GridLayout>
        <MainContentWrapper
          artworkFormState={artworkFormState}
          artwork={artwork}
          listUploadedImages={listUploadedImages}
          setListUploadedImages={setListUploadedImages}
          requestUser={requestUser}
        />

        <>
          <Button
            variant="contained"
            fullWidth
            className={classes.button}
            onClick={handleSubmit(handleUpdateArtwork)}
          >
            {t('text_act_button_next')}
          </Button>
          <Button
            variant="outlined"
            fullWidth
            className={classes.buttonCancel}
            onClick={handleCancel}
          >
            {t('cancel')}
          </Button>
        </>
      </GridLayout>

      <DialogCustom
        isShowDialog={isShowDialog}
        dialogTitle={t('cancel_request')}
        textCancel={t('continue')}
        textSubmit={t('yes_canccel')}
        onCancel={handleCancelDialog}
        onSubmit={handleSubmitlDialog}
        maxWidth={'md'}
      >
        <div>{t('are_you_sure_exit_certificate')}</div>
      </DialogCustom>
    </Box>
  );
};

export default UpdateInfoStep;
