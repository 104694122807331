import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import FormInput from 'commons/Form/FormInput';
import FormAutocompleteController from 'commons/FormHook/FormAutocompleteController';
import Image from 'commons/Image';
import ImageAttachmentDisplay from 'commons/ImageAttachmentDisplay';
import Measurement from 'commons/Measurement';
import Text from 'commons/Text';
import Section from 'features/Certificate/Section';

import useStyles from './styles';

const ArtworkInfo = ({ artworkFormState, artwork, images, keyImages }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { control, register, formState } = artworkFormState;
  const [checkedPeriod] = useState(
    artwork?.year_created || artwork?.period_created ? !artwork?.year_created : false,
  );
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [imageDisplayItem, setImageDisplayItem] = useState('');

  const handleClickImageDisplay = (src) => {
    setImageDisplayItem(src);
    setIsShowDialog(true);
  };

  const handleCancelDialog = () => {
    setIsShowDialog(false);
  };

  return (
    <Section title={t('artwork_information')}>
      <Box sx={{ pt: 3 }}>
        <Text className={classes.inputLabel}>
          {t('artwork_cover')}
          <span className={classes.require}>*</span>
        </Text>
        <Image
          src={artwork?.image}
          imageStyle={classes.imageCover}
        />
      </Box>
      <Box sx={{ pt: 2 }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            lg={6}
          >
            <FormInput
              {...register('artistName')}
              label={t('artist_name')}
              wrapFormInputStyle={classes.formControl}
              labelStyle={classes.labelStyle}
              disabled
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            <FormInput
              {...register('titleArtwork')}
              label={t('artwork_title')}
              placeholder={t('please_enter')}
              isRequired
              error={!!formState?.errors.titleArtwork}
              helperText={formState?.errors.titleArtwork?.message}
              labelStyle={classes.formInputLabel}
              wrapFormInputStyle={classes.wrapFormInputStyle}
            />
          </Grid>
        </Grid>
      </Box>

      <Measurement
        register={register}
        formState={formState}
      />

      <Box sx={{ pt: 2 }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            lg={6}
          >
            <FormAutocompleteController
              control={control}
              name={'mediumArtWork'}
              options={[]}
              placeholder={t('select_medium_on_material')}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              isRequired
              label={t('medium_on_material')}
              helperText={formState?.errors.mediumArtWork?.message}
              error={!!formState?.errors.mediumArtWork}
              fontWeightTitle={400}
              autocompleteStyle={classes.autocompleteStyle}
              textTitleCustom={classes.textTitleMedium}
              wrapFormStyle={classes.wrapFormStyle}
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            {!checkedPeriod && (
              <FormInput
                {...register('yearCreated', { valueAsNumber: true })}
                label={t('creation_year')}
                isRequired={!checkedPeriod}
                error={!!formState?.errors?.yearCreated}
                helperText={!checkedPeriod && formState?.errors.yearCreated?.message}
                disabled={checkedPeriod}
                labelStyle={classes.formInputLabel}
                textFieldStyle={clsx(classes.textFieldStyle, {
                  [classes.disableTextFieldStyle]: checkedPeriod,
                })}
                InputProps={{
                  inputProps: { type: 'number', step: '1' },
                }}
                wrapFormInputStyle={classes.wrapFormInputYearCreated}
              />
            )}

            {checkedPeriod && (
              <FormInput
                {...register('periodYear')}
                label={t('period_year')}
                isRequired={checkedPeriod}
                error={!!formState?.errors?.periodYear}
                helperText={formState?.errors.periodYear?.message}
                labelStyle={classes.formInputLabel}
                wrapFormInputStyle={classes.wrapFormInputYearCreated}
              />
            )}
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            <FormInput
              {...register('editionVolume', { valueAsNumber: true })}
              label={t('edition_volume')}
              isRequired
              error={!!formState?.errors?.editionVolume}
              helperText={formState?.errors.editionVolume?.message}
              InputProps={{
                inputProps: { type: 'number', step: '1' },
              }}
              wrapFormInputStyle={classes.wrapFormInputStyle}
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={6}
          >
            <FormInput
              {...register('edition.edition_number', { valueAsNumber: true })}
              label={t('edition_number')}
              isRequired
              error={!!formState?.errors?.edition?.edition_number}
              helperText={formState?.errors.edition?.edition_number?.message}
              InputProps={{
                inputProps: { type: 'number', step: '1' },
              }}
              wrapFormInputStyle={classes.wrapFormInputStyle}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ pt: 2 }}>
        <Text className={classes.textAttachment}>{t('attachments')}</Text>
        <ImageAttachmentDisplay
          images={images}
          keyImages={keyImages}
          onClickImageDisplay={handleClickImageDisplay}
        />
      </Box>
      <DialogCustom
        isShowDialog={isShowDialog}
        textCancel={t('cancel')}
        onCancel={handleCancelDialog}
        maxWidth={'md'}
      >
        <img
          src={imageDisplayItem}
          className={classes.dialogImageItem}
        />
      </DialogCustom>
    </Section>
  );
};

export default ArtworkInfo;
