import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Stepper from 'commons/Stepper';

import useStepper from '../../../hooks/useSteper';
import ReviewCertificateStep from './components/ReviewCertificateStep';
import UpdateInfoStep from './components/UpdateInfoStep';
import { artworkInfoSchema } from './validations';

const CollectorRequestCertificate = ({ artwork }) => {
  const { step, handlBackStep, handleNextStep } = useStepper(1);
  const [listUploadedImages, setListUploadedImages] = useState([]);
  const user = useSelector((state) => state.auth.account.user);

  const requestUser = artwork?.artist_tag_request?.request_to;

  const artworkFormState = useForm({
    defaultValues: {
      artistNameArtwork: artwork?.artist_name || artwork?.artist_tag_request?.request_to?.name,
      titleArtwork: artwork?.title,
      height: artwork?.size?.height,
      width: artwork?.size?.width,
      depth: artwork?.size?.depth,
      yearCreated: artwork?.year_created,
      periodYear: artwork?.period_created,
      mediumArtWork: artwork?.medium,
      editionVolume: artwork?.total_edition,
      ownerName: user?.legal_name,
      yearOfBirth: user?.year_of_birth,
      ownerAddress: user?.place_of_birth,
      recipient: user?.legal_name,
      address: user?.place_of_birth,
      phoneNumber: user?.phone_number,
      edition: artwork?.editions[0],
      inputSearchUser: '',
      selectedUser: requestUser,
      tagRequest: artwork?.artist_tag_request,
    },
    mode: 'onChange',
    resolver: yupResolver(artworkInfoSchema),
  });

  return (
    <>
      <Stepper active={step === 1}>
        <UpdateInfoStep
          onNextStep={handleNextStep}
          artwork={artwork}
          artworkFormState={artworkFormState}
          listUploadedImages={listUploadedImages}
          setListUploadedImages={setListUploadedImages}
          requestUser={requestUser}
        />
      </Stepper>

      <Stepper active={step === 2}>
        <ReviewCertificateStep
          onBackStep={handlBackStep}
          artworkFormState={artworkFormState}
          artwork={artwork}
          listUploadedImages={listUploadedImages}
        />
      </Stepper>
    </>
  );
};

export default CollectorRequestCertificate;
