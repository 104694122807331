import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterResults: 0,
};

const manageArtworkSlice = createSlice({
  name: 'manageArtwork',
  initialState,
  reducers: {
    updateFilterResults: (state, action) => {
      return {
        ...state,
        filterResults: action.payload,
      };
    },
  },
});

export const { updateFilterResults } = manageArtworkSlice.actions;
export default manageArtworkSlice.reducer;
