import React, { createContext, useContext, useState } from 'react';

const RequestCertificateContext = createContext();

export const RequestCertificateProvider = ({ children }) => {
  const [ownerInfor, setOwnerInfor] = useState({
    owner: null,
    newOwner: null,
    yearOfBirth: '',
    address: '',
    contractNumber: '',
  });

  const [artworkInfo, setArtworkInfo] = useState({
    titleArtwork: '',
    lengthArtwork: '',
    widthArtwork: '',
    yearCreated: '',
    edition: {},
    mediumArtWork: '',
  });

  return (
    <RequestCertificateContext.Provider
      value={{
        ownerInfor,
        artworkInfo,
        setOwnerInfor,
        setArtworkInfo,
      }}
    >
      {children}
    </RequestCertificateContext.Provider>
  );
};

export const useRequestCertificateContext = () => {
  return useContext(RequestCertificateContext);
};
