import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from './styles';

const EditArtworkEditionHead = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ mt: 2 }}
    >
      <Text
        className={classes.statusNumberWidth}
        type="sm"
      >
        {t('edition')}
      </Text>
      <Text
        className={classes.columnWdith}
        type="sm"
      >
        {t('status')}
      </Text>
      <Text
        className={classes.columnWdith}
        type="sm"
      >
        {t('location')}
      </Text>
    </Stack>
  );
};

export default EditArtworkEditionHead;
