import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  bannerBox: {
    width: '100%',
    height: 'calc(71vh - 75px)',
    background: Colors.Grey3,
    position: 'relative',
  },
  bannerBoxTextVisible: {
    '&:hover': {
      '& $wrapBannerText': {
        opacity: 1,
        visibility: 'visible',
      },
    },
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  wrapBannerText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  hidden: {
    display: 'none',
  },
  visibilityHidden: {
    opacity: 0,
    transition: 'all 0.4s',
    visibility: 'hidden',
  },
  wrapBtnActions: {
    position: 'absolute',
    top: 48,
    right: 48,
  },
  wrapButtonEditAction: {
    marginTop: 8,
  },
}));

export default useStyles;
