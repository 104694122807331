import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapFilterTag: {
    marginTop: 16,
  },
  hiddenTag: {
    display: 'none',
  },
}));

export default useStyles;
