import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapColRight: {
    width: '65%',
    marginLeft: 40,
  },
  boxStatements: {
    marginTop: 15,
    backgroundColor: Colors.Gray3,
    flexShrink: 0,
    padding: 16,
    marginBottom: 40,
  },
  textStatements: {
    fontFamily: 'Nunito',
    fontStyle: 'italic',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
    letterSpacing: '0.72px',
  },
  textSeeMore: {
    fontSize: 16,
    color: Colors.Blue6,
    marginTop: 10,
    cursor: 'pointer',
  },
  textSeeMoreText: {
    fontSize: 16,
    color: Colors.Blue6,
    marginTop: 2,
    cursor: 'pointer',
  },
  textAbout: {
    marginTop: 10,
    letterSpacing: '0.64px',
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
  wrapArtistAbout: {
    marginBottom: 40,
  },
  wrapItemProfile: {
    display: 'flex',
    width: '100%',
    marginLeft: 40,
  },
  iconButtonRadio: {
    position: 'absolute',
    top: 4,
    left: 0,
    transform: 'translateX(-50%)',
  },
  wrapDescriptionAndItem: {
    display: 'block',
    width: '87%',
    color: Colors.Grey8,
    wordWrap: 'break-word',
  },
  wrapImageItem: {
    display: 'flex',
    width: '90%',
  },
  imageItem: {
    width: '80px !important',
    height: 60,
    objectFit: 'cover',
    display: 'block',
    marginRight: 8,
    borderRadius: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  iconPdf: {
    width: 80,
    height: 60,
    cursor: 'pointer',
    border: `1px solid ${Colors.Grey3}`,
    marginRight: 8,
  },
  dialogImageItem: {
    width: '100% !important',
    height: 500,
    objectFit: 'cover',
    display: 'block',
    borderRadius: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
  textYear: {
    width: '9%',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.64px',
    color: Colors.Grey10,
  },
  textDescription: {
    lineHeight: '24px',
    letterSpacing: '0.64px',
    color: Colors.Grey10,
    marginBottom: 5,
  },
  textDescriptionExhibition: {
    color: Colors.Grey10,
    lineHeight: '24px',
    letterSpacing: '0.64px',
  },
  boxItemProfile: {
    width: '93%',
  },
  wrapListItemProfile: {
    display: 'flex',
    marginTop: 8,
  },
  boxWrapItemsProfile: {
    borderLeft: `1px solid ${Colors.Grey3}`,
    position: 'relative',
    paddingBottom: 40,
    '&:last-child': {
      borderLeft: 'none',
    },
  },
  linkItem: {
    color: Colors.Grey10,
    lineHeight: '24px',
    letterSpacing: '0.64px',
  },
  wrapListProfile: {
    marginTop: 13,
  },

  [mediaQueries.tabletMedium]: {
    wrapColRight: {
      width: '100%',
      marginLeft: 0,
      marginTop: 40,
    },
  },
}));

export default useStyles;
