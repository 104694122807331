import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';
import UserEventHandler from '@sendbird/uikit-react/handlers/UserEventHandler';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Text from 'commons/Text';
import { ReactComponent as MessageIcon } from 'images/message.svg';
import Routes from 'utils/Route';

import useStyles from '../styles';

const HANDLER_ID = 'HANDLER_ID';

const MessageNotification = () => {
  const classes = useStyles();
  const [unreadMessage, setUnreadMessage] = useState(0);

  const globalStore = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);

  useEffect(() => {
    const getUnReadMessage = async () => {
      if (!sdkInstance || !sdkInstance.groupChannel) {
        return 0;
      }

      const unreadMessageNumber = await sdkInstance.groupChannel.getTotalUnreadMessageCount();

      setUnreadMessage(unreadMessageNumber);
    };

    getUnReadMessage();
  }, [sdkInstance]);

  useEffect(() => {
    if (!sdkInstance || !sdkInstance.groupChannel) {
      return;
    }

    const handler = new UserEventHandler({
      onTotalUnreadMessageCountChanged: (unreadMessageCount) => {
        setUnreadMessage(unreadMessageCount.groupChannelCount);
      },
    });

    sdkInstance.addUserEventHandler(HANDLER_ID, handler);

    return () => {
      sdkInstance.removeUserEventHandler(HANDLER_ID);
    };
  }, [sdkInstance]);

  return (
    <Link
      className={classes.wrapIconMessage}
      to={Routes.Chat}
    >
      <MessageIcon />

      {!!unreadMessage && (
        <Text
          className={classes.messageCounterNumber}
          type="xs"
        >
          {unreadMessage}
        </Text>
      )}
    </Link>
  );
};

export default MessageNotification;
