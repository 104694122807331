import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '24px',
    background: Colors.White,
  },
  btn: {
    '&.MuiButton-root': {
      padding: '12px 20px',

      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: 0.64,
    },
  },
  btnBorderGrey: {
    '&.MuiButton-root': {
      borderColor: Colors.Grey4,
    },
  },
}));

export default useStyles;
