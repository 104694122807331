import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import IosSwitch from 'commons/IosSwitch';
import Text from 'commons/Text';
import API from 'configs/API';
import { TESTID } from 'configs/Constant';
import SectionWrapper from 'pages/EditArtistProfile/commons/SectionWrapper';
import YearDescriptionInput from 'pages/EditArtistProfile/commons/YearDescriptionInput';
import { isFieldValid, isValidYear } from 'utils/Validation';
import { axiosDelete, axiosGet, axiosPost } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

import DialogEditPublicationArtist from './components/DialogEditPublicationArtist';
import PublicationListGrid from './components/PublicationListGrid';
import useStyles from './styles';

const ArtistPublication = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [listPublication, setListPublication] = useState([]);
  const [year, setYear] = useState('');
  const [description, setDescription] = useState('');
  const [isPublic, setIsPublic] = useState(true);
  const [wrongYear, setWrongYear] = useState(false);
  const [isShowDialogDelete, setIsShowDialogDelete] = useState(false);
  const [isShowDialogEdit, setIsShowDialogEdit] = useState(false);
  const [selectedPublication, setSelectedPublication] = useState({});

  const [editedYear, setEditedYear] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedIsPublic, setEditedIsPublic] = useState(true);

  const isYearValid = isFieldValid(year, wrongYear);
  const isFormValid = isYearValid && description;

  const getListPublication = async () => {
    const { success, data } = await axiosGet(API.AUTH.PUBLICATION.LIST);
    if (success) {
      setListPublication(data);
    }
  };

  const handleChangeYear = (e) => {
    const value = e.target.value;
    setWrongYear(!isValidYear(value));
    setYear(value);
  };

  const handleChangeDescription = (e) => {
    const value = e.target.value;
    if (value?.length > 200) {
      return;
    }
    setDescription(value);
  };

  const handleSwitchChange = (e) => {
    const isHidePrice = e.target.checked;
    setIsPublic(isHidePrice);
  };

  const createUserPublication = async () => {
    const formData = {
      year: year,
      description: description,
      is_public: isPublic,
    };
    const { success } = await axiosPost(API.AUTH.PUBLICATION.CREATE, formData);
    if (success) {
      getListPublication();
      setDescription('');
      setYear('');
      setIsPublic(true);
      ToastTopHelper.success(t('create_successfully'));
    }
  };

  const handleSubmitPublicationDeletion = async () => {
    const { success } = await axiosDelete(API.AUTH.PUBLICATION.DETAIL(selectedPublication?.id));
    if (success) {
      getListPublication();
      setIsShowDialogDelete(false);
      ToastTopHelper.success(t('delete_success'));
    }
  };

  const handleCancelPublicationDeletion = () => {
    setIsShowDialogDelete(false);
  };

  const handleOpenDialogDelete = (item) => {
    setSelectedPublication(item);
    setIsShowDialogDelete(true);
  };

  const handleOpenDialogEdit = (item) => {
    setSelectedPublication(item);
    setEditedYear(item?.year);
    setEditedDescription(item?.description);
    setEditedIsPublic(item?.is_public);
    setIsShowDialogEdit(true);
  };

  useEffect(() => {
    getListPublication();
  }, []);

  return (
    <SectionWrapper heading={t('publications')}>
      <Box className={classes.bodyBox}>
        <YearDescriptionInput
          year={year}
          description={description}
          onChangeYear={handleChangeYear}
          onChangeDescription={handleChangeDescription}
          errorYear={wrongYear}
          helperTextYear={wrongYear && t('please_enter_correct_year')}
          testid={TESTID.YEAR_DESCRIPTION_INPUT}
        />
        <div className={classes.wrapIosSwitch}>
          <IosSwitch
            isChecked={isPublic}
            onSwitchChange={handleSwitchChange}
            testid={TESTID.IOS_SWITCH_PUBLICATION}
          />
          <Text
            className={classes.textPublicProfile}
            mt={12}
          >
            {isPublic ? t('public_on_profile') : t('private')}
          </Text>
        </div>
        <div className={classes.wrapButtonAdd}>
          <Button
            variant="contained"
            disabled={!isFormValid}
            onClick={createUserPublication}
            className={classes.buttonAdd}
            testid={TESTID.BUTTON_ADD_PUBLICATION}
          >
            {t('add')}
          </Button>
        </div>
      </Box>
      <Text className={classes.textAddEventAutomatic}>{t('added_events_will_automatically')}</Text>
      <PublicationListGrid
        listPublication={listPublication}
        onClickOpenDialogDelete={handleOpenDialogDelete}
        onClickOpenDialogEdit={handleOpenDialogEdit}
      />

      <DialogEditPublicationArtist
        isShowDialog={isShowDialogEdit}
        editedYear={editedYear}
        editedDescription={editedDescription}
        editedIsPublic={editedIsPublic}
        selectedPublication={selectedPublication}
        setEditYear={setEditedYear}
        setEditDescription={setEditedDescription}
        setEditedIsPublic={setEditedIsPublic}
        setIsShowDialogEdit={setIsShowDialogEdit}
        getListPublication={getListPublication}
      />
      <DialogCustom
        isShowDialog={isShowDialogDelete}
        textCancel={t('cancel')}
        dialogTitle={t('delete_publication')}
        textSubmit={t('delete')}
        onCancel={handleCancelPublicationDeletion}
        onSubmit={handleSubmitPublicationDeletion}
        testid={TESTID.DIALOG_BUTTON_DELETE_PUBLICATION}
      >
        {t('are_you_sure_you_want_delete_your', { name: t('publication') })}
        <Text
          bold
          mt={4}
        >
          {selectedPublication?.description}
        </Text>
      </DialogCustom>
    </SectionWrapper>
  );
};

export default ArtistPublication;
