import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formControl: {
    '&.MuiFormControl-root': {
      width: '16%',
      marginTop: 0,
    },
    '& .MuiSelect-select': {
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 0,
    },
  },
  select: {
    '& .MuiSelect-select': {
      lineHeight: 1.4,
    },
  },
}));

export default useStyles;
