import React from 'react';
import { Controller } from 'react-hook-form';

import AutocompleteWithOptionExtend from '../AutocompleteWithOptionExtend';

const AutocompleteWithOptionExtendController = ({
  name,
  control,
  rules,
  options,
  label,
  isRequired,
  helperText,
  error,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => {
        const { value, onChange, ref } = field;

        return (
          <AutocompleteWithOptionExtend
            value={value || null}
            options={options}
            label={label}
            isRequired={isRequired}
            onChange={(data) => onChange(data)}
            helperText={helperText}
            error={error}
            ref={ref}
            {...props}
          />
        );
      }}
    />
  );
};

export default AutocompleteWithOptionExtendController;
