import { Box, Container } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Breadcrumbs from 'commons/Breadcrumbs';
import { STATUS_REQUEST_KEY } from 'configs/Constant';
import GridLayout from 'layouts/GridLayout';
import Routes from 'utils/Route';

import GroupButtonRejectCertificate from './components/GroupButtonRejectCertificate';
import GroupButtonReviewCertificate from './components/GroupButtonReviewCertificate';
import ReviewCertificateMainContent from './components/ReviewCertificateMainContent';
import useStyles from './styles';

const ArtistReviewCertificateRequest = ({ data, user, breadCrumbs }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const isUserRequestRecipient = data?.request_to?.id === user?.id;
  if (!isUserRequestRecipient) {
    navigate(Routes.PageNotFound, { replace: true });
  }

  return (
    <main className={classes.wrapPage}>
      <Container>
        <Breadcrumbs items={breadCrumbs} />
        <h1 className={classes.heading}>{t('review_certificate_request')}</h1>

        <Box sx={{ pt: 3 }}>
          <GridLayout>
            <ReviewCertificateMainContent data={data} />

            {data?.status === STATUS_REQUEST_KEY['REQUEST_DENIED'] && (
              <GroupButtonRejectCertificate message={data?.message} />
            )}

            {data?.status !== STATUS_REQUEST_KEY['REQUEST_DENIED'] && (
              <GroupButtonReviewCertificate data={data} />
            )}
          </GridLayout>
        </Box>
      </Container>
    </main>
  );
};

export default ArtistReviewCertificateRequest;
