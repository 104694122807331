import { Container, Stack } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import Breadcrumbs from 'commons/Breadcrumbs';
import SearchInputIcon from 'commons/Search/SearchInputIcon';
import Text from 'commons/Text';
import { useSearch } from 'hooks/useSearch';

import useStyles from './styles';

const ManageWrapper = ({
  breadCrumbs,
  heading,
  children,
  actionUi,
  searchPlaceholder,
  breadCrumbsStyle,
}) => {
  const classes = useStyles();

  const { search, handleSearch } = useSearch();

  return (
    <Container className={classes.container}>
      <Breadcrumbs
        items={breadCrumbs}
        breadcrumbsStyle={clsx(classes.breadCrumbs, breadCrumbsStyle)}
      />

      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center', mt: 2 }}
      >
        <Text
          type="2xl"
          title
          variant="h1"
          className={classes.heading}
        >
          {heading}
        </Text>

        <Stack
          direction="row"
          spacing={2}
        >
          <SearchInputIcon
            value={search}
            onChange={handleSearch}
            placeholder={searchPlaceholder}
            wrapStyle={classes.search}
          />
          {actionUi}
        </Stack>
      </Stack>

      {children}
    </Container>
  );
};

export default ManageWrapper;
