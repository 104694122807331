import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapContent: {
    marginTop: 24,
    display: 'flex',
  },
  line: {
    width: 1,
    margin: '0px 40px',
    height: 'auto',
    background: Colors.Grey3,
  },
  wrapUploadImage: {
    flex: 1,
  },
  wrapForm: {
    flex: 3,
  },
  row: {
    display: 'flex',
  },
  inputStyle: {
    marginTop: 0,
    marginLeft: 16,
    '&:first-child': {
      marginLeft: 0,
    },
  },
  inputStyleHaftWidth: {
    width: '50%',
  },
  inputStyleFullWidth: {
    width: '100%',
  },
  inputStyle30Width: {
    width: '30%',
  },
  inputStyle70Width: {
    width: '70%',
  },

  spacing24: {
    marginTop: 24,
  },

  boxImage: {
    aspectRatio: '1/1',
    border: `1px solid ${Colors.Grey3}`,
  },
  avatarIcon: {
    '&.MuiSvgIcon-root': {
      width: '100%',
      height: '100%',
      color: Colors.Grey3,
    },
  },
  reviewAvatar: {
    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
  },
  buttonUpload: {
    marginTop: 16,
    padding: '8px 16px',
    width: '100%',
    textAlign: 'center',
    border: `1px solid ${Colors.Primary}`,
  },
  wrapFormInput: {
    marginTop: 0,
  },
  spacingTop: {
    marginTop: 16,
    '&:first-child': {
      marginTop: 0,
    },
  },
  btnSubmit: {
    '&.MuiButton-root': {
      display: 'block',
      marginLeft: 'auto',
      padding: '8px 16px',
      width: 90,
      height: 44,
    },
  },
}));

export default useStyles;
