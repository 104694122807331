import React from 'react';
import { useSelector } from 'react-redux';

import { USER_ROLE_BY_KEY } from 'configs/Constant';

import ArtistEditionUIView from './ArtistEditionUIView';
import CollectorEditionUIView from './CollectorEditionUIView';

const { ARTIST, COLLECTOR, GALLERY_OWNER } = USER_ROLE_BY_KEY;
const OPTION_FORM_ARTIST_BY_ROLE = {
  [ARTIST]: ArtistEditionUIView,
  [COLLECTOR]: CollectorEditionUIView,
  [GALLERY_OWNER]: CollectorEditionUIView,
};

const UploadArtworkEditionUIView = ({ artistEditionProps, collectionEditionProps }) => {
  const userRole = useSelector((state) => state.auth.account.user.role);
  const DisplayEditionByRole = OPTION_FORM_ARTIST_BY_ROLE[userRole];

  if (!DisplayEditionByRole) {
    return;
  }

  return (
    <DisplayEditionByRole
      {...artistEditionProps}
      {...collectionEditionProps}
    />
  );
};

export default UploadArtworkEditionUIView;
