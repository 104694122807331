import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapBoxStatement: {
    marginTop: 16,
    paddingTop: 16,
    borderTop: `1px solid ${Colors.Gray19}`,
  },
  icon: {
    width: 20,
    height: 20,
    color: Colors.Primary,
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
