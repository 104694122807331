import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { STATUS_REQUEST_KEY } from 'configs/Constant';

import GroupButtonReceiveTagRequest from '../GroupButtonReceiveTagRequest';
import GroupButtonRejectTagRequest from '../GroupButtonRejectTagRequest';
import useStyles from './styles';

const ReviewTagRequestAction = ({ data }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <>
      {data?.status === STATUS_REQUEST_KEY['REQUEST_RECEIVED'] && (
        <GroupButtonReceiveTagRequest data={data} />
      )}

      {data?.status === STATUS_REQUEST_KEY['REQUEST_APPROVED'] && (
        <Button
          variant="outlined"
          fullWidth
          className={classes.buttonCancel}
          onClick={handleCancel}
        >
          {t('cancel')}
        </Button>
      )}

      {data?.status === STATUS_REQUEST_KEY['REQUEST_DENIED'] && (
        <GroupButtonRejectTagRequest message={data?.message} />
      )}
    </>
  );
};

export default ReviewTagRequestAction;
