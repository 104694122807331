import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapSmallUpload: {
    width: '100%',
    height: 109,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    border: `2px dashed ${Colors.Primary}`,
    color: Colors.Primary,
  },
  listImageItemWrapper: {
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
  },
  imagItem: {
    width: '100%',
    height: 109,
    objectFit: 'cover',
    display: 'block',
  },
  removeIcon: {
    '&.MuiSvgIcon-root': {
      color: Colors.White,
      position: 'absolute',
      top: 4,
      right: 4,
    },
  },
  iconAddImage: {
    '&.MuiSvgIcon-root': {
      width: 40,
      height: 40,
    },
  },
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
    fontWeight: 500,
  },
}));

export default useStyles;
