import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 19,
    minHeight: 162,
  },
  textInfoArtwork: {
    marginTop: 4,
    letterSpacing: '0.56px',
    '&:first-child': {
      marginTop: 0,
    },
  },
  letterSpacing: {
    letterSpacing: '0.56px',
  },
  divier: {
    '&.MuiDivider-root': {
      marginTop: 116,
      background: Colors.Primary,
    },
  },
  textWidth: {
    minWidth: '50%',
  },
  textDate: {
    '&::after': {
      content: '"|"',
      marginLeft: 5,
      display: 'inline-block',
    },
  },
  line: {
    marginLeft: 5,
    marginBottom: 4,
    flex: 1,
    borderBottom: `1px dashed ${Colors.Primary}`,
  },
}));

export default useStyles;
