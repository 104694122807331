import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapFilterContent: {
    padding: '8px 16px',
    border: `1px solid ${Colors.Grey10}`,
  },
  lineSeparate: {
    width: '100%',
    height: 1,
    margin: '8px 0',
    color: Colors.Grey8,
  },
}));

export default useStyles;
