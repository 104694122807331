import { Stack } from '@mui/material';
import { GroupChannel } from '@sendbird/uikit-react/GroupChannel';
import { GroupChannelList } from '@sendbird/uikit-react/GroupChannelList';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { CHAT_USER_PARAMS } from 'configs/Constant';

import ChatHeader from '../ChatHeader';
import useStyles from './styles';

const ChatUI = () => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth.account.user);

  const globalStore = useSendbirdStateContext();
  const createChannel = sendbirdSelectors.getCreateGroupChannel(globalStore);

  const [currentChannelUrl, setCurrentChannelUrl] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const getUsersChannel = (channel) => {
    let members = [...channel.members];
    members = members.filter((member) => member.userId != user.uuid);
    const member_ids = members.map((member) => member.userId);
    return member_ids;
  };

  const handleChannelChange = (channel) => {
    if (!channel) {
      return;
    }

    setCurrentChannelUrl(channel?.url);

    const member_ids = getUsersChannel(channel);
    searchParams.set(CHAT_USER_PARAMS, member_ids);
    setSearchParams(searchParams);
  };

  const handleBeforeCreateChannel = (users) => {
    return {
      invitedUserIds: [...users],
      isDistinct: true,
    };
  };

  useEffect(() => {
    const sdk = globalStore.stores.sdkStore.sdk;
    if (!sdk || !sdk.groupChannel) {
      return;
    }

    const chatUserId = searchParams.get(CHAT_USER_PARAMS);
    if (!chatUserId) {
      return;
    }

    const handleCreateChannel = async () => {
      try {
        const params = {
          invitedUserIds: [chatUserId],
          isDistinct: true,
        };

        const channel = await createChannel(params);
        setCurrentChannelUrl(channel?.url);
      } catch (error) {
        console.log('error', error);
      }
    };

    handleCreateChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalStore.stores.sdkStore.sdk]);

  return (
    <>
      <Stack
        direction="row"
        sx={{ height: 'calc(100vh - 105px)' }}
      >
        <GroupChannelList
          onBeforeCreateChannel={handleBeforeCreateChannel}
          onChannelCreated={handleChannelChange}
          onChannelSelect={handleChannelChange}
          selectedChannelUrl={currentChannelUrl}
          disableAutoSelect={!!searchParams.get(CHAT_USER_PARAMS)}
          renderHeader={ChatHeader}
          className={classes.channelList}
        />
        <GroupChannel channelUrl={currentChannelUrl} />
      </Stack>
    </>
  );
};

export default ChatUI;
