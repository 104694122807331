import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import CertificateAutoView from 'commons/CertificateAutoView';
import { useRequestCertificateContext } from 'contexts/RequestCertificateContext';

import GroupButtonBottomCertificate from '../GroupButtonBottomCertificate';

const CertificateInfo = ({ onNextStep, artwork }) => {
  const { artworkInfo } = useRequestCertificateContext();
  const user = useSelector((state) => state.auth.account.user);

  const certificate = {
    edition_number: artworkInfo?.edition?.edition_number,
    issued_by: user,
  };

  return (
    <Box>
      <CertificateAutoView
        artwork={artwork}
        certificate={certificate}
      />
      <GroupButtonBottomCertificate onNextStep={onNextStep} />
    </Box>
  );
};

export default CertificateInfo;
