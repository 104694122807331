import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import SortArtwork from 'commons/FilterArtwork/SortArtwork';
import SearchInputIcon from 'commons/Search/SearchInputIcon';
import Text from 'commons/Text';
import { useSearch } from 'hooks/useSearch';

import useStyles from '../styles';
import SelectedArtworksItem from './SelectedArtworksItem';

const DialogSelectArtworks = ({
  isShowDialog,
  selectedArtworks,
  artworks,
  onCancel,
  onSubmit,
  onScroll,
  onClickSelectArtworkItem,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { search, handleSearch } = useSearch();

  return (
    <DialogCustom
      isShowDialog={isShowDialog}
      dialogTitle={t('add_artworks_to_collection')}
      textCancel={t('cancel')}
      textSubmit={t('add')}
      textBottom={`${selectedArtworks?.length} ${t('selected')}`}
      maxWidth={false}
      PaperProps={{
        className: classes.dialogPaper,
      }}
      onCancel={onCancel}
      onSubmit={onSubmit}
      typeDialogTitle={'xl'}
      submitButtonStyle={clsx(classes.buttonDialog, classes.buttonDanger)}
      cancelButtonStyle={clsx(classes.buttonDialog)}
    >
      <div
        className={classes.wrapDialogContent}
        onScroll={onScroll}
      >
        <Text fontWeightBold>{t('select_artworks')}</Text>
        <div className={classes.wrapSearchAndSort}>
          <SearchInputIcon
            value={search}
            onChange={handleSearch}
            placeholder={t('search_id_artwork_name')}
            wrapStyle={classes.wrapSearchInput}
          />
          <SortArtwork isShowSortPriceMenu={false} />
        </div>

        <Box className={classes.wrapArtworksSelection}>
          {artworks?.pages[0]?.count === 0 ? (
            <div className={classes.wrapTextNoResult}>
              <Text>{t('no_results_that_match_your_search')}</Text>
              <Text>{t('please_try_adjust_your_keywords')}</Text>
            </div>
          ) : (
            <Grid
              container
              spacing={2}
            >
              {artworks?.pages?.map((group, i) => (
                <React.Fragment key={i}>
                  {group.results.map((artwork) => (
                    <Grid
                      item
                      xs={12}
                      lg={2}
                      key={artwork.id}
                    >
                      <SelectedArtworksItem
                        artwork={artwork}
                        selectedArtworks={selectedArtworks}
                        onClick={onClickSelectArtworkItem(artwork)}
                      />
                    </Grid>
                  ))}
                </React.Fragment>
              ))}
            </Grid>
          )}
        </Box>
      </div>
    </DialogCustom>
  );
};

export default DialogSelectArtworks;
