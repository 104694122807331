import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import { ReactComponent as IconButtonRadio } from 'images/icon_button_radio.svg';

import useStyles from '../styles';

const Biography = ({ userProfile }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const trainingBackground = userProfile?.training_background;
  const membership = userProfile?.membership;
  const maxLines = 5;
  const [showMore, setShowMore] = useState(false);

  const isVisibilityBiography =
    userProfile?.year_of_birth || trainingBackground?.length || membership?.length;

  if (!isVisibilityBiography) {
    return null;
  }

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const listBackgroundAndMembership = trainingBackground.concat(membership);
  listBackgroundAndMembership.sort((a, b) => parseInt(a.year) - parseInt(b.year));

  const visibleCombinedList = showMore
    ? listBackgroundAndMembership
    : listBackgroundAndMembership.slice(0, maxLines);

  return (
    <Box className={classes.boxWrapItemsProfile}>
      <IconButtonRadio className={classes.iconButtonRadio} />
      <div className={classes.wrapItemProfile}>
        <Box className={classes.boxItemProfile}>
          <Text
            type="xl"
            fontWeightBold
          >
            {`${userProfile?.name || ''}'s ${t('biography')}`}
          </Text>
          <Box className={classes.wrapListProfile}>
            <div className={classes.wrapListItemProfile}>
              <div className={classes.textYear}>{userProfile?.year_of_birth}</div>
              <div className={classes.wrapDescriptionAndItem}>
                <Text className={classes.textDescription}>{userProfile?.place_of_birth}</Text>
              </div>
            </div>

            {(visibleCombinedList || []).map((item, index) => (
              <div
                key={index}
                className={classes.wrapListItemProfile}
              >
                <div className={classes.textYear}>{item?.year}</div>
                <div className={classes.wrapDescriptionAndItem}>
                  <Text className={classes.textDescription}>{item?.description}</Text>
                </div>
              </div>
            ))}
            {listBackgroundAndMembership.length > maxLines && (
              <div className={classes.textSeeMore}>
                <Text
                  bold
                  onClick={toggleShowMore}
                >
                  {showMore ? t('show_less') : t('see_more')}
                </Text>
              </div>
            )}
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default Biography;
