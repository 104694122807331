import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
  },
  autocompleteSearchUser: {
    '&.MuiAutocomplete-root': {
      '& .MuiOutlinedInput-root': {
        padding: 3.5,
        background: Colors.White,
      },
    },
  },
}));

export default useStyles;
