import { saveAs } from 'file-saver';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ImageCustom from 'commons/ImageCustom';
import { IMAGE_TYPE } from 'configs/Constant';
import { ReactComponent as IconPdf } from 'images/icon_pdf.svg';
import { ToastTopHelper } from 'utils/utils';

import useStyles from './styles';

const ImageAttachmentDisplay = ({ images, keyImages, onClickImageDisplay }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const downloadPDF = (pdfUrl, index) => {
    const pdfFileName = `attachment-certificate-request-${index}.pdf`;

    fetch(pdfUrl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, pdfFileName);
      })
      .catch(() => {
        ToastTopHelper.error(t('error_download_pdf_file'));
      });
  };

  return (
    <div className={classes.wrapImage}>
      {(images || []).map((item, index) => {
        const fileName = keyImages[index];
        const typeFile = typeof fileName === 'string' ? fileName.split('.').pop() : undefined;
        return (
          <div key={index}>
            {typeFile === IMAGE_TYPE.PDF ? (
              <IconPdf
                className={classes.imageStyle}
                onClick={() => downloadPDF(item, index)}
              />
            ) : (
              <ImageCustom
                src={item}
                onClickImageDisplay={onClickImageDisplay}
                imageStyle={classes.imageStyle}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ImageAttachmentDisplay;
