import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  textAwards: {
    fontSize: 24,
  },
  bodyBox: {
    backgroundColor: Colors.Grey1,
    width: '100%',
    padding: 24,
    border: `1px solid ${Colors.Grey3}`,
    marginTop: 16,
  },
  textFieldStyle: {
    '& .MuiInputBase-input': {
      padding: '16px 14px',
      backgroundColor: Colors.White,
    },
  },
  textCanUploadFile: {
    marginTop: 16,
    fontSize: 12,
    color: Colors.Grey8,
  },
  buttonAdd: {
    '&.MuiButton-root': {
      padding: '8px 16px',
      width: 90,
      height: 44,
    },
  },
  wrapButtonAdd: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 16,
  },
  textAddEventAutomatic: {
    marginTop: 8,
    fontSize: 12,
    color: Colors.Grey8,
  },
}));

export default useStyles;
