import { Box, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import ImageQrCode from 'images/image-qr-code.png';

import useStyles from './styles';

const CertificateOwnerInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.wrapper}>
      <Stack direction="row">
        <div className={classes.item}>
          <Text
            className={classes.commonText}
            type="sm"
            fontWeightBold
          >
            {t('property_rights')}
          </Text>

          <Text
            mt={4}
            type="xs"
            className={classes.letterSpacing48}
          >
            {t('text_certificate_property_rights')}
          </Text>
        </div>
      </Stack>

      <img
        className={classes.imageQrCode}
        src={ImageQrCode}
      />
    </Box>
  );
};

export default CertificateOwnerInfo;
