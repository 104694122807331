import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapBoxBtn: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btn: {
    '&.MuiButton-root': {
      padding: '4px 8px',
      marginLeft: 8,
    },
  },
}));

export default useStyles;
