import React from 'react';

import SearchInputIcon from 'commons/Search/SearchInputIcon';
import Text from 'commons/Text';
import { useSearch } from 'hooks/useSearch';

import useStyles from './styles';

const ArtCatalogHeader = ({ title, placeholder }) => {
  const classes = useStyles();

  const { search, handleSearch } = useSearch();

  return (
    <div className={classes.wrapHearder}>
      <Text
        type="2xl"
        fontWeightBold
        className={classes.textArtists}
      >
        {title}
      </Text>

      <SearchInputIcon
        value={search}
        onChange={handleSearch}
        placeholder={placeholder}
      />
    </div>
  );
};

export default ArtCatalogHeader;
