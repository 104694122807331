import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    aspectRatio: '310 / 206',
    gap: 2,
  },
  mainImageBlock: {
    background: Colors.Grey2,
    gridRow: '1 / span 2',
    aspectRatio: '206 / 206',
  },
  sideImageBlock: {
    background: Colors.Grey2,
    aspectRatio: '102 / 102',
  },
  image: {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  noneImage: {
    display: 'none',
  },
}));

export default useStyles;
