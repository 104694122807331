import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LinkRef from 'commons/LinkRef';
import Text from 'commons/Text';

import useStyles from './styles';

const ChatButton = ({ to }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      component={LinkRef}
      to={to}
      variant="outlined"
      fullWidth
      className={classes.button}
      sx={{ mt: 1 }}
    >
      <Text
        fontWeightMedium
        type="md"
        ml={8}
      >
        {t('chat')}
      </Text>
    </Button>
  );
};

export default ChatButton;
