import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import API from 'configs/API';
import { axiosPatch } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

const updateLocation = async (updateData) => {
  const { data } = await axiosPatch(
    false,
    API.ARTWORK.EDITION_UPDATE_STATUS(updateData.id),
    updateData,
  );

  return data;
};

const useUpdateLocation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: updateLocation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [API.ARTWORK.EDITION] });
      ToastTopHelper.success(t('update_successfully'));
    },
  });
};

export default useUpdateLocation;
