import { Box } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import TabPanel from 'commons/TabPanel';

import TableShowAllCollection from '../TableShowAllCollection';
import TableShowPrivateCollection from '../TableShowPrivateCollection';

const TableManager = () => {
  const [searchParams] = useSearchParams();
  const tabValue = Number(searchParams.get('tab') || 0);

  return (
    <Box sx={{ mt: 3 }}>
      <TabPanel
        value={tabValue}
        index={0}
      >
        <TableShowAllCollection />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={1}
      >
        <TableShowPrivateCollection />
      </TabPanel>
    </Box>
  );
};

export default TableManager;
