import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import FormInput from 'commons/Form/FormInput';
import { ARTWORK_FILTER_PARAMS } from 'configs/FilterArtwork';

import useStyles from './styles';

const { YEAR } = ARTWORK_FILTER_PARAMS;

const FilterYearCreation = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const yearParams = searchParams.get(YEAR)?.split(',') || [];

  const [fromYearParam, toYearParam] = yearParams;
  const [fromYear, setFromYear] = useState(fromYearParam || '');
  const [toYear, setToYear] = useState(toYearParam || '');

  const handleFromYearChange = (e) => {
    const { value } = e.target;

    if (isNaN(Number(value))) {
      return;
    }

    setFromYear(value);
  };

  const handleToYearChange = (e) => {
    const { value } = e.target;

    if (isNaN(Number(value))) {
      return;
    }

    setToYear(value);
  };

  const handleApplyFilterPrice = () => {
    const FROM_YEAR = fromYear || 0;
    const TO_YEAR = toYear;

    const year = TO_YEAR ? [FROM_YEAR, TO_YEAR] : [FROM_YEAR];
    searchParams.set(YEAR, year);
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    <div className={classes.wrapper}>
      <FormInput
        value={fromYear}
        onChange={handleFromYearChange}
        label={t('from')}
        placeholder={t('enter_a_year')}
        wrapFormInputStyle={classes.wrapFormInput}
      />
      <FormInput
        value={toYear}
        onChange={handleToYearChange}
        label={t('to')}
        placeholder={t('enter_a_year')}
        wrapFormInputStyle={classes.wrapFormInput}
      />

      <Button
        onClick={handleApplyFilterPrice}
        variant="contained"
        className={classes.button}
      >
        {t('apply')}
      </Button>
    </div>
  );
};

export default FilterYearCreation;
