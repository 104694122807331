import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapArtwork: {
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      '& $wrapInfo': {
        opacity: 1,
      },
      '& $wrapdots': {
        opacity: 0.5,
        visibility: 'visible',
      },
    },
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  line: {
    '&::after': {
      content: '""',
      width: 1,
      height: 12,
      margin: '0px 8px',
      display: 'inline-block',
      background: Colors.Grey7,
    },
    '&:last-child': {
      '&::after': {
        content: '""',
        display: 'none',
      },
    },
  },
  image: {
    height: 360,
  },
  imageLoading: {
    '&.MuiSkeleton-root': {
      borderRadius: 8,
    },
  },
  spacingTop: {
    marginTop: 6,
  },

  // ArtworkItemInfo
  wrapInfo: {
    width: '100%',
    padding: 16,
    paddingTop: 4,
    position: 'absolute',
    left: 0,
    bottom: 0,
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.70)  12.27%, rgba(0, 0, 0, 0.00) 98%)',
    opacity: 0,
    transition: 'all 0.5s',
    zIndex: 0,
  },

  // ArtworkItemPopover
  wrapdots: {
    width: 22,
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 16,
    right: 16,
    borderRadius: 2,
    background: Colors.Grey10,
    opacity: 0,
  },
  dotActive: {
    opacity: '0.5 !important',
  },
  iconDots: {
    '&.MuiSvgIcon-root': {
      width: 12,
      height: 12,
      color: Colors.White,
      zIndex: 999,
    },
  },
  wrapPopoverContent: {
    padding: 8,
    background: Colors.White,
    cursor: 'pointer',
  },
  popover: {
    '& .MuiPopover-paper': {
      marginTop: 8,
      padding: 8,
    },
  },

  buttonDialog: {
    '&.MuiButton-root': {
      minWidth: 90,
      padding: '10px 16px',
      fontSize: '1.6rem',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: 0.64,
    },
  },
  buttonDanger: {
    '&.MuiButton-root': {
      marginLeft: 16,
      background: Colors.Error,
      '&:hover': {
        background: Colors.Error,
      },
    },
  },

  disable: {
    pointerEvents: 'none',
    opacity: 0.5,
    cursor: 'not-allowed',
  },
}));

export default useStyles;
