import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
    flexWrap: 'nowrap',
    gap: 32,
  },
  icon: {
    '&.MuiSvgIcon-root': {
      width: 20,
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
