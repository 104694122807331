import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import FormInput from 'commons/Form/FormInput';
import Text from 'commons/Text';

import { ownerInfoSchema } from './ validate';
import useStyles from './styles';

const DialogOwnerInfo = ({
  isShowDialog,
  disabledButton,
  loading,
  onCancel,
  onSubmit,
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { register, formState, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: yupResolver(ownerInfoSchema),
  });

  const handleSubmitDialog = (data) => {
    onSubmit(data);
  };

  return (
    <DialogCustom
      isShowDialog={isShowDialog}
      disabledButton={disabledButton}
      loading={loading}
      dialogTitle={t('add_owner_name')}
      textSubmit={t('add')}
      onCancel={onCancel}
      onSubmit={handleSubmit(handleSubmitDialog)}
    >
      <FormInput
        {...register('name')}
        isRequired={true}
        label={t('owner_name')}
        placeholder={t('enter_owner_name')}
        error={!!formState?.errors.name}
        helperText={formState?.errors.name?.message}
        labelStyle={classes.formInputLabel}
        textFieldStyle={classes.textFieldStyle}
        wrapFormInputStyle={classes.wrapFormInput}
        {...props}
      />

      <FormInput
        {...register('contactInfo')}
        isRequired={true}
        label={t('email_phone_number')}
        placeholder={t('enter_artist_email_phone_number')}
        error={!!formState?.errors.contactInfo}
        helperText={formState?.errors.contactInfo?.message}
        labelStyle={classes.formInputLabel}
        textFieldStyle={classes.textFieldStyle}
        wrapFormInputStyle={classes.wrapFormInput2}
        {...props}
      />

      <Text
        mt={8}
        type="xs"
      >
        {t('adding_the_owner_name_along')}
      </Text>
    </DialogCustom>
  );
};

export default DialogOwnerInfo;
