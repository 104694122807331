import { FormHelperText, MenuItem, Select } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const FormSelect = ({
  value,
  onChange,
  options = [],
  helperText,
  label,
  labelStyle,
  wrapFormInputStyle,
}) => {
  const classes = useStyles();

  return (
    <div className={wrapFormInputStyle}>
      <Text className={clsx(classes.inputLabel, labelStyle)}>{label}</Text>

      <Select
        value={value}
        onChange={onChange}
        className={classes.select}
        error={!!helperText}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText error>{helperText}</FormHelperText>
    </div>
  );
};

export default FormSelect;
