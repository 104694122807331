import { Container } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';

import Breadcrumbs from 'commons/Breadcrumbs';
import Stepper from 'commons/Stepper';
import Text from 'commons/Text';
import { RequestCertificateProvider } from 'contexts/RequestCertificateContext';
import useStepper from 'hooks/useSteper';
import useArtwork from 'react-query/hooks/queries/useArtwork';
import Routes from 'utils/Route';

import CertificateInfoStep from './components/CertificateInfoStep';
import DeliveryCertificateStep from './components/DeliveryCertificateStep';
import useStyles from './styles';

const CreateCertificate = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const user = useSelector((state) => state.auth.account.user);
  const { id } = useParams();
  const { artwork, isPending } = useArtwork(id);
  const { step, handlBackStep, handleNextStep } = useStepper(1);

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      to: Routes.GalleryDetail(user?.uuid),
      text: t('gallery'),
    },
    {
      text: t('create_certificate'),
      active: true,
    },
  ];

  useEffect(() => {
    return () => {
      reactLocalStorage.remove(`ownerInfo-${id}`);
      reactLocalStorage.remove(`edition-${id}`);
    };
  }, [id]);

  return (
    <main className={classes.wrapPage}>
      <Container>
        <Breadcrumbs items={breadCrumbs} />
        <Text
          variant="h1"
          className={classes.heading}
          type="2xl"
          fontWeightBold
        >
          {t('create_certificate')}
        </Text>

        <RequestCertificateProvider>
          <Stepper active={step === 1}>
            <CertificateInfoStep
              artwork={artwork}
              onNextStep={handleNextStep}
              isPending={isPending}
            />
          </Stepper>

          <Stepper active={step === 2}>
            <DeliveryCertificateStep
              onBackStep={handlBackStep}
              artwork={artwork}
            />
          </Stepper>
        </RequestCertificateProvider>
      </Container>
    </main>
  );
};

export default CreateCertificate;
