import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import CheckBoxCustom from 'commons/CheckBoxCustom';

import useStyles from './styles';

const CheckBoxGroupAlias = ({ onCheckboxChange, listCheckBox, renderText, checked }) => {
  const classes = useStyles();
  const checkBoxRefs = useRef([]);
  const [longestWidth, setLongestWidth] = useState(160);
  const language = useSelector((state) => state?.language?.currentLanguage);

  const getCheckedValue = (checkBoxItem) => {
    const isFunction = typeof checked === 'function';
    if (isFunction) {
      return checked(checkBoxItem);
    }

    return checked;
  };

  useLayoutEffect(() => {
    let max = 160;
    checkBoxRefs.current.forEach((ref) => {
      if (ref) {
        const width = ref.getBoundingClientRect().width;
        if (width > max) {
          max = width;
        }
      }
    });
    setLongestWidth(max);
  }, [listCheckBox, language]);

  return (
    <div className={classes.wrapper}>
      {listCheckBox?.map((checkBoxItem, index) => (
        <div
          key={index}
          ref={(el) => (checkBoxRefs.current[index] = el)}
          style={{
            minWidth: `max(160px, ${longestWidth}px)`,
          }}
        >
          <CheckBoxCustom
            checked={getCheckedValue(checkBoxItem)}
            onChange={() => onCheckboxChange(checkBoxItem)}
            textCheckBox={renderText?.(checkBoxItem)}
          />
        </div>
      ))}
    </div>
  );
};

export default CheckBoxGroupAlias;
