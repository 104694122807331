import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import Text from 'commons/Text';
import API from 'configs/API';
import useUploadImageS3 from 'hooks/useUploadImageS3';
import YearDescriptionInput from 'pages/EditArtistProfile/commons/YearDescriptionInput';
import { isFieldValid, isValidYear } from 'utils/Validation';
import { axiosPatch } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

import ImageListGridEdit from '../ImageListGridEdit';
import UploadImageAward from '../UploadImageAward';
import useStyles from './styles';

const DialogEditAward = ({
  isShowDialog,
  editedYear,
  setEditYear,
  editedDescription,
  setEditDescription,
  setEditedIsPublic,
  editedIsPublic,
  editedListAward,
  setIsShowDialogEdit,
  getListAward,
  selectedAward,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { uploadListImage, generateImageKey } = useUploadImageS3();

  const [listImageUpload, setListImageUpload] = useState([]);
  const [listImageAward, setListImageAward] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [wrongYear, setWrongYear] = useState(false);
  const isYearValid = isFieldValid(editedYear, wrongYear);
  const mergedListImages = (listImageAward || []).concat(listImageUpload);
  const isValid = editedDescription && isYearValid && !isDisabled;
  const listkeyImage = (editedListAward || []).map((item) => item.key_images);

  const handleChangeYear = (e) => {
    const value = e.target.value;
    setWrongYear(!isValidYear(value));
    setEditYear(value);
  };

  const handleChangeDescription = (e) => {
    const value = e.target.value;
    if (value?.length > 200) {
      return;
    }
    setEditDescription(value);
  };

  const handleSwitchChange = (e) => {
    const isHidePrice = e.target.checked;
    setEditedIsPublic(isHidePrice);
  };

  const handleUploadImage = async (e) => {
    const file = e?.target?.files[0];
    if (!file || listImageUpload.length >= 5) {
      return;
    }

    const maxPDFSizeMB = 200;
    const fileSizeMB = file.size / (1024 * 1024);

    if (file?.type === 'application/pdf' && fileSizeMB > maxPDFSizeMB) {
      ToastTopHelper.error(t('file_size_exceeded'));
      return;
    }

    const imageKey = generateImageKey(file);
    const uri = URL.createObjectURL(file);

    const data = {
      uri,
      uploadFile: file,
      imageKey,
    };
    setListImageUpload([...listImageUpload, { ...data }]);
  };

  const handleRemoveImage = (objectImage) => {
    if (objectImage?.uri) {
      const listImageClone = [...listImageUpload];
      const newListImage = listImageClone.filter((item) => {
        if (item?.uri === objectImage?.uri) {
          URL.revokeObjectURL(item?.uri);
        }
        return item?.uri !== objectImage?.uri;
      });
      setListImageUpload(newListImage);
    } else {
      const listImageClone = [...listImageAward];
      const newListImage = listImageClone.filter((item) => {
        return item !== objectImage;
      });
      setListImageAward(newListImage);
    }
  };

  const handleCancelAwardEdit = () => {
    setIsShowDialogEdit(false);
    setListImageAward(listkeyImage);
    setListImageUpload([]);
    setWrongYear(false);
  };

  const updateAward = async () => {
    const imagesKey = mergedListImages.map((item) =>
      typeof item === 'string' ? item : item.imageKey,
    );
    const { success } = await axiosPatch(API.AUTH.AWARD.DETAIL(selectedAward?.id), {
      year: editedYear,
      description: editedDescription,
      is_public: editedIsPublic,
      images: imagesKey,
    });
    if (success) {
      getListAward();
      setIsShowDialogEdit(false);
      setListImageUpload([]);
      ToastTopHelper.success(t('edit_success'));
    }
    setIsDisabled(false);
  };

  const handleSubmitAwardEdit = async () => {
    setIsDisabled(true);
    const uploadS3Response = await uploadListImage(listImageUpload);
    const allSuccessful = uploadS3Response.every((response) => response?.success === true);
    if (allSuccessful) {
      updateAward();
    } else {
      setIsDisabled(false);
      ToastTopHelper.error(t('upload_file_failed'));
    }
  };

  useEffect(() => {
    setListImageAward(listkeyImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedListAward]);

  return (
    <DialogCustom
      isShowDialog={isShowDialog}
      disabledButton={!isValid}
      textCancel={t('cancel')}
      dialogTitle={t('edit_award')}
      textSubmit={t('save')}
      onCancel={handleCancelAwardEdit}
      onSubmit={handleSubmitAwardEdit}
      maxWidth={'md'}
      fullWidth
    >
      <YearDescriptionInput
        year={editedYear}
        description={editedDescription}
        onChangeYear={handleChangeYear}
        onChangeDescription={handleChangeDescription}
        errorYear={wrongYear}
        helperTextYear={wrongYear && t('please_enter_correct_year')}
      />
      <UploadImageAward
        listImage={mergedListImages}
        isChecked={editedIsPublic}
        onUploadImage={handleUploadImage}
        onSwitchChange={handleSwitchChange}
      />
      <Text className={classes.textCanUploadFile}>{t('you_can_upload_file_with_format')}</Text>
      <ImageListGridEdit
        listImageAward={listImageAward}
        listKeyImageAward={mergedListImages}
        onClickRemoveImage={handleRemoveImage}
        editedListAward={editedListAward}
      />
    </DialogCustom>
  );
};

export default DialogEditAward;
