import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import Colors from 'configs/Colors';
import { TESTID } from 'configs/Constant';
import Cube from 'images/cube.png';

import useStyles from './styles';

const ReviewImage = ({ reviewImage }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.wrapBoxReviewImage}>
      {reviewImage ? (
        <img
          src={reviewImage}
          className={classes.reviewImage}
          testid={TESTID.REVIEW_IMAGE}
        />
      ) : (
        <>
          <img src={Cube} />

          <Text
            color={Colors.Grey10}
            fontWeightBold
            mt={16}
            type={'2xl'}
          >
            {t('your_images_will_be_up_here')}
          </Text>

          <Text
            color={Colors.Grey10}
            mt={16}
            type="lg"
          >
            {t('click_to_upload_image')}
          </Text>
        </>
      )}
    </Box>
  );
};

export default ReviewImage;
