import { FormLabel, Stack, TextField } from '@mui/material';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

import useStyles from './styles';

const MeasurementItem = (
  { unit, value, name, onChange, placeholder, isRequired, textFieldStyle, ...props },
  ref,
) => {
  const classes = useStyles();

  return (
    <Stack
      direction={'row'}
      sx={{ flex: 1 }}
    >
      <div className={classes.measureTypeWrap}>
        <FormLabel
          className={classes.measureText}
          required={isRequired}
        >
          {unit}
        </FormLabel>
      </div>

      <TextField
        fullWidth
        value={value}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        className={clsx(classes.textField, textFieldStyle)}
        inputRef={ref}
        autoComplete="off"
        inputProps={{ ...props?.inputProps, 'aria-label': name }}
        {...props}
      />
    </Stack>
  );
};

export default forwardRef(MeasurementItem);
