import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

import BackGroundJoinUs from '../../../../images/background-join-us.png';

const useStyles = makeStyles(() => ({
  textProvideInnovative: {
    marginTop: 34,
    textAlign: 'center',
    color: Colors.Grey10,
    fontSize: '18px',
    fontWeight: 250,
    lineHeight: '28px',
    letterSpacing: '0.72px',
    paddingLeft: 250,
    paddingRight: 250,
  },
  gridOurService: {
    '&.MuiGrid-root': {
      marginTop: 40,
      paddingLeft: 54,
      paddingRight: 45,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  wrapOurService: {
    position: 'relative',
  },
  ourService: {
    width: '100%',
    textAlign: 'center',
    display: 'block',
  },
  centeredText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: Colors.White,
    fontSize: '36px',
    fontWeight: 250,
    lineHeight: '60px',
    letterSpacing: '7.2px',
  },
  relativeContainer: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
  },
  subText: {
    marginTop: 24,
    color: Colors.Grey10,
    fontSize: '18px',
    fontWeight: 250,
    lineHeight: '32px',
    letterSpacing: '0.72px',
    textAlign: 'justify',
  },
  wrapJoinUs: {
    marginTop: 48,
  },

  backgroundJoinUs: {
    marginTop: 40,
    width: '100%',
    backgroundImage: `url(${BackGroundJoinUs})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textJoinUs: {
    textAlign: 'center',
    color: Colors.White,
    fontSize: '28px',
    fontWeight: 250,
    lineHeight: '48px',
    letterSpacing: '1.12px',
    paddingRight: 200,
    paddingLeft: 200,
    paddingTop: 40,
  },
  buttonJoinUs: {
    textAlign: 'center',
    color: Colors.White,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.64px',
    padding: '12px 20px',
    border: `1px solid ${Colors.White}`,
    marginTop: 40,
    marginBottom: 40,
    cursor: 'pointer',
  },

  [mediaQueries.mobile]: {
    textProvideInnovative: {
      padding: 0,
    },
  },
}));

export default useStyles;
