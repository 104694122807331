import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LinkRef from 'commons/LinkRef';
import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import Routes from 'utils/Route';

import useStyles from '../styles';

const ButtonGroup = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wrapButtonGroup}>
      <Button
        component={LinkRef}
        to={Routes.Login}
        variant="outlined"
        className={classes.buttonLogin}
        data-testid={TESTID.BUTTON_LOGIN_PAGE_HOME}
      >
        <Text className={classes.textButton}>{t('login_in')}</Text>
      </Button>
    </div>
  );
};
export default ButtonGroup;
