import { useQuery } from '@tanstack/react-query';

import API from 'configs/API';

const useDropDown = ({ artwork }) => {
  const { data: styleDropdown, isPending: isStylePending } = useQuery({
    queryKey: [API.ARTWORK.FILTER.STYLE, { category: artwork?.category?.key }],
    enabled: !!artwork?.category?.key,
  });

  const { data: subjectDropdown, isPending: isSubjectPending } = useQuery({
    queryKey: [API.ARTWORK.FILTER.SUBJECT, { category: artwork?.category?.key }],
    enabled: !!artwork?.category?.key,
  });

  const { data: mediumDropdown, isPending: isMediumPending } = useQuery({
    queryKey: [API.ARTWORK.FILTER.MEDIUM, { category: artwork?.category?.key }],
    enabled: !!artwork?.category?.key,
  });

  const { data: orientationDropdown, isPending: isOrientationPending } = useQuery({
    queryKey: [API.ARTWORK.FILTER.ORIENTATION, { category: artwork?.category?.key }],
    enabled: !!artwork?.category?.key,
  });

  const isPending = isStylePending || isSubjectPending || isMediumPending || isOrientationPending;

  return { styleDropdown, subjectDropdown, mediumDropdown, orientationDropdown, isPending };
};

export default useDropDown;
