import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapYearSelector: {
    marginTop: 16,
  },
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
    fontWeight: 500,
  },
  datePicker: {
    width: '100%',
  },
  datePickerDisable: {
    backgroundColor: Colors.GrayScale10,
  },
}));

export default useStyles;
