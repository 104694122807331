import { makeStyles } from '@mui/styles';

const buttonBaseStyle = {
  padding: '12px 20px',
  fontSize: '1.6rem',
  fontWeight: 400,
  lineHeight: '150%',
  letterSpacing: 0.64,
};

const useStyles = makeStyles(() => ({
  button: {
    '&.MuiButton-root': {
      ...buttonBaseStyle,
    },
  },
  buttonCancel: {
    '&.MuiButton-root': {
      ...buttonBaseStyle,
      padding: '10px 20px',
      marginTop: 10,
    },
  },
}));

export default useStyles;
