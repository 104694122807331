import * as yup from 'yup';

const currentYear = new Date().getFullYear();

export const editArtistSchema = yup.object().shape({
  artistName: yup.string().required('This field is required').max(50),
  yearOfBirth: yup
    .number()
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
    .typeError('Year of birth must be a number')
    .integer('Year of birth must be an integer')
    .min(1700, 'Year of birth must be after 1700')
    .max(currentYear, `Year of birth cannot exceed ${currentYear}`),
});
