import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid } from '@mui/material';
import Icon from '@mui/material/Icon';
import React from 'react';

import ImageCustom from 'commons/ImageCustom';
import Text from 'commons/Text';
import { IMAGE_TYPE, TESTID } from 'configs/Constant';
import { ReactComponent as IconPdf } from 'images/icon_pdf.svg';

import useStyles from './styles';

const ImageListGrid = ({ listImage, onClickRemoveImage, listUrlImageAward }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={3}
      rowSpacing={1}
    >
      {listImage.map((item, index) => {
        const parts = item.imageKey.split('.');
        const typeFile = parts.pop();
        return (
          <Grid
            key={index}
            item
            lg={6}
            xs={6}
          >
            <div className={classes.listImageItemWrapper}>
              <div className={classes.wrapImageItem}>
                {typeFile === IMAGE_TYPE.PDF ? (
                  <IconPdf className={classes.iconPdf} />
                ) : (
                  <ImageCustom
                    src={item?.uri || listUrlImageAward[index]}
                    imageStyle={classes.imagItem}
                  />
                )}
                <Text className={classes.nameItemImage}>{item?.uploadFile?.name}</Text>
              </div>
              <Icon
                className={classes.removeIcon}
                onClick={() => onClickRemoveImage(item)}
                testid={TESTID.REMOVE_ICON_IMAGE_AWARD}
              >
                <CloseIcon />
              </Icon>
            </div>
            <Divider className={classes.dividerStyle} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ImageListGrid;
