import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DialogCustom from 'commons/DialogCustom';
import Text from 'commons/Text';
import GridLayout from 'layouts/GridLayout';

import MainContentWrapper from '../MainContentWrapper';
import useStyles from './styles';

const UpdateInfoStep = ({
  onNextStep,
  artwork,
  artworkFormState,
  message,
  images,
  keyImages,
  listUploadedImages,
  setSelectedArtist,
  setListUploadedImages,
  mergedListImages,
  setMergedListImages,
  searchAndInviteProps,
  ownerInfo,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [isShowDialog, setIsShowDialog] = useState(false);

  const { handleSubmit } = artworkFormState;

  const handleUpdateArtwork = () => {
    onNextStep();
  };

  const handleCancel = () => {
    setIsShowDialog(true);
  };

  const handleCancelDialog = () => {
    setIsShowDialog(false);
  };

  const handleSubmitlDialog = () => {
    setIsShowDialog(false);
    navigate(-1);
  };

  return (
    <Box sx={{ pt: 3 }}>
      <GridLayout>
        <MainContentWrapper
          artworkFormState={artworkFormState}
          artwork={artwork}
          listUploadedImages={listUploadedImages}
          images={images}
          keyImages={keyImages}
          setSelectedArtist={setSelectedArtist}
          setListUploadedImages={setListUploadedImages}
          mergedListImages={mergedListImages}
          setMergedListImages={setMergedListImages}
          searchAndInviteProps={searchAndInviteProps}
          ownerInfo={ownerInfo}
        />

        <div>
          <Box className={classes.boxRejectReason}>
            <h2 className={classes.textReject}>{t('reject_reason')}</h2>
            <Text className={classes.textMessage}>{message}</Text>
          </Box>
          <Box className={classes.groupActionButtonWrap}>
            <Button
              variant="contained"
              fullWidth
              className={classes.button}
              onClick={handleSubmit(handleUpdateArtwork)}
            >
              {t('text_act_button_next')}
            </Button>
            <Button
              variant="outlined"
              fullWidth
              className={classes.buttonCancel}
              onClick={handleCancel}
            >
              {t('cancel')}
            </Button>
          </Box>
        </div>
      </GridLayout>

      <DialogCustom
        isShowDialog={isShowDialog}
        dialogTitle={t('cancel_request')}
        textCancel={t('continue')}
        textSubmit={t('yes_canccel')}
        onCancel={handleCancelDialog}
        onSubmit={handleSubmitlDialog}
        maxWidth={'md'}
      >
        <div>{t('are_you_sure_exit_certificate')}</div>
      </DialogCustom>
    </Box>
  );
};

export default UpdateInfoStep;
