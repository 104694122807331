import { createTheme } from '@mui/material/styles';

import Colors from 'configs/Colors';

export const theme = createTheme({
  typography: {
    htmlFontSize: 10,
    fontFamily: ['Readex Pro', '-apple-system', 'sans-serif'].join(','),
    fontWeightRegular: 200,
  },
  palette: {
    primary: {
      main: Colors.Primary,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          textTransform: 'capitalize',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          font: 'inherit',
          color: 'inherit',
        },
        asterisk: {
          color: Colors.Red6,
          fontWeight: 500,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 'none',
          boxShadow: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 16,
          textTransform: 'capitalize',
        },
      },
    },
  },
});
