import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  textStatement: {
    wordWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
  textSeeMore: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 14,
    cursor: 'pointer',
    color: Colors.Gray7,
  },
}));

export default useStyles;
