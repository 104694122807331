import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tableCell: {
    '&.MuiTableCell-root': {
      textAlign: 'center',
    },
  },
  iconOutLine: {
    '&.MuiSvgIcon-root': {
      width: '100%',
      cursor: 'pointer',
    },
  },
  popover: {
    '& .MuiPopover-paper': {
      padding: 8,
    },
  },
  textItemPopover: {
    padding: 8,
  },
  disable: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
}));

export default useStyles;
