import { Box, Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TextArea from 'commons/TextArea';
import { TESTID } from 'configs/Constant';

import useStyles from './styles';

const EditStatement = ({ statement, onStatementChange, onCancel, onSave }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box testid={TESTID.BOX_EDIT_STATEMENT}>
      <TextArea
        value={statement}
        onChange={onStatementChange}
        maxLength={201}
        textMaxLength={200}
        placeholder={t('place_holder_statement')}
        helperText={statement?.length >= 201 && t('text_max_length_error', { length: 200 })}
        error={statement?.length >= 201}
        testid={TESTID.TEXT_AREA_STATEMENT}
      />

      <div className={classes.wrapBoxBtn}>
        <Button
          variant="outlined"
          className={classes.btn}
          onClick={onCancel}
          testid={TESTID.BUTTON_CANCEL_EDIT}
        >
          {t('cancel')}
        </Button>

        <Button
          variant="contained"
          className={classes.btn}
          onClick={onSave}
          testid={TESTID.BUTTON_UPDATE_STATEMENT}
        >
          {t('save_changes')}
        </Button>
      </div>
    </Box>
  );
};

export default EditStatement;
