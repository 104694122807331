import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import API from 'configs/API';
import { login, loginFailure } from 'redux/reducer/authSlice';
import { axiosPost } from 'utils/apis/axios';

export const useLogin = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = useCallback(async () => {
    const { success, data } = await axiosPost(API.AUTH.LOGIN, {
      username: username,
      password: password,
    });
    if (success) {
      dispatch(login(data));
    } else {
      dispatch(loginFailure(data));
    }
  }, [dispatch, password, username]);

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return {
    username,
    password,

    setUsername,
    setPassword,

    onKeyDown,
    handleSubmit,
  };
};

export default useLogin;
