import { Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';
import { TESTID } from 'configs/Constant';
import Logo from 'images/logo_platform_text.png';

import useStyles from './styles';

const HeaderRedirect = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div data-testid={TESTID.HEADER_REDICRECT}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Text
          fontWeightBold
          type="H2"
          className={classes.textHeader}
        >
          {t('welcome_to')}
        </Text>

        <img src={Logo} />
      </Stack>

      <Text
        type="lg"
        mt={8}
        className={classes.extraSpacing}
      >
        {t('please_login_your_account_to_view_more')}
      </Text>
    </div>
  );
};

export default HeaderRedirect;
