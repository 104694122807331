import { Button, Stack } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Routes from 'utils/Route';

import useStyles from './styles';

const DeliveryAction = ({ onBackStep, onSubmit }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.account.user);

  const handleBackStep = () => {
    onBackStep();
  };

  const handleClickCancel = () => {
    navigate(Routes.GalleryDetail(user?.uuid));
  };

  return (
    <Stack
      direction={{ sm: 'row', xs: 'column' }}
      className={classes.wrapActionButton}
    >
      <Button
        variant="outlined"
        className={classes.button}
        onClick={handleBackStep}
      >
        {t('back')}
      </Button>

      <Stack
        direction={{ sm: 'row', xs: 'column' }}
        spacing={2}
        sx={{ mt: { sm: 0, xs: 2 } }}
      >
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleClickCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={clsx(classes.button, classes.buttonExtend)}
          onClick={onSubmit}
        >
          {t('validate')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default DeliveryAction;
