import React from 'react';

import Certificate from 'commons/Certificate';
import useResizeCertificate from 'hooks/useResizeCertificate';

const CertificateAutoView = ({ artwork, certificate, scaleMode = 'xAxis', rootStyle }) => {
  const { getRootProps, getParentProps, getCertificateProps, resizeStyle } =
    useResizeCertificate(scaleMode);

  return (
    <div
      {...getRootProps()}
      className={rootStyle}
    >
      <div {...getParentProps()}>
        <Certificate
          {...getCertificateProps()}
          artwork={artwork}
          certificate={certificate}
          wrapperInlineStyle={resizeStyle}
        />
      </div>
    </div>
  );
};

export default CertificateAutoView;
