import { Box, Container } from '@mui/material';
import '@sendbird/uikit-react/dist/index.css';
import React from 'react';

import ChatUI from './components/ChatUI';

const Chat = () => {
  return (
    <Container>
      <Box sx={{ mt: 2 }}>
        <ChatUI />
      </Box>
    </Container>
  );
};

export default Chat;
