import { useMutation } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPost } from 'utils/apis/axios';

const createLocation = async (createData) => {
  const { data } = await axiosPost(false, API.AUTH.LOCATION, createData);
  return data;
};

const useCreateLocation = () => {
  return useMutation({
    mutationFn: createLocation,
  });
};

export default useCreateLocation;
