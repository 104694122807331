import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  divier: {
    '&.MuiDivider-root': {
      marginTop: 16,
      marginBottom: 16,
      background: Colors.Grey3,
      height: 1,
    },
  },
}));

export default useStyles;
