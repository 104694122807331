import { Box, Container } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import GridLayout from 'layouts/GridLayout';
import Routes from 'utils/Route';

import ReviewTagRequestAction from './components/ReviewTagRequestAction';
import ReviewTagRequestMainContent from './components/ReviewTagRequestMainContent';
import useStyles from './styles';

const ArtistReviewTagRequest = ({ data, user }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const isUserRequestRecipient = data?.request_to?.id === user?.id;

  if (!isUserRequestRecipient) {
    navigate(Routes.PageNotFound, { replace: true });
  }

  return (
    <main className={classes.wrapPage}>
      <Container>
        <h1 className={classes.heading}>{t('tagged_request_details')}</h1>

        <Box sx={{ pt: 3 }}>
          <GridLayout>
            <ReviewTagRequestMainContent data={data} />
            <ReviewTagRequestAction data={data} />
          </GridLayout>
        </Box>
      </Container>
    </main>
  );
};

export default ArtistReviewTagRequest;
