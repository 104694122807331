import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import useStyles from './styles';

const ContactUs = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Text className={classes.textContactUs}>{t('contact_us')}</Text>
      <Text className={classes.textForMoreInfo}>{t('for_more_information')}</Text>
      <Grid
        container
        className={classes.gridContactUs}
      >
        <Grid
          item
          xs={12}
          md={3.8}
          className={classes.gridItem}
        >
          <EmailOutlinedIcon />
          <Text className={classes.textGridItem1}>{t('email')}</Text>
          <Text className={classes.textGridItem2}>contact@gladiusart.com</Text>
        </Grid>

        <Grid
          item
          xs={12}
          md={3.8}
          className={classes.gridItem}
        >
          <LocalPhoneOutlinedIcon />
          <Text className={classes.textGridItem1}>{t('phone')}</Text>
          <Text className={classes.textGridItem3}>[+84] 566 024 888</Text>
        </Grid>

        <Grid
          item
          xs={12}
          md={3.8}
          className={classes.gridItem}
        >
          <LocationOnOutlinedIcon />
          <Text className={classes.textGridItem1}>{t('address')}</Text>
          <Text className={classes.textGridItem3}>
            5th floor, Fafilm building, 8 Nguyen Sieu street, Ben Thanh ward, District 1, HCMC,
            Vietnam
          </Text>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactUs;
