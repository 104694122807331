import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormInput from 'commons/Form/FormInput';
import FormCheckBoxController from 'commons/FormHook/FormCheckBoxController';

import useStyles from './styles';

const EditArtworkPriceVisible = ({ artworkFormState }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { control, formState, setValue, watch, register } = artworkFormState;

  const isCheckAskForPrice = watch('isAskPriceVisible');

  const handleCheckPriceVisible = () => {
    setValue('isAskPriceVisible', false);
  };

  const handleCheckAskForPrice = (e) => {
    setValue('isHidePriceVisible', false, { shouldValidate: true });

    if (e.target.checked) {
      setValue('price', '', { shouldValidate: true });
    }
  };

  return (
    <Box>
      <FormCheckBoxController
        name="isHidePriceVisible"
        control={control}
        onChange={handleCheckPriceVisible}
        helperText={formState?.errors.isHidePriceVisible?.message}
        error={formState?.errors.isHidePriceVisible}
        textCheckBox={t('hide_price_only_show_to_me')}
      />

      <FormCheckBoxController
        name="isAskPriceVisible"
        control={control}
        onChange={handleCheckAskForPrice}
        helperText={formState?.errors.isAskPriceVisible?.message}
        error={formState?.errors.isAskPriceVisible}
        textCheckBox={t('ask_for_price')}
      />

      {isCheckAskForPrice && (
        <FormInput
          {...register('contactInformation')}
          label={t('your_contact_information')}
          isRequired
          error={!!formState?.errors.contactInformation}
          helperText={formState?.errors.contactInformation?.message}
          wrapFormInputStyle={classes.formControl}
          labelStyle={classes.labelStyle}
        />
      )}
    </Box>
  );
};

export default EditArtworkPriceVisible;
