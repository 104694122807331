import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  gridAlphabetContainer: {
    '&.MuiGrid-root': {
      marginTop: 15,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  itemAlphabet: {
    width: 40,
    height: 40,
    border: `1px solid ${Colors.Grey3}`,
    cursor: 'pointer',
    backgroundColor: Colors.White,
  },
  active: {
    backgroundColor: Colors.Grey3,
    border: `1px solid ${Colors.Primary}`,
  },

  [mediaQueries.laptop]: {
    itemAlphabet: {
      width: 38,
      height: 38,
    },
  },
  [mediaQueries.tabletLarge]: {
    itemAlphabet: {
      width: 30,
      height: 30,
    },
  },
}));

export default useStyles;
