import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  formControlStyle: {
    '&.MuiFormControl-root': {
      width: '100%',
      marginTop: 2,
    },
    '& .MuiSelect-select': {
      padding: 15,
    },
  },
  textPlacehold: {
    color: Colors.Grey6,
  },
  boxSelect: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
  },
  wrapItemSelected: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${Colors.Grey3}`,
    padding: 4,
  },
  itemSelected: {
    marginRight: 3,
  },
  boxDropdown: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 3,
  },
  wrapSearch: { marginRight: 12, marginLeft: 12, backgroundColor: Colors.Grey2 },
  wrapIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconAdd: {
    '&.MuiSvgIcon-root': {
      width: 24,
      height: 24,
      marginLeft: 10,
    },
  },
  textCreateItem: {
    fontSize: 16,
    marginLeft: 5,
    width: '100%',
  },
  image: {
    height: 32,
    width: 32,
    marginRight: 8,
  },
  imageCustom: {
    height: 32,
    width: 32,
    marginRight: 8,
    backgroundColor: Colors.Grey3,
  },
}));

export default useStyles;
