import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const CommonTag = ({ text, onRemoveFilter }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.wrapper}
      data-group="filter-tag"
    >
      <Text
        type="xs"
        fontWeightMedium
      >
        {text}
      </Text>

      <CloseIcon
        className={classes.closeIcon}
        onClick={onRemoveFilter}
      />
    </div>
  );
};

export default CommonTag;
