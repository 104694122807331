import { Box, Container, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Breadcrumbs from 'commons/Breadcrumbs';
import API from 'configs/API';
import Routes from 'utils/Route';
import { axiosGet } from 'utils/apis/axios';

import ArtCatalogHeader from '../../commons/ArtCatalogHeader';
import FilterAlphabet from './components/FilterAlphabet';
import ListArtist from './components/ListArtist';
import useStyles from './styles';

const Artists = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const [artists, setArtists] = useState([]);

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      text: t('artists'),
      active: true,
    },
  ];

  useEffect(() => {
    const getListArtist = async () => {
      const { success, data } = await axiosGet(API.AUTH.ARTIST.LIST, {
        params: searchParams,
      });
      if (success) {
        setArtists(data);
      }
    };

    getListArtist();
  }, [searchParams]);

  return (
    <Box className={classes.wrapper}>
      <Container>
        <Box sx={{ pt: 3 }}>
          <Breadcrumbs items={breadCrumbs} />

          <Box sx={{ pt: 1 }}>
            <ArtCatalogHeader
              title={t('artist')}
              placeholder={t('search_artist_name')}
            />
            <FilterAlphabet />
            <Divider className={classes.dividerStyle} />
            <ListArtist artists={artists} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Artists;
