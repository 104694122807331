import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  textFieldController: {
    flex: 3,
  },
  selectFormControl: {
    '&.MuiFormControl-root': {
      flex: 1,
      marginTop: 0,
    },
  },
  select: {
    '& .MuiSelect-select': {
      padding: '10px 8px',
    },
  },
}));

export default useStyles;
