import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const TableCellContent = ({ isUnique, contentUnique, contentGeneral, onClickViewDetail }) => {
  const classes = useStyles();

  return (
    <div>
      {isUnique ? (
        <Text className={classes.textDetail}>{contentUnique}</Text>
      ) : (
        <Text
          onClick={onClickViewDetail}
          className={classes.textViewDetail}
        >
          {contentGeneral}
        </Text>
      )}
    </div>
  );
};

export default TableCellContent;
