import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  text3Xl: {
    fontSize: '2.8rem',
    lineHeight: '114.286%',
  },
  text2Xl: {
    fontSize: '2.4rem',
    lineHeight: '133.333%',
  },
  textXl: {
    fontSize: '2rem',
    lineHeight: '150%',
  },
  textLg: {
    fontSize: '1.8rem',
    lineHeight: '155.555%',
  },
  textMd: {
    fontSize: '1.6rem',
    lineHeight: '150%',
  },
  textSm: {
    fontSize: '1.4rem',
    lineHeight: '142.857%',
  },
  textXs: {
    fontSize: '1.2rem',
    lineHeight: '150%',
  },
  text2Xs: {
    fontSize: '1rem',
    lineHeight: '120%',
  },
  fontWeightRegular: {
    fontWeight: 200,
  },
  fontWeightMedium: {
    fontWeight: 400,
  },
  fontWeightBold: {
    fontWeight: 500,
  },
  title: {
    fontFamily: 'Afacad',
    fontWeight: 700,
  },
  clickAble: {
    cursor: 'pointer',
  },
}));

export default useStyles;
