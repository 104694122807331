import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapHearder: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textArtists: {
    letterSpacing: '1.44px',
  },
}));

export default useStyles;
