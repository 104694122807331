import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ImageCustom from 'commons/ImageCustom';
import Text from 'commons/Text';
import API from 'configs/API';
import { STATUS_REQUEST_KEY, USER_ROLE_BY_KEY } from 'configs/Constant';
import Routes from 'utils/Route';
import { axiosGet } from 'utils/apis/axios';

import useStyles from './styles';

const SameCollectionArtworks = ({
  artistId,
  yearCreated,
  artworkId,
  requestToId,
  owner,
  statusTatRequest,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [artwork, setArtwork] = useState([]);

  const getArtWork = async () => {
    let params;

    if (owner?.role === USER_ROLE_BY_KEY.ARTIST) {
      params = {
        user_id: artistId,
        year_created: yearCreated,
        artwork_id: artworkId,
        owner_id: owner?.id,
      };
    } else if (
      owner?.role === USER_ROLE_BY_KEY.COLLECTOR &&
      statusTatRequest === STATUS_REQUEST_KEY.REQUEST_APPROVED
    ) {
      params = {
        user_id: requestToId,
        owner_id: owner?.id,
      };
    }

    const { success, data } = await axiosGet(API.ARTWORK.LIST_ARTWORK_SAME_COLLECTION, { params });
    if (success) {
      setArtwork(data);
    }
  };

  useEffect(() => {
    if (artworkId) {
      getArtWork();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkId]);

  const handleClickArtwork = (item) => {
    navigate(Routes.ArtworkDetail(item?.certificate?.code || item?.id));
  };

  const title =
    owner?.role === USER_ROLE_BY_KEY.ARTIST
      ? t('other_created_year')
      : t('other_works_same_artist');

  if (!artwork?.length) {
    return null;
  }

  return (
    <Box className={classes.wrapBox}>
      <Text
        fontWeightBold
        type="xl"
      >
        {title}
      </Text>
      <div className={classes.wrapListArtwork}>
        <Grid
          container
          spacing={3}
        >
          {artwork.map((item) => (
            <Grid
              key={item?.id}
              item
              xs={12}
              lg={3}
            >
              <ImageCustom
                src={item?.images[0]}
                imageStyle={classes.image}
                onClickImageDisplay={() => handleClickArtwork(item)}
                loadingStyle={classes.imageLoading}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </Box>
  );
};

export default SameCollectionArtworks;
