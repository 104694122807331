import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import Layout from 'commons/Layout';
import Routes from 'utils/Route';
import { isUserInformationVerified } from 'utils/utils';

const PublicRoute = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.account.user);
  const { pathname } = useLocation();

  const isVerifyInformation = isUserInformationVerified(user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (isLoggedIn && !isVerifyInformation) {
    return (
      <Navigate
        to={Routes.VerifyInformation}
        replace
      />
    );
  }

  if (isLoggedIn && isVerifyInformation && !user.role) {
    return (
      <Navigate
        to={Routes.ChooseAccountType}
        replace
      />
    );
  }

  return <Layout />;
};

export default PublicRoute;
