import { useMutation } from '@tanstack/react-query';

import API from 'configs/API';
import { axiosPost } from 'utils/apis/axios';

const savedUser = async (formData) => {
  const { data } = await axiosPost(false, API.AUTH.SAVED_USER, formData);
  return data;
};

const useSavedUser = () => {
  return useMutation({
    mutationFn: savedUser,
  });
};

export default useSavedUser;
