import { Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Text from 'commons/Text';
import API from 'configs/API';
import { TESTID } from 'configs/Constant';
import useLikeCollection from 'hooks/collection/useLikeCollection';
import useUnLikeCollection from 'hooks/collection/useUnLikeCollection';
import { ReactComponent as HitLikeIcon } from 'images/hit_like.svg';
import { ReactComponent as LikeIcon } from 'images/like.svg';
import Routes from 'utils/Route';

import useStyles from './styles';

const CollectionLikeStat = ({ collection, redirectTo }) => {
  const classes = useStyles();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();

  const [isLiked, setIsLiked] = useState(!!collection?.is_user_like);
  const [numberOfLike, setNumberOfLike] = useState(collection?.number_of_likes);
  const [shouldUpdate, setShouldUpdate] = useState(true);

  const { mutate: likeCollection, isPending: isLikePending } = useLikeCollection();
  const { mutate: unLikeCollection, isPending: isUnlikePending } = useUnLikeCollection();
  const queryClient = useQueryClient();

  const isPending = isLikePending || isUnlikePending;

  const handleLikeCollection = () => {
    setIsLiked((prev) => !prev);
    setNumberOfLike((prev) => prev + 1);

    likeCollection(collection?.uuid, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [API.ARTWORK.COLLECTION] });
        queryClient.invalidateQueries({
          queryKey: [API.ARTWORK.COLLECTION_DETAIL(collection?.uuid)],
        });
      },
      onError: () => {
        setIsLiked((prev) => !prev);
        setNumberOfLike((prev) => prev - 1);
      },
    });
  };

  const handleUnLikeCollection = () => {
    setIsLiked((prev) => !prev);
    setNumberOfLike((prev) => prev - 1);

    unLikeCollection(collection?.uuid, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [API.ARTWORK.COLLECTION] });
        queryClient.invalidateQueries({
          queryKey: [API.ARTWORK.COLLECTION_DETAIL(collection?.uuid)],
        });
      },
      onError: () => {
        setIsLiked((prev) => !prev);
        setNumberOfLike((prev) => prev + 1);
      },
    });
  };

  const handleClickLikeButton = (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      navigate(Routes.Login, { state: { redirectTo } });
      return;
    }

    if (isPending) {
      return;
    }

    setShouldUpdate(false);
    isLiked ? handleUnLikeCollection() : handleLikeCollection();
  };

  useEffect(() => {
    if (!shouldUpdate) {
      return;
    }
    setIsLiked(!!collection?.is_user_like);
  }, [collection?.is_user_like, shouldUpdate]);

  useEffect(() => {
    if (!shouldUpdate) {
      return;
    }
    setNumberOfLike(collection?.number_of_likes);
  }, [collection?.number_of_likes, shouldUpdate]);

  return (
    <Stack
      direction="row"
      sx={{ alignItems: 'center' }}
    >
      <div
        className={clsx(classes.likeIconWrapper, { [classes.pending]: isPending })}
        onClick={handleClickLikeButton}
        data-testid={TESTID.LIKE_COLLECTION_WRAPPER}
      >
        {isLiked ? <HitLikeIcon /> : <LikeIcon />}
      </div>

      <Text
        variant="span"
        type="xs"
      >
        {numberOfLike}
      </Text>
    </Stack>
  );
};

export default CollectionLikeStat;
