import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 56,
  },
  gridItem: {
    '&.MuiGrid-root': {
      marginTop: -20,
    },
  },
}));

export default useStyles;
