import { Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TabPanel from 'commons/TabPanel';
import { ARTWORK_FILTER_PARAMS } from 'configs/FilterArtwork';
import useRole from 'hooks/useRole';

import FilterArtist from './FilterType/FilterArtist';
import FilterCategory from './FilterType/FilterCategory';
import FilterCreationYear from './FilterType/FilterCreationYear';
import FilterLocation from './FilterType/FilterLocation';
import FilterMedium from './FilterType/FilterMedium';
import FilterOrientation from './FilterType/FilterOrientation';
import FilterPrice from './FilterType/FilterPrice';
import FilterStatus from './FilterType/FilterStatus';
import FilterStyle from './FilterType/FilterStyle';
import FilterSubject from './FilterType/FilterSubject';
import FilterWrapper from './FilterWrapper';
import useStyles from './styles';

const FilterArtwork = ({ numberFilter }) => {
  const [tabValue, setTabValue] = useState(1);

  const { isCollectorOrGalleryOwner } = useRole();

  const classes = useStyles();
  const { t } = useTranslation();

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={classes.tabs}
      >
        <Tab
          label={t('category')}
          value={1}
          className={classes.tab}
        />
        <Tab
          label={t('style')}
          value={2}
          className={classes.tab}
        />
        <Tab
          label={t('subject')}
          value={3}
          className={classes.tab}
        />
        <Tab
          label={t('medium')}
          value={4}
          className={classes.tab}
        />
        <Tab
          label={t('creation_year')}
          value={5}
          className={classes.tab}
        />
        <Tab
          label={t('price')}
          value={6}
          className={classes.tab}
        />
        {isCollectorOrGalleryOwner && (
          <Tab
            label={t('cost_price')}
            value={7}
            className={classes.tab}
          />
        )}

        <Tab
          label={t('orientation')}
          value={8}
          className={classes.tab}
        />

        <Tab
          label={t('status')}
          value={9}
          className={classes.tab}
        />

        <Tab
          label={t('location')}
          value={10}
          className={classes.tab}
        />

        {isCollectorOrGalleryOwner && (
          <Tab
            label={t('artist')}
            value={11}
            className={classes.tab}
          />
        )}
      </Tabs>

      <TabPanel
        value={tabValue}
        index={1}
      >
        <FilterWrapper
          numberFilter={numberFilter}
          filterType={<FilterCategory />}
        />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={2}
      >
        <FilterWrapper
          numberFilter={numberFilter}
          filterType={<FilterStyle />}
        />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={3}
      >
        <FilterWrapper
          numberFilter={numberFilter}
          filterType={<FilterSubject />}
        />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={4}
      >
        <FilterWrapper
          numberFilter={numberFilter}
          filterType={<FilterMedium />}
        />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={5}
      >
        <FilterWrapper
          numberFilter={numberFilter}
          filterType={<FilterCreationYear />}
        />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={6}
      >
        <FilterWrapper
          numberFilter={numberFilter}
          filterType={<FilterPrice priceParam={ARTWORK_FILTER_PARAMS.PRICE} />}
        />
      </TabPanel>

      {isCollectorOrGalleryOwner && (
        <TabPanel
          value={tabValue}
          index={7}
        >
          <FilterWrapper
            numberFilter={numberFilter}
            filterType={<FilterPrice priceParam={ARTWORK_FILTER_PARAMS.COST_PRICE} />}
          />
        </TabPanel>
      )}

      <TabPanel
        value={tabValue}
        index={8}
      >
        <FilterWrapper
          numberFilter={numberFilter}
          filterType={<FilterOrientation />}
        />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={9}
      >
        <FilterWrapper
          numberFilter={numberFilter}
          filterType={<FilterStatus />}
        />
      </TabPanel>

      <TabPanel
        value={tabValue}
        index={10}
      >
        <FilterWrapper
          numberFilter={numberFilter}
          filterType={<FilterLocation />}
        />
      </TabPanel>

      {isCollectorOrGalleryOwner && (
        <TabPanel
          value={tabValue}
          index={11}
        >
          <FilterWrapper
            numberFilter={numberFilter}
            filterType={<FilterArtist />}
          />
        </TabPanel>
      )}
    </>
  );
};

export default FilterArtwork;
