import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  drawer: {
    '& .MuiDrawer-paper': {
      position: 'static',
    },
  },
  menuItemActive: {
    '&.MuiListItemButton-root': {
      backgroundColor: Colors.Gray3,
    },
  },
}));

export default useStyles;
