import { Button } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useArtworkLikeStatContext } from 'commons/ArtworkLikeStatProvider';
import { ReactComponent as HitLikeIcon } from 'images/hit_like.svg';
import { ReactComponent as LikeIcon } from 'images/like.svg';
import Routes from 'utils/Route';

import useStyles from './styles';

const ArtworkLikeUI = ({ artworkId }) => {
  const classes = useStyles();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const navigate = useNavigate();

  const { isLiked, onClick, isPending } = useArtworkLikeStatContext();

  const handleLike = () => {
    if (!isLoggedIn) {
      navigate(Routes.Login, { state: { redirectTo: Routes.ArtworkDetail(artworkId) } });
      return;
    }
    if (isPending) {
      return;
    }

    onClick();
  };

  return (
    <>
      <Button
        variant="outlined"
        fullWidth
        className={clsx(classes.button, { [classes.pending]: isPending })}
        onClick={handleLike}
      >
        {isLiked ? (
          <HitLikeIcon
            width={24}
            height={24}
          />
        ) : (
          <LikeIcon
            width={24}
            height={24}
          />
        )}
      </Button>
    </>
  );
};

export default ArtworkLikeUI;
