import { Button, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from 'commons/Header';
import LinkRef from 'commons/LinkRef';
import SendbirdProviderGate from 'commons/SendbirdProviderGate';
import Text from 'commons/Text';
import Routes from 'utils/Route';

import useStyles from './style';

const PageNotFound = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <SendbirdProviderGate>
      <Header />

      <Stack
        direction={{
          lg: 'row',
        }}
        sx={{
          justifyContent: {
            lg: 'space-between',
            xs: 'center',
          },
          alignItems: {
            lg: 'center',
          },
        }}
        className={classes.wrap}
      >
        <div className={classes.boxText}>
          <Text
            type="2xl"
            fontWeightBold
            className={classes.textPageNotFound}
          >
            {t('page_not_found')}
          </Text>

          <Text
            type="md"
            mt={8}
          >
            {t('text_page_not_exist')}
          </Text>

          <Button
            component={LinkRef}
            to={Routes.Home}
            variant="contained"
            className={classes.button}
          >
            {t('back_to_home')}
          </Button>
        </div>

        <div>
          <Text className={classes.textMainError}>404</Text>
        </div>
      </Stack>
    </SendbirdProviderGate>
  );
};

export default PageNotFound;
