import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid } from '@mui/material';
import Icon from '@mui/material/Icon';
import React from 'react';

import ImageCustom from 'commons/ImageCustom';
import Text from 'commons/Text';
import { IMAGE_TYPE } from 'configs/Constant';
import { ReactComponent as IconPdf } from 'images/icon_pdf.svg';

import useStyles from './styles';

const ImagesGridEditCertificate = ({ listKeyImage, onClickRemoveImage, images }) => {
  const classes = useStyles();

  const getFileDetails = (item) => {
    let fileKey;
    if (typeof item === 'string') {
      fileKey = item;
    } else if (item?.imageKey) {
      fileKey = item.imageKey;
    }
    const parts = fileKey.split('.');
    const fileType = parts.pop();
    const fileName = `image.${fileType}`;
    return { fileName, fileType };
  };

  return (
    <Grid
      container
      spacing={3}
      rowSpacing={1}
    >
      {listKeyImage.map((item, index) => {
        const { fileName, fileType } = getFileDetails(item);
        const itemName = item?.uploadFile?.name || fileName;

        return (
          <Grid
            key={index}
            item
            lg={6}
            xs={6}
          >
            <div className={classes.listImageItemWrapper}>
              <div className={classes.wrapImageItem}>
                {fileType === IMAGE_TYPE.PDF ? (
                  <IconPdf className={classes.iconPdf} />
                ) : (
                  <ImageCustom
                    src={item?.uri || images[index]}
                    imageStyle={classes.imagItem}
                  />
                )}
                <Text className={classes.nameItemImage}>{itemName}</Text>
              </div>
              <Icon
                className={classes.removeIcon}
                onClick={() => onClickRemoveImage(item)}
              >
                <CloseIcon />
              </Icon>
            </div>
            <Divider className={classes.dividerStyle} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ImagesGridEditCertificate;
