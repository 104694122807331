import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import GroupButtonArtwork from 'commons/GroupButtonArtwork';
import RadioCustom from 'commons/RadioCustom';
import Text from 'commons/Text';
import API from 'configs/API';
import Colors from 'configs/Colors';
import { TESTID } from 'configs/Constant';
import { axiosGet } from 'utils/apis/axios';

import useStyles from './styles';

const ArtworkStepOne = ({ step, setStep, selectedCategory, setSelectCategory }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [categories, setCategories] = useState([]);

  const validateStep = selectedCategory?.key;

  const handleChangeCategory = (item) => {
    setSelectCategory(item);
  };

  const handleNextStep = () => {
    if (!validateStep) {
      return;
    }
    setStep((prevStep) => prevStep + 1);
  };

  useEffect(() => {
    const getCategories = async () => {
      const { success, data } = await axiosGet(API.ARTWORK.CATEOGRY);
      if (success) {
        setCategories(data);
      }
    };

    getCategories();
  }, []);

  return (
    <div>
      <GroupButtonArtwork
        textButtonOne={t('next')}
        onClickButtonOne={handleNextStep}
        disabledButtonOne={!validateStep}
        step={step}
        marginBottomBox={2}
        testid={TESTID.BUTTON_ARTWORK}
      />

      <Box className={classes.wrapBoxContent}>
        <Text
          fontWeightBold
          type="lg"
        >
          {t('category')}
          <span className={classes.require}>*</span>
        </Text>

        <Text
          type={'md'}
          mt={8}
          color={Colors.Grey8}
        >
          {t('text_choose_category')}
        </Text>

        <div className={classes.formControlStyle}>
          {categories.map((item, index) => (
            <RadioCustom
              item={item}
              selectedOption={selectedCategory}
              onOptionChange={() => handleChangeCategory(item)}
              key={index}
              keyLabel={item.key}
              value={t(item.key)}
              testid={TESTID.FORM_INPUT_RADIO}
            />
          ))}
        </div>
      </Box>
    </div>
  );
};

export default ArtworkStepOne;
