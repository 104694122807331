import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapArtistSocial: {
    marginTop: 16,
    justifyContent: 'center',
    display: 'flex',
  },
  socialItem: {
    color: Colors.Primary,
    marginRight: 16,
  },
  icon: {
    display: 'block',
    '&.MuiSvgIcon-root': {
      display: 'block',
    },
  },
}));

export default useStyles;
