import { Box } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const BoxSection = ({ children, heading, sx, wrapperStyle }) => {
  const classes = useStyles();

  return (
    <Box
      sx={{ p: 3, ...sx }}
      className={clsx(classes.wrapper, wrapperStyle)}
    >
      <Text
        type="xl"
        fontWeightBold
        variant="h2"
      >
        {heading}
      </Text>

      {children}
    </Box>
  );
};

export default BoxSection;
