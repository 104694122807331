import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

import Text from 'commons/Text';
import { formatArtworkTitle } from 'utils/utils';

import useStyles from '../styles';

const AddedArtworkItem = ({ artwork, onClick }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.imageContainer}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={onClick}
        />
        <img
          src={artwork.images[0]}
          className={classes.image}
        />
      </div>
      <Text
        fontWeightBold
        className={classes.titleArtwork}
      >
        {formatArtworkTitle(artwork)}
      </Text>
    </div>
  );
};

export default AddedArtworkItem;
