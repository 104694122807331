import { TableBody } from '@mui/material';
import React from 'react';

import TagRequestRow from '../TagRequestRow';

const TableArtistTagRequestBody = ({ listTagrequests, page, pageSize }) => {
  return (
    <TableBody>
      {listTagrequests.map((tagRequest, index) => (
        <TagRequestRow
          key={tagRequest?.id}
          index={index}
          tagRequest={tagRequest}
          page={page}
          pageSize={pageSize}
        />
      ))}
    </TableBody>
  );
};

export default TableArtistTagRequestBody;
