import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapRadioCustom: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
    fontSize: 17,
    color: Colors.Grey10,
    fontWeight: 500,
    fontFamily: 'NuOrder Trial',
    position: 'relative',
    paddingLeft: 25,
  },
  customRadioInput: {
    opacity: 0,
    position: 'absolute',
    width: 20,
    height: 20,
    marginRight: 10,
  },
  customRadioIcon: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: `1px solid ${Colors.Black}`,
    backgroundColor: 'white',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: 'black',
      display: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  customRadioChecked: {
    '& $customRadioIcon::after': {
      display: 'block',
    },
  },
  disabled: {
    opacity: 0.7,
  },
}));

export default useStyles;
