import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormInput from 'commons/Form/FormInput';
import { TESTID } from 'configs/Constant';

import ContentGroup from '../ContentGroup';
import useStyles from './styles';

const ArtistPlacement = ({
  yearOfBirth,
  setYearOfBirth,
  placeOfBirth,
  setPleaceOfBirth,
  errorYearOfBirth,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ContentGroup heading={t('year_of_birth_and_hometown')}>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          lg={3}
        >
          <FormInput
            value={yearOfBirth}
            onChange={(e) => setYearOfBirth(e?.target?.value)}
            label={t('year_of_birth')}
            placeholder={t('enter_a_year')}
            wrapFormInputStyle={classes.wrapFormInput}
            InputProps={{
              inputProps: { type: 'number', step: '1' },
            }}
            error={!!errorYearOfBirth()}
            helperText={errorYearOfBirth()}
            testid={`${TESTID.INPUT_FIELD}_YEAR`}
          />
        </Grid>

        <Grid
          item
          xs={12}
          lg={9}
        >
          <FormInput
            value={placeOfBirth}
            onChange={(e) => setPleaceOfBirth(e?.target?.value)}
            label={t('place_of_birth')}
            placeholder={t('text_placeholder_place_of_birth')}
            wrapFormInputStyle={classes.wrapFormInput}
            testid={`${TESTID.INPUT_FIELD}_PLACE`}
          />
        </Grid>
      </Grid>
    </ContentGroup>
  );
};

export default ArtistPlacement;
