import ClearIcon from '@mui/icons-material/Clear';
import { Avatar } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const InfoUser = ({ user, onClearIcon, isShowClearIcon = true, infoUserStyle }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, infoUserStyle)}>
      <div className={classes.wrapUserInfo}>
        <Avatar
          className={classes.avatar}
          src={user?.avatar}
        />

        <div>
          <div className={classes.WrapNameArtist}>
            <Text
              className={classes.textNameArtist}
              fontWeightBold
            >
              {user?.legal_name}
            </Text>
            {user?.name && <Text>{`[${user?.name}]`}</Text>}
          </div>
          <Text className={classes.textId}>
            {user?.id ? `ID: ${user.id}` : `Email: ${user?.email}`}
          </Text>
        </div>
      </div>

      {isShowClearIcon && (
        <ClearIcon
          onClick={onClearIcon}
          className={classes.iconClear}
        />
      )}
    </div>
  );
};

export default InfoUser;
