import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  require: {
    marginLeft: 6,
    color: Colors.Red6,
    fontSize: 18,
    fontWeight: 500,
  },
  flexItem: {
    flex: 1,
  },
  lineSeparate: {
    marginLeft: 8,
    marginRight: 8,
    transform: 'translateY(10px)',
  },
}));

export default useStyles;
