import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import centeredFlexStyles from 'configs/CommonStyles';

const useStyles = makeStyles(() => ({
  listImageItemWrapper: {
    position: 'relative',
    cursor: 'pointer',
    marginTop: 16,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  wrapImageItem: {
    ...centeredFlexStyles,
  },
  imagItem: {
    width: 45,
    height: 45,
    objectFit: 'cover',
    display: 'block',
    borderRadius: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  nameItemImage: {
    fontSize: 12,
    color: Colors.Grey8,
    marginLeft: 8,
  },
  removeIcon: {
    '&.MuiIcon-root': {
      color: Colors.Black,
      position: 'absolute',
      top: 11,
      right: 4,
    },
  },
  dividerStyle: {
    '&.MuiDivider-root': {
      color: Colors.Grey3,
      marginTop: 16,
      width: '100%',
    },
  },
  iconPdf: {
    width: 45,
    height: 45,
  },
}));

export default useStyles;
