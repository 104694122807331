import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 24,
  },
  tableContainer: {
    overflowX: 'auto',
  },
  pagination: {
    '&.MuiPagination-root': {
      marginTop: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

export default useStyles;
