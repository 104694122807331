import { Container } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

import useCollection from 'react-query/hooks/queries/useCollection';

import CollectionMainContent from './components/CollectionMainContent';

const EditCollection = () => {
  const { id: collectionId } = useParams();
  const { collection, isSuccess } = useCollection(collectionId);

  return (
    <Container>
      {isSuccess && (
        <CollectionMainContent
          collection={collection}
          key={collection?.id}
        />
      )}
    </Container>
  );
};

export default EditCollection;
