import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapBoxSetting: {
    minWidth: 122,
    padding: '4px 8px',
    border: `1px solid ${Colors.Primary}`,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  popover: {
    '& .MuiPopover-paper': {
      marginTop: 8,
      padding: 8,
      minWidth: 195,
    },
  },
  popoverItem: {
    padding: 8,
  },
  textItem: {
    padding: 8,
  },
}));

export default useStyles;
