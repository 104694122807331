import { TableBody } from '@mui/material';
import React from 'react';

import ArtworkRow from './ArtworkRow';

const TableArtworkCollectorBody = ({ listArtwork, page, pageSize }) => {
  return (
    <TableBody>
      {listArtwork.map((artwork, index) => (
        <ArtworkRow
          key={artwork?.id}
          index={index}
          artwork={artwork}
          page={page}
          pageSize={pageSize}
        />
      ))}
    </TableBody>
  );
};

export default TableArtworkCollectorBody;
