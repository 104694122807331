import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  tableRowBorder: {
    borderBottom: `1px solid ${Colors.Grey3}`,
  },
  textCodeCertificate: {
    color: Colors.Gray34,
  },
  statusDenied: {
    '&.MuiTableCell-body': {
      color: Colors.Error,
    },
  },
}));

export default useStyles;
