import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  tableRowBorder: {
    borderBottom: `1px solid ${Colors.Grey3}`,
  },
  wrapIcon: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  iconOutLine: {
    '&.MuiSvgIcon-root': {
      width: 20,
      height: 20,
      cursor: 'pointer',
      marginRight: 45,
    },
  },
  textCodeCertificate: {
    color: Colors.Gray34,
  },
  textViewDetail: {
    textDecoration: 'underline',
    fontSize: 14,
    cursor: 'pointer',
  },
  textDetail: {
    fontSize: 14,
  },
  textItemPopover: {
    fontSize: 14,
    margin: 8,
    cursor: 'pointer',
  },
  textItemDelete: {
    fontSize: 14,
    margin: 8,
    cursor: 'pointer',
    color: 'red',
  },
  disable: {
    pointerEvents: 'none',
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  popover: {
    '& .MuiPopover-paper': {
      padding: 8,
    },
  },
}));

export default useStyles;
