import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TESTID } from 'configs/Constant';

import useUpdateEdition from './hooks/useUpdateEdition';
import useStyles from './styles';

const EditionStatus = ({ editionId, editionStatus, statusOption, editionHasCertificate }) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(editionStatus);
  const { mutate: updateEdition } = useUpdateEdition();
  const classes = useStyles();

  const handleChange = (e) => {
    const { value } = e.target;
    setStatus(value);
    updateEdition({ id: editionId, status: value });
  };

  return (
    <FormControl fullWidth>
      <Select
        value={status}
        onChange={handleChange}
        className={classes.select}
        testid={TESTID.SELECT_OPTION}
        disabled={editionHasCertificate}
      >
        {statusOption?.map((item) => (
          <MenuItem
            key={item?.key}
            value={item?.key}
          >
            {t(item?.key)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EditionStatus;
