import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  wrapTextArea: {
    position: 'relative',
  },
  textArea: {
    width: '100%',
    minHeight: 150,
    padding: 8,
    marginTop: 8,
    fontSize: 12,
    lineHeight: '22px',
    borderRadius: 4,
    border: `1px solid ${Colors.Grey3}`,
  },
  textLengthStatement: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.48px',
    color: Colors.Grey6,
  },
  textAreaError: {
    border: `1px solid ${Colors.Red}`,
    '&:focus': {
      outlineColor: Colors.Red,
    },
  },
}));

export default useStyles;
