import React from 'react';
import { useTranslation } from 'react-i18next';

import Text from 'commons/Text';

import MainFeature1 from '../../../../images/MainFeatures/main_feature1.png';
import MainFeature2 from '../../../../images/MainFeatures/main_feature2.png';
import MainFeature3 from '../../../../images/MainFeatures/main_feature3.png';
import MainFeature4 from '../../../../images/MainFeatures/main_feature4.png';
import MainFeature5 from '../../../../images/MainFeatures/main_feature5.png';
import MainFeature6 from '../../../../images/MainFeatures/main_feature6.png';
import MainFeature7 from '../../../../images/MainFeatures/main_feature7.png';
import MainFeature8 from '../../../../images/MainFeatures/main_feature8.png';
import MainFeature9 from '../../../../images/MainFeatures/main_feature9.png';
import MainFeature10 from '../../../../images/MainFeatures/main_feature10.png';
import MainFeature11 from '../../../../images/MainFeatures/main_feature11.png';
import MainFeature12 from '../../../../images/MainFeatures/main_feature12.png';
import GridMainFeature1 from './GridMainFeature1';
import GridMainFeature2 from './GridMainFeature2';
import useStyles from './styles';

const MainFeatures = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Text className={classes.textDiscover}>{t('discover_the_core_functionalities')}</Text>

      <GridMainFeature1
        xsGridLeft={12}
        mdGridLeft={8}
        xsGridRight={12}
        mdGridRight={4}
        src1={MainFeature1}
        src2={MainFeature2}
        src3={MainFeature3}
        textContent={t('online_gallery_your_artworks')}
        textSubContent={t('showcase_your_createtion')}
        textButton={t('start_exploring')}
      />

      <GridMainFeature2
        xsGridLeft={12}
        mdGridLeft={4}
        xsGridRight={12}
        mdGridRight={8}
        src1={MainFeature4}
        src2={MainFeature5}
        src3={MainFeature6}
        textContent={t('search_and_upload_artworks')}
        textSubContent={t('easy_upload_and_tag')}
        textButton={t('start_exploring')}
        gridStyle={classes.grid}
      />

      <GridMainFeature1
        xsGridLeft={12}
        mdGridLeft={8}
        xsGridRight={12}
        mdGridRight={4}
        src1={MainFeature7}
        src2={MainFeature8}
        src3={MainFeature9}
        textContent={t('create_your_art_certificate')}
        textSubContent={t('use_our_in_platform_certificate')}
        textButton={t('start_exploring')}
        gridStyle={classes.grid}
      />

      <GridMainFeature2
        xsGridLeft={12}
        mdGridLeft={4}
        xsGridRight={12}
        mdGridRight={8}
        src1={MainFeature10}
        src2={MainFeature11}
        src3={MainFeature12}
        textContent={t('manage_your_artworks_and_editions')}
        textSubContent={t('streamline_inventory_management')}
        textButton={t('start_exploring')}
        gridStyle={classes.grid}
      />
    </div>
  );
};

export default MainFeatures;
