import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import IosSwitch from 'commons/IosSwitch';
import Text from 'commons/Text';
import UploadImage from 'commons/UploadImage';

import useStyles from './styles';

const UploadImageAward = ({ listImage, isChecked, onSwitchChange, onUploadImage }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <div className={classes.wrapIosSwitch}>
        <IosSwitch
          isChecked={isChecked}
          onSwitchChange={onSwitchChange}
        />
        <Text
          className={classes.textPublicProfile}
          mt={12}
        >
          {isChecked ? t('public_on_profile') : t('private')}
        </Text>
      </div>
      <UploadImage
        onUploadImage={onUploadImage}
        accept=".png, .jpeg, .jpg, .pdf"
        disabled={listImage.length >= 5}
        uploadWrapStyle={clsx(classes.wrapSmallUpload, {
          [classes.disabledUpload]: listImage.length >= 5,
        })}
      >
        <FileUploadOutlinedIcon className={classes.iconAddImage} />
        <Text
          bold
          className={classes.textUploadMedia}
        >
          {t('upload_media')}
        </Text>
      </UploadImage>
    </div>
  );
};

export default UploadImageAward;
