import { Box } from '@mui/material';
import React from 'react';

import Text from 'commons/Text';

import useStyles from './styles';

const SectionWrapper = ({ heading, children }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.wrapBox}
      name="section-wrapper"
    >
      <Text
        type="2xl"
        fontWeightBold
      >
        {heading}
      </Text>

      {children}
    </Box>
  );
};

export default SectionWrapper;
