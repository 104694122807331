import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';

const useStyles = makeStyles(() => ({
  tabs: {
    '&.MuiTabs-root': {
      minHeight: 'unset',
      marginTop: 16,
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    '&.MuiTab-root': {
      padding: '8px 16px',
      border: `1px solid ${Colors.Primary}`,
      borderBottom: 'none',
      fontSize: '1.4rem',
      fontWeight: 400,
      lineHeight: ' 142.857%',
      letterSpacing: 0.56,
      color: Colors.Primary,
      minHeight: 'unset',
      textTransform: 'none',

      '&.Mui-selected': {
        background: Colors.Grey4,
      },
    },
  },
}));

export default useStyles;
