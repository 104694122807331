import { Container } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Breadcrumbs from 'commons/Breadcrumbs';
import API from 'configs/API';
import useRole from 'hooks/useRole';
import Routes from 'utils/Route';

import InfoCertificateRequest from './components/InfoCertificateRequest';
import useStyles from './styles';

const EditCertificateRequest = () => {
  const { isCollector } = useRole();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.account.user);

  const { data, isPending } = useQuery({
    queryKey: [API.ARTWORK.REVIEW_CERTIFICATE_REQUEST(id)],
  });

  if (isPending) {
    return;
  }

  const isUserRequestBy = data?.request_by?.id === user?.id;
  if (!isUserRequestBy && !isCollector) {
    navigate(Routes.PageNotFound, { replace: true });
  }

  const breadCrumbs = [
    {
      to: Routes.Home,
      text: t('home'),
    },
    {
      to: Routes.ManageCertificate,
      text: t('manage_certificates'),
    },
    {
      text: t('edit_certificate_request'),
      active: true,
    },
  ];

  return (
    <main className={classes.wrapPage}>
      <Container>
        <Breadcrumbs items={breadCrumbs} />
        <h1 className={classes.heading}>{t('edit_certificate_request')}</h1>
        <InfoCertificateRequest data={data} />
      </Container>
    </main>
  );
};

export default EditCertificateRequest;
