import { makeStyles } from '@mui/styles';

import Colors from 'configs/Colors';
import mediaQueries from 'configs/mediaQueries';

const useStyles = makeStyles(() => ({
  wrapper: {
    flex: 1,
    background: Colors.White,
    paddingBottom: 142,
  },
  wrapCol: {
    display: 'flex',
  },

  [mediaQueries.tabletMedium]: {
    wrapCol: {
      display: 'block',
    },
  },
}));

export default useStyles;
