const { useState } = require('react');

const useStepper = (defaultStep = 1) => {
  const [step, setStep] = useState(defaultStep);

  const handleNextStep = () => {
    window.scrollTo(0, 0);
    setStep(step + 1);
  };

  const handlBackStep = () => {
    window.scrollTo(0, 0);
    setStep(step - 1);
  };

  return { step, handleNextStep, handlBackStep };
};

export default useStepper;
