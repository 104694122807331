import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogCustom from 'commons/DialogCustom';
import IosSwitch from 'commons/IosSwitch';
import Text from 'commons/Text';
import API from 'configs/API';
import YearDescriptionInput from 'pages/EditArtistProfile/commons/YearDescriptionInput';
import { isFieldValid, isValidYear } from 'utils/Validation';
import { axiosPatch } from 'utils/apis/axios';
import { ToastTopHelper } from 'utils/utils';

import useStyles from './styles';

const DialogEditPublicationArtist = ({
  isShowDialog,
  editedYear,
  editedDescription,
  editedIsPublic,
  selectedPublication,
  setEditYear,
  setEditDescription,
  setEditedIsPublic,
  setIsShowDialogEdit,
  getListPublication,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [wrongYear, setWrongYear] = useState(false);

  const isYearValid = isFieldValid(editedYear, wrongYear);
  const isFormValid = isYearValid && editedDescription;

  const handleChangeYear = (e) => {
    const value = e.target.value;
    setWrongYear(!isValidYear(value));
    setEditYear(value);
  };

  const handleChangeDescription = (e) => {
    const value = e.target.value;
    if (value?.length > 200) {
      return;
    }
    setEditDescription(value);
  };

  const handleSwitchChange = (e) => {
    const isHidePrice = e.target.checked;
    setEditedIsPublic(isHidePrice);
  };

  const handleCancelPublication = () => {
    setIsShowDialogEdit(false);
    setWrongYear(false);
  };

  const handleSubmitPublication = async () => {
    const { success } = await axiosPatch(API.AUTH.PUBLICATION.DETAIL(selectedPublication?.id), {
      year: editedYear,
      description: editedDescription,
      is_public: editedIsPublic,
    });
    if (success) {
      getListPublication();
      setIsShowDialogEdit(false);
      ToastTopHelper.success(t('edit_success'));
    }
  };
  return (
    <DialogCustom
      isShowDialog={isShowDialog}
      textCancel={t('cancel')}
      dialogTitle={t('edit_publication')}
      textSubmit={t('save')}
      disabledButton={!isFormValid}
      onCancel={handleCancelPublication}
      onSubmit={handleSubmitPublication}
      maxWidth={'md'}
    >
      <YearDescriptionInput
        year={editedYear}
        description={editedDescription}
        onChangeYear={handleChangeYear}
        onChangeDescription={handleChangeDescription}
        errorYear={editedYear && wrongYear}
        helperTextYear={editedYear && wrongYear && t('please_enter_correct_year')}
      />
      <div className={classes.wrapIosSwitch}>
        <IosSwitch
          isChecked={editedIsPublic}
          onSwitchChange={handleSwitchChange}
        />
        <Text
          className={classes.textPublicProfile}
          mt={12}
        >
          {editedIsPublic ? t('public_on_profile') : t('private')}
        </Text>
      </div>
    </DialogCustom>
  );
};

export default DialogEditPublicationArtist;
