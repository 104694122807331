import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapFooter: {
    maxWidth: '33.5%',
  },

  textFooter: {
    fontSize: '0.8rem',
    letterSpacing: '0.32px',
    lineHeight: '150%',
  },
}));

export default useStyles;
