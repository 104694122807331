import { useQuery } from '@tanstack/react-query';

import API from 'configs/API';

const useStatusCanRequestCertificate = () => {
  return useQuery({
    queryKey: [API.ARTWORK.STATUS_CAN_REQUEST_CERTIFICATE],
    placeholderData: [],
  });
};

export default useStatusCanRequestCertificate;
