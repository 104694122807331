import { Table } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePagination from 'hooks/usePagination';
import useArtworks from 'pages/ManageArtwork/hooks/useArtworks';
import { updateFilterResults } from 'redux/reducer/manageArtworkSlice';

import TableWrapper from '../../TableWrapper';
import TableArtistShowAllBody from '../Common/TableArtistShowAllBody';
import TableArtistShowAllHead from '../Common/TableArtistShowAllHead';

const TableShowAllRoleArtist = () => {
  const user = useSelector((state) => state.auth.account.user);
  const { data: artworks } = useArtworks(user?.id);

  const dispatch = useDispatch();

  const count = artworks?.count || 0;
  const pageSize = artworks?.page_size || 10;

  const { page, totalPage, onPageChange } = usePagination(count, pageSize);

  const listArtwork = artworks?.results || [];

  useEffect(() => {
    dispatch(updateFilterResults(count));
  }, [count, dispatch]);

  return (
    <TableWrapper
      page={page}
      totalPage={totalPage}
      onPageChange={onPageChange}
    >
      <Table>
        <TableArtistShowAllHead />
        <TableArtistShowAllBody
          listArtwork={listArtwork}
          page={page}
          pageSize={artworks?.page_size}
        />
      </Table>
    </TableWrapper>
  );
};

export default TableShowAllRoleArtist;
